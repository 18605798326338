import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ddd from './assets/ddd.css'
import { createPinia } from 'pinia'
// import ddd from './assets/ddd.css'
import commonCss from './assets/css/common.css'
import commonJs from './assets/js/common'
import iconfont from './assets/iconfont/iconfont.css'
import cander from './components/cander'
import multipleSelect from './components/multipleSelect'
import model from './components/model'
import page from './components/page'
import axiox from './assets/js/axios'
import myCharts from './assets/js/myCharts.js'
import Datagraph5_Copy from "@/views/dorm/Datagraph/Datagraph5_Copy.vue";
const piania = createPinia()
// Vue.use(ddd)

Vue.use(myCharts)
Vue.component('cander', cander)
Vue.component('page', page)
Vue.component('model', model)
Vue.component('allSelect', multipleSelect)
Vue.component('Datagraph5Copy', Datagraph5_Copy)
Vue.use(piania)
Vue.config.productionTip = false
Vue.prototype.$commonJs = commonJs
Vue.prototype.$axiox = axiox
new Vue({
  router,
  pinia: piania,
  render: h => h(App),
}).$mount('#app')
