<template>
  <div class="Datagraph6">
    <div  style="background-color: rgb(34, 48, 77);border-radius: 10px;margin-top: 15px;padding-left: 15px;height: calc(100% - 40px)">
      <div style="text-align: left;padding: 15px 0">
        <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
          <span class="mag_r-20">开始时间</span>
          <span>
            <div class="xd disp">
          <cander :newWidth="'150px'" class="allInput d_l" v-model="findStartTime"  ></cander>
              <img class="jd" src="../../../assets/img/riqi.png" height="20px" style="margin-left: -27px;margin-top: 5px" alt="">
            </div>
            <!--          <input @change="initRamdomData()" v-model="newStartDate" type="date" class="i_time" />-->
        </span>
        </div>
        <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
          <span class="mag_r-20">结束时间</span>
          <span>
            <div class="disp xd">
          <cander :newWidth="'150px'" class="allInput d_l" :min-date="findStartTime" v-model="fineEndTime" ></cander>
            <img class="jd" src="../../../assets/img/riqi.png" height="20px" style="margin-left: -27px;margin-top: 5px" alt=""></div>
            <!--          <input @change="initRamdomData()" v-model="newEndDate" type="date" class="i_time" />-->
        </span>
        </div>
        <button @click="getTimeTypeHistoryData()" class="btn btn_success mag_l-20 mag_r-20"><span>搜索</span></button>
        <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
          <span style="border-radius: 5px;border: 1px solid #eee;padding: 10px 20px;cursor: pointer" @click="exportExcel" class="mag_r-20">导出数据</span>
        </div>
      </div>
      <div class="row-12">
        <div class="col-6"
       :class="{
          'col-6': (Object.keys(store.devicePartitioningHash[store.currentlySelectedArea])?.length || 0) > 1,
          'col-12': (Object.keys(store.devicePartitioningHash[store.currentlySelectedArea])?.length || 0) <= 1
       }"
        v-for="(item, lineName) of store.devicePartitioningHash[store.currentlySelectedArea]"
         :key="lineName"
        >
          <h2>{{lineName}}</h2>
          <Datagraph5Copy
              ref="chartRef"
              :style="`height: ${(Object.keys(store.devicePartitioningHash[store.currentlySelectedArea])?.length || 0) > 2 ? 'calc(50vh - 130px)' : 'calc(100vh - 210px)'};`"
              :newStartDate="findStartTime"
              :newEndDate="fineEndTime"
              :building="store.currentlySelectedArea"
              :lineCount="Object.keys(store.devicePartitioningHash[store.currentlySelectedArea])?.length || 0"
              :line="lineName" hidden="true" />
        </div>
      </div>
    </div>
  <!--  导出用表格-->
  <!--  <div id="hiddenHistoryList" v-show="false" class="jz_flex"  style="height: 250px;overflow: auto;">-->
  <!--    <table class="allTable bd ba_white wz_black" border="1" cellspacing="0">-->
  <!--      <thead>-->
  <!--      <tr class="bd">-->
  <!--        <th>区域</th>-->
  <!--        <th>线别</th>-->
  <!--        <th>喷砂机</th>-->
  <!--        <th>时间</th>-->
  <!--        <th>开机时长</th>-->
  <!--        <th>停机时长</th>-->
  <!--        <th>离线时长</th>-->
  <!--        &lt;!&ndash;<th>审核状态</th>&ndash;&gt;-->
  <!--      </tr>-->
  <!--      </thead>-->
  <!--      <tbody>-->
  <!--      <tr class="bd" :key="index" v-for="(item, index) in maintain">-->
  <!--        <td>{{ item.Building }}</td>-->
  <!--        <td>{{ item.Line }}</td>-->
  <!--        <td>{{ item.Port }}</td>-->
  <!--        <td>{{ item.Date }}</td>-->
  <!--        <td>{{ item.openTime }}</td>-->
  <!--        <td>{{ item.closeTime }}</td>-->
  <!--        <td>0</td>-->
  <!--        &lt;!&ndash;<td>{{ item.handleStatus }}</td>&ndash;&gt;-->
  <!--      </tr>-->
  <!--      </tbody>-->
  <!--    </table>-->
  <!--  </div>-->
  </div>
</template>
<script setup>
import {useStore} from "@/store/pinia";
import {ref, watch} from "vue";
import dayjs from "dayjs";
import {shakeFun} from "@/assets/js/shakeAndThrottle";
const store = useStore()
// const { proxy } = getCurrentInstance()
const chartRef = ref()
// 4小时一统计
// const timeType = ref(4)
const findStartTime = ref(dayjs().subtract(7, 'day').format('YYYY-MM-DD'))
const fineEndTime = ref(dayjs().format('YYYY-MM-DD'))
const exportExcel = () => {
  chartRef.value[0].exportExcel()
  // let html = '<html><head><meta charset=\'utf-8\' /></head><body>'
  // html += document.getElementById('hiddenHistoryList').innerHTML
  // html += '</body></html>'
  // // 实例化一个Blob对象，其构造函数的第一个参数是包含文件内容的数组，第二个参数是包含文件类型属性的对象
  // let blob = new Blob([html], {
  //   type: 'application/vnd.ms-excel'
  // })
  // let a = document.createElement('a')
  // a.href = URL.createObjectURL(blob)
  // a.download = '喷砂机数据.xls'
  // a.click()
}
const maintain = ref([])
const lineHashDeviceParamsList = ref({})
// 增加防抖
const getTimeTypeHistoryData = shakeFun(async () => {
  console.log(chartRef.value, 'chartRef')
  if (!Object.keys(store.devicePartitioningHash).length) {
    return
  }
  // showLoading.value = true
  // lineHashDeviceParamsList.value = {}
  // maintain.value = []
  // // 查询timeType4， 4小时一次数据接口
  // const promiseAll = []
  // for (const line in store.devicePartitioningHash[store.currentlySelectedArea]) {
  //   console.log(line)
  //   const lineList = store.devicePartitioningHash[store.currentlySelectedArea]
  //   for (const lineName in lineList) {
  //     for (const device of lineList[lineName]) {
  //       // 调用的4小时记录地址(如果更换别的记录地址, 建datagraph5)
  //       const url = '/device/dataOfDeByTime?deviceId=' + device.id + '&startTime=' + findStartTime.value +' 00:00:00&endTime=' + fineEndTime.value +' 23:59:59&t=1635866005546'
  //       // 使用hash取总echarts表总时长
  //       const renderPointAddreddHashMap = (lineItem, pointTimeRecord) => {
  //         lineItem.push(pointTimeRecord)
  //       }
  //       promiseAll.push(proxy.$axiox.post(url).then(({ data }) => {
  //         for (const timeKey in data) {
  //           const openParams = data[timeKey]
  //           // 统计开机时间的hash表
  //           const openTimeHash = {}
  //           for (const openParam of openParams) {
  //             const param = device.templaparams[openParam.pointId] || {}
  //             if (parseInt(param?.penshajiStatus) === 1) {
  //               if (!openTimeHash[openParam.pointId]) {
  //                 openTimeHash[openParam.pointId] = 0
  //               } else {
  //                 openTimeHash[openParam.pointId] += timeType.value
  //               }
  //               // 导出表格数据
  //               maintain.value.push({
  //                 Building: store.currentlySelectedArea,
  //                 Line: lineName,
  //                 Port: openParam.pointId,
  //                 Date: openParam.time,
  //                 openTime: openTimeHash[openParam.pointId],
  //                 closeTime: 24 - openTimeHash[openParam.pointId]
  //               })
  //               // echarts
  //               if (lineName) {
  //                 if (!lineHashDeviceParamsList.value[lineName]) {
  //                   lineHashDeviceParamsList.value[lineName] = []
  //                   renderPointAddreddHashMap(lineHashDeviceParamsList.value[lineName], {
  //                     ...param,
  //                     lineName,
  //                     openTime: openTimeHash[openParam.pointId],
  //                     Date: openParam.time,
  //                     closeTime: 24 - openTimeHash[openParam.pointId],
  //                     pointId: openParam.pointId,
  //                   })
  //                 } else {
  //                   renderPointAddreddHashMap(lineHashDeviceParamsList.value[lineName], {
  //                     ...param,
  //                     lineName,
  //                     openTime: openTimeHash[openParam.pointId],
  //                     Date: openParam.time,
  //                     closeTime: 24 - openTimeHash[openParam.pointId],
  //                     pointId: openParam.pointId,
  //                   })
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }))
  //     }
  //   }
  // }
  // await Promise.all(promiseAll)
  if (chartRef.value && chartRef.value?.length) {
    for (const ref of chartRef.value) {
      ref.getParams()
    }
  }
  // showLoading.value = false
  // console.log(lineHashDeviceParamsList.value, 'lineHashDeviceParamsList', maintain.value)
}, 500)
getTimeTypeHistoryData()
watch(() => store.currentlySelectedArea, getTimeTypeHistoryData)
// // 监听区域列表变化
// watch(() => store.devicePartitioningHash, () => {
//   if (Object.keys(store.devicePartitioningHash)?.length) {
//     getTimeTypeHistoryData()
//   }
// }, { deep: true })
console.log(store.devicePartitioningHash, store.currentlySelectedArea, maintain.value, lineHashDeviceParamsList.value, fineEndTime.value, findStartTime.value, exportExcel)
</script>
<style lang="scss" scoped>
</style>
