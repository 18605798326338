import echarts from 'echarts'
// import 'echarts/map/js/china.js'
const install = function (Vue) {
  Object.defineProperties(Vue.prototype, {
    $chart: {
      get () {
        return {
          line1: function (id) {
            // require('echarts/map/js/china.js')
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            let data = [
              {name: '海门', value: 9},
              {name: '鄂尔多斯', value: 12},
              {name: '招远', value: 12},
              {name: '舟山', value: 12},
              {name: '齐齐哈尔', value: 14},
              {name: '盐城', value: 15},
              {name: '赤峰', value: 16},
              {name: '青岛', value: 18},
              {name: '乳山', value: 18},
              {name: '金昌', value: 19},
              {name: '泉州', value: 21},
              {name: '莱西', value: 21},
              {name: '日照', value: 21},
              {name: '胶南', value: 22},
              {name: '南通', value: 23},
              {name: '拉萨', value: 24},
              {name: '云浮', value: 24},
              {name: '梅州', value: 25},
              {name: '文登', value: 25},
              {name: '上海', value: 25},
              {name: '攀枝花', value: 25},
              {name: '威海', value: 25},
              {name: '承德', value: 25},
              {name: '厦门', value: 26},
              {name: '汕尾', value: 26},
              {name: '潮州', value: 26},
              {name: '丹东', value: 27},
              {name: '太仓', value: 27},
              {name: '曲靖', value: 27},
              {name: '烟台', value: 28},
              {name: '福州', value: 29},
              {name: '瓦房店', value: 30},
              {name: '即墨', value: 30},
              {name: '抚顺', value: 31},
              {name: '玉溪', value: 31},
              {name: '张家口', value: 31},
              {name: '阳泉', value: 31},
              {name: '莱州', value: 32},
              {name: '湖州', value: 32},
              {name: '汕头', value: 32},
              {name: '昆山', value: 33},
              {name: '宁波', value: 33},
              {name: '湛江', value: 33},
              {name: '揭阳', value: 34},
              {name: '荣成', value: 34},
              {name: '连云港', value: 35},
              {name: '葫芦岛', value: 35},
              {name: '常熟', value: 36},
              {name: '东莞', value: 36},
              {name: '河源', value: 36},
              {name: '淮安', value: 36},
              {name: '泰州', value: 36},
              {name: '南宁', value: 37},
              {name: '营口', value: 37},
              {name: '惠州', value: 37},
              {name: '江阴', value: 37},
              {name: '蓬莱', value: 37},
              {name: '韶关', value: 38},
              {name: '嘉峪关', value: 38},
              {name: '广州', value: 38},
              {name: '延安', value: 38},
              {name: '太原', value: 39},
              {name: '清远', value: 39},
              {name: '中山', value: 39},
              {name: '昆明', value: 39},
              {name: '寿光', value: 40},
              {name: '盘锦', value: 40},
              {name: '长治', value: 41},
              {name: '深圳', value: 41},
              {name: '珠海', value: 42},
              {name: '宿迁', value: 43},
              {name: '咸阳', value: 43},
              {name: '铜川', value: 44},
              {name: '平度', value: 44},
              {name: '佛山', value: 44},
              {name: '海口', value: 44},
              {name: '江门', value: 45},
              {name: '章丘', value: 45},
              {name: '肇庆', value: 46},
              {name: '大连', value: 47},
              {name: '临汾', value: 47},
              {name: '吴江', value: 47},
              {name: '石嘴山', value: 49},
              {name: '沈阳', value: 50},
              {name: '苏州', value: 50},
              {name: '茂名', value: 50},
              {name: '嘉兴', value: 51},
              {name: '长春', value: 51},
              {name: '胶州', value: 52},
              {name: '银川', value: 52},
              {name: '张家港', value: 52},
              {name: '三门峡', value: 53},
              {name: '锦州', value: 54},
              {name: '南昌', value: 54},
              {name: '柳州', value: 54},
              {name: '三亚', value: 54},
              {name: '自贡', value: 56},
              {name: '吉林', value: 56},
              {name: '阳江', value: 57},
              {name: '泸州', value: 57},
              {name: '西宁', value: 57},
              {name: '宜宾', value: 58},
              {name: '呼和浩特', value: 58},
              {name: '成都', value: 58},
              {name: '大同', value: 58},
              {name: '镇江', value: 59},
              {name: '桂林', value: 59},
              {name: '张家界', value: 59},
              {name: '宜兴', value: 59},
              {name: '北海', value: 60},
              {name: '西安', value: 61},
              {name: '金坛', value: 62},
              {name: '东营', value: 62},
              {name: '牡丹江', value: 63},
              {name: '遵义', value: 63},
              {name: '绍兴', value: 63},
              {name: '扬州', value: 64},
              {name: '常州', value: 64},
              {name: '潍坊', value: 65},
              {name: '重庆', value: 66},
              {name: '台州', value: 67},
              {name: '南京', value: 67},
              {name: '滨州', value: 70},
              {name: '贵阳', value: 71},
              {name: '无锡', value: 71},
              {name: '本溪', value: 71},
              {name: '克拉玛依', value: 72},
              {name: '渭南', value: 72},
              {name: '马鞍山', value: 72},
              {name: '宝鸡', value: 72},
              {name: '焦作', value: 75},
              {name: '句容', value: 75},
              {name: '北京', value: 79},
              {name: '徐州', value: 79},
              {name: '衡水', value: 80},
              {name: '包头', value: 80},
              {name: '绵阳', value: 80},
              {name: '乌鲁木齐', value: 84},
              {name: '枣庄', value: 84},
              {name: '杭州', value: 84},
              {name: '淄博', value: 85},
              {name: '鞍山', value: 86},
              {name: '溧阳', value: 86},
              {name: '库尔勒', value: 86},
              {name: '安阳', value: 90},
              {name: '开封', value: 90},
              {name: '济南', value: 92},
              {name: '德阳', value: 93},
              {name: '温州', value: 95},
              {name: '九江', value: 96},
              {name: '邯郸', value: 98},
              {name: '临安', value: 99},
              {name: '兰州', value: 99},
              {name: '沧州', value: 100},
              {name: '临沂', value: 103},
              {name: '南充', value: 104},
              {name: '天津', value: 105},
              {name: '富阳', value: 106},
              {name: '泰安', value: 112},
              {name: '诸暨', value: 112},
              {name: '郑州', value: 113},
              {name: '哈尔滨', value: 114},
              {name: '聊城', value: 116},
              {name: '芜湖', value: 117},
              {name: '唐山', value: 119},
              {name: '平顶山', value: 119},
              {name: '邢台', value: 119},
              {name: '德州', value: 120},
              {name: '济宁', value: 120},
              {name: '荆州', value: 127},
              {name: '宜昌', value: 130},
              {name: '义乌', value: 132},
              {name: '丽水', value: 133},
              {name: '洛阳', value: 134},
              {name: '秦皇岛', value: 136},
              {name: '株洲', value: 143},
              {name: '石家庄', value: 147},
              {name: '莱芜', value: 148},
              {name: '常德', value: 152},
              {name: '保定', value: 153},
              {name: '湘潭', value: 154},
              {name: '金华', value: 157},
              {name: '岳阳', value: 169},
              {name: '长沙', value: 175},
              {name: '衢州', value: 177},
              {name: '廊坊', value: 193},
              {name: '菏泽', value: 194},
              {name: '合肥', value: 229},
              {name: '武汉', value: 273},
              {name: '大庆', value: 279}
            ]
            let geoCoordMap = {
              '海门': [121.15, 31.89],
              '鄂尔多斯': [109.781327, 39.608266],
              '招远': [120.38, 37.35],
              '舟山': [122.207216, 29.985295],
              '齐齐哈尔': [123.97, 47.33],
              '盐城': [120.13, 33.38],
              '赤峰': [118.87, 42.28],
              '青岛': [120.33, 36.07],
              '乳山': [121.52, 36.89],
              '金昌': [102.188043, 38.520089],
              '泉州': [118.58, 24.93],
              '莱西': [120.53, 36.86],
              '日照': [119.46, 35.42],
              '胶南': [119.97, 35.88],
              '南通': [121.05, 32.08],
              '拉萨': [91.11, 29.97],
              '云浮': [112.02, 22.93],
              '梅州': [116.1, 24.55],
              '文登': [122.05, 37.2],
              '上海': [121.48, 31.22],
              '攀枝花': [101.718637, 26.582347],
              '威海': [122.1, 37.5],
              '承德': [117.93, 40.97],
              '厦门': [118.1, 24.46],
              '汕尾': [115.375279, 22.786211],
              '潮州': [116.63, 23.68],
              '丹东': [124.37, 40.13],
              '太仓': [121.1, 31.45],
              '曲靖': [103.79, 25.51],
              '烟台': [121.39, 37.52],
              '福州': [119.3, 26.08],
              '瓦房店': [121.979603, 39.627114],
              '即墨': [120.45, 36.38],
              '抚顺': [123.97, 41.97],
              '玉溪': [102.52, 24.35],
              '张家口': [114.87, 40.82],
              '阳泉': [113.57, 37.85],
              '莱州': [119.942327, 37.177017],
              '湖州': [120.1, 30.86],
              '汕头': [116.69, 23.39],
              '昆山': [120.95, 31.39],
              '宁波': [121.56, 29.86],
              '湛江': [110.359377, 21.270708],
              '揭阳': [116.35, 23.55],
              '荣成': [122.41, 37.16],
              '连云港': [119.16, 34.59],
              '葫芦岛': [120.836932, 40.711052],
              '常熟': [120.74, 31.64],
              '东莞': [113.75, 23.04],
              '河源': [114.68, 23.73],
              '淮安': [119.15, 33.5],
              '泰州': [119.9, 32.49],
              '南宁': [108.33, 22.84],
              '营口': [122.18, 40.65],
              '惠州': [114.4, 23.09],
              '江阴': [120.26, 31.91],
              '蓬莱': [120.75, 37.8],
              '韶关': [113.62, 24.84],
              '嘉峪关': [98.289152, 39.77313],
              '广州': [113.23, 23.16],
              '延安': [109.47, 36.6],
              '太原': [112.53, 37.87],
              '清远': [113.01, 23.7],
              '中山': [113.38, 22.52],
              '昆明': [102.73, 25.04],
              '寿光': [118.73, 36.86],
              '盘锦': [122.070714, 41.119997],
              '长治': [113.08, 36.18],
              '深圳': [114.07, 22.62],
              '珠海': [113.52, 22.3],
              '宿迁': [118.3, 33.96],
              '咸阳': [108.72, 34.36],
              '铜川': [109.11, 35.09],
              '平度': [119.97, 36.77],
              '佛山': [113.11, 23.05],
              '海口': [110.35, 20.02],
              '江门': [113.06, 22.61],
              '章丘': [117.53, 36.72],
              '肇庆': [112.44, 23.05],
              '大连': [121.62, 38.92],
              '临汾': [111.5, 36.08],
              '吴江': [120.63, 31.16],
              '石嘴山': [106.39, 39.04],
              '沈阳': [123.38, 41.8],
              '苏州': [120.62, 31.32],
              '茂名': [110.88, 21.68],
              '嘉兴': [120.76, 30.77],
              '长春': [125.35, 43.88],
              '胶州': [120.03336, 36.264622],
              '银川': [106.27, 38.47],
              '张家港': [120.555821, 31.875428],
              '三门峡': [111.19, 34.76],
              '锦州': [121.15, 41.13],
              '南昌': [115.89, 28.68],
              '柳州': [109.4, 24.33],
              '三亚': [109.511909, 18.252847],
              '自贡': [104.778442, 29.33903],
              '吉林': [126.57, 43.87],
              '阳江': [111.95, 21.85],
              '泸州': [105.39, 28.91],
              '西宁': [101.74, 36.56],
              '宜宾': [104.56, 29.77],
              '呼和浩特': [111.65, 40.82],
              '成都': [104.06, 30.67],
              '大同': [113.3, 40.12],
              '镇江': [119.44, 32.2],
              '桂林': [110.28, 25.29],
              '张家界': [110.479191, 29.117096],
              '宜兴': [119.82, 31.36],
              '北海': [109.12, 21.49],
              '西安': [108.95, 34.27],
              '金坛': [119.56, 31.74],
              '东营': [118.49, 37.46],
              '牡丹江': [129.58, 44.6],
              '遵义': [106.9, 27.7],
              '绍兴': [120.58, 30.01],
              '扬州': [119.42, 32.39],
              '常州': [119.95, 31.79],
              '潍坊': [119.1, 36.62],
              '重庆': [106.54, 29.59],
              '台州': [121.420757, 28.656386],
              '南京': [118.78, 32.04],
              '滨州': [118.03, 37.36],
              '贵阳': [106.71, 26.57],
              '无锡': [120.29, 31.59],
              '本溪': [123.73, 41.3],
              '克拉玛依': [84.77, 45.59],
              '渭南': [109.5, 34.52],
              '马鞍山': [118.48, 31.56],
              '宝鸡': [107.15, 34.38],
              '焦作': [113.21, 35.24],
              '句容': [119.16, 31.95],
              '北京': [116.46, 39.92],
              '徐州': [117.2, 34.26],
              '衡水': [115.72, 37.72],
              '包头': [110, 40.58],
              '绵阳': [104.73, 31.48],
              '乌鲁木齐': [87.68, 43.77],
              '枣庄': [117.57, 34.86],
              '杭州': [120.19, 30.26],
              '淄博': [118.05, 36.78],
              '鞍山': [122.85, 41.12],
              '溧阳': [119.48, 31.43],
              '库尔勒': [86.06, 41.68],
              '安阳': [114.35, 36.1],
              '开封': [114.35, 34.79],
              '济南': [117, 36.65],
              '德阳': [104.37, 31.13],
              '温州': [120.65, 28.01],
              '九江': [115.97, 29.71],
              '邯郸': [114.47, 36.6],
              '临安': [119.72, 30.23],
              '兰州': [103.73, 36.03],
              '沧州': [116.83, 38.33],
              '临沂': [118.35, 35.05],
              '南充': [106.110698, 30.837793],
              '天津': [117.2, 39.13],
              '富阳': [119.95, 30.07],
              '泰安': [117.13, 36.18],
              '诸暨': [120.23, 29.71],
              '郑州': [113.65, 34.76],
              '哈尔滨': [126.63, 45.75],
              '聊城': [115.97, 36.45],
              '芜湖': [118.38, 31.33],
              '唐山': [118.02, 39.63],
              '平顶山': [113.29, 33.75],
              '邢台': [114.48, 37.05],
              '德州': [116.29, 37.45],
              '济宁': [116.59, 35.38],
              '荆州': [112.239741, 30.335165],
              '宜昌': [111.3, 30.7],
              '义乌': [120.06, 29.32],
              '丽水': [119.92, 28.45],
              '洛阳': [112.44, 34.7],
              '秦皇岛': [119.57, 39.95],
              '株洲': [113.16, 27.83],
              '石家庄': [114.48, 38.03],
              '莱芜': [117.67, 36.19],
              '常德': [111.69, 29.05],
              '保定': [115.48, 38.85],
              '湘潭': [112.91, 27.87],
              '金华': [119.64, 29.12],
              '岳阳': [113.09, 29.37],
              '长沙': [113, 28.21],
              '衢州': [118.88, 28.97],
              '廊坊': [116.7, 39.53],
              '菏泽': [115.480656, 35.23375],
              '合肥': [117.27, 31.86],
              '武汉': [114.31, 30.52],
              '大庆': [125.03, 46.58]
            }
            let convertData = function (data) {
              let res = []
              for (var i = 0; i < data.length; i++) {
                var geoCoord = geoCoordMap[data[i].name]
                if (geoCoord) {
                  res.push({
                    name: data[i].name,
                    value: geoCoord.concat(data[i].value)
                  })
                }
              }
              return res
            }
            const optionData = {
              // title: {
              //   text: '全国主要城市空气质量 - 百度地图',
              //   subtext: 'data from PM25.in',
              //   sublink: 'http://www.pm25.in',
              //   left: 'center'
              // },
              tooltip: {
                trigger: 'item'
              },
              bmap: {
                center: [104.114129, 37.550339],
                zoom: 5,
                roam: true,
                mapStyle: {
                  styleJson: [{
                    'featureType': 'water',
                    'elementType': 'all',
                    'stylers': {
                      'color': '#d1d1d1'
                    }
                  }, {
                    'featureType': 'land',
                    'elementType': 'all',
                    'stylers': {
                      'color': '#f3f3f3'
                    }
                  }, {
                    'featureType': 'railway',
                    'elementType': 'all',
                    'stylers': {
                      'visibility': 'off'
                    }
                  }, {
                    'featureType': 'highway',
                    'elementType': 'all',
                    'stylers': {
                      'color': '#fdfdfd'
                    }
                  }, {
                    'featureType': 'highway',
                    'elementType': 'labels',
                    'stylers': {
                      'visibility': 'off'
                    }
                  }, {
                    'featureType': 'arterial',
                    'elementType': 'geometry',
                    'stylers': {
                      'color': '#fefefe'
                    }
                  }, {
                    'featureType': 'arterial',
                    'elementType': 'geometry.fill',
                    'stylers': {
                      'color': '#fefefe'
                    }
                  }, {
                    'featureType': 'poi',
                    'elementType': 'all',
                    'stylers': {
                      'visibility': 'off'
                    }
                  }, {
                    'featureType': 'green',
                    'elementType': 'all',
                    'stylers': {
                      'visibility': 'off'
                    }
                  }, {
                    'featureType': 'subway',
                    'elementType': 'all',
                    'stylers': {
                      'visibility': 'off'
                    }
                  }, {
                    'featureType': 'manmade',
                    'elementType': 'all',
                    'stylers': {
                      'color': '#d1d1d1'
                    }
                  }, {
                    'featureType': 'local',
                    'elementType': 'all',
                    'stylers': {
                      'color': '#d1d1d1'
                    }
                  }, {
                    'featureType': 'arterial',
                    'elementType': 'labels',
                    'stylers': {
                      'visibility': 'off'
                    }
                  }, {
                    'featureType': 'boundary',
                    'elementType': 'all',
                    'stylers': {
                      'color': '#fefefe'
                    }
                  }, {
                    'featureType': 'building',
                    'elementType': 'all',
                    'stylers': {
                      'color': '#d1d1d1'
                    }
                  }, {
                    'featureType': 'label',
                    'elementType': 'labels.text.fill',
                    'stylers': {
                      'color': '#999999'
                    }
                  }]
                }
              },
              series: [
                {
                  name: 'pm2.5',
                  type: 'scatter',
                  coordinateSystem: 'bmap',
                  data: convertData(data),
                  symbolSize: function (val) {
                    return val[2] / 10
                  },
                  encode: {
                    value: 2
                  },
                  label: {
                    formatter: '{b}',
                    position: 'right',
                    show: false
                  },
                  emphasis: {
                    label: {
                      show: true
                    }
                  }
                },
                {
                  name: 'Top 5',
                  type: 'effectScatter',
                  coordinateSystem: 'bmap',
                  data: convertData(data.sort(function (a, b) {
                    return b.value - a.value
                  }).slice(0, 6)),
                  symbolSize: function (val) {
                    return val[2] / 10
                  },
                  encode: {
                    value: 2
                  },
                  showEffectOn: 'render',
                  rippleEffect: {
                    brushType: 'stroke'
                  },
                  hoverAnimation: true,
                  label: {
                    formatter: '{b}',
                    position: 'right',
                    show: true
                  },
                  itemStyle: {
                    shadowBlur: 10,
                    shadowColor: '#333'
                  },
                  zlevel: 1
                }
              ]
            }
            this.chart.setOption(optionData)
          },
          line2: function (id, data, totl) {
            // require('echarts/src/chart/pie')
            // require('echarts/lib/component/tooltip')
            // require('echarts/lib/component/title')
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              title: {
                show: true,
                text: '分类\n占比',
                left: 'center',
                top: '42%',
                textStyle: {
                  fontSize: 20,
                  fontWeight: 'normal',
                  color: '#ffffff'
                }
              },
              tooltip: {
                trigger: 'item',
                // formatter: '{a} <br/>{b}: {c} ({d}%)'
                formatter: '{b}: {c} ({d}%)'
              },
              // legend: {
              //   orient: 'vertical',
              //   x: 'center',
              //   y: 'bottom',
              //   itemWidth: 10,
              //   itemHeight: 10,
              //   icon: 'circle',
              //   data: ['已使用', '未使用', '未分配', '1', '2']
              // },
              series: [
                {
                  name: '预算比例',
                  type: 'pie',
                  radius: ['30%', '60%'],
                  center: ['50%', '50%'],
                  label: {
                    formatter (p) {
                      // let zb = '\n' + ((p.value / 5000000) * 100).toFixed(1) + '%'
                      // return (parseFloat(p.value).toFixed(0) + '').replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,') + zb
                      return p.name + ' ' + ((p.value / totl) * 100).toFixed(2) + '%'
                    },
                    show: true
                    // position: 'top'
                  },
                  itemStyle: { // 此配置
                    // normal: {
                    //   borderWidth: 2,
                    //   borderColor: '#ffffff'
                    // },
                    emphasis: {
                      borderWidth: 0,
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                  },
                  // label: {
                  //   show: false,
                  //   position: 'center'
                  // },
                  // emphasis: {
                  //   label: {
                  //     show: true,
                  //     fontSize: '12'
                  //   }
                  // },
                  // labelLine: {
                  //   show: false,
                  //   normal: {
                  //     length: 5,
                  //     position: 'inner'
                  //   }
                  // },
                  // data: data
                  data: data
                }
              ]
            }
            this.chart.setOption(optionData)
          },
          line3: function (id, data, num) {
            let xData = []
            let seriesData = []
            for (let i = 0; i < data.length; i++) {
              if (parseInt(num) === 1) {
                xData.push(data[i].createTime)
              } else {
                xData.push(data[i].createData)
              }
              seriesData.push(data[i].countNum)
            }
            require('echarts/lib/chart/line')
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              // title: {
              //   subtext: type
              // },
              // title: [
              //   {
              //     text: 'Internal NPS ',
              //     left: 0,
              //     textStyle: {
              //       color: '#000',
              //       fontWeight: 'normal',
              //       fontSize: 14
              //     }
              //   }
              //   // {
              //   //   text: internalNPS,
              //   //   left: 100,
              //   //   textStyle: {
              //   //     color: '#525ca3',
              //   //     fontSize: 16
              //   //   }
              //   // }
              // ],
              tooltip: {
                trigger: 'axis',
                // backgroundColor: '#fff',
                // borderColor: '#333',
                // borderWidth: 1,
                textStyle: {
                  color: '#ffffff'
                }
              },
              // legend: {
              //   top: '1%',
              //   right: '10px',
              //   // x: 'right',
              //   // y: 'bottom',
              //   // itemWidth: 10,
              //   // itemHeight: 10,
              //   // icon: 'circle',
              //   selectedMode: false,
              //   data: ['NPS(初版)', 'NPS(终版)']
              // },
              grid: {
                width: 'calc(100% - 50px)',
                top: '40px',
                left: '50px',
                bottom: '30px',
                right: '40px'
                // bottom: '20%'
              },
              xAxis: {
                name: parseInt(num) === 1 ? 'D' : 'Y',
                nameLocation: 'end',
                nameGap: 15,
                nameTextStyle: {
                  color: '#ffffff'
                },
                type: 'category',
                data: xData,
                axisTick: {
                  show: false
                },
                axisLabel: {
                  show: true,
                  color: '#ffffff',
                  fontSize: 10
                }
              },
              yAxis: {
                name: '个',
                nameLocation: 'end',
                nameGap: 15,
                nameTextStyle: {
                  color: '#ffffff'
                },
                show: true,
                axisLine: {
                  show: false
                },
                axisTick: {
                  show: true
                },
                axisLabel: {
                  show: true,
                  color: '#ffffff',
                  fontSize: 10
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: '#304e91',
                    width: 1,
                    type: 'solid'
                  }
                }
              },
              series: [
                {
                  data: seriesData,
                  type: 'line',
                  areaStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {offset: 0, color: '#3ca3f6'},
                        {offset: 0.5, color: 'rgba(60,163,246, 0.5)'},
                        {offset: 1, color: 'rgba(60,163,246, 0)'}
                      ])
                    }
                  },
                  itemStyle: {
                    normal: {
                      color: '#3ca3f6',
                      lineStyle: {
                        color: '#3ca3f6'
                      }
                    }
                  }
                }
                // {
                //   name: 'NPS(终版)',
                //   data: myData[1],
                //   type: 'line',
                //   stack: '总量',
                //   symbol: 'circle',
                //   symbolSize: 20,
                //   lineStyle: {
                //     color: '#b4b331',
                //     width: 1,
                //     type: 'solid'
                //   },
                //   itemStyle: {
                //     borderWidth: 3,
                //     // borderColor: '#b4b331',
                //     color: '#b4b331'
                //     // normal: {
                //     //   color: '#b4b331',
                //     //
                //     // }
                //   },
                //   label: {
                //     show: true,
                //     position: 'inside',
                //     textStyle: {
                //       color: '#fff',
                //       fontSize: 12
                //     }
                //   }
                // }
              ]
            }
            this.chart.setOption(optionData)
          },
          line4: function (id, data, num) {
            let xData = []
            let seriesData = []
            for (let i = 0; i < data.length; i++) {
              if (parseInt(num) === 1) {
                xData.push(data[i].createTime)
              } else {
                xData.push(data[i].createData)
              }
              seriesData.push(data[i].countNum)
            }
            require('echarts/lib/chart/line')
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              // title: {
              //   subtext: type
              // },
              // title: [
              //   {
              //     text: 'Internal NPS ',
              //     left: 0,
              //     textStyle: {
              //       color: '#000',
              //       fontWeight: 'normal',
              //       fontSize: 14
              //     }
              //   }
              //   // {
              //   //   text: internalNPS,
              //   //   left: 100,
              //   //   textStyle: {
              //   //     color: '#525ca3',
              //   //     fontSize: 16
              //   //   }
              //   // }
              // ],
              tooltip: {
                trigger: 'axis',
                // backgroundColor: '#fff',
                // borderColor: '#333',
                // borderWidth: 1,
                textStyle: {
                  color: '#ffffff'
                }
              },
              // legend: {
              //   top: '1%',
              //   right: '10px',
              //   // x: 'right',
              //   // y: 'bottom',
              //   // itemWidth: 10,
              //   // itemHeight: 10,
              //   // icon: 'circle',
              //   selectedMode: false,
              //   data: ['NPS(初版)', 'NPS(终版)']
              // },
              grid: {
                width: 'calc(100% - 50px)',
                top: '40px',
                left: '50px',
                bottom: '30px',
                right: '40px'
                // bottom: '20%'
              },
              xAxis: {
                name: parseInt(num) === 1 ? 'D' : 'Y',
                nameLocation: 'end',
                nameGap: 15,
                nameTextStyle: {
                  color: '#ffffff'
                },
                type: 'category',
                data: xData,
                axisTick: {
                  show: false
                },
                axisLabel: {
                  show: true,
                  color: '#ffffff',
                  fontSize: 10
                }
              },
              yAxis: {
                name: '个',
                nameLocation: 'end',
                nameGap: 15,
                nameTextStyle: {
                  color: '#ffffff'
                },
                show: true,
                axisLine: {
                  show: false
                },
                axisTick: {
                  show: true
                },
                axisLabel: {
                  show: true,
                  color: '#ffffff',
                  fontSize: 10
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: '#304e91',
                    width: 1,
                    type: 'solid'
                  }
                }
              },
              series: [
                {
                  data: seriesData,
                  type: 'line',
                  areaStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {offset: 0, color: '#3bf0c1'},
                        {offset: 0.5, color: 'rgba(59,240,193, 0.5)'},
                        {offset: 1, color: 'rgba(59,240,193, 0)'}
                      ])
                    }
                  },
                  itemStyle: {
                    normal: {
                      color: '#3ca3f6',
                      lineStyle: {
                        color: '#3ca3f6'
                      }
                    }
                  }
                }
                // {
                //   name: 'NPS(终版)',
                //   data: myData[1],
                //   type: 'line',
                //   stack: '总量',
                //   symbol: 'circle',
                //   symbolSize: 20,
                //   lineStyle: {
                //     color: '#b4b331',
                //     width: 1,
                //     type: 'solid'
                //   },
                //   itemStyle: {
                //     borderWidth: 3,
                //     // borderColor: '#b4b331',
                //     color: '#b4b331'
                //     // normal: {
                //     //   color: '#b4b331',
                //     //
                //     // }
                //   },
                //   label: {
                //     show: true,
                //     position: 'inside',
                //     textStyle: {
                //       color: '#fff',
                //       fontSize: 12
                //     }
                //   }
                // }
              ]
            }
            this.chart.setOption(optionData)
          },
          line41: function (id, data, num) {
            let xData = []
            let seriesData = []
            for (let i = 0; i < data.length; i++) {
              if (parseInt(num) === 1) {
                xData.push(data[i].createTime)
              } else {
                xData.push(data[i].createData)
              }
              seriesData.push(data[i].countNum)
            }
            require('echarts/lib/chart/line')
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              // title: {
              //   subtext: type
              // },
              // title: [
              //   {
              //     text: 'Internal NPS ',
              //     left: 0,
              //     textStyle: {
              //       color: '#000',
              //       fontWeight: 'normal',
              //       fontSize: 14
              //     }
              //   }
              //   // {
              //   //   text: internalNPS,
              //   //   left: 100,
              //   //   textStyle: {
              //   //     color: '#525ca3',
              //   //     fontSize: 16
              //   //   }
              //   // }
              // ],
              tooltip: {
                trigger: 'axis',
                // backgroundColor: '#fff',
                // borderColor: '#333',
                // borderWidth: 1,
                textStyle: {
                  color: '#ffffff'
                }
              },
              // legend: {
              //   top: '1%',
              //   right: '10px',
              //   // x: 'right',
              //   // y: 'bottom',
              //   // itemWidth: 10,
              //   // itemHeight: 10,
              //   // icon: 'circle',
              //   selectedMode: false,
              //   data: ['NPS(初版)', 'NPS(终版)']
              // },
              grid: {
                width: 'calc(100% - 50px)',
                top: '40px',
                left: '50px',
                bottom: '30px',
                right: '40px'
                // bottom: '20%'
              },
              xAxis: {
                name: parseInt(num) === 1 ? 'D' : 'Y',
                nameLocation: 'end',
                nameGap: 15,
                nameTextStyle: {
                  color: '#ffffff'
                },
                type: 'category',
                data: xData,
                axisTick: {
                  show: false
                },
                axisLabel: {
                  show: true,
                  color: '#ffffff',
                  fontSize: 10
                }
              },
              yAxis: {
                name: '个',
                nameLocation: 'end',
                nameGap: 15,
                nameTextStyle: {
                  color: '#ffffff'
                },
                show: true,
                axisLine: {
                  show: false
                },
                axisTick: {
                  show: true
                },
                axisLabel: {
                  show: true,
                  color: '#ffffff',
                  fontSize: 10
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: '#304e91',
                    width: 1,
                    type: 'solid'
                  }
                }
              },
              series: [
                {
                  data: seriesData,
                  type: 'line',
                  areaStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {offset: 0, color: '#3bf0c1'},
                        {offset: 0.5, color: 'rgba(59,240,193, 0.5)'},
                        {offset: 1, color: 'rgba(59,240,193, 0)'}
                      ])
                    }
                  },
                  itemStyle: {
                    normal: {
                      color: '#3ca3f6',
                      lineStyle: {
                        color: '#3ca3f6'
                      }
                    }
                  }
                }
                // {
                //   name: 'NPS(终版)',
                //   data: myData[1],
                //   type: 'line',
                //   stack: '总量',
                //   symbol: 'circle',
                //   symbolSize: 20,
                //   lineStyle: {
                //     color: '#b4b331',
                //     width: 1,
                //     type: 'solid'
                //   },
                //   itemStyle: {
                //     borderWidth: 3,
                //     // borderColor: '#b4b331',
                //     color: '#b4b331'
                //     // normal: {
                //     //   color: '#b4b331',
                //     //
                //     // }
                //   },
                //   label: {
                //     show: true,
                //     position: 'inside',
                //     textStyle: {
                //       color: '#fff',
                //       fontSize: 12
                //     }
                //   }
                // }
              ]
            }
            this.chart.setOption(optionData)
          },
          line5: function (id, data) {
            let xData = []
            let fistData = []
            let twoData = []
            for (let i = 0; i < data.length; i++) {
              xData.push(data[i].templateName)
              fistData.push(data[i].numMonth)
              twoData.push(data[i].numYear)
            }
            require('echarts/lib/chart/bar')
            require('echarts/lib/component/tooltip')
            require('echarts/lib/component/title')
            require('echarts/lib/component/legend')
            require('echarts/lib/component/grid')
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              title: {
                // text: '设备',
                left: 'left',
                top: 0,
                textStyle: {
                  fontSize: 14,
                  fontWeight: 'normal',
                  color: '#ffffff'
                }
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow'
                },
                textStyle: {
                  align: 'left'
                }
              },
              legend: {
                data: ['一月', '一年'],
                right: '50px',
                itemWidth: 10,
                itemHeight: 10,
                icon: 'circle',
                textStyle: {
                  color: '#ffffff'
                },
                selectedMode: false // 取消点击事件
              },
              grid: {
                width: 'auto',
                top: '40px',
                left: '60px',
                right: '50px',
                bottom: '30px'
              },
              xAxis: {
                triggerEvent: true,
                type: 'category',
                data: xData,
                axisTick: {
                  show: false
                },
                axisLine: {
                  lineStyle: {
                    color: '#344786',
                    width: 1,
                    type: 'solid'
                  }
                },
                axisLabel: {
                  // width: '20px',
                  // overflow: 'truncate',
                  show: true,
                  color: '#ffffff',
                  fontSize: 10,
                  formatter (p) {
                    var res = p
                    if (res.length > 5) {
                      res = res.substring(0, 4) + '...'
                    }
                    return res
                  }
                }
              },
              yAxis: {
                name: '设备',
                nameLocation: 'end',
                nameGap: 15,
                nameTextStyle: {
                  color: '#ffffff'
                },
                show: true,
                axisLine: {
                  show: false
                },
                axisTick: {
                  show: false
                },
                axisLabel: {
                  show: true,
                  color: '#ffffff',
                  fontSize: 10
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: '#344786',
                    width: 1,
                    type: 'solid'
                  }
                }
              },
              series: [
                {
                  name: '一月',
                  data: fistData,
                  type: 'bar',
                  itemStyle: {
                    normal: {
                      // 每根柱子颜色设置
                      color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                        offset: 0,
                        color: '#53a9fc' // 0% 处的颜色
                      }, {
                        offset: 1,
                        color: '#4978f8' // 100% 处的颜色
                      }], false)
                    }
                  },
                  barWidth: 12
                },
                {
                  name: '一年',
                  data: twoData,
                  type: 'bar',
                  itemStyle: {
                    normal: {
                      // 每根柱子颜色设置
                      color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                        offset: 0,
                        color: '#e2724d' // 0% 处的颜色
                      }, {
                        offset: 1,
                        color: '#de4128' // 100% 处的颜色
                      }], false)
                    }
                  },
                  barWidth: 12
                }
              ]
            }
            this.chart.setOption(optionData)
          },
          line6: function (id, data, num) {
            let xData = []
            let seriesData = []
            for (let i = 0; i < data.length; i++) {
              if (parseInt(num) === 1) {
                xData.push(data[i].time)
              } else {
                xData.push(data[i].createData)
              }
              // * 0.997
              seriesData.push(parseFloat(data[i].elcNum).toFixed(2))
            }
            require('echarts/lib/chart/line')
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              // title: {
              //   subtext: type
              // },
              // title: [
              //   {
              //     text: 'Internal NPS ',
              //     left: 0,
              //     textStyle: {
              //       color: '#000',
              //       fontWeight: 'normal',
              //       fontSize: 14
              //     }
              //   }
              //   // {
              //   //   text: internalNPS,
              //   //   left: 100,
              //   //   textStyle: {
              //   //     color: '#525ca3',
              //   //     fontSize: 16
              //   //   }
              //   // }
              // ],
              tooltip: {
                trigger: 'axis',
                // backgroundColor: '#fff',
                // borderColor: '#333',
                // borderWidth: 1,
                textStyle: {
                  color: '#ffffff'
                }
              },
              // legend: {
              //   top: '1%',
              //   right: '10px',
              //   // x: 'right',
              //   // y: 'bottom',
              //   // itemWidth: 10,
              //   // itemHeight: 10,
              //   // icon: 'circle',
              //   selectedMode: false,
              //   data: ['NPS(初版)', 'NPS(终版)']
              // },
              grid: {
                width: 'calc(100% - 50px)',
                top: '50px',
                left: '80px',
                bottom: '30px',
                right: '40px'
                // bottom: '20%'
              },
              xAxis: {
                name: parseInt(num) === 1 ? 'D' : 'Y',
                nameLocation: 'end',
                nameGap: 15,
                nameTextStyle: {
                  color: '#ffffff'
                },
                type: 'category',
                data: xData,
                axisTick: {
                  show: false
                },
                axisLabel: {
                  show: true,
                  color: '#ffffff',
                  fontSize: 10
                }
              },
              yAxis: {
                name: '消耗电量 Kw/h',
                nameLocation: 'end',
                nameGap: 15,
                nameTextStyle: {
                  color: '#ffffff'
                },
                show: true,
                axisLine: {
                  show: false
                },
                axisTick: {
                  show: true
                },
                axisLabel: {
                  show: true,
                  color: '#ffffff',
                  fontSize: 10
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: '#304e91',
                    width: 1,
                    type: 'solid'
                  }
                }
              },
              series: [
                {
                  data: seriesData,
                  type: 'line',
                  areaStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {offset: 0, color: '#3ca3f6'},
                        {offset: 0.5, color: '#3970b7'},
                        {offset: 1, color: '#112e68'}
                      ])
                    }
                  },
                  itemStyle: {
                    normal: {
                      color: '#3ca3f6',
                      lineStyle: {
                        color: '#3ca3f6'
                      }
                    }
                  }
                }
                // {
                //   name: 'NPS(终版)',
                //   data: myData[1],
                //   type: 'line',
                //   stack: '总量',
                //   symbol: 'circle',
                //   symbolSize: 20,
                //   lineStyle: {
                //     color: '#b4b331',
                //     width: 1,
                //     type: 'solid'
                //   },
                //   itemStyle: {
                //     borderWidth: 3,
                //     // borderColor: '#b4b331',
                //     color: '#b4b331'
                //     // normal: {
                //     //   color: '#b4b331',
                //     //
                //     // }
                //   },
                //   label: {
                //     show: true,
                //     position: 'inside',
                //     textStyle: {
                //       color: '#fff',
                //       fontSize: 12
                //     }
                //   }
                // }
              ]
            }
            this.chart.setOption(optionData)
          },
          line7: function (id, data, num) {
            let xData = []
            let seriesData = []
            for (let i = 0; i < data.length; i++) {
              if (parseInt(num) === 1) {
                xData.push(data[i].time)
              } else {
                xData.push(data[i].createData)
              }
              seriesData.push(data[i].num)
            }
            require('echarts/lib/chart/line')
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              // title: {
              //   subtext: type
              // },
              // title: [
              //   {
              //     text: 'Internal NPS ',
              //     left: 0,
              //     textStyle: {
              //       color: '#000',
              //       fontWeight: 'normal',
              //       fontSize: 14
              //     }
              //   }
              //   // {
              //   //   text: internalNPS,
              //   //   left: 100,
              //   //   textStyle: {
              //   //     color: '#525ca3',
              //   //     fontSize: 16
              //   //   }
              //   // }
              // ],
              tooltip: {
                trigger: 'axis',
                // backgroundColor: '#fff',
                // borderColor: '#333',
                // borderWidth: 1,
                textStyle: {
                  color: '#ffffff'
                }
              },
              // legend: {
              //   top: '1%',
              //   right: '10px',
              //   // x: 'right',
              //   // y: 'bottom',
              //   // itemWidth: 10,
              //   // itemHeight: 10,
              //   // icon: 'circle',
              //   selectedMode: false,
              //   data: ['NPS(初版)', 'NPS(终版)']
              // },
              grid: {
                width: 'calc(100% - 50px)',
                top: '40px',
                left: '50px',
                bottom: '30px',
                right: '40px'
              },
              xAxis: {
                name: parseInt(num) === 1 ? 'D' : 'Y',
                nameLocation: 'end',
                nameGap: 15,
                nameTextStyle: {
                  color: '#ffffff'
                },
                type: 'category',
                data: xData,
                axisTick: {
                  show: false
                },
                axisLabel: {
                  show: true,
                  color: '#ffffff',
                  fontSize: 10
                }
              },
              yAxis: {
                name: '个',
                nameLocation: 'end',
                nameGap: 15,
                nameTextStyle: {
                  color: '#ffffff'
                },
                show: true,
                axisLine: {
                  show: false
                },
                axisTick: {
                  show: true
                },
                axisLabel: {
                  show: true,
                  color: '#ffffff',
                  fontSize: 10
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: '#304e91',
                    width: 1,
                    type: 'solid'
                  }
                }
              },
              series: [
                {
                  data: seriesData,
                  type: 'line',
                  areaStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {offset: 0, color: '#d44061'},
                        {offset: 0.5, color: 'rgba(212,64,97, 0.5)'},
                        {offset: 1, color: 'rgba(212,64,97, 0)'}
                      ])
                    }
                  },
                  itemStyle: {
                    normal: {
                      color: '#3ca3f6',
                      lineStyle: {
                        color: '#3ca3f6'
                      }
                    }
                  }
                }
                // {
                //   name: 'NPS(终版)',
                //   data: myData[1],
                //   type: 'line',
                //   stack: '总量',
                //   symbol: 'circle',
                //   symbolSize: 20,
                //   lineStyle: {
                //     color: '#b4b331',
                //     width: 1,
                //     type: 'solid'
                //   },
                //   itemStyle: {
                //     borderWidth: 3,
                //     // borderColor: '#b4b331',
                //     color: '#b4b331'
                //     // normal: {
                //     //   color: '#b4b331',
                //     //
                //     // }
                //   },
                //   label: {
                //     show: true,
                //     position: 'inside',
                //     textStyle: {
                //       color: '#fff',
                //       fontSize: 12
                //     }
                //   }
                // }
              ]
            }
            this.chart.setOption(optionData)
          },
          line8: function (id, data, totle) {
            let xData = []
            let fistData = []
            for (let i = 0; i < data.length; i++) {
              xData.push(data[i].time)
              for (let j = 0; j < totle.length; j++) {
                if (parseInt(data[i].time) === parseInt(totle[j].time)) {
                  fistData.push(parseFloat(parseFloat(parseFloat(totle[j].elcNum) - parseFloat(data[i].elcNum)) * 0.997).toFixed(2))
                }
              }
            }
            console.log(data)
            console.log(totle)
            console.log(fistData)
            require('echarts/lib/chart/bar')
            require('echarts/lib/component/tooltip')
            require('echarts/lib/component/title')
            require('echarts/lib/component/legend')
            require('echarts/lib/component/grid')
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              title: {
                text: '碳排放 kg',
                left: 'left',
                top: 0,
                textStyle: {
                  fontSize: 14,
                  fontWeight: 'normal',
                  color: '#ffffff'
                }
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow'
                },
                textStyle: {
                  align: 'left'
                }
              },
              legend: {
                data: ['一月'],
                right: '50px',
                itemWidth: 10,
                itemHeight: 10,
                icon: 'circle',
                textStyle: {
                  color: '#ffffff'
                },
                selectedMode: false // 取消点击事件
              },
              grid: {
                width: 'auto',
                top: '40px',
                left: '100px',
                right: '50px',
                bottom: '30px'
              },
              xAxis: {
                type: 'category',
                data: xData,
                axisTick: {
                  show: false
                },
                axisLine: {
                  lineStyle: {
                    color: '#344786',
                    width: 1,
                    type: 'solid'
                  }
                },
                axisLabel: {
                  show: true,
                  color: '#ffffff',
                  fontSize: 10,
                  formatter (p) {
                    var res = p
                    if (res.length > 5) {
                      res = res.substring(0, 4) + '...'
                    }
                    return res
                  }
                }
              },
              yAxis: {
                show: true,
                axisLine: {
                  show: false
                },
                axisTick: {
                  show: false
                },
                axisLabel: {
                  show: true,
                  color: '#ffffff',
                  fontSize: 10
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: '#344786',
                    width: 1,
                    type: 'solid'
                  }
                }
              },
              series: [
                {
                  name: '每日',
                  data: fistData,
                  type: 'bar',
                  itemStyle: {
                    normal: {
                      // 每根柱子颜色设置
                      color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                        offset: 0,
                        color: '#53a9fc' // 0% 处的颜色
                      }, {
                        offset: 1,
                        color: '#4978f8' // 100% 处的颜色
                      }], false)
                    }
                  },
                  barWidth: 12
                }
                // {
                //   name: '一年',
                //   data: [13],
                //   type: 'bar',
                //   itemStyle: {
                //     normal: {
                //       // 每根柱子颜色设置
                //       color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                //         offset: 0,
                //         color: '#e2724d' // 0% 处的颜色
                //       }, {
                //         offset: 1,
                //         color: '#de4128' // 100% 处的颜色
                //       }], false)
                //     }
                //   },
                //   barWidth: 12
                // }
              ]
            }
            this.chart.setOption(optionData)
          },
          line9: function (id, data, totl) {
            // require('echarts/src/chart/pie')
            // require('echarts/lib/component/tooltip')
            // require('echarts/lib/component/title')
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              title: {
                show: true,
                text: '分类\n占比',
                left: 'center',
                top: '42%',
                textStyle: {
                  fontSize: 20,
                  fontWeight: 'normal',
                  color: '#ffffff'
                }
              },
              tooltip: {
                trigger: 'item',
                // formatter: '{a} <br/>{b}: {c} ({d}%)'
                formatter: '{b}: {c} ({d}%)'
              },
              // legend: {
              //   orient: 'vertical',
              //   x: 'center',
              //   y: 'bottom',
              //   itemWidth: 10,
              //   itemHeight: 10,
              //   icon: 'circle',
              //   data: ['已使用', '未使用', '未分配', '1', '2']
              // },
              series: [
                {
                  name: '预算比例',
                  type: 'pie',
                  radius: ['30%', '60%'],
                  center: ['50%', '50%'],
                  label: {
                    formatter (p) {
                      // let zb = '\n' + ((p.value / 5000000) * 100).toFixed(1) + '%'
                      // return (parseFloat(p.value).toFixed(0) + '').replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,') + zb
                      return p.name + '\n' + ((p.value / totl) * 100).toFixed(2) + '%'
                    },
                    show: true
                    // position: 'top'
                  },
                  itemStyle: { // 此配置
                    // normal: {
                    //   borderWidth: 2,
                    //   borderColor: '#ffffff'
                    // },
                    emphasis: {
                      borderWidth: 0,
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                  },
                  // label: {
                  //   show: false,
                  //   position: 'center'
                  // },
                  // emphasis: {
                  //   label: {
                  //     show: true,
                  //     fontSize: '12'
                  //   }
                  // },
                  // labelLine: {
                  //   show: false,
                  //   normal: {
                  //     length: 5,
                  //     position: 'inner'
                  //   }
                  // },
                  // data: data
                  data: data
                }
              ]
            }
            this.chart.setOption(optionData)
          },
          line10: function (id, data) {
            // require('echarts/src/chart/pie')
            // require('echarts/lib/component/tooltip')
            // require('echarts/lib/component/title')
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              title: {
                show: false,
                text: '分类\n占比',
                left: 'center',
                top: '42%',
                textStyle: {
                  fontSize: 20,
                  fontWeight: 'normal',
                  color: '#ffffff'
                }
              },
              tooltip: {
                trigger: 'item',
                // formatter: '{a} <br/>{b}: {c} ({d}%)'
                formatter: '{b}: {c} ({d}%)'
              },
              // legend: {
              //   orient: 'vertical',
              //   x: 'center',
              //   y: 'bottom',
              //   itemWidth: 10,
              //   itemHeight: 10,
              //   icon: 'circle',
              //   data: ['已使用', '未使用', '未分配', '1', '2']
              // },
              series: [
                {
                  name: '预算比例',
                  type: 'pie',
                  radius: '80%',
                  // center: ['50%', '50%'],
                  label: {
                    // formatter (p) {
                    //   // let zb = '\n' + ((p.value / 5000000) * 100).toFixed(1) + '%'
                    //   // return (parseFloat(p.value).toFixed(0) + '').replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,') + zb
                    //   return ((p.value / 60) * 100).toFixed(2) + '%'
                    // },
                    // show: true,
                    normal: {
                      show: false,
                      position: 'inside',
                      formatter: '{b}:{d}%'
                    }
                    // position: 'top'
                  },
                  itemStyle: { // 此配置
                    // normal: {
                    //   borderWidth: 2,
                    //   borderColor: '#ffffff'
                    // },
                    emphasis: {
                      borderWidth: 0,
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                  },
                  // label: {
                  //   show: false,
                  //   position: 'center'
                  // },
                  // emphasis: {
                  //   label: {
                  //     show: true,
                  //     fontSize: '12'
                  //   }
                  // },
                  // labelLine: {
                  //   show: false,
                  //   normal: {
                  //     length: 5,
                  //     position: 'inner'
                  //   }
                  // },
                  // data: data
                  data: data
                }
              ]
            }
            this.chart.setOption(optionData)
          },
          line11: function (id, data, num) {
            let xData = []
            let seriesData = []
            for (let i = 0; i < data.length; i++) {
              if (parseInt(num) === 1) {
                xData.push(data[i].time)
              } else {
                xData.push(data[i].createData)
              }
              seriesData.push(parseFloat(data[i].elcNum * 0.997).toFixed(2))
            }
            require('echarts/lib/chart/line')
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              // title: {
              //   subtext: type
              // },
              // title: [
              //   {
              //     text: 'Internal NPS ',
              //     left: 0,
              //     textStyle: {
              //       color: '#000',
              //       fontWeight: 'normal',
              //       fontSize: 14
              //     }
              //   }
              //   // {
              //   //   text: internalNPS,
              //   //   left: 100,
              //   //   textStyle: {
              //   //     color: '#525ca3',
              //   //     fontSize: 16
              //   //   }
              //   // }
              // ],
              tooltip: {
                trigger: 'axis',
                // backgroundColor: '#fff',
                // borderColor: '#333',
                // borderWidth: 1,
                textStyle: {
                  color: '#ffffff'
                }
              },
              // legend: {
              //   top: '1%',
              //   right: '10px',
              //   // x: 'right',
              //   // y: 'bottom',
              //   // itemWidth: 10,
              //   // itemHeight: 10,
              //   // icon: 'circle',
              //   selectedMode: false,
              //   data: ['NPS(初版)', 'NPS(终版)']
              // },
              grid: {
                width: 'calc(100% - 50px)',
                top: '40px',
                left: '80px',
                bottom: '30px',
                right: '40px'
              },
              xAxis: {
                name: parseInt(num) === 1 ? 'D' : 'Y',
                nameLocation: 'end',
                nameGap: 15,
                nameTextStyle: {
                  color: '#000000'
                },
                type: 'category',
                data: xData,
                axisTick: {
                  show: false
                },
                axisLabel: {
                  show: true,
                  color: '#000000',
                  fontSize: 10
                }
              },
              yAxis: {
                name: '碳排放',
                nameLocation: 'end',
                nameGap: 15,
                nameTextStyle: {
                  color: '#000000'
                },
                show: true,
                axisLine: {
                  show: false
                },
                axisTick: {
                  show: true
                },
                axisLabel: {
                  show: true,
                  color: '#000000',
                  fontSize: 10
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: '#eeeeee',
                    width: 1,
                    type: 'solid'
                  }
                }
              },
              series: [
                {
                  data: seriesData,
                  type: 'line',
                  areaStyle: {
                    normal: {
                      color: '#377efc'
                    }
                  },
                  lineStyle: {
                    width: 0
                  },
                  symbol: 'none',
                  smooth: true
                  // itemStyle: {
                  //   normal: {
                  //     color: '#377efc',
                  //     lineStyle: {
                  //       show: false,
                  //       color: '#377efc'
                  //     }
                  //   }
                  // }
                }
                // {
                //   name: 'NPS(终版)',
                //   data: myData[1],
                //   type: 'line',
                //   stack: '总量',
                //   symbol: 'circle',
                //   symbolSize: 20,
                //   lineStyle: {
                //     color: '#b4b331',
                //     width: 1,
                //     type: 'solid'
                //   },
                //   itemStyle: {
                //     borderWidth: 3,
                //     // borderColor: '#b4b331',
                //     color: '#b4b331'
                //     // normal: {
                //     //   color: '#b4b331',
                //     //
                //     // }
                //   },
                //   label: {
                //     show: true,
                //     position: 'inside',
                //     textStyle: {
                //       color: '#fff',
                //       fontSize: 12
                //     }
                //   }
                // }
              ]
            }
            this.chart.setOption(optionData)
          },
          line12: function (id, data) {
            let xData = []
            let fistData = []
            for (let i = 0; i < data.length; i++) {
              xData.push(data[i].customerName)
              fistData.push(parseFloat(data[i].elcNum * 0.997).toFixed(2))
            }
            require('echarts/lib/chart/bar')
            require('echarts/lib/component/tooltip')
            require('echarts/lib/component/title')
            require('echarts/lib/component/legend')
            require('echarts/lib/component/grid')
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              // title: {
              //   text: '',
              //   left: 'left',
              //   top: 0,
              //   textStyle: {
              //     fontSize: 14,
              //     fontWeight: 'normal',
              //     color: '#ffffff'
              //   }
              // },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow'
                },
                textStyle: {
                  align: 'left'
                }
              },
              // legend: {
              //   data: ['一月', '一年'],
              //   right: '50px',
              //   itemWidth: 10,
              //   itemHeight: 10,
              //   icon: 'circle',
              //   textStyle: {
              //     color: '#ffffff'
              //   },
              //   selectedMode: false // 取消点击事件
              // },
              grid: {
                width: 'auto',
                top: '40px',
                left: '60px',
                right: '50px',
                bottom: '30px'
              },
              xAxis: {
                type: 'category',
                data: xData,
                axisTick: {
                  show: false
                },
                axisLine: {
                  lineStyle: {
                    color: '#344786',
                    width: 1,
                    type: 'solid'
                  }
                },
                axisLabel: {
                  show: true,
                  color: '#ffffff',
                  fontSize: 10,
                  formatter (p) {
                    var res = p
                    if (res.length > 5) {
                      res = res.substring(0, 4) + '...'
                    }
                    return res
                  }
                }
              },
              yAxis: {
                name: '碳排放 吨',
                nameLocation: 'end',
                nameGap: 15,
                nameTextStyle: {
                  color: '#ffffff'
                },
                show: true,
                axisLine: {
                  show: false
                },
                axisTick: {
                  show: false
                },
                axisLabel: {
                  show: true,
                  color: '#ffffff',
                  fontSize: 10
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: '#344786',
                    width: 1,
                    type: 'solid'
                  }
                }
              },
              series: [
                {
                  name: '耗电',
                  data: fistData,
                  type: 'bar',
                  itemStyle: {
                    normal: {
                      // 每根柱子颜色设置
                      color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                        offset: 0,
                        color: '#53a9fc' // 0% 处的颜色
                      }, {
                        offset: 1,
                        color: '#4978f8' // 100% 处的颜色
                      }], false)
                    }
                  },
                  barWidth: 12
                }
                // {
                //   name: '一年',
                //   data: [13],
                //   type: 'bar',
                //   itemStyle: {
                //     normal: {
                //       // 每根柱子颜色设置
                //       color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                //         offset: 0,
                //         color: '#e2724d' // 0% 处的颜色
                //       }, {
                //         offset: 1,
                //         color: '#de4128' // 100% 处的颜色
                //       }], false)
                //     }
                //   },
                //   barWidth: 12
                // }
              ]
            }
            this.chart.setOption(optionData)
          }
        }
      }
    }
  })
}

export default {
  install
}
