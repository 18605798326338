<template>
  <div>
    <div style="background: #22304D;padding: 10px 10px">
      <div class="split_2">
        <div style="color: #fff" class="font18"><img src="../../assets/img/taikunlogin.png" height="40" width="40" class="m_r_10"/>TK-E-board</div>
        <div style="color: #fff" class="t_r">
          <span>{{lxUserInfo.name}}，欢迎您!</span>
          <span @click="logOut()" class="m_l_10 c_p">退出</span>
        </div>
      </div>
    </div>
    <div class="fa_body split_1_9 " style="width:100vw;height:calc(100% - 60px);background: #021132">
      <div class="he_max l_menu wz_topdq yj"  style="vertical-align: top;overflow: auto;height: 100%;width: 220px">
        <ul>
          <li v-if="menuList.zonglan" @click="toNext('allShow')"><i class="icon iconfont iconziyuan mag_r-10"></i>总览</li>
          <li v-if="menuList.shebeizonglan" @click="toNext('monitorList')"><i class="icon iconfont iconziyuan mag_r-10"></i>设备总览</li>
            <template v-if="menuList.penshaji">
              <li class="sb" :class="{alive: $route.name === 'Datagraph7' && store.currentlySelectedArea === key}" v-for="(item, key) of store.devicePartitioningHash" :key="key" style="padding-left: 40px;list-style: inside" @click="() => toDatagrap(key)">
                {{key}}
              </li>
            </template>
          <li v-if="menuList.liansuo" @click="toNext('chainPage')"  ><i class="icon iconfont iconzhexiantu mag_r-10"></i>联锁实时状态</li>
          <!--                @mouseenter="erji = true" @mouseleave="erji = false"-->
          <li v-if="menuList.fengsu" v-show="zd" style="padding-left: 40px;list-style: inside" @click="toNext('Datagraph1')">风速数据</li>
          <li v-if="menuList.shuiliu" v-show="zd" style="padding-left: 40px;list-style: inside" @click="toNext('Datagraph4')">水流状态数据</li>
          <li v-if="menuList.yewei" v-show="zd" style="padding-left: 40px;list-style: inside" @click="toNext('Datagraph3')">液位状态数据</li>
          <li v-if="menuList.zhouwen" v-show="zd" style="padding-left: 40px;list-style: inside" @click="toNext('Datagraph2')">轴承温度数据</li>
<!--          <li v-if="menuList.penshaji" v-show="zd" style="padding-left: 40px;list-style: inside" @click="toNext('Datagraph5')">喷砂机状态</li>-->
<!--          <li @click="toNext('checking')"><i class="icon iconfont iconcomputer mag_r-10"></i>巡检状况</li>-->
          <li v-if="menuList.baoyang" @click="toNext('upKeep')"><i class="icon iconfont iconcomputer mag_r-10"></i>保养状况</li>
          <li v-if="menuList.nenghao" @click="toNext('energyPage')"><i class="icon iconfont iconqushi_shangsheng mag_r-10"></i>能耗</li>
          <li v-if="menuList.baojing" @click="toNext('policesing')"><i class="icon iconfont icontuwen mag_r-10"></i>报警记录</li>
          <!--                <li @click="toNext('dataTable')">日志管理</li>-->
          <li v-if="menuList.canshu" @click="toNext('parameter')"><i class="icon iconfont iconwenben mag_r-10"></i>参数管理</li>
          <li v-if="menuList.rizhi" @click="toNext('myLogManager')"><i class="icon iconfont iconwenben mag_r-10"></i>日志管理</li>
<!--          <li @click="toNext('alarmList')"><i class="icon iconfont iconchilun mag_r-10"></i>报警列表</li>-->
<!--          <li @click="toNext('checkingList')"><i class="icon iconfont iconchilun mag_r-10"></i>巡检列表</li>-->
<!--          <li @click="toNext('maintainList')"><i class="icon iconfont iconchilun mag_r-10"></i>保养列表</li>-->
        </ul>
      </div><div style="height: 100%;overflow: hidden;width: calc(100% - 220px)" class="wz_white wz_jz">
      <!--    <div class="jd ej" v-show="erji" style="height: 100vh;width: 150px;z-index: 15000;background-color: #4A89C2">-->
      <!--      <ul class="" @mouseenter="erji = true" @mouseleave="erji = false">-->
      <!--        <li @click="toNext('Datagraph1')">主管风速数据</li>-->
      <!--        <li @click="toNext('Datagraph3')">液位状态数据</li>-->
      <!--        <li @click="toNext('Datagraph4')">水流状态数据</li>-->
      <!--        <li @click="toNext('Datagraph2')">轴承温度数据</li>-->
      <!--      </ul>-->
      <!--    </div>-->
      <div style="padding:20px;height: calc(100% - 40px);overflow: auto;background-color: #111F3A">
        <router-view :key="$route.query.t"></router-view>
      </div>
<!--      <div class="colorWidth t_c p_b_20" style="background-color: rgb(17, 31, 58)">Copyright © 苏州钛锟环保节能科技有限公司 苏ICP备19012628号-3</div>-->
    </div>
    </div>
  </div>
</template>

<script>
// import allShow from "../dorm/allShow";
import {useStore} from "@/store/pinia";

export default {
  name: "dorm01",
  data(){
     return {
       zd: true,
       erji: false,
       menuList: {},
       lxUserInfo: {},
       store: useStore()
     }
  },
  mounted () {
    if (sessionStorage.lxUserInfo) {
      this.lxUserInfo = JSON.parse(sessionStorage.lxUserInfo)
      if (this.lxUserInfo.menu) {
        this.menuList = JSON.parse(this.lxUserInfo.menu)
      }
    }
    this.getRegionalDevices()
  },
  methods: {
    toNext (page) {
      this.$router.push({
        name: page
        // query: {
        //   t: Date.now()
        // }
      })
      setTimeout(() => {
        window.location.reload()
      }, 100)
    },
    toDatagrap (key) {
      this.$router.push({
        name: 'Datagraph7',
        query: {
          formSortType: key,
          t: Date.now()
        }
      })
      this.store.setCurrentlySelectedArea(key)
    },
    logOut () {
      window.sessionStorage.removeItem('lixtoken')
      this.$router.push({
        name: 'login'
      })
    },
    async getRegionalDevices () {
      try {
        let url = this.$commonJs.returnQuery('/customer/devicePage', {page: 1, size: 99999, parentId: this.lxUserInfo.parentId || ''})
        const { data } = await this.$axiox.cpost(url)
        // 分线字段
        const lineKey = 'lineName'
        const lineKey2 = 'deviceName'
        // 分区字段
        const areaKey = 'sortType'
        const areaKey2 = 'totalPreview'
        const areaKey3 = 'deviceName'
        const chidrenFind = []
        if (data.content.length) {
          // 栋区分
          const areaHash = {}
          // 根据线区分设备的方法
          // const templateHash = {}
          const planeLineDevice = (lineHash, paramVal) => {
            for (const device of data.content) {
              if (paramVal !== (device[areaKey] || device[areaKey2] || device[areaKey3])) {
                continue
              }
              if (!lineHash[device[lineKey]] && !lineHash[device[lineKey2]]) {
                (lineHash[device[lineKey] || device[lineKey2]]) = [
                  device
                ]
              } else {
                (lineHash[device[lineKey] || device[lineKey2]]).push(device)
              }
              device.templaparams= {}
              // chidrenFind.push(
              //     new Promise((resolve, reject) => {
              //       if (!templateHash[device.templateId]) {
              //         templateHash[device.templateId] = true
              //         this.$axiox.cpost('/customer/paramsList?templateId=' + device.templateId)
              //             .then(({ data }) => {
              //               templateHash[device.templateId] = param
              //               for (const param of data) {
              //                 device.templaparams[param.paramsAddress] = param
              //                 resolve(true)
              //               }
              //             }).catch(() => {
              //               reject(false)
              //         })
              //       } else {
              //
              //       }
              //     })
              // )
            }
          }
          // 循环区分区域
          for (const device of data.content) {
            if (!areaHash[device[areaKey]] && !areaHash[device[areaKey2]]) {
              // 创建线区分
              areaHash[device[areaKey] || device[areaKey2] || device[areaKey3]] = {}
              planeLineDevice(areaHash[device[areaKey] || device[areaKey2] || device[areaKey3]], (device[areaKey] || device[areaKey2] || device[areaKey3]))
            }
          }
          await Promise.all(chidrenFind)
          this.store.setDevicePartitioningHash(areaHash)
          console.log(this.store.devicePartitioningHash, '喷砂机数据')
        }
      } catch (err) {
        console.log(err)
        throw new Error('获取账号设备错误')
      }
    }
  },
  created() {
  },
  comments:{
    // allShow
  }
}
</script>
<style scoped>
*{
  padding: 0;
  margin: 0;
}
.fa_body{
}
li{
  /*text-align: left;*/
  padding-left: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 22px;
  /*padding: 20px 0;*/
  color: #6ECBF6;
  list-style: none;
  transition: all 0.2s;
  cursor: pointer;
  font-weight: normal;
}
.yj >ul >li:hover{
  color: #6ECBF6;
  font-weight: bold;
  border-left: 6px solid #6ECBF6;
  background: linear-gradient(to right, #042051, #021132);
}
.yj >ul >li {
  padding: 20px 0px 10px 15px;
}
.yj >ul >li.sb:hover{
  color: #6ECBF6;
  font-weight: bold;
  border-left: 6px solid #6ECBF6;
  background: linear-gradient(to right, #042051, #021132);
}
.yj >ul >li.sb.alive{
  color: #6ECBF6;
  font-weight: bold;
  border-left: 6px solid #6ECBF6;
  background: linear-gradient(to right, #042051, #021132);
}
.ej ul li:hover{
  color: #333;
  background: rgb(236, 234, 236);
}
</style>
