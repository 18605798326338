import axios from './axios'
import pubilshSet from "./pubilshSet";
export default {
  imgUpLoadFileList: ['jpg', 'png', 'jpeg', 'bmp', 'gif'],
  alertAppTips (object) {
    alert(object.content)
  },
  alertTips (object) {
    let DOM = document.createElement('div')
    if (object.type === 'success') {
      DOM.className = 'allTips success'
    } else {
      DOM.className = 'allTips error'
    }
    let html = `<span>${object.title}</span>: <span>${object.content}</span>`
    DOM.innerHTML = html
    document.body.appendChild(DOM)
    let tips = document.querySelectorAll('.allTips')
    if (tips.length === 1) {
      DOM.style.top = '40px'
    } else {
      DOM.style.top = parseInt(tips[tips.length - 2].style.top.replace('px', ''), 0) + (tips[tips.length - 2].clientHeight + 12) + 'px'
    }
    setTimeout(() => {
      DOM.style.right = '20px'
      setTimeout(() => {
        DOM.style.right = '-300px'
        setTimeout(() => {
          DOM.remove()
        }, 500)
      }, object.stayTime * 1000)
    }, 10)
  },
  returnQuery (url, object) {
    if (object) {
      let html = ''
      for (let i in object) {
        html += '&' + i + '=' + object[i]
      }
      html = html.substr(1, html.length - 1)
      return url + '?' + html
    }
  },
  returnAllDate (date) {
    if (date === null || date === '' || date === undefined) {
      return ''
    } else {
      let newDate = new Date(date.split('T')[0].replace(/-/g, '/'))
      let newYear = newDate.getFullYear()
      let newMouth = newDate.getMonth() + 1
      let ToDate = newDate.getDate()
      newMouth = newMouth < 10 ? '0' + newMouth : newMouth
      ToDate = ToDate < 10 ? '0' + ToDate : ToDate
      return newYear + '/' + newMouth + '/' + ToDate
    }
  },
  todayDate: new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)) + '-' + (new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()),
  lastWeekDay () {
    let lastDay = new Date().getTime() - 1000 * 60 * 60 * 24 * 7
    return new Date(lastDay).getFullYear() + '-' + ((new Date(lastDay).getMonth() + 1) < 10 ? '0' + (new Date(lastDay).getMonth() + 1) : (new Date(lastDay).getMonth() + 1)) + '-' + (new Date(lastDay).getDate() < 10 ? '0' + new Date(lastDay).getDate() : new Date(lastDay).getDate())
  },
  returnYMD (data, type) {
    let newData = new Date(data.replace(/-/g, '/'))
    if (type === 'Y') {
      return newData.getFullYear()
    } else if (type === 'M') {
      return newData.getMonth() + 1
    } else if (type === 'D') {
      return newData.getDate()
    } else {
      return ''
    }
  },
  doubleComfirm (object) {
    let img = require('../img/model_title.png')
    let dom = document.createElement('div')
    dom.id = 'doubleConfirm'
    let html = ''
    if (object.remark) {
      html = `<div class="newTips">
        <div class="cover"  style="opacity: 1"></div>
        <div id="newTipsBody2" class="newTipsBody">
          <ul>
            <li class="d_f" style="justify-content: space-between;align-items: center">` +
            // <span style="display:inline-block;width: 95%" class="font20 f_w">${window.localStorage.getItem('language') === 'en-US' ? 'Prompt' : '提示'}</span><i id="cancelButtom2" class="icon iconfont iconclose colorGray959595" style="font-size: 25px"></i>
            `<div class="allboxHead">
                <img src="${img}" width="430" height="32"/>
                <div class="colorWidth font14" style="top: -5px">
                  <span>提示</span>
                </div>
              </div>
            </li>
            <li class="t_c font18" style="padding-bottom: 2%;"><i class="icon iconfont icongantan m_r_10" style="font-size: 25px;color: #e8a827"></i><span class="colorGray7F7F7F">${object.content}</span></li>
            <li class="t_c font12" style="padding-bottom: 8%;"><span class="colorBlue4884E8">${object.remark}</span></li>
            <li class="t_c m_b_10">
              <button id="confirmButtom1" class="suerButton m_r_10 m_l_10" ><span class="font12">${window.localStorage.getItem('language') === 'en-US' ? 'Yes' : '确定'}</span></button>
              <button id="cancelButtom1" class="cancelButton m_l_10 m_r_10" ><span class="font12">${window.localStorage.getItem('language') === 'en-US' ? 'NO' : '取消'}</span></button>
            </li>
          </ul>
        </div>
      </div>`
    } else {
      html = `<div class="newTips">
        <div class="cover"  style="opacity: 1"></div>
        <div id="newTipsBody2" class="newTipsBody">
          <ul>
            <li class="d_f" style="justify-content: space-between;align-items: center">` +
            // <span style="display:inline-block;width: 95%" class="font20 f_w">${window.localStorage.getItem('language') === 'en-US' ? 'Prompt' : '提示'}</span><i id="cancelButtom2" class="icon iconfont iconclose colorGray959595" style="font-size: 25px"></i>
            `<div class="allboxHead">
                <img src="${img}" width="430" height="32"/>
                <div class="colorWidth font14" style="top: -5px">
                  <span>提示</span>
                </div>
              </div>
            </li>
            <li class="t_c font18"><i class="icon iconfont icongantan m_r_10" style="font-size: 25px;color: #e8a827"></i><span class="colorGray7F7F7F">${object.content}</span></li>
            <li class="t_c m_b_10">
                <button id="cancelButtom1" class="cancelButton1 m_l_10 m_r_10" ><span class="font12">${window.localStorage.getItem('language') === 'en-US' ? 'NO' : '取消'}</span></button>
              <button id="confirmButtom1" class="suerButton m_r_10 m_l_10" ><span class="font12">${window.localStorage.getItem('language') === 'en-US' ? 'Yes' : '确定'}</span></button>
            </li>
          </ul>
        </div>
      </div>`
    }
    dom.innerHTML = html
    document.body.appendChild(dom)
    setTimeout(function () {
      document.getElementById('newTipsBody2').style.opacity = '1'
      document.getElementById('newTipsBody2').style.top = '-30px'
    }, 10)
    let confirmButtom1 = document.getElementById('confirmButtom1')
    if (confirmButtom1) {
      confirmButtom1.onclick = function (e) {
        object.successFun(e)
        document.getElementById('newTipsBody2').style.opacity = '0'
        document.getElementById('newTipsBody2').style.top = '-100px'
        setTimeout(function () {
          document.body.removeChild(document.getElementById('doubleConfirm'))
        }, 300)
      }
    }
    let cancelButtom1 = document.getElementById('cancelButtom1')
    if (cancelButtom1) {
      cancelButtom1.onclick = function (e) {
        object.errorFun(e)
        document.getElementById('newTipsBody2').style.opacity = '0'
        document.getElementById('newTipsBody2').style.top = '-100px'
        setTimeout(function () {
          document.body.removeChild(document.getElementById('doubleConfirm'))
        }, 300)
      }
    }
    let cancelButtom2 = document.getElementById('cancelButtom2')
    if (cancelButtom2) {
      cancelButtom2.onclick = function (e) {
        object.errorFun(e)
        document.getElementById('newTipsBody2').style.opacity = '0'
        document.getElementById('newTipsBody2').style.top = '-100px'
        setTimeout(function () {
          document.body.removeChild(document.getElementById('doubleConfirm'))
        }, 300)
      }
    }
  },
  numFixed (num, fixedNum) {
    if (num !== null && num !== undefined) {
      let newFixedNum = fixedNum || 2
      if (fixedNum) {
        return (parseFloat(num).toFixed(newFixedNum) + '').replace(/\d{2,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
      } else {
        return (parseFloat(num).toFixed(newFixedNum) + '').replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
      }
    } else {
      return 0
    }
  },
  // 正则校验
  returnDigit (type, name, content) {
    if (name === 'email') { // 邮箱
      let newEmal = /^[A-Za-z0-9]+([-_.][A-Za-z0-9]+)*@([A-Za-z0-9-_]+\.)+[A-Za-z]{2,6}$/
      if (!newEmal.test(type)) {
        this.alertTips({
          type: 'error',
          title: '错误',
          content: content,
          stayTime: 5
        })
        return true
      }
    } else if (name === 'number') { // 数字
      let freg = /^[1-9]\d*$/
      if (!freg.test(type)) {
        this.alertTips({
          type: 'error',
          title: '错误',
          content: content,
          stayTime: 5
        })
        return true
      }
    } else if (name === 'en') { // 英文
      let freg = /^[a-zA-Z]+$/
      if (!freg.test(type)) {
        this.alertTips({
          type: 'error',
          title: '错误',
          content: content,
          stayTime: 5
        })
        return true
      }
    } else if (name === 'phone') { // 手机
      let freg = /^[1][3,4,5,7,8][0-9]{9}$/
      if (!freg.test(type)) {
        this.alertTips({
          type: 'error',
          title: '错误',
          content: content,
          stayTime: 5
        })
        return true
      }
    }
    return false
  },
  //error 字典
  errorData: [
    '系统运行',
    '系统停止',
    '1#水泵故障',
    '2#水泵故障',
    '风机故障',
    '水压过高',
    '水压过低',
    '轴温过高',
    '水位过低',
    '水压不足',
    '水流量不足',
    '主管风速过低1#',
    '主管风速过低2#',
    '主管风速过低3#',
    '主管风速过低4#',
    '主管风速过低5#',
    '主管风速过低6#',
    '主管风速过低7#',
    '主管风速过低8#',
    '支管风速过低1#',
    '支管风速过低2#',
    '支管风速过低3#',
    '支管风速过低4#',
    '支管风速过低5#',
    '支管风速过低6#',
    '支管风速过低7#',
    '支管风速过低8#',
    '支管风速过低9#',
    '支管风速过低10#',
    '支管风速过低11#',
    '支管风速过低12#',
    '支管风速过低13#',
    '支管风速过低14#',
    '支管风速过低15#',
    '支管风速过低16#',
    '集尘塔保养时间到期',
    '电控系统保养时间到期',
    '防爆风机保养时间到期',
    '管道系统保养时间到期'
  ],
  // 校验必填项
  checkIfEmpty (data, list, value) {
    let content = window.localStorage.getItem('language') === 'en-US' ? 'Please check the required fields' : '请检查必填项!'
    if (value) {
      content = value
    }
    for (let i in data) {
      if (list.indexOf(i) >= 0 && (data[i] === '' || data[i] === null)) {
        this.alertTips({
          type: 'error',
          title: '提示',
          content: content,
          stayTime: 5
        })
        return true
      }
    }
    return false
  },
  uploadFile (file, type) {
    let postFile = new FormData()
    postFile.append('picturefile', file)
    var p = new Promise((resolve, reject) => {
      console.log(reject)
      axios.postTwo('/upload/pictures?messageType=BROADCAST', postFile, type).then(msg => {
        resolve(msg.data)
      })
    })
    return p
  },
  getRouteLink (query) {
    // console.log(window.location)
    let url = null
    if (window.location.hostname !== 'localhost') {
      url = `${pubilshSet.tkBaseUrl}?from=outside&path=${query}` // tk登录
    } else {
      url = `http://localhost:8083/#/login?from=outside&path=${query}` // tk登录
    }
    console.log('token--111', url)
    return url
  }
}
