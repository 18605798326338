<template>
  <div>
    <div style="text-align: left;font-size: 22px;border-left: white 3px solid;padding: 0 14px">巡检状况</div>
    <div style="background-color: rgb(34, 48, 77);border-radius: 10px;margin-top: 15px;padding-left: 15px">
      <div style="text-align: left;padding: 15px 0">
        <div class="mag_r-20" style="display: inline-block;vertical-align: middle;">
          <span class="mag_r-20">区域</span>
          <span>
          <select @change="getTemplates()" v-model="building" class="allSelect sele" name="">
              <option :value="''">全部</option>
            <option :value="'A'">A区</option>
            <option :value="'B'">B区</option>
            <option :value="'C'">C区</option>
            <option :value="'F'">F区</option>
            <option :value="'G'">G区</option>
            <option :value="'H'">H区</option>
            <option :value="'I'">I区</option>
          </select>
        </span>
        </div>
        <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
          <span class="mag_r-20">线别</span>
          <span>
          <select @change="getTemplates()" v-model="line" class="allSelect sele" name="">
                        <option :value="''">全部</option>
            <option :value="'1线'">线别1</option>
            <option :value="'2线'">线别2</option>
            <option :value="'3线'">线别3</option>
            <option :value="'4线'">线别4</option>
          </select>
        </span>
        </div>
        <!--      <div class="mag_r-20" style="display: inline-block;vertical-align: middle">-->
        <!--        <span class="mag_r-20">开始时间</span>-->
        <!--        <span>-->
        <!--          <div class="xd disp">-->
        <!--              <cander :newWidth="'150px'" class="allInput d_l" v-model="newStartDate" ></cander>-->
        <!--              <img class="jd" src="../../assets/img/riqi.png" height="20px" style="margin-left: -27px;margin-top: 5px" alt=""></div>-->
        <!--          &lt;!&ndash;          <input @change="show()" v-model="newStartDate" type="date" class="i_time" />&ndash;&gt;-->
        <!--        </span>-->
        <!--      </div>-->
        <!--      <div class="mag_r-20" style="display: inline-block;vertical-align: middle">-->
        <!--        <span class="mag_r-20">结束时间</span>-->
        <!--        <span>-->
        <!--          <div class="disp">-->
        <!--        <cander :newWidth="'150px'" class="allInput d_l" v-model="newEndDate" ></cander>-->
        <!--              <img class="jd" src="../../assets/img/riqi.png" height="20px" style="margin-left: -27px;margin-top: 5px" alt="">-->
        <!--          </div>-->
        <!--          &lt;!&ndash;          <input @change="show()" v-model="newEndDate" type="date" class="i_time" />&ndash;&gt;-->
        <!--        </span>-->
        <!--      </div>-->
        <button @click="getTemplates()" class="btn btn_success mag_l-20 mag_r-20"><span>搜索</span></button>
        <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
          <span style="border-radius: 5px;border: 1px solid #eee;padding: 10px 20px;cursor: pointer" @click="exportExcel" class="mag_r-20">导出数据</span>
        </div>
      </div>
      <div id="hiddenHistoryList" v-show="dao" class="jz_flex"  style="height: 250px;overflow: auto;">
        <!--      <table class="allTable bd ba_white wz_black" border="1" cellspacing="0">-->
        <!--        <thead>-->
        <!--        <tr class="bd">-->
        <!--          <th>区域</th>-->
        <!--          <th>线别</th>-->
        <!--          <th>保养名称</th>-->
        <!--          <th>保养频率</th>-->
        <!--          <th>上次保养时间</th>-->
        <!--          <th>下次保养时间</th>-->
        <!--          <th>今日距离下次保养</th>-->
        <!--          <th>完成情况</th>-->
        <!--          <th>管理员</th>-->
        <!--          <th>确认时间</th>-->
        <!--          &lt;!&ndash;<th>审核状态</th>&ndash;&gt;-->
        <!--        </tr>-->
        <!--        </thead>-->
        <!--        <tbody>-->
        <!--        <tr class="bd" :key="index" v-for="(item, index) in maintain">-->
        <!--          <td>{{ item.buiname }}</td>-->
        <!--          <td>{{ item.li }}</td>-->
        <!--          <td>{{ item.byname }}</td>-->
        <!--          <td>{{ item.bypinlv }}</td>-->
        <!--          <td>{{ item.scby }}</td>-->
        <!--          <td>{{ item.xcby }}</td>-->
        <!--          <td>{{ item.jrjxcby }}</td>-->
        <!--          <td>{{ item.over }}</td>-->
        <!--          <td>{{ item.admin }}</td>-->
        <!--          <td>{{ item.overtime }}</td>-->
        <!--          &lt;!&ndash;<td>{{ item.handleStatus }}</td>&ndash;&gt;-->
        <!--        </tr>-->
        <!--        </tbody>-->
        <!--      </table>-->
        <table class="allTable bd ba_white wz_black" border="1" cellspacing="0">
          <thead>
          <tr class="bd">
            <th>区域</th>
            <th>楼栋</th>
            <th>线别</th>
            <th>名称</th>
            <th>代码</th>
            <th>保养项目</th>
            <th>保养周期</th>
            <th>核查明细</th>
            <th>检查工具</th>
            <th>管理员</th>
            <th>负责人</th>
            <th>保养时间</th>
            <th>保养时间</th>
            <th>保养时间</th>
            <th>保养时间</th>
            <!--<th>审核状态</th>-->
          </tr>
          </thead>
          <tbody>
          <tr class="bd" :key="index" v-for="(item, index) in maintain2">
            <td>{{ item.area }}</td>
            <td>{{ item.buiname }}</td>
            <td>{{ item.li }}</td>
            <td>{{ item.data1 }}</td>
            <td>{{ item.data2 }}</td>
            <td>{{ item.data3 }}</td>
            <td>{{ item.data4 }}</td>
            <td>{{ item.data5 }}</td>
            <td>{{ item.data6 }}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <!--<td>{{ item.handleStatus }}</td>-->
          </tr>
          </tbody>
        </table>
      </div>
      <div>
        <div class="split_2">
          <div id="main" style="margin-top:50px" :style="{  height: 'calc(100vh - 290px)'}"></div>
          <div style="vertical-align: bottom;margin-bottom: 40px" class="t_l" >
            <div style="width: 90%" class="split_1_9">
              <div style="vertical-align: top;width: 120px">
                <div>
                  <div :key="index" v-for="(item, index) in showleasonData">
                    <div style="padding: 15px 0" :key="deviceIndex"  v-for="(deviceItem, deviceIndex) in item.deviceList">
                      <div><span style="display: block;width: calc(100% - 40px);padding: 10px 8px">{{ deviceItem.deviceName }}</span></div>
                    </div>
                  </div>
                </div>
                <div>
                </div>
              </div>
              <div style="width: calc(100% - 120px)">
                <div style="border-left: 1px solid #fff;border-bottom: 1px solid #fff">
                  <div :key="index" v-for="(item, index) in showleasonData">
                    <div :key="deviceIndex" v-for="(deviceItem, deviceIndex) in item.deviceList">
                      <template  class="d_in" v-for="(tdItem, tdIndex) in deviceItem.paramsDataList" >
                        <div  class="d_in p_r" v-if="tdItem.paramsAddress == 4358  || tdItem.paramsAddress == 4360 || tdItem.paramsAddress == 4364" :key="tdIndex" style=" width: 200px;border: 1px solid #1d97ff;margin:20px;height: 30px;line-height: 30px;border-radius: 100px">
                          <div style="border-radius: 100px" :style="{'width': parseFloat((tdItem.paramsNowValue / 700) * 100 ).toFixed(1)+ '%','background': returnBackColor(tdItem)}"> <span style="display: block;">{{returnNowValue(tdItem)}}</span></div>
                          <div style="position: absolute;right: 0;top: 0px">700小时</div>
                        </div>
                      </template>

                    </div>
                  </div>
                </div>
                <div style="margin-top: 20px">
                  <div :key="index" v-for="(item, index) in showleasonData">
                    <div :key="deviceIndex"  class="d_in v_m" v-for="(deviceItem, deviceIndex) in item.deviceList">
                      <template v-for="(tdItem, tdIndex) in deviceItem.paramsDataList" >
                        <div class="d_in" v-if="(tdItem.paramsAddress == 4358  || tdItem.paramsAddress == 4360 || tdItem.paramsAddress == 4364) && deviceIndex === 0" :key="tdIndex" style="width: 200px;margin: 10px 20px;text-align: center">
                          <div style="color: #fff" class="m_l_10 d_in "> <span>{{returnNewName(tdItem)}}</span></div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--          <div style="height: 60vh;border: 2px #4A576A solid;box-sizing: border-box;border-radius: 10px;overflow: auto">-->
            <!--            <div class=" he_max jz" style="background-color: rgb(34, 48, 77);border-radius: 10px;">-->
            <!--              <div class="he_max" style="">-->
            <!--                <div  style="text-align: center;width: 100%" class="">-->
            <!--                  <div style="border-bottom: 2px #4A576A solid;padding: 20px 0">参数值</div>-->
            <!--                  <div class="split_3" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">-->
            <!--                    <div>名称:</div>-->
            <!--                    <div>标准</div>-->
            <!--                    <div>剩余</div>-->
            <!--                  </div>-->
            <!--                  <div :key="index" v-for="(item, index) in showleasonData">-->
            <!--                    <div :key="deviceIndex" style="border: 1px solid rgb(74, 87, 106)" v-for="(deviceItem, deviceIndex) in item.deviceList">-->
            <!--                      <div style="color: #fff" class="p_d20 back_color_blue7fa5f8 ">{{ deviceItem.deviceName }}</div>-->
            <!--                      <template v-for="(tdItem, tdIndex) in deviceItem.paramsDataList" >-->
            <!--                        <div v-if="tdItem.paramsAddress == 4358  || tdItem.paramsAddress == 4360 || tdItem.paramsAddress == 4364" :key="tdIndex" class="split_3" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">-->
            <!--                          <div>{{ returnNewName(tdItem) }}:</div>-->
            <!--                          <div>700h</div>-->
            <!--                          <div > <span style="display: block;width: calc(100% - 40px);padding: 10px 8px">{{returnNowValue(tdItem)}}</span></div>-->
            <!--                        </div>-->
            <!--                      </template>-->
            <!--&lt;!&ndash;                      <div class="split_3" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">&ndash;&gt;-->
            <!--&lt;!&ndash;                        <div>电控系统:</div>&ndash;&gt;-->
            <!--&lt;!&ndash;                        <div>700h</div>&ndash;&gt;-->
            <!--&lt;!&ndash;                        <div>{{ baoyangB }}h</div>&ndash;&gt;-->
            <!--&lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--&lt;!&ndash;                      <div class="split_3" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">&ndash;&gt;-->
            <!--&lt;!&ndash;                        <div>管道系统:</div>&ndash;&gt;-->
            <!--&lt;!&ndash;                        <div>700h</div>&ndash;&gt;-->
            <!--&lt;!&ndash;                        <div>{{ baoyangC }}h</div>&ndash;&gt;-->
            <!--&lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                <div :key="index" v-for="(item, index) in maintain" class="split_2" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">-->
            <!--                  <div>{{ item.name }}:</div>-->
            <!--                  <div>{{ item.value }}</div>-->
            <!--                </div>-->
            <!--                <div class="split_2" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">-->
            <!--                  <div>水流量标准值:</div>-->
            <!--                  <div>≥60m³/H</div>-->
            <!--                </div>-->
            <!--                <div class="split_2" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">-->
            <!--                  <div>液位标准值:</div>-->
            <!--                  <div>液位正常</div>-->
            <!--                </div>-->
            <!--                <div class="split_2" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">-->
            <!--                  <div>轴温标准值:</div>-->
            <!--                  <div>≤ 80°C</div>-->
            <!--                </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--          </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let timeInter
import * as echarts from "echarts";
export default {
  name: "checking",
  data () {
    return {
      dao: false,
      newStartDate: '',
      newEndDate: '',
      showleasonData: [],
      maintain:[
      ],
      maintain2:[
      ],
      building: '',
      line: '',
      baoyangA: 0,
      baoyangB: 0,
      baoyangC: 0,
      msg: "Welcome to Your Vue.js App",
    }
  },
  comments: {
    echarts,
  },
  methods:{
    returnNewName (item) {
      if (item.paramType === 'FLOAT_THIRTYTWO' || item.paramType === 'FLOAT_SIXTEEN') {
        return item.floatName || item.paramsAddress
      }
      if (item.paramType === 'INTEGER_SIXTEEN' || item.paramType === 'INTEGER_THIRTYTWO') {
        return item.integerName || item.paramsAddress
      }
      if (item.paramType === 'TIMING' || item.paramType === 'TIMING_INTEGER') {
        return item.timingName || item.paramsAddress
      }
      return item.paramsAddress
    },
    returnBackColor (object) {
      if (object.paramsNowValue <= 100) {
        return 'orange'
      }
      if (object.paramsNowValue <= 0) {
        return 'red'
      }
      return '#1d97ff'
    },
    returnNowValue (object) {
      let unit = ''
      if (object.paramType === 'FLOAT_THIRTYTWO' || object.paramType === 'FLOAT_SIXTEEN') {
        unit = object.floatUnit || ''
      }
      if (object.paramType === 'INTEGER_SIXTEEN' || object.paramType === 'INTEGER_THIRTYTWO') {
        unit = object.integerUnit || ''
      }
      if (object.paramType === 'TIMING' || object.paramType === 'TIMING_INTEGER') {
        unit = object.timingUnit || ''
      }
      if ((object.paramType === 'INTEGER_SIXTEEN' || object.paramType === 'INTEGER_THIRTYTWO') && (object.zeroName || object.oneName || object.twoName)) {
        if (parseInt(object.paramsNowValue) === 0) {
          return object.zeroName
        }
        if (parseInt(object.paramsNowValue) === 1) {
          return object.oneName
        }
        if (parseInt(object.paramsNowValue) === 2) {
          return object.twoName
        }
        if (parseInt(object.paramsNowValue) !== 0 && parseInt(object.paramsNowValue) !== 1 && parseInt(object.paramsNowValue) !== 2) {
          return '数据采集中'
        }
      } else {
        return (parseFloat(object.paramsNowValue).toFixed(2) || '-') + ' ' + unit
      }
      return (parseFloat(object.paramsNowValue).toFixed(2) || '-') + ' ' + unit
    },
    returnClass (object) {
      if (object.integerWarning && (object.zeroName || object.oneName || object.twoName)) {
        console.log(object.integerName, (parseInt(object.paramsNowValue) === parseInt(object.integerWarning)))
        if (parseInt(object.paramsNowValue) === parseInt(object.integerWarning)) {
          return 'warning_yellow'
        }
      }
      if (object.paramType === 'INTEGER_SIXTEEN' || object.paramType === 'INTEGER_THIRTYTWO') {
        if (parseInt(object.paramsNowValue) === 0) {
          if (object.zeroColor && parseInt(object.zeroColor) === 0) {
            return 'warning_red'
          } else if (object.zeroColor && parseInt(object.zeroColor) === 1) {
            return 'greed_Nomal'
          } else if (object.zeroColor && parseInt(object.zeroColor) === 2) {
            return 'yellow_nomal'
          } else {
            return 'warning_red'
          }
        } else if (parseInt(object.paramsNowValue) === 1) {
          if (object.oneColor && parseInt(object.oneColor) === 0) {
            return 'warning_red'
          } else if (object.oneColor && parseInt(object.oneColor) === 1) {
            return 'greed_Nomal'
          } else if (object.oneColor && parseInt(object.oneColor) === 2) {
            return 'yellow_nomal'
          } else {
            return 'yellow_nomal'
          }
        } else {
          if (object.twoColor && parseInt(object.twoColor) === 0) {
            return 'warning_red'
          } else if (object.twoColor && parseInt(object.twoColor) === 1) {
            return 'greed_Nomal'
          } else if (object.twoColor && parseInt(object.twoColor) === 2) {
            return 'yellow_nomal'
          } else {
            return 'greed_Nomal'
          }
        }
      }
      let warningLine = object.integerWarning || object.floatWarning || object.timingWarning
      if (warningLine && parseFloat(object.paramsNowValue) < parseFloat(warningLine)) {
        return 'warning_red'
      }
      if (object.paramsNowValue) {
        return 'greed_Nomal'
      } else {
        return ''
      }
    },
    exportExcel () {
      let html = '<html><head><meta charset=\'utf-8\' /></head><body>'
      html += document.getElementById('hiddenHistoryList').innerHTML
      html += '</body></html>'
      // 实例化一个Blob对象，其构造函数的第一个参数是包含文件内容的数组，第二个参数是包含文件类型属性的对象
      let blob = new Blob([html], {
        type: 'application/vnd.ms-excel'
      })
      let a = document.createElement('a')
      a.href = URL.createObjectURL(blob)
      a.download = '保养数据.xls'
      a.click()
    },
    show(){
      let mouseCurValue = ''
      let hasBaoyang = this.normalData
      let waitBaoyang = this.timeoffData
      let Baoyangout = this.warningData
      console.log(hasBaoyang, waitBaoyang, Baoyangout, 'Baoyangout')
      let Xdata = ["A区", "B区", "C区", "F区", "G区",  "H区", "I区"]
      var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: "axis",
          extraCssText: "background-color: #202F50;border:1px #234A71 solid",
          textStyle: {
            color:"white"
          },
          formatter: function (params) {
            let res = "", sum = 0;
            for (let i = 0; i < params.length; i++) {
              let series = params[i];
              // 累计series.data，与mouseCurValue做比较，找出鼠标位置对应的series
              sum += Number(series.data);
              if (sum >= mouseCurValue) {
                // res = series.axisValue + "<br/>" + series.marker + series.seriesName + ":" + series.data + "<br/>";
                res = '<p style="border-bottom: 1px #414C62 solid;padding: 10px;text-align:left">'+ series.axisValue + '<br />'+'</p>'
                    + '<p style="border-bottom: 2px #414C62 dotted;padding: 10px;text-align:left">'+series.seriesName + '：' + series.data + '<br />'+'</p>'
                break;
              }
            }
            return res;
          }
        },
        legend: {
          textStyle:{
            color:"white"
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: Xdata,
            axisLabel: {
              show: true,
              textStyle:{
                color:"white"
              }
            }
          }
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              show: true,
              textStyle:{
                color:"white"
              }
            }
          }
          ,
        ],
        series: [
          {
            name: "已保养",
            type: "bar",
            stack: "Ad",
            color: "#0CCF67",
            axisLabel: {
              show: true,
              textStyle:{
                color:"white"
              }
            },
            emphasis: {
              focus: "series",
            },
            data: hasBaoyang,
            label:{
              show: true,
              formatter:function (e) {console.log(hasBaoyang[e.dataIndex])
                return parseInt(((hasBaoyang[e.dataIndex] / (hasBaoyang[e.dataIndex] + waitBaoyang[e.dataIndex] + Baoyangout[e.dataIndex])) * 100)).toFixed(0) + '%'

              },
              position:'inside',
              color: 'white',
              fontSize: '12px'
            }
          },
          {
            name: "待保养",
            type: "bar",
            stack: "Ad",
            color: "#FEF770",
            emphasis: {
              focus: "series",
            },
            data: waitBaoyang,
            label:{
              show: true,
              formatter:function (e) {
                return parseInt(((waitBaoyang[e.dataIndex] / (hasBaoyang[e.dataIndex] + waitBaoyang[e.dataIndex] + Baoyangout[e.dataIndex])) * 100)).toFixed(0) + '%'
              },
              position:'inside',
              color: 'black',
              fontSize: '12px'
            }
          },
          {
            name: "到期",
            type: "bar",
            stack: "Ad",
            color: "red",
            emphasis: {
              focus: "series",
            },
            data: Baoyangout,
            label:{
              show: true,
              formatter:function (e) {
                return parseInt(((Baoyangout[e.dataIndex] / (hasBaoyang[e.dataIndex] + waitBaoyang[e.dataIndex] + Baoyangout[e.dataIndex])) * 100)).toFixed(0) + '%'
              },
              position:'inside',
              color: 'white',
              fontSize: '12px'
            }
          },
        ],
      };
      option && myChart.setOption(option);
    },
    getTemplates () {
      this.leasionData = []
      let data = {current: 1, size: 99999}
      this.$axiox.eomsApi(`/sys/sysPollInfo/listPoll`, data).then(msg => {
        this.leasionData = msg.data
        // this.getParamData()
        this.getMyDeviceData()
      }).catch(() => {
        this.$commonJs.alertTips({
          type: 'error',
          title: '获取接口错误:',
          content: '服务器报错',
          stayTime: 5
        })
      })
    },
    async getParamData () {
      for (let i = 0; i < this.leasionData.length; i++) {
        if (this.leasionData[i]) {
          this.leasionData[i].paramsList = []
          this.leasionData[i].deviceList = []
          let paramsList = await this.getParamsSet(this.leasionData[i].id)
          this.leasionData[i].paramsList = paramsList
        }
      }
      this.getDevice()
    },
    getParamsSet (id) {
      var p = new Promise((resolve, reject) => {
        this.$axiox.cpost('/customer/paramsList?templateId=' + id).then(msg => {
          if (msg.message === '成功') {
            resolve(msg.data)
          } else {
            reject(msg.data)
          }
        }).catch(msg => {
          reject(msg.data)
        })
      })
      return p
    },
    getDevice () {
      let url = this.$commonJs.returnQuery('/customer/devicePage', {page: 1, size: 99999, parentId: this.lxUserInfo.parentId || ''})
      this.$axiox.cpost(url).then(msg => {
        if (msg.message === '成功') {
          let newList = []
          if (this.lxUserInfo.devices) {
            for (let i = 0; i < msg.data.content.length; i++) {
              if (this.lxUserInfo.devices.indexOf(msg.data.content[i].id) >=0) {
                newList.push(msg.data.content[i])
              }
            }
          } else {
            newList = msg.data.content
          }
          this.deviceList = newList
          for (let i = 0; i < msg.data.content.length; i++) {
            for (let j = 0; j < this.leasionData.length; j++) {
              if (msg.data.content[i].templateId === this.leasionData[j].id) {
                if (this.building === '' || msg.data.content[i].deviceName.indexOf(this.building) >= 0) {
                  if (this.line === '' || msg.data.content[i].deviceName.indexOf(this.line) >= 0) {
                    msg.data.content[i].paramsDataList = this.leasionData[j].paramsList
                    this.leasionData[j].deviceList.push(msg.data.content[i])
                  }
                }
              }
            }
          }
          this.getMyDeviceData()
        } else {
          this.$commonJs.alertTips({
            type: 'error',
            title: '错误:',
            content: msg.message,
            stayTime: 5
          })
        }
      }).catch(() => {
        this.$commonJs.alertTips({
          type: 'error',
          title: '获取接口错误:',
          content: '服务器报错',
          stayTime: 5
        })
      })
    },
    async getMyDeviceData () {
      console.log(this.leasionData)
      this.initEcharts = false
      this.warningDianwei = 0
      this.normalData = [0, 0,0, 0,0, 0,0]
      this.warningData = [0, 0,0, 0,0, 0,0]
      this.warningContent = [[], [], [], [], [], [], []]
      this.normalContent = [[], [], [], [], [], [], []]
      this.maintain = []
      this.maintain2 = []
      for (let i = 0; i < this.leasionData.length; i++) {
        for (let j = 0; j < this.leasionData[i].deviceList.length; j++) {
          let array = [
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0010',
              data3: '除雾环保球清洗或更换',
              data4: '700',
              data5: '检查环保球是否有杂物，更换环保球是否新品',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0011',
              data3: '观察口透明板更换',
              data4: '700',
              data5: '检查透明板是否是新品',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0012',
              data3: '塔体清洗',
              data4: '700',
              data5: '检查塔体内测是否有杂物',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0013',
              data3: '旋流板清洗',
              data4: '700',
              data5: '检查旋流板上是否有杂物',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0014',
              data3: '喷头更换',
              data4: '700',
              data5: '检查喷头是否是新品',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0015',
              data3: '喷淋管道清理',
              data4: '700',
              data5: '检查喷淋管内是否有杂物',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0016',
              data3: '水泵进出口清理',
              data4: '700',
              data5: '检查水泵进出口过滤网是否有干净',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0017',
              data3: '水泵散热风扇清理',
              data4: '700',
              data5: '检查水泵散热口是否干净有杂物',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0018',
              data3: '水槽清理',
              data4: '700',
              data5: '检查环保球是否有杂物',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0019',
              data3: '液位传感器清理',
              data4: '700',
              data5: '检查水泥是否泥沙，水是否清澈',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0020',
              data3: '温控传感器清理',
              data4: '700',
              data5: '检查传感器上是否干净，有没有杂物',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0021',
              data3: '加热管清理',
              data4: '700',
              data5: '检查加热管上是否有泥沙。',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0022',
              data3: '液位浮球清理',
              data4: '700',
              data5: '检查浮球上是否有杂物，进水是否顺畅',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BFJ0010',
              data3: '叶轮清理',
              data4: '700',
              data5: '检查叶轮是否有干净有没有杂物',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BFJ0011',
              data3: '叶轮震动检查',
              data4: '700',
              data5: '用震动仪测试震动值是否有30以下',
              data6: '震动仪'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BFJ0012',
              data3: '机壳清理',
              data4: '700',
              data5: '检查机壳是否有干净有没有杂物',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BFJ0013',
              data3: '传动皮带调整或更换',
              data4: '700',
              data5: '检查皮带是否有打滑，磨损是否更换为新品',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BFJ0014',
              data3: '轴承箱油添加或更换',
              data4: '700',
              data5: '检查轴承箱油量是否在中间油标处。',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BFJ0015',
              data3: '马达散热风扇清理',
              data4: '700',
              data5: '检查散热网上是否有干净有没有杂物。',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BFJ0016',
              data3: '马达轴承加油',
              data4: '700',
              data5: '检查轴承加油是否有黄油。',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BFJ0017',
              data3: '减震器维护',
              data4: '700',
              data5: '检查减震器螺丝是否有松动。',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BFJ0018',
              data3: '进出口软接维护',
              data4: '700',
              data5: '检查进出口软件是否有漏风。',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '电控系统',
              data2: 'TK-BDK0010',
              data3: '热成像检测',
              data4: '700',
              data5: '用热成像仪测试温度是否在60°以下',
              data6: '热成像仪'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '电控系统',
              data2: 'TK-BDK0011',
              data3: '变频器散热风扇清理',
              data4: '700',
              data5: '检查变频器散热口是否干净，有没有杂物。',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '电控系统',
              data2: 'TK-BDK0012',
              data3: '电箱散热风扇清理',
              data4: '700',
              data5: '检查电箱散热口是否干净，有没有杂物。',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '电控系统',
              data2: 'TK-BDK0013',
              data3: '电器元件维护',
              data4: '700',
              data5: '检查电器元件螺丝是否上紧。',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '电控系统',
              data2: 'TK-BDK0014',
              data3: '风压传感器传送管清理',
              data4: '700',
              data5: '检查传送管是否干净，有没有粉尘',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '电控系统',
              data2: 'TK-BDK0015',
              data3: '风压传感器校正或更换',
              data4: '700',
              data5: '用风速仪测试对比风速误差是否在3m/s内',
              data6: '风速仪'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '电控系统',
              data2: 'TK-BDK0016',
              data3: '水流传感器清理',
              data4: '700',
              data5: '检查传感器上是否干净，有没有杂物',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '管道系统',
              data2: 'TK-XGD0010',
              data3: '阀体清理',
              data4: '700',
              data5: '检查阀体内是否干净，有没有杂物',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '管道系统',
              data2: 'TK-XGD0011',
              data3: '叶片轴承加油',
              data4: '700',
              data5: '检查轴承加油是否有黄油。',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '管道系统',
              data2: 'TK-XGD0012',
              data3: '泄爆片清理或更换',
              data4: '700',
              data5: '检查泄爆片是否有杂物，更换是否为新品，有没有漏气。',
              data6: '目测'
            },
            {
              buiname: this.leasionData[i].deviceList[j].deviceName.split('-')[1],
              area: this.leasionData[i].deviceList[j].deviceName.split('-')[0],
              li: '1线',
              data1: '管道系统',
              data2: 'TK-XGD0013',
              data3: '管道清理',
              data4: '700',
              data5: '检查管道内是否干净',
              data6: '目测'
            }
          ]
          for (let s = 0; s < array.length; s++) {
            this.maintain2.push(array[s])
          }

          let deviceData = await this.DeviceData(this.leasionData[i].deviceList[j].id)
          let nowDevide = JSON.parse(JSON.stringify(this.leasionData[i].deviceList[j]))
          for (let o = 0; o < nowDevide.paramsDataList.length; o++) {
            for (let k = 0; k < deviceData.length; k++) {
              if (parseInt(nowDevide.paramsDataList[o].paramsAddress) === 4358|| parseInt(nowDevide.paramsDataList[o].paramsAddress) === 4360|| parseInt(nowDevide.paramsDataList[o].paramsAddress) === 4364) {
                let tableData = {
                  buiname: this.leasionData[i].deviceList[j].deviceName,
                  li: '1线',
                  byname: nowDevide.paramsDataList[o].timingName,
                  bypinlv:'700hrs/time',
                  scby: '',
                  xcby: '',
                  jrjxcby:  deviceData[k].paramsValue,
                  over: '',
                  admin: '',
                  overtime: ''
                }
                if (nowDevide.paramsDataList[o].paramsId === deviceData[k].paramsId) {
                  if (parseInt(nowDevide.paramsDataList[o].paramsAddress) === 4358) {
                    this.baoyangA = deviceData[k].paramsValue
                  }
                  if (parseInt(nowDevide.paramsDataList[o].paramsAddress) === 4360) {
                    this.baoyangB = deviceData[k].paramsValue
                  }
                  if (parseInt(nowDevide.paramsDataList[o].paramsAddress) === 4364) {
                    this.baoyangC = deviceData[k].paramsValue
                  }
                  nowDevide.paramsDataList[o].paramsNowValue = deviceData[k].paramsValue
                  nowDevide.paramsDataList[o].paramsNowAddress = deviceData[k].address
                  if (deviceData[k].paramsValue > 100) {
                    if (this.leasionData[i].deviceList[j].deviceName.indexOf('A区') >= 0) {
                      this.normalData[0]++
                      this.normalContent[0].push(nowDevide.paramsDataList[o].floatName)
                    }
                    if (this.leasionData[i].deviceList[j].deviceName.indexOf('B区') >= 0) {
                      this.normalData[1]++
                      this.normalContent[1].push(nowDevide.paramsDataList[o].floatName)
                    }
                    if (this.leasionData[i].deviceList[j].deviceName.indexOf('C区') >= 0) {
                      this.normalData[2]++
                      this.normalContent[2].push(nowDevide.paramsDataList[o].floatName)
                    }
                    if (this.leasionData[i].deviceList[j].deviceName.indexOf('F区') >= 0) {
                      this.normalData[3]++
                      this.normalContent[3].push(nowDevide.paramsDataList[o].floatName)
                    }
                    if (this.leasionData[i].deviceList[j].deviceName.indexOf('G区') >= 0) {
                      this.normalData[4]++
                      this.normalContent[4].push(nowDevide.paramsDataList[o].floatName)
                    }
                    if (this.leasionData[i].deviceList[j].deviceName.indexOf('H区') >= 0) {
                      this.normalData[5]++
                      this.normalContent[5].push(nowDevide.paramsDataList[o].floatName)
                    }
                    if (this.leasionData[i].deviceList[j].deviceName.indexOf('I区') >= 0) {
                      this.normalData[6]++
                      this.normalContent[6].push(nowDevide.paramsDataList[o].floatName)
                    }
                  }
                  if (deviceData[k].paramsValue <= 0) {
                    if (this.leasionData[i].deviceList[j].deviceName.indexOf('A区') >= 0) {
                      this.warningData[0]++
                      this.warningContent[0].push(nowDevide.paramsDataList[o].floatName)
                    }
                    if (this.leasionData[i].deviceList[j].deviceName.indexOf('B区') >= 0) {
                      this.warningData[1]++
                      this.warningContent[1].push(nowDevide.paramsDataList[o].floatName)
                    }
                    if (this.leasionData[i].deviceList[j].deviceName.indexOf('C区') >= 0) {
                      this.warningData[2]++
                      this.warningContent[2].push(nowDevide.paramsDataList[o].floatName)
                    }
                    if (this.leasionData[i].deviceList[j].deviceName.indexOf('F区') >= 0) {
                      this.warningData[3]++
                      this.warningContent[3].push(nowDevide.paramsDataList[o].floatName)
                    }
                    if (this.leasionData[i].deviceList[j].deviceName.indexOf('G区') >= 0) {
                      this.warningData[4]++
                      this.warningContent[4].push(nowDevide.paramsDataList[o].floatName)
                    }
                    if (this.leasionData[i].deviceList[j].deviceName.indexOf('H区') >= 0) {
                      this.warningData[5]++
                      this.warningContent[5].push(nowDevide.paramsDataList[o].floatName)
                    }
                    if (this.leasionData[i].deviceList[j].deviceName.indexOf('I区') >= 0) {
                      this.warningData[6]++
                      this.warningContent[6].push(nowDevide.paramsDataList[o].floatName)
                    }
                  }
                  this.maintain.push(tableData)
                }
              }
            }
            if (deviceData.length === 0) {
              nowDevide.paramsDataList[o].paramsNowValue = ''
            }
          }
          this.leasionData[i].deviceList[j] = nowDevide
        }
      }
      console.log(this.normalData)
      console.log(this.warningData)
      this.showleasonData = []
      this.showleasonData = JSON.parse(JSON.stringify(this.leasionData))
      // this.$nextTick(p => {
      //   this.showLoading = false
      // })
      console.log(this.showleasonData)
      this.initEcharts = true
      this.$nextTick(() => {
        this.show()
      })
      // timeInter = setTimeout(() => {
      //   this.getMyDeviceData()
      // }, 30000)
    },
    DeviceData (id) {
      var p = new Promise((resolve, reject) => {
        this.$axiox.post('/device/dataOfDevice?deviceId=' + id).then(msg => {
          resolve(msg.data || [])
          console.log(reject)
        }).catch(() => {
        })
      })
      return p
    }
  },
  destroyed() {
    clearTimeout(timeInter)
  },
  mounted(){
    this.getTemplates()
  }
}
</script>

<style scoped>

</style>
