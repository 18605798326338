<template>
  <!--日志管理-->
  <div id="myLogManager">
    <div class="allBody" style="background: rgba(30,46,104, 0.5)">
      <div class="allSearch" v-if="userAccount === 'admin'">
        <div class="searchBody">
          <div class="m_r_10">
            <allSelect v-on:on-change="getList(1)" :needReturn="'id'" :placeholder="'品牌商'" :matchValue="'enterpriseName'" :showValue="'{{enterpriseName}}'" :chooseData="myBrandList" v-model="postData.enterpriseId">
            </allSelect>
          </div>
        </div>
      </div>
      <div class="allTablePlace">
        <table cellspacing="0" class="allTable ">
          <thead>
          <tr class="t_c">
            <th>序号</th>
            <th>用户名</th>
            <th>操作记录</th>
            <th>IP</th>
            <th>时间</th>
          </tr>
          </thead>
          <tbody>
          <tr :key="index" v-for="(item, index) in myList">
            <td>{{ (index + 1) + (postData.size * (page - 1)) }}</td>
            <td>{{item.managerName || item.customerName}}</td>
            <td>{{item.operation}}</td>
            <td>{{item.ip}}</td>
            <td>{{$commonJs.returnAllDate(item.createTime)}}</td>
          </tr>
          </tbody>
        </table>
        <page v-if="totalPage !== 0" v-on:on-getList="getCustomerLogList($event)" :totalPage="totalPage" :page="page"></page>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'myLogManager',
  data () {
    return {
      lxUserInfo: JSON.parse(sessionStorage.lxUserInfo || '{}'),
      userAccount: '',
      totalPage: 0,
      page: 1,
      postData: {
        customerId: 54,
        enterpriseId: 45,
        managerId: '',
        parentId: 54,
        roleName: '',
        size: 20,
        page: 1
      },
      myBrandList: [
        {
          id: 0,
          name: '所有'
        }
      ],
      myList: [],
      tkUserInfo: ''
    }
  },
  created () {
    this.postData.customerId = this.lxUserInfo.id
    this.postData.parentId = this.lxUserInfo.id
    this.postData.enterpriseId = this.lxUserInfo.enterpriseId
    this.userAccount = window.sessionStorage.getItem('userAccount') || ''
    if (sessionStorage.tkUserInfo && sessionStorage.tkUserInfo !== 'undefined' && sessionStorage.tkUserInfo !== 'null') {
      this.tkUserInfo = JSON.parse(sessionStorage.tkUserInfo)
    }
    if (this.userAccount === 'admin') {
      this.getBrandList()
      this.getList(1)
    } else {
      this.getCustomerLogList(1)
    }
    // this.getList(1)
  },
  methods: {
    // 获取品牌商
    getBrandList () {
      this.$axiox.postTwo('/auth/childPage', {page: 1, size: 9999}).then(msg => {
        let data = msg.data.content || []
        this.myBrandList = this.myBrandList.concat(data)
      })
    },
    getList (page) {
      if (this.userAccount === 'admin') {
        // this.postData.enterpriseId = parseInt(this.postData.enterpriseId) === 0 ? '' : this.postData.enterpriseId
        // this.postData.managerId = parseInt(this.postData.managerId) === 0 ? '' : this.postData.managerId
      } else {
        // this.postData.enterpriseId = this.tkUserInfo.id
      }
      let data = JSON.parse(JSON.stringify(this.postData))
      data.page = page
      this.$axiox.postTwo('/managerHistory/managerLogPage', data).then(msg => {
        this.myList = msg.data.list || []
        this.page = msg.data.pageNum
        this.totalPage = Math.ceil(msg.data.total / this.postData.size)
      })
    },
    // 客户端获取数据
    getCustomerLogList (page) {
      // this.postData.customerId = this.tkUserInfo.id
      let data = JSON.parse(JSON.stringify(this.postData))
      data.page = page
      this.$axiox.cpostTwo('/managerHistory/managerLogPage', data).then(msg => {
        this.myList = msg.data.list || []
        this.page = msg.data.pageNum
        this.totalPage = Math.ceil(msg.data.total / this.postData.size)
      })
    }
  }
}
</script>

<style scoped>

</style>
