<template>
  <div style="height: 49%;box-sizing: border-box;">
    <div class="p_l_20"><span>轴承温度</span></div>
    <div id="main3" style="margin-top:20px" :style="{ height: '35vh'}"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "echart",
  data() {
    return {
      lxUserInfo: JSON.parse(sessionStorage.lxUserInfo || '{}'),
      msg: "Welcome to Your Vue.js App",
    };
  },
  props: [
    'normalData',
    'sortName',
    'warningData',
    'closeData',
    'closeContent',
    'normalContent',
    'warningContent',
  ],
  comments: {
    echarts,
  },
  methods:{
    show(){
      let mouseCurValue = ''
      const normalData = this.normalData
      const warningData = this.warningData
      const closeData = this.closeData
      const sortName = this.sortName
      const normalContent = [this.normalContent, this.warningContent, this.closeContent]
      var chartDom = document.getElementById("main3");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: "axis",
          extraCssText: "background-color: #202F50;border:1px #234A71 solid",
          textStyle: {
            color:"white"
          },
          formatter: function (params) {
            let res = "", sum = 0;

            for (let i = 0; i < params.length; i++) {
              let series = params[i];
              // 累计series.data，与mouseCurValue做比较，找出鼠标位置对应的series
              sum += Number(series.data);
              if (sum >= mouseCurValue) {
                console.log(series)
                // res = series.axisValue + "<br/>" + series.marker + series.seriesName + ":" + series.data + "<br/>";
                res = '<p style="border-bottom: 1px #414C62 solid;padding: 10px;text-align:left">'+ series.axisValue + '<br />'+'</p>' + '<p style="border-bottom: 2px #414C62 dotted;padding: 10px;text-align:left;display: flex;align-items: center;">'+ `<span style="width: 50px;">${series.seriesName}：</span>` + series.data + '<br />'+'</p>' + '<p style="text-align: left;padding: 10px;display: flex;align-items: center;">'+`<span style="width: 50px;">线别：</span>` +normalContent[i][series.dataIndex]+'</p>'
                break;
              }
            }
            return res;
          },
          axisPointer: {
            type: "cross",
            label: {
              formatter: function (params) {
                if (params.seriesData.length === 0) {
                  // 就是这里，可以获取到我想要的那个数据
                  mouseCurValue = params.value;
                  console.log(mouseCurValue)
                }
              }
            }
          },
        },
        legend: {
          textStyle:{
            color:"white"
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: sortName,
            axisLabel: {
              show: true,
              textStyle:{
                color:"white"
              }
            }
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              show: true,
              textStyle:{
                color:"white"
              }
            }
          }
          ,
        ],
        series: [
          {
            name: "正常",
            type: "bar",
            stack: "Ad",
            color: "#0CCF67",
            emphasis: {
              focus: "series",
            },
            data: normalData,
          },
          {
            name: "异常",
            type: "bar",
            stack: "Ad",
            color: "red",
            emphasis: {
              focus: "series",
            },
            data: warningData,
          },
          {
            name: "离线",
            type: "bar",
            stack: "Ad",
            color: "#999999",
            emphasis: {
              focus: "series",
            },
            data: closeData,
          },
        ],
      };
      option && myChart.setOption(option);
    }
  },
  mounted(){
    this.show();
  }
}
</script>

<style scoped>

</style>
