<template>
  <div style="height: 100%;position: relative">
    <div style="text-align: left;font-size: 22px;border-left: white 3px solid;padding: 0 14px">风速数据</div>
    <div  style="background-color: rgb(34, 48, 77);border-radius: 10px;margin-top: 15px;padding-left: 15px;height: calc(100% - 40px)">
      <div v-if="!hidden" style="text-align: left;padding: 15px 0">
        <div class="mag_r-20" style="display: inline-block;vertical-align: middle;">
          <span class="mag_r-20">区域</span>
          <span>
          <select @change="getParams()" v-model="building" class="allSelect sele" name="">
             <option :value="''">全部</option>
             <option v-for="(item, index) in sortTypeFilter" :key="index" :value="item">{{ item }}</option>
          </select>
        </span>
        </div>
        <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
          <span class="mag_r-20">线别</span>
          <span>
          <select @change="getParams()" v-model="line" class="allSelect sele" name="">
                     <option :value="''">全部</option>
                      <option v-for="(item, index) in lineNameFilter" :key="index" :value="item">{{ item }}</option>
          </select>
        </span>
        </div>
        <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
          <span class="mag_r-20">开始时间</span>
          <span>
            <div class="xd disp">
          <cander :newWidth="'150px'" class="allInput d_l" v-model="newStartDate"  ></cander>
              <img class="jd" src="../../../assets/img/riqi.png" height="20px" style="margin-left: -27px;margin-top: 5px" alt="">
            </div>
            <!--          <input @change="initRamdomData()" v-model="newStartDate" type="date" class="i_time" />-->
        </span>
        </div>
        <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
          <span class="mag_r-20">结束时间</span>
          <span>
            <div class="disp xd">
          <cander :newWidth="'150px'" class="allInput d_l" :min-date="newStartDate" v-model="newEndDate" ></cander>
            <img class="jd" src="../../../assets/img/riqi.png" height="20px" style="margin-left: -27px;margin-top: 5px" alt=""></div>
            <!--          <input @change="initRamdomData()" v-model="newEndDate" type="date" class="i_time" />-->
        </span>
        </div>

          <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
              <span class="mag_r-20">筛选间隔</span>
              <span>
                <select @change="getParams()" v-model="timeType" class="allSelect sele" name="">
                            <option :value="1">10分钟</option>
                            <option :value="2">30分钟</option>
                            <option :value="3">1小时</option>
                            <option :value="4">4小时</option>
                </select>
              </span>
          </div>
        <button @click="getParams()" class="btn btn_success mag_l-20 mag_r-20"><span>搜索</span></button>
        <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
          <span style="border-radius: 5px;border: 1px solid #eee;padding: 10px 20px;cursor: pointer" @click="exportExcel" class="mag_r-20">导出数据</span>
        </div>
      </div>
      <div id="hiddenHistoryList" v-show="dao" class="jz_flex"  style="height: 250px;overflow: auto;">
        <table class="allTable bd ba_white wz_black" border="1" cellspacing="0">
          <thead>
          <tr class="bd">
            <th>区域</th>
            <th>线别</th>
            <th>端口</th>
<!--            <th>类型</th>-->
            <th>风速</th>
            <th>时间</th>
            <th>结果</th>
            <!--<th>排序</th>-->
            <!--<th>审核状态</th>-->
          </tr>
          </thead>
          <tbody>
          <tr class="bd" :key="index" v-for="(item, index) in tableMaintain">
            <td>{{ item.Building }}</td>
            <td>{{ item.Line }}</td>
            <td>{{ item.Port }}</td>
<!--            <td>{{ item.Category }}</td>-->
            <td>{{ item.Velocity }}</td>
            <td>{{ item.Date }}</td>
            <td>{{ item.Result }}</td>
            <!--<td>{{item.sort}}</td>-->
            <!--<td>{{ item.handleStatus }}</td>-->
          </tr>
          </tbody>
        </table>
      </div>
      <div style="position: relative;height: 100%" class="jz_flex">

        <div v-if="showLoading" style="position: absolute;opacity: 1;background: #001E31;display: flex;align-items: center;justify-content: center;right: 20px" class="cover">
          <div style="position: relative;top: -30px">
            <img src="../../../assets/img/newLoading.gif" width="50%"/>
            <p class="font20">加载中，请耐心等待</p>
          </div>
        </div>
        <div id="mains1" :style="{'height': !hidden ? 'calc(100vh - 290px)' : '100%'}" style="padding: 20px;border-radius: 10px;width: 100%;"></div>
      </div>
    </div>

  </div>
</template>
<script>
import * as echarts from "echarts";
import lodash from 'lodash'
export default {
  name: "Datagraph1",
  props: ['hidden'],
  data() {
    return {
      warningData: [],
      templateArray: [],
      likaidelDataArr: [],
      likaidelDataArrTable: [],
      templateData: [],
      sortTypeFilter: [],
      lineNameFilter: [],
      templateDetailArray: [],
      sortName: [],
      lxUserInfo: JSON.parse(sessionStorage.lxUserInfo || '{}'),
      newStartDate: '',
      newEndDate: '',
      building: '',
        timeType: 3,
      line: '',
      dao: false,
      showLoading: false,
      lineName: [],
      deviceSortArray: [],
      deviceSortType: [],
      list: [],
      windChart: {},
      maintain: [
      ],
      tableMaintain: [],
      echartsData: [
      ]
    };
  },
  created() {
    this.newEndDate = this.$commonJs.todayDate
    this.newStartDate = this.$commonJs.lastWeekDay()
    if (this.lxUserInfo.windChart) {
      this.windChart = JSON.parse(this.lxUserInfo.windChart)
      // console.log(this.windChart)
    }
    this.getHistoryData()
  },
  methods: {
    pad(num, n) {
      // // console.log(num, 'num')
      num = num || 999999
      var str = num.toString();
      while (str.length < n) {
        str = '0' + str;
      }
      return str;
    },
    getHistoryData () {
      let url = this.$commonJs.returnQuery('/customer/devicePage', {page: 1, size: 99999, parentId: this.lxUserInfo.parentId || ''})
      this.$axiox.cpost(url).then(async msg => {
        if (msg.message === '成功') {
          let newList = []
          if (this.lxUserInfo.devices) {
            for (let i = 0; i < msg.data.content.length; i++) {
              if (this.lxUserInfo.devices.indexOf(msg.data.content[i].id) >=0) {
                newList.push(msg.data.content[i])
              }
            }
          } else {
              newList = msg.data.content
          }
          for (let i = 0; i < newList.length; i++) {
              if (this.templateArray.indexOf(newList[i].templateId) >= 0) {
                  newList[i].templateParam = this.templateData[this.templateArray.indexOf(newList[i].templateId)]
                  newList[i].templateDetail = this.templateDetailArray[this.templateArray.indexOf(newList[i].templateId)]
              } else {
                  const detail = await this.$axiox.cpost('/customer/paramsList?templateId=' + newList[i].templateId)
                  const array = []
                  for (let i = 0; i < detail.data.length; i++) {
                      if (detail.data[i].chart === 1) {
                          array.push(+detail.data[i].paramsAddress)
                      }
                  }
                  // console.log(array,'array')
                  newList[i].templateParam = array
                  newList[i].templateDetail = detail.data
                  this.templateData.push(array)
                  this.templateDetailArray.push(detail.data)
                  this.templateArray.push(newList[i].templateId)
              }
          }
          for (let i = 0; i < newList.length; i++) {
            if (newList[i].sortTypeId && this.deviceSortArray.indexOf(newList[i].sortTypeId) >= 0) {
              this.deviceSortType[this.deviceSortArray.indexOf(newList[i].sortTypeId)].devices.push(newList[i])
            } else {
              this.deviceSortType.push({
                sortTypeId: newList[i].sortTypeId || newList[i].id,
                sortType: newList[i].sortType || newList[i].deviceName,
                buildingName: newList[i].sortType || newList[i].deviceName,
                devices: [newList[i]]
              })
              this.deviceSortArray.push(newList[i].sortTypeId || null)
              if (this.sortTypeFilter.indexOf(newList[i].sortType || newList[i].deviceName) >= 0) {
                // console.log(1)
              } else {
                this.sortTypeFilter.push(newList[i].sortType || newList[i].deviceName)
              }
            }
          }
          for (let i = 0; i < this.deviceSortType.length; i++) {
            const lineIdArray = []
            this.deviceSortType[i].lineData = []
            for (let j = 0; j < this.deviceSortType[i].devices.length; j++) {
              if (this.deviceSortType[i].devices[j].lineId && lineIdArray.indexOf(this.deviceSortType[i].devices[j].lineId) >= 0) {
                this.deviceSortType[i].lineData[lineIdArray.indexOf(this.deviceSortType[i].devices[j].lineId)].devices.push(this.deviceSortType[i].devices[j])
              } else {
                this.deviceSortType[i].lineData.push({
                  lineName: this.deviceSortType[i].devices[j].lineName || this.deviceSortType[i].devices[j].deviceName,
                  lineId: this.deviceSortType[i].devices[j].lineId || this.deviceSortType[i].devices[j].id,
                  devices: [this.deviceSortType[i].devices[j]],
                    portData: [],
                    paramsNameData: [],
                    time: [],
                    deviceName: [],
                    detailData: []
                })
                lineIdArray.push(this.deviceSortType[i].devices[j].lineId || null)
                if (this.lineNameFilter.indexOf(this.deviceSortType[i].devices[j].lineName || this.deviceSortType[i].devices[j].deviceName) >= 0) {
                  // console.log(1)
                } else {
                  this.lineNameFilter.push(this.deviceSortType[i].devices[j].lineName || this.deviceSortType[i].devices[j].deviceName)
                }
              }
            }
          }
          this.deviceList = newList
          this.getParams()
        }
      })
    },
    async getParams () {
      this.showLoading = true
      // const getHistoryData = await this.$axiox.cpost('/warning/handles', {handle:1,deviceName:'',customerId:null,typeId:"",start:this.newStartDate,end:this.newEndDate,size:9999,keep:0,page:1, type: 0})
      // console.log(getHistoryData, 'getHistoryData')
      // const historyData = getHistoryData.data.list
      let date = []
      this.echartsData = []
      if (this.newStartDate && this.newEndDate) {
        date = this.getAllDates(this.newStartDate, this.newEndDate)
      }
      let allDeviceParams = []
      this.maintain = []
      let newArr = {}
      let promiseAll = []
      let nowDeviceList = []
      for (let i = 0; i < this.deviceList.length; i++) {
          if (this.building === '' || (this.deviceList[i].sortType && this.deviceList[i].sortType.indexOf(this.building) >= 0) || this.deviceList[i].deviceName.indexOf(this.building) >= 0) {
              if (this.line === '' || (this.deviceList[i].lineName && this.deviceList[i].lineName.indexOf(this.line) >= 0)  || this.deviceList[i].deviceName.indexOf(this.line) >= 0) {
              let url = '/device/dataOfDeByTime?deviceId=' + this.deviceList[i].id + '&startTime=' + this.newStartDate +' 00:00:00&endTime=' + this.newEndDate +' 23:59:59&t=1635866005546'
              if (this.timeType === 1) {
                  url = '/device/dataOfDeByTenMinutes?deviceId=' + this.deviceList[i].id + '&startTime=' + this.newStartDate +' 00:00:00&endTime=' + this.newEndDate +' 23:59:59&t=1635866005546'
              }
              if (this.timeType === 2) {
                  url = '/device/dataOfDeByHalfHour?deviceId=' + this.deviceList[i].id + '&startTime=' + this.newStartDate +' 00:00:00&endTime=' + this.newEndDate +' 23:59:59&t=1635866005546'
              }
              if (this.timeType === 3) {
                  url = '/device/dataOfDeByOneHour?deviceId=' + this.deviceList[i].id + '&startTime=' + this.newStartDate +' 00:00:00&endTime=' + this.newEndDate +' 23:59:59&t=1635866005546'
              }
            promiseAll.push(this.$axiox.post(url))
            nowDeviceList.push(this.deviceList[i])
          }
        }
      }
      // // console.log(nowDeviceList)
      Promise.all(promiseAll).then((e) => {
        // // console.log(e[0], '所有数据')
        // 处理立凯的数据


        // if (this.lxUserInfo.name.indexOf('立铠') !== -1) {
        //   this.likaidelDataArr = []
        //   this.likaidelDataArrTable = []
        //   let curIndex = 0
        //   e = e.reduce((prev, item) => {
        //     this.likaidelDataArr.push({
        //       data: {}
        //     })
        //     for (const key in item.data) {
        //       if (!this.likaidelDataArr[curIndex].data[key]) {
        //         this.likaidelDataArr[curIndex].data[key] = []
        //       }
        //       item.data[key] = item.data[key].reduce((prev, item2) => {
        //         if (Number(item2.value) >= 23) {
        //           prev.push(item2)
        //         } else {
        //           this.likaidelDataArr[curIndex].data[key].push(item2)
        //         }
        //         return prev
        //       }, [])
        //       // this.likaidelDataArr[curIndex].data[key] = item.data[key].filter(item2 => Number(item2?.value || 0) < 23)
        //     }
        //     curIndex++
        //     prev.push(item)
        //     return prev
        //   }, [])
        //   // // console.log(this.likaidelDataArr, 'likaidelDataArr', e)
        // }
        // for (let j = 0; j < e.length; j++) {
        //   for (let i = 0; i < historyData.length; i++) {
        //     if (nowDeviceList[j].id === historyData[i].deviceId) {
        //       // console.log( historyData[i], ' historyData[i].deviceId historyData[i].deviceId historyData[i].deviceId historyData[i].deviceId historyData[i].deviceId')
        //       if(!e[j].data[historyData[i].createTime]) {
        //         e[j].data[historyData[i].createTime] = []
        //       }
        //       if (e[j].data[historyData[i].createTime]) {
        //         const param = {
        //           pointId: historyData[i].paramsAddress,
        //           time: historyData[i].createTime,
        //           value: "20.1"
        //         }
        //         e[j].data[historyData[i].createTime].push(param)
        //       }
        //     }
        //   }
        // }
        // // console.log(e, '处理过的')
        for (let i = 0; i < e.length; i++) {
          let paramsData = e[i]
          allDeviceParams.push(paramsData.data)
          for (let i = 0; i < allDeviceParams.length;i++){
            for (let j in allDeviceParams[i]) {
              for (let k in allDeviceParams[i][j]) {
                allDeviceParams[i][j][k].device = nowDeviceList[i]
              }
            }
          }
          for (let j in paramsData.data) {
            for (let k in paramsData.data[j]) {
              paramsData.data[j][k].device = nowDeviceList[i]
                // todo
                newArr[j] = paramsData.data[j]
              // if (parseInt(paramsData.data[j][k].pointId) === 4301 && parseInt(paramsData.data[j][k].value) === 0
              // ) {
              //   newArr[j] = paramsData.data[j]
              // }
            }
          }
        }
        for (let s = 0; s < allDeviceParams.length;s++) {
          for (let i in allDeviceParams[s]) {
            allDeviceParams[s][i].forEach(p => {
                // console.log(p.device && p.device.templateParam && p.device.templateParam, 'p.device && p.device.templateParam && p.device.templateParam')
              if (p.device && p.device.templateParam && p.device.templateParam.indexOf(+p.pointId) >= 0 && p.value !== 0.25) {
                  let tableData = {
                      Building: p.device.deviceName,
                      Line: p.device.lineName,
                      Port: p.device.templateDetail.find(s => {
                          return +s.paramsAddress === +p.pointId
                      }).paramsName,
                      Category: this.returnFengsuName(p.pointId).indexOf('主') >= 0 ? '主管':'支管',
                      Velocity: parseFloat(p.value).toFixed(1),
                      Date: i,
                      sort: p.device.templateDetail.find(s => {
                        return +s.paramsAddress === +p.pointId
                      }).sort,
                      // sort: s,
                      Result: (parseFloat(p.value).toFixed(1) < +this.windChart.warnLine ? 'NG' : 'OK')
                  }
                  // console.log(tableData,'tableData')
                  this.maintain.push(tableData)
              }
            })
          }
        }
        if (this.likaidelDataArr.length) {
          let allDeviceParams = []
          const e = this.likaidelDataArr
          for (let i = 0; i < e.length; i++) {
            let paramsData = e[i]
            allDeviceParams.push(paramsData.data)
            for (let i = 0; i < allDeviceParams.length;i++){
              for (let j in allDeviceParams[i]) {
                for (let k in allDeviceParams[i][j]) {
                  allDeviceParams[i][j][k].device = nowDeviceList[i]
                }
              }
            }
            for (let j in paramsData.data) {
              for (let k in paramsData.data[j]) {
                paramsData.data[j][k].device = nowDeviceList[i]
                // todo
                newArr[j] = paramsData.data[j]
                // if (parseInt(paramsData.data[j][k].pointId) === 4301 && parseInt(paramsData.data[j][k].value) === 0
                // ) {
                //   newArr[j] = paramsData.data[j]
                // }
              }
            }
          }
          for (let s = 0; s < allDeviceParams.length;s++) {
            for (let i in allDeviceParams[s]) {
              allDeviceParams[s][i].forEach(p => {
                // console.log(p.device && p.device.templateParam && p.device.templateParam, 'p.device && p.device.templateParam && p.device.templateParam')
                if (p.device && p.device.templateParam && p.device.templateParam.indexOf(+p.pointId) >= 0 && p.value !== 0.25) {
                  let tableData = {
                    Building: p.device.deviceName,
                    Line: p.device.lineName,
                    Port: p.device.templateDetail.find(s => {
                      return +s.paramsAddress === +p.pointId
                    }).paramsName,
                    Category: this.returnFengsuName(p.pointId).indexOf('主') >= 0 ? '主管':'支管',
                    Velocity: parseFloat(p.value).toFixed(1),
                    Date: i,
                    sort: p.device.templateDetail.find(s => {
                      return +s.paramsAddress === +p.pointId
                    }).sort,
                    // sort: s,
                    Result: (parseFloat(p.value).toFixed(1) < +this.windChart.warnLine ? 'NG' : 'OK')
                  }
                  // // console.log(tableData,'tableData')
                  this.likaidelDataArrTable.push(tableData)
                }
              })
            }
          }
        }
        // // console.log(this.likaidelDataArrTable, this.likaidelDataArr, e, 'this.likaidelDataArrTable,')
        this.tableMaintain = lodash.sortBy([...this.likaidelDataArrTable, ...this.maintain], (a) => {
          // // console.log(a, '数据')
          return `${a.Date}${a.Building}${a.Line}${this.pad(a.sort, 6)}`
        })
        // console.log( this.maintain, ' this.maintain')
        for (let i = 0; i < date.length; i++) {
          let data = {
            time: date[i],
            bulidingData: JSON.parse(JSON.stringify(this.deviceSortType))
          }
          let newArray = []
          let index = 0
          let index1 = 0
          let index2 = 0
          for (let j in allDeviceParams) {
            index++
            for (let k in allDeviceParams[j]) {
              if (date[i].split('-')[0] === k.split('-')[0]
                  && date[i].split('-')[1] === k.split('-')[1]
                  && date[i].split('-')[2] === k.split('-')[2].split(' ')[0]
              ) {
                index1++
                // console.log(allDeviceParams, 'allDeviceParams')
                // console.log(allDeviceParams[j][k], 'allDeviceParams[j][k]')
                // console.log(date[i], 'date[i]')
                for (let o = 0; o < allDeviceParams[j][k].length; o++) {
                  index2++
                    // todo
                    newArray.push(allDeviceParams[j][k][o])
                  // if (parseInt(allDeviceParams[j][k][o].pointId) === 4301 && parseInt(allDeviceParams[j][k][o].value) === 0) {
                  //   newArray.push(allDeviceParams[j][k])
                  // }
                }
              }
            }
          }
          // console.log(index)
          // console.log(index1)
          // console.log(index2)
          // console.log(newArray, 'newArraynewArraynewArraynewArraynewArraynewArraynewArraynewArraynewArray')
          // console.log( this.deviceSortType, ' this.deviceSortType this.deviceSortType')
          newArray.forEach((j) => {
            // p.forEach(j => {
              if (j.device && j.device.templateParam && j.device.templateParam.indexOf(+j.pointId) >= 0) {
                for (let n = 0; n < data.bulidingData.length; n++) {
                  for (let m = 0; m < data.bulidingData[n].lineData.length; m++) {
                    for (let s = 0; s < data.bulidingData[n].lineData[m].devices.length; s++) {
                      if (data.bulidingData[n].lineData[m].devices[s].id === j.device.id) {
                          data.bulidingData[n].lineData[m].detailData.push(parseFloat(j.value))
                          data.bulidingData[n].lineData[m].portData.push(j.device.lineName)
                          data.bulidingData[n].lineData[m].paramsNameData.push(
                              j.device.templateDetail.find(p => {
                                  return +p.paramsAddress === +j.pointId
                              }).paramsName
                          )
                          data.bulidingData[n].lineData[m].time.push(j.time)
                          data.bulidingData[n].lineData[m].deviceName.push(j.device.deviceName)
                      }
                    }
                  }
                }
              }
            })
          // })
          this.echartsData.push(data)
        }
        // // console.log(this.echartsData, 'this.echartsDatathis.echartsDatathis.echartsData')
        this.showLoading = false
        this.show(date)
      })
    },
    getAllDates (begin, end) {
      var arr = []
      var ab = begin.split('-')
      var ae = end.split('-')
      var db = new Date()
      db.setUTCFullYear(ab[0], ab[1] - 1, ab[2])
      var de = new Date()
      de.setUTCFullYear(ae[0], ae[1] - 1, ae[2])
      var unixDb = db.getTime() - 24 * 60 * 60 * 1000
      var unixDe = de.getTime() - 24 * 60 * 60 * 1000
      for (var k = unixDb; k <= unixDe;) {
        k = k + 24 * 60 * 60 * 1000
        arr.push(this.returnTimeDate(parseInt(k), 'YYMMDDHeng'))
      }
      return arr
    },
    // 时间格式
    returnTimeDate (date, type) {
      if (date === null || date === '' || date === undefined) {
        return ''
      } else {
        let newDate = new Date(date)
        let year = newDate.getFullYear()
        let month = newDate.getMonth() + 1
        let Todate = newDate.getDate()
        let hour = newDate.getHours()
        let min = newDate.getMinutes()
        let sec = newDate.getSeconds()
        let zhYear = window.localStorage.getItem('language') === 'en-US' ? '/' : '年'
        let zhMouth = window.localStorage.getItem('language') === 'en-US' ? '/' : '月'
        let zhDate = window.localStorage.getItem('language') === 'en-US' ? ' ' : '日'
        month = month < 10 ? '0' + month : month
        Todate = Todate < 10 ? '0' + Todate : Todate
        hour = hour < 10 ? '0' + hour : hour
        min = min < 10 ? '0' + min : min
        sec = sec < 10 ? '0' + sec : sec
        if (type === 'YYMMDDHHMMSS') {
          return year + '/' + month + '/' + Todate + ' ' + hour + ':' + min + ':' + sec
        } else if (type === 'YYMMDD') {
          return year + '/' + month + '/' + Todate
        } else if (type === 'YYMMDDHHMM') {
          return year + '/' + month + '/' + Todate + ' ' + hour + ':' + min
        } else if (type === 'YYMMDDZH') {
          return year + zhYear + month + zhMouth + Todate + zhDate
        } else if (type === 'YYMMDDHHMMSSHeng') {
          return year + '-' + month + '-' + Todate + ' ' + hour + ':' + min + ':' + sec
        } else if (type === 'YYMMDDHeng') {
          return year + '-' + month + '-' + Todate
        }
      }
    },
    exportExcel () {
      let html = '<html><head><meta charset=\'utf-8\' /></head><body>'
      html += document.getElementById('hiddenHistoryList').innerHTML
      html += '</body></html>'
      // 实例化一个Blob对象，其构造函数的第一个参数是包含文件内容的数组，第二个参数是包含文件类型属性的对象
      let blob = new Blob([html], {
        type: 'application/vnd.ms-excel'
      })
      let a = document.createElement('a')
      a.href = URL.createObjectURL(blob)
      a.download = '风速数据.xls'
      a.click()
    },
    returnFengsuName (address) {
      if (parseInt(address) === 4306) {
        return '1#主风速'
      }
      if (parseInt(address) === 4308) {
        return '2#主风速'
      }
      if (parseInt(address) === 4310) {
        return '3#主风速'
      }
      if (parseInt(address) === 4312) {
        return '4#主风速'
      }
      if (parseInt(address) === 4314) {
        return '5#主风速'
      }
      if (parseInt(address) === 4316) {
        return '6#主风速'
      }
      if (parseInt(address) === 4318) {
        return '7#主风速'
      }
      if (parseInt(address) === 4320) {
        return '8#主风速'
      }
      if (parseInt(address) === 4322) {
        return '1#支风速'
      }
      if (parseInt(address) === 4324) {
        return '2#支风速'
      }
      if (parseInt(address) === 4326) {
        return '3#支风速'
      }
      if (parseInt(address) === 4328) {
        return '4#支风速'
      }
      if (parseInt(address) === 4330) {
        return '5#支风速'
      }
      if (parseInt(address) === 4332) {
        return '6#支风速'
      }
      if (parseInt(address) === 4334) {
        return '7#支风速'
      }
      if (parseInt(address) === 4336) {
        return '8#支风速'
      }
      if (parseInt(address) === 4338) {
        return '9#支风速'
      }
      if (parseInt(address) === 4340) {
        return '10#支风速'
      }
      if (parseInt(address) === 4342) {
        return '11#支风速'
      }
      if (parseInt(address) === 4344) {
        return '12#支风速'
      }
      if (parseInt(address) === 4346) {
        return '13#支风速'
      }
      if (parseInt(address) === 4348) {
        return '14#支风速'
      }
      if (parseInt(address) === 4350) {
        return '15#支风速'
      }
      if (parseInt(address) === 4352) {
        return '16#支风速'
      }
      return ''
    },
    show(date, yrate, newhours) {
      let that = this
      // const lineName = this.lineName
      // const line = this.line
      var chartDom = document.getElementById("mains1");
      var myChart = echarts.init(chartDom);
      var option;
      let arr = []
      let arr2 = []
      let index = parseFloat(this.windChart.ymin)
      for (let i = index; i < this.windChart.ymax / this.windChart.yjiange; i++) {
        if (parseFloat(parseFloat(index).toFixed(1)) === +this.windChart.preLine) {
          arr.push(parseFloat(parseFloat(index).toFixed(1)))
          arr2.push(parseFloat(parseFloat(index).toFixed(1)) + 'm/s预警')
        } else {
          if (parseFloat(parseFloat(index).toFixed(1)) === +this.windChart.warnLine) {
            arr.push(parseFloat(parseFloat(index).toFixed(1)))
            arr2.push(parseFloat(parseFloat(index).toFixed(1)) + 'm/s标准')
          } else {
            arr.push(parseFloat(parseFloat(index).toFixed(1)))
            arr2.push(parseFloat(parseFloat(index).toFixed(1)) + 'm/s')
          }
        }
        index += parseFloat(this.windChart.yjiange)
      }
      let Yrate = yrate || arr || [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21]
      const hours = newhours || arr2 || Yrate.map((p) => {return p + 'm/s'});
      const days = []
      for (let i = 0; i < date.length; i++) {
        days.push(date[i].split('-')[1] + '-' + date[i].split('-')[2])
      }
      let series = []
      // let colorArray = ["#64AD59"]
      let allData = []
      for (let n = 0; n < this.deviceSortType.length; n++) {
        allData.push([])
      }
      for (let i = 0; i < this.echartsData.length; i++) {
        for (let j = 0; j < this.echartsData[i].bulidingData.length; j++) {
          for (let o = 0; o < this.echartsData[i].bulidingData[j].lineData.length; o++) {
            for (let k = 0; k < this.echartsData[i].bulidingData[j].lineData[o].detailData.length; k++) {
              this.lineName.push(this.line.indexOf(o))
              let yRate = -1
              for (let h = 0; h < Yrate.length; h++) {
                if (Yrate[h - 1]) {
                  let value = this.echartsData[i].bulidingData[j].lineData[o].detailData[k]
                  if (value >= Yrate[h - 1] && value < Yrate[h]) {
                    yRate = parseFloat(h - 1) + parseFloat((value - Yrate[h - 1]) / (Yrate[h] - Yrate[h - 1]))
                  }
                }
              }
                let SerData = {deviceName: this.echartsData[i].bulidingData[j].lineData[o].deviceName[k],port: this.echartsData[i].bulidingData[j].lineData[o].portData[k],paramName: this.echartsData[i].bulidingData[j].lineData[o].paramsNameData[k],time: this.echartsData[i].bulidingData[j].lineData[o].time[k], line: (o + 1) + '线', value: [i, parseFloat(parseFloat(yRate).toFixed(1))]}
                allData[j].push(SerData)
            }
          }
        }
      }
      // console.log(this.echartsData,'this.echartsData')
      for (let i = 0; i < this.deviceSortType.length; i++) {
        let seriseData = {
          name: this.deviceSortType[i].buildingName,
          type: "scatter",
          itemStyle: {
            // normal: {
            //   color: colorArray[i],
            // },
          },
          data: [],
          animationDelay: function(idx) {
            return idx * 5;
          },
          markLine: {
            silent: true,
            symbol: ["none", "none"],
            data : [{
              // silent:true,             //鼠标悬停事件  true没有，false有
              lineStyle:{               //警戒线的样式  ，虚实  颜色
                type:"solid",
                color:"#ffffff",
                width: 2,
              },
              label:{
                position:'end',
                formatter: that.windChart.warnLine + "m/s标准",
                color: '#ffffff',
                fontSize: 18
              },
              yAxis: that.windChart.warnLine + 'm/s标准'         // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
            }, {
              // silent:true,             //鼠标悬停事件  true没有，false有
              lineStyle:{               //警戒线的样式  ，虚实  颜色
                type:"dashed",
                width: 2,
                color:"yellow",
              },
              label:{
                position:'end',
                formatter: that.windChart.preLine + "m/s预警",
                color: '#ffffff',
                fontSize: 18
              },
              yAxis: that.windChart.preLine + 'm/s预警'         // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
            }]
          }
        }
        for (let j = 0; j < allData[i].length; j++) {
          seriseData.data.push(allData[i][j])
        }
        series.push(seriseData)
      }
      option = {
        title: {
          text: "风速/日期",
          textStyle: {
            color: 'white'
          }
        },
        legend: {
          left: "right",
          textStyle:{
            color:"white"
          }
        },
        backgroundColor: "transparent",
        tooltip: {
          position: "top",
          extraCssText: "background-color: #202F50;border:1px #234A71 solid",
          textStyle: {
            color:"white"
          },
          formatter: function(params) {
            if (params.seriesName) {
              // console.log(params)
              // let newlineName = (lineName[params.dataIndex] + 1)
              // if (line.length === 1) {
              //   newlineName = (line[0] + 1)
              //   newlineName = (line[0] + 1)
              // }
              let value = 0
              if (params.value[1].toString().indexOf('.') >= 0) {
                value = (Yrate[Math.floor(params.value[1])] + parseFloat(((Yrate[Math.floor(params.value[1]) + 1] - Yrate[Math.floor(params.value[1])]) * (params.value[1].toString().split('.')[1] / 10)))) + '°'
              } else {
                value = hours[params.value[1]]
              }
              return (
                  // params.value[1] +
                  '<p style="border-bottom: 2px #414C62 dotted;padding: 5px;text-align:left">'
                  +" 风速: " +
                  parseFloat(parseFloat(value).toFixed(1)) +'</br>' +'</p>'+
                  '<p style="border-bottom: 2px #414C62 dotted;padding: 5px;text-align:left">'
                  +" 时间: " + days[params.value[0]]+'</br>' +'</p>'+
                  '<p style="border-bottom: 2px #414C62 dotted;padding: 5px;text-align:left">'
                  +" 区域: " +params.seriesName +'</br>' +'</p>'+
                  '<p style="border-bottom: 2px #414C62 dotted;padding: 5px;text-align:left">'
                  +" 线别:" + params.data.port +'</br>' + '</p>'+
                  '<p style="border-bottom: 2px #414C62 dotted;padding: 5px;text-align:left">'
                  +" 端口:" + params.data.paramName +'</br>' + '</p>'
              );
            }
          }
        },
        grid: {
          left: 50,
          bottom: 40,
          right: 150,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: days,
          // boundaryGap: false,
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
          },
          axisLabel: {
            show: true,
            textStyle:{
              color:"white",
              fontSize: 18
            }
          }
        },
        yAxis: {
          data: hours,
          type : 'category',
          minInterval: 0.5,
          axisLine: {
            show: true
          },
          axisLabel: {
            show: true,
            textStyle:{
              color:"white",
              fontSize: 18
            }
          }
        },
        series: series,
      };
      option && myChart.setOption(option);
    }
  },
  mounted() {
  },
};
</script>

<style scoped></style>
