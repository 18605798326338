<template>
  <div style="width: 100%;border-bottom: #00A6EC solid 1px;">
    <div style="width: 70%;margin: auto;max-height: 70vh;overflow: auto">
      <table data-v-3cbae52a="" cellspacing="0" class="allTable"><thead data-v-3cbae52a="">
      <tr data-v-3cbae52a=""><th data-v-3cbae52a="">序号</th>
        <!----> <th data-v-3cbae52a="">品牌商名</th>
        <th data-v-3cbae52a="">操作记录</th>
        <th data-v-3cbae52a="">IP</th>
        <th data-v-3cbae52a="">时间</th>
      </tr>
      </thead>
        <tbody data-v-3cbae52a="">
        <tr data-v-3cbae52a="">
          <td data-v-3cbae52a="">1</td>
          <!----> <td data-v-3cbae52a="">管理员</td>
          <td data-v-3cbae52a="">查询管理员信息</td>
          <td data-v-3cbae52a="">117.83.114.91</td>
          <td data-v-3cbae52a="">2021/09/24</td>
        </tr>
        <tr data-v-3cbae52a="">
          <td data-v-3cbae52a="">2</td>
          <!----> <td data-v-3cbae52a="">管理员</td>
          <td data-v-3cbae52a="">查询管理员信息</td>
          <td data-v-3cbae52a="">117.83.114.91</td>
          <td data-v-3cbae52a="">2021/09/24</td>
        </tr>
        <tr data-v-3cbae52a="">
          <td data-v-3cbae52a="">3</td>
          <!----> <td data-v-3cbae52a="">管理员
        </td>
          <td data-v-3cbae52a="">查询管理员信息</td>
          <td data-v-3cbae52a="">117.83.114.91</td>
          <td data-v-3cbae52a="">2021/09/24</td></tr><tr data-v-3cbae52a=""><td data-v-3cbae52a="">4</td> <!----> <td data-v-3cbae52a="">管理员</td> <td data-v-3cbae52a="">查询管理员信息</td> <td data-v-3cbae52a="">117.83.114.91</td> <td data-v-3cbae52a="">2021/09/24</td></tr><tr data-v-3cbae52a=""><td data-v-3cbae52a="">5</td> <!----> <td data-v-3cbae52a="">管理员</td> <td data-v-3cbae52a="">查询管理员信息</td> <td data-v-3cbae52a="">117.83.114.91</td> <td data-v-3cbae52a="">2021/09/24</td></tr><tr data-v-3cbae52a=""><td data-v-3cbae52a="">6</td> <!----> <td data-v-3cbae52a="">管理员</td> <td data-v-3cbae52a="">查询操作日志</td> <td data-v-3cbae52a="">117.83.114.91</td> <td data-v-3cbae52a="">2021/09/24</td></tr><tr data-v-3cbae52a=""><td data-v-3cbae52a="">7</td> <!----> <td data-v-3cbae52a="">管理员</td> <td data-v-3cbae52a="">查询管理员信息</td> <td data-v-3cbae52a="">117.83.114.91</td> <td data-v-3cbae52a="">2021/09/24</td></tr><tr data-v-3cbae52a=""><td data-v-3cbae52a="">8</td> <!----> <td data-v-3cbae52a="">管理员</td> <td data-v-3cbae52a="">用户登陆</td> <td data-v-3cbae52a="">117.83.114.91</td> <td data-v-3cbae52a="">2021/09/24</td></tr><tr data-v-3cbae52a=""><td data-v-3cbae52a="">9</td> <!----> <td data-v-3cbae52a="">管理员</td> <td data-v-3cbae52a="">查询管理员信息</td> <td data-v-3cbae52a="">117.83.114.91</td> <td data-v-3cbae52a="">2021/09/23</td></tr><tr data-v-3cbae52a=""><td data-v-3cbae52a="">10</td> <!----> <td data-v-3cbae52a="">管理员</td> <td data-v-3cbae52a="">查询管理员信息</td> <td data-v-3cbae52a="">117.83.114.91</td> <td data-v-3cbae52a="">2021/09/23</td></tr> <!----></tbody></table>
    </div>
  </div>
</template>

<script>
export default {
  name: "dataTable"
}
</script>

<style scoped>
.allTable{
  width: 100%;
  /*border: 1px solid #233574;*/
  border-radius: 2px;
  color: #fff;
}

</style>
