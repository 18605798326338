<template>
  <div class="p_r" style="height: 100%;background-color: rgb(17, 31, 58);">
    <iframe :src="url" frameborder="0" style="width: 100%; height: 100%"></iframe>
  </div>
</template>

<script>
export default {
  name: "checkingList",
  data () {
    return {
      url: null
    }
  },
  watch: {
    '$route': 'getRouteLink'
  },
  created () {
  },
  methods: {
    getRouteLink () {
      this.url = this.$commonJs.getRouteLink('checkingListOutSide')
    }
  },
  mounted () {
    this.getRouteLink()
  }
}
</script>

<style scoped>

</style>
