<template>
  <div class="whe_max">
    <div style="text-align: left;font-size: 22px;border-left: white 3px solid;padding: 0 14px">连锁实时状态</div>
    <div class="mag_t-15" style="font-size: 30px;width: 100%;text-align: left;height: calc(100% - 49px);">
      <chainPage1 v-show="windChart.ifShow" v-if="initEcharts" style="background-color: rgb(34, 48, 77);border-radius:10px;display: inline-block;vertical-align: middle; width: 49%;margin:0.5%" :sortName="sortName" :normalData="normalData" :closeData="closeData" :closeContent="closeContent" :warningData="warningData" :normalContent="normalContent" :warningContent="warningContent"></chainPage1>
      <chainPage2 v-show="waterChart.ifShow" v-if="initEcharts" style="background-color: rgb(34, 48, 77);border-radius:10px;display: inline-block;vertical-align: middle; width: 49%;margin:0.5%" :sortName="sortName" :normalData="normalData2" :closeData="closeData2" :closeContent="closeContent2" :warningData="warningData2" :normalContent="normalContent2" :warningContent="warningContent2"></chainPage2>
      <chainPage3 v-show="temperatureChart.ifShow" v-if="initEcharts" style="background-color: rgb(34, 48, 77);border-radius:10px;display: inline-block;vertical-align: middle; width: 49%;margin:0.5%" :sortName="sortName" :normalData="normalData3" :closeData="closeData3" :closeContent="closeContent3" :warningData="warningData3" :normalContent="normalContent3" :warningContent="warningContent3"></chainPage3>
      <chainPage4 v-show="flowChart.ifShow" v-if="initEcharts" style="background-color: rgb(34, 48, 77);border-radius:10px;display: inline-block;vertical-align: middle; width: 49%;margin:0.5%" :sortName="sortName" :normalData="normalData4" :closeData="closeData4" :closeContent="closeContent4" :warningData="warningData4" :normalContent="normalContent4" :warningContent="warningContent4"></chainPage4>
    </div>
    <router-view>
    </router-view>
  </div>
</template>

<script>
let timeInter
import mqtt from "@/assets/js/mqtt";
import chainPage1 from '../dorm/chainPage/chainPage1.vue'
import chainPage2 from '../dorm/chainPage/chainPage2.vue'
import chainPage3 from '../dorm/chainPage/chainPage3.vue'
import chainPage4 from '../dorm/chainPage/chainPage4.vue'
export default {
  name: "chainPage",
  data () {
    return {
      buildName: [],
      sortName: [],
      deviceSortType: [],
      deviceSortArray: [],
      lxUserInfo: JSON.parse(sessionStorage.lxUserInfo || '{}'),
      normalData: [],
      warningData: [],
      closeData: [],
      normalContent: ['(line1,line2,line3,line4)', '(line1,line3)', '(line2,line3,line4)', '(line2)', '(line1,line2,line3,line4)', '(line1,line3,line4)', '(line3,line4)'],
      warningContent: ['(line5)', '(line2,line4, line5)', '(line1,line5)', '(line1,line3,line4,line5)', '(line5)', '(line2,line5)', '(line1,line2,line5)'],
      closeContent: [],
      normalData2: [],
      warningData2: [],
      closeData2: [],
      normalContent2: ['(line1,line2,line3,line4)', '(line1,line2,line3,line4)', '(line2,line3,line4,line5)', '(line1,line2,line3,line4,line5)', '(line1,line2,line3,line4)', '(line1,line3,line4)', '(line3,line4)'],
      warningContent2: ['(line5)', '(line5)', '(line1)', '', '(line5)', '(line2,line5)', '(line1,line2,line5)'],
      closeContent2: [],
      normalData3: [],
      warningData3: [],
      closeData3: [],
      normalContent3: ['(line1,line2,line3,line4)', '(line1,line3)', '(line2,line3,line4)', '(line2)', '(line1,line2,line3,line4)', '(line1,line3,line4)', '(line3,line4)'].reverse(),
      warningContent3: ['(line5)', '(line2,line4, line5)', '(line1,line5)', '(line1,line3,line4,line5)', '(line5)', '(line2,line5)', '(line1,line2,line5)'].reverse(),
      closeContent3: [],
      normalData4: [],
      warningData4: [],
      closeData4: [],
      normalContent4: ['(line1,line2,line3,line4)', '(line1,line3)', '(line2,line3,line4)', '(line2)', '(line1,line2,line3,line4)', '(line1,line3,line4)', '(line3,line4)'],
      warningContent4: ['(line5)', '(line2,line4, line5)', '(line1,line5)', '(line1,line3,line4,line5)', '(line5)', '(line2,line5)', '(line1,line2,line5)'],
      closeContent4: [],
      newEtartDate: '',
      newEndDate: '',
      initEcharts: true,
      leasionData: [],
      showleasonData: [],
      templateList: [],
        waterChart: [],
        windChart: [],
        flowChart: [],
        penshajiChart: [],
        temperatureChart: [],
      deviceList: [],
    }
  },
  destroyed () {
    clearTimeout(timeInter)
  },
  created () {
    const options = {
      clean: true, // true: 清除会话, false: 保留会话
      connectTimeout: 4000, // 超时时间
      // 认证信息
      clientId: new Date().getTime() + '_' + (Math.random() * 99999),	//客户端ID
      username: 'admin', //连接用户名
      password: 'YXVIVLDHREDF',//连接密码，有的密码默认为public
      // 心跳时间
      keepalive: 60,
    }
    // 连接字符串, 通过协议指定使用的连接方式
    const connectUrl = 'wss://mqtt.taikunyun.com/mqtt' //连接服务端地址，注意查看ws协议对应的端口号
    const client = mqtt.connect(connectUrl, options)

    client.on('connect', () => {
      console.log('连接成功')
      // 订阅多个主题
      client.subscribe(
          ['SBOX/+/+/+/device/data/push'], //主题
          { qos: 1 },
          (err) => {
            console.log(err || '订阅成功')
          },
      );
      // // 发布消息
      // client.publish('tourist_enter', 'Hello EMQ X', (err) => {
      //   console.log(err || '发布成功')
      // })
    })
    //失败重连
    client.on('reconnect', (error) => {
      console.log('正在重连:', error)
    })
    //连接失败
    client.on('error', (error) => {
      console.log('连接失败:', error)
    })
    //接收消息
    client.on('message', (topic, message) => {
      if (topic && message) {
        const deviceName = topic.split('/')[3]
        if (this.deviceList[deviceName]) {
          console.log(this.deviceList[deviceName], 'this.deviceList[deviceName]');
          this.deviceList[deviceName].params = JSON.parse(message.toString()).Params
        }
      }
    })
    this.getTemplate()
    setTimeout(() => {
      this.getMyDeviceData()
    }, 2000)
    timeInter = setInterval(() => {
      this.getMyDeviceData()
    }, 10000)
  },
  mounted () {
      if (this.lxUserInfo.waterChart) {
          this.waterChart = JSON.parse(this.lxUserInfo.waterChart)
      }
      if (this.lxUserInfo.windChart) {
          this.windChart = JSON.parse(this.lxUserInfo.windChart)
      }
      if (this.lxUserInfo.temperatureChart) {
          this.temperatureChart = JSON.parse(this.lxUserInfo.temperatureChart)
      }
      if (this.lxUserInfo.flowChart) {
          this.flowChart = JSON.parse(this.lxUserInfo.flowChart)
      }
  },
  methods: {
    getNewDate () {

    },
    getTemplate () {
      const url = this.$commonJs.returnQuery('/customer/templates', { page: 1, size: 99999 })
      this.$axiox.cpost(url).then((msg) => {
        const promiseAll = []
        for (let i = 0; i < msg.data.content.length; i++) {
          promiseAll.push(this.getParamsSet(msg.data.content[i].id))
          this.templateList[msg.data.content[i].id] = msg.data.content[i]
        }
        Promise.all(promiseAll).then(e => {
          console.log(e)
          console.log(this.templateList)
          for (let i = 0; i < e.length; i++) {
            for (let j = 0; j < e[i].length; j++) {
              this.templateList[e[i][0].templateId][e[i][j].paramsAddress] = e[i][j]
            }
          }
          this.getDevideList(e)
        })
      })
    },
    getDevideList () {
      const url = this.$commonJs.returnQuery('/customer/devicePage', { page: 1, size: 99999, parentId: this.lxUserInfo.parentId || '' })
      this.$axiox.cpost(url).then((msg) => {
        let newList = []
        if (this.lxUserInfo.devices) {
          for (let i = 0; i < msg.data.content.length; i++) {
            if (this.lxUserInfo.devices.indexOf(msg.data.content[i].id) >=0) {
              newList.push(msg.data.content[i])
            }
          }
        } else {
          newList = msg.data.content
        }
        for (let i = 0; i < newList.length; i++) {
          this.deviceList[newList[i].macAddress] = newList[i]
          this.deviceList[newList[i].macAddress].template = this.templateList[this.deviceList[newList[i].macAddress].templateId]
        }
        for (let i = 0; i < newList.length; i++) {
          if (newList[i].sortTypeId) {
            if (this.deviceSortArray.indexOf(newList[i].sortTypeId) < 0) {
              this.deviceSortType.push({
                sortTypeId: newList[i].sortTypeId,
                sortType: newList[i].sortType,
                devices: [newList[i]]
              })
              this.deviceSortArray.push(newList[i].sortTypeId)
            } else {
              this.deviceSortType[this.deviceSortArray.indexOf(newList[i].sortTypeId)].devices.push(newList[i])
            }
          } else {
            this.deviceSortType.push({
              sortTypeId: newList[i].id,
              sortType: newList[i].deviceName,
              devices: [newList[i]]
            })
            this.deviceSortArray.push(newList[i].sortTypeId)
          }
        }

        this.getMyDeviceData()
        console.log(this.deviceSortType, 'this.deviceSortType')
        console.log(this.deviceSortArray)
      })
      // console.log(myData.deviceList, 'dev');
    },
    getParamsSet (id) {
      var p = new Promise((resolve, reject) => {
        this.$axiox.cpost('/customer/paramsList?templateId=' + id || '').then(msg => {
          if (msg.message === '成功') {
            resolve(msg.data)
          } else {
            reject(msg.data)
          }
        }).catch(msg => {
          reject(msg.data)
        })
      })
      return p
    },
    async getMyDeviceData () {
      this.initEcharts = false
      this.warningDianwei = 0
      this.buildName = []
      this.normalData = []
      this.closeData = []
      this.warningData = []
      this.normalData2 = []
      this.closeData2 = []
      this.warningData2 = []
      this.normalData3 = []
      this.closeData3 = []
      this.warningData3 = []
      this.normalData4 = []
      this.closeData4 = []
      this.warningData4 = []
      this.warningContent = []
      this.closeContent = []
      this.normalContent = []
      this.warningContent2 = []
      this.closeContent2 = []
      this.normalContent2 = []
      this.warningContent3 = []
      this.closeContent3 = []
      this.normalContent3 = []
      this.warningContent4 = []
      this.closeContent4 = []
      this.normalContent4 = []
      this.sortName = []
      for (let i = 0; i < this.deviceSortType.length; i++) {
        this.normalData.push(0)
        this.warningData.push(0)
        this.closeData.push(0)
        this.normalData2.push(0)
        this.warningData2.push(0)
        this.closeData2.push(0)
        this.normalData3.push(0)
        this.warningData3.push(0)
        this.closeData3.push(0)
        this.normalData4.push(0)
        this.warningData4.push(0)
        this.closeData4.push(0)
        this.warningContent.push([])
        this.normalContent.push([])
        this.closeContent.push([])
        this.warningContent2.push([])
        this.normalContent2.push([])
        this.closeContent2.push([])
        this.warningContent3.push([])
        this.normalContent3.push([])
        this.closeContent3.push([])
        this.warningContent4.push([])
        this.normalContent4.push([])
        this.closeContent4.push([])
        this.sortName.push(this.deviceSortType[i].sortType)
        for (let j = 0; j < this.deviceSortType[i].devices.length; j++) {
          let flag = -1
          console.log(this.deviceSortType[i].devices[j].params && this.deviceSortType[i].devices[j].params[4301])
          if (this.deviceSortType[i].devices[j].params && (this.deviceSortType[i].devices[j].params['4396'] !== undefined)) {
            flag = this.deviceSortType[i].devices[j].params['4396']
            // console.log(this.deviceSortType[i].devices[j].params, 'paramssss')
          }
          if (this.deviceSortType[i].devices[j].params && (this.deviceSortType[i].devices[j].params[4396] !== undefined)) {
            flag = this.deviceSortType[i].devices[j].params[4396]
          }
          console.log(this.deviceSortType[i].devices[j].deviceName, this.deviceSortType[i].devices[j].params,flag, 'paramssparamssssparamssssss')
          if (parseInt(flag) === 1 || parseInt(flag) === 0 || (parseInt(flag) > 18)) {
            this.normalContent[i].push(this.deviceSortType[i].devices[j].deviceName)
            this.normalData[i]++
            this.normalContent2[i].push(this.deviceSortType[i].devices[j].deviceName)
            this.normalData2[i]++
            this.normalContent3[i].push(this.deviceSortType[i].devices[j].deviceName)
            this.normalData3[i]++
            this.normalContent4[i].push(this.deviceSortType[i].devices[j].deviceName)
            this.normalData4[i]++
          } else if ((parseInt(flag) >=2 && parseInt(flag) <=18)) {
            this.warningContent[i].push(this.deviceSortType[i].devices[j].deviceName)
            this.warningData[i]++
            this.warningContent2[i].push(this.deviceSortType[i].devices[j].deviceName)
            this.warningData2[i]++
            this.warningContent3[i].push(this.deviceSortType[i].devices[j].deviceName)
            this.warningData3[i]++
            this.warningContent4[i].push(this.deviceSortType[i].devices[j].deviceName)
            this.warningData4[i]++
          } else {
            this.closeContent[i].push(this.deviceSortType[i].devices[j].deviceName)
            this.closeData[i]++
            this.closeContent2[i].push(this.deviceSortType[i].devices[j].deviceName)
            this.closeData2[i]++
            this.closeContent3[i].push(this.deviceSortType[i].devices[j].deviceName)
            this.closeData3[i]++
            this.closeContent4[i].push(this.deviceSortType[i].devices[j].deviceName)
            this.closeData4[i]++
          }
        }
      }
      console.log(this.sortName)
      console.log(this.normalData)
      console.log(this.warningData)
      setTimeout(() => {
        this.initEcharts = true
      }, 1)
    },
  },
  components:{
    chainPage1,
    chainPage2,
    chainPage3,
    chainPage4
  }
}
</script>

<style scoped>
</style>
