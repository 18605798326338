<template>
  <div id="model">
    <div style="position: fixed;top: 0;left: 0;right: 0;bottom: 0;z-index: 100;">
      <div ref="cover" class="cover"></div>
      <div ref="allModel" class="allModel">
        <div :style="{'width':minWidth === undefined || minWidth === null ?'800px':minWidth}" class="content">
          <!--<div class="modelHead">-->
          <!--  <span class="split2_l">{{title === undefined || title === null ? '提示': title}}</span>-->
          <!--  <span class="split2_r"><i @click="cancelModel()" class="icon iconfont iconcha"></i></span>-->
          <!--</div>-->
          <div class="allboxHead">
            <img src="../assets/img/model_title.png" width="430" height="32"/>
            <div class="colorWidth font14" style="top:-5px">
              <span>{{title === undefined || title === null ? '提示': title}}</span>
              <!--<span><i @click="cancelModel()" class="icon iconfont iconcha"></i></span>-->
            </div>
          </div>
          <div class="modelBody">
            <slot>
              没内容
            </slot>
          </div>
          <div v-if="button !== 1" class="modelFoot">
            <button @click="cancelModel()" class="cancelButton1 m_l_10 m_r_10">取 消</button>
            <button @click="confirmModel()" class="suerButton m_r_10 m_l_10">确 定</button>
          </div>
          <div v-if="button === 1" class="modelFoot">
            <button @click="cancelModel()" class="suerButton m_r_10 m_l_10">确 定</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'model',
  props: [
    'minWidth',
    'title',
    'button',
    'showModel'
  ],
  data () {
    return {
    }
  },
  created () { // vue 页面的生命周期 当我一进来页面之后 我先执行
  },
  mounted () { // vue 页面的生命周期 当我进来之后 且加载完资源了之后再执行
    const that = this
    setTimeout(function () {
      that.$refs.cover.style.opacity = 1
      that.$refs.allModel.style.opacity = 1
      that.$refs.allModel.style.top = '0px'
    }, 10)
  },
  methods: { // 所有的方法封装
    cancelModel () {
      const that = this
      that.$refs.cover.style.opacity = 0
      that.$refs.allModel.style.opacity = 0
      that.$refs.allModel.style.top = '-100px'
      setTimeout(function () {
        that.$emit('on-cancel', '')
      }, 500)
    },
    confirmModel () {
      this.$emit('on-confirm', '')
    }
  },
  components: {
  }
}
</script>

<style scoped>

</style>
