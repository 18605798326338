<template>
  <div id="index">
    <div class="topInput">
      <input placeholder="输入VIN号进行查询" type="text">
      <span style="color: #1d97ff">查询</span>
    </div>
    <div class="history">
      <div style="margin-bottom: 10px">
        历史记录
      </div>
      <div>
        <span class="historyVin">LS12312312312</span>
      </div>
    </div>
    <div class="wuliuPlace">
      <div class="wuliuList active">
        <div>
          <div class="iconPlace"></div>
        </div>
        <div>
          <div class="wuliutitlePlace">
            <span>收车</span>
            <span>2022-12-11</span>
          </div>
          <div>车辆已经收车</div>
        </div>
      </div>
      <div class="wuliuList">
        <div>
          <div class="dot"></div>
        </div>
        <div>
          <div class="wuliutitlePlace">
            <span>收车</span>
            <span>2022-12-11</span>
          </div>
          <div>车辆已经收车</div>
          <div style="margin-top: 5px;color: #1d97ff">查看此运单详情</div>
        </div>
      </div>
    </div>
    <div>
      <div class="wuliuCover"></div>
      <div class="wuliuModel">
        <div style="text-align: center;margin:10px 0;border-bottom: 1px solid #eee;position: relative;padding-bottom: 10px">
          运单详情
          <span style="position:absolute;display: inline-block;transform: rotate(45deg);font-size: 20px;right: 20px;top: -10px">+</span>
        </div>
        <div class="wuliudetail">
          <div>
            <div>起始地</div>
            <div>临港VSC</div>
          </div>
          <div>
            <div>运单号</div>
          </div>
          <div>
            <div>目的地</div>
            <div>南沙VSC</div>
          </div>
        </div>
        <div style="height: calc(80vh - 160px);overflow: auto">
          <div class="wuliuList active">
            <div>
              <div class="dot"></div>
            </div>
            <div>
              <div class="wuliutitlePlace">
                <span>收车</span>
                <span>大家开始的哈萨克接电话就卡刷道具卡熬试</span>
              </div>
              <div>2022-12-11</div>
            </div>
          </div>
          <div class="wuliuList">
            <div>
              <div class="dot"></div>
            </div>
            <div>
              <div class="wuliutitlePlace">
                <span>收车</span>
                <span>大家开始的哈萨克接电话就卡刷道具卡熬试</span>
              </div>
              <div>2022-12-11</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  data () { // 所有的数据都写在里面
    return {

    }
  },
  mounted () {

  },
  methods: {// 所有的方法写在里面

  }
}
</script>

<style scoped>
.topInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.history {
  padding:10px 20px;
}
.topInput input {
  width: calc(100% - 80px);
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}
.topInput input::placeholder {
  color: #999;
}
.historyVin {
  display: inline-block;
  background: #eee;
  border-radius: 100px;
  padding: 4px 12px;
}
.wuliuPlace {
  padding: 10px 20px;
}
.wuliuList {
  margin-bottom: 20px;
}
.wuliuList > div {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  position: relative;
}

.wuliuList:not(:first-child) > div:nth-child(1)::before {
  content: '';
  position: absolute;
  left: 19px;
  height: 70px;
  border: 1px solid #eee;
  z-index: -1;
  bottom: 20px;
}
.wuliuList:not(:last-child) > div:nth-child(1)::after {
  content: '';
  position: absolute;
  left: 19px;
  height: 70px;
  border: 1px solid #eee;
  z-index: -1;
  top: 20px;
}
.wuliuPlace .iconPlace {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background: #eee;
}
.wuliuList .dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background: #eee;
}
.wuliuList > div:nth-child(1) {
  width: 40px;
  height: 40px;
}
.wuliuList > div:nth-child(2) {
  width: calc(100% - 50px);
  margin-left: 10px;
  text-align: left;
}
.wuliutitlePlace {
  margin-bottom: 10px;
  color: #999;
}
.wuliutitlePlace > span:nth-child(1) {
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
}
.wuliuList.active .wuliutitlePlace{
  color: #1d97ff;
}
.wuliuModel {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 20%;
  border-radius: 20px 20px 0 0;
  z-index: 2;
  background: #fff;
}
.wuliuCover {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
}
.wuliudetail {
  padding:20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wuliudetail > div:nth-child(2) {
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
  width: 50%;
  text-align: center;
}
</style>
