import axios from 'axios'
import base from './pubilshSet'
import connom from './common'
import avatar from '@/assets/img/loading.gif'
axios.defaults.timeout = 600000 // 超时响应
axios.interceptors.request.use(
  config => {
    // const token = getCookie('名称');注意使用的时候需要引入cookie方法，推荐js-cookie
    // config.data = JSON.stringify(config.data)
    config.headers = {
      'token': window.sessionStorage.getItem('lixtoken')
    }
    if (config.url.indexOf('?') >= 0) {
      config.url = config.url + '&t=' + new Date().getTime()
    } else {
      config.url = config.url + '?t=' + new Date().getTime()
    }
    if (config.url.indexOf('/customer/') >= 0 && config.url.indexOf('/manager/') < 0) {
      config.headers.userType = '2'
    } else {
      config.headers.userType = '1'
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
export default {
  get (url) {
    return new Promise((resolve, reject) => {
      axios.get(base.baseUrl + url)
        .then(response => {
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  post (url, data) {
    return new Promise((resolve, reject) => {
      axios.post(base.baseUrl + url, data)
        .then(response => {
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  postTwo (url, data, type) {
    let newBase = base.baseUrl
    if (type === 1) {
      newBase = base.fileBaseUrl
    }
    return new Promise((resolve, reject) => {
      coverGif()
      axios.post(newBase + url, data)
        .then(response => {
          disCover()
          // console.log(response)
          if (response.data.response === '000000') {
            resolve(response.data)
          } else {
            // resetUrl(response.data.message)
            if (response.data.message.indexOf('用户状态异常') >= 0) {
              connom.alertTips({
                type: 'error',
                title: '错误:',
                content: response.data.message,
                stayTime: 5
              })
              window.location.href = base.baseUrl + '/login'
              return
            }
            connom.alertTips({
              type: 'error',
              title: '错误:',
              content: response.data.message,
              stayTime: 5
            })
          }
        })
        .catch(err => {
          disCover()
          if (err.message.indexOf('用户状态异常') >= 0) {
            window.location.href = base.baseUrl + '/login'
            return
          }
          connom.alertTips({
            type: 'error',
            title: '错误:',
            content: '服务器报错',
            stayTime: 5
          })
          reject(err)
        })
    })
  },
  cpostTwo (url, data, type) {
    let newBase = base.custBaseUrl + '/customer'
    if (type === 1) {
      newBase = base.fileBaseUrl
    }
    return new Promise((resolve, reject) => {
      // coverGif()
      axios.post(newBase + url, data)
        .then(response => {
          // disCover()
          // console.log(response)
          if (response.data.response === '000000') {
            resolve(response.data)
          } else {
            // resetUrl(response.data.message)
            if (response.data.message.indexOf('用户状态异常') >= 0) {
              connom.alertTips({
                type: 'error',
                title: '错误:',
                content: response.data.message,
                stayTime: 5
              })
              window.location.href = base.baseUrl + '/login'
              return
            }
            connom.alertTips({
              type: 'error',
              title: '错误:',
              content: response.data.message,
              stayTime: 5
            })
          }
        })
        .catch(err => {
          // disCover()
          if (err.message.indexOf('用户状态异常') >= 0) {
            window.location.href = base.baseUrl + '/login'
            return
          }
          connom.alertTips({
            type: 'error',
            title: '错误:',
            content: '服务器报错',
            stayTime: 5
          })
          reject(err)
        })
    })
  },
  cget (url) {
    return new Promise((resolve, reject) => {
      axios.get(base.custBaseUrl + url)
        .then(response => {
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  cpost (url, data) {
    return new Promise((resolve, reject) => {
      axios.post(base.custBaseUrl + '/customer' + url, data)
        .then(response => {
          // resolve(response.data)
          if (response.data.response === '000000') {
            resolve(response.data)
          } else {
            // resetUrl(response.data.message)
            if (response.data?.message?.indexOf('用户状态异常') >= 0) {
              connom.alertTips({
                type: 'error',
                title: '错误:',
                content: response.data.message,
                stayTime: 5
              })
              window.location.href = base.baseUrl + '/login'
              return
            }
            connom.alertTips({
              type: 'error',
              title: '错误:',
              content: response.data.message,
              stayTime: 5
            })
          }
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  cpost3 (url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: base.custBaseUrl + '/customer' + url,
        responseType: 'blob',
        data
      }).then(response => {
        // resolve(response.data)
        // console.log(response, '哈哈哈哈哈')
        if (response.data !== undefined) {
          resolve(response.data)
        }
        throw 'response.data不能为undfined'
      })
      .catch(err => {
        reject(err)
      })
      // axios.post(base.custBaseUrl + '/customer' + url, data)
      //   .then(response => {
      //     // resolve(response.data)
      //     // console.log(response, '哈哈哈哈哈')
      //     if (response.data !== undefined) {
      //       resolve(response.data)
      //     }
      //     throw 'response.data不能为undfined'
      //   })
      //   .catch(err => {
      //     reject(err)
      //   })
    })
  },
  eomsApi (url, data) {
    return new Promise((resolve, reject) => {
      axios.post(base.eomsApi + url, data)
          .then(response => {
            // resolve(response.data)
            if (response.status === 200) {
              resolve(response.data)
            } else {
              reject(response.data)
              connom.alertTips({
                type: 'error',
                title: '错误:',
                content: response.data.message,
                stayTime: 5
              })
            }
          })
          .catch(err => {
            reject(err)
          })
    })
  },
  del (url) {
    return new Promise((resolve, reject) => {
      axios.delete(base.baseUrl + url)
        .then(response => {
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}
function coverGif () {
  if (!document.getElementById('loadingGif')) {
    let dom = document.createElement('div')
    dom.id = 'loadingGif'
    let html = '<img src="' + avatar + '" height="144" width="192"/>'
    dom.innerHTML = html
    document.getElementById('app').appendChild(dom)
  }
}
function disCover () {
  if (document.getElementById('loadingGif')) {
    document.getElementById('app').removeChild(document.getElementById('loadingGif'))
  }
}
