<template>
    <div>
        <div style="text-align: left;font-size: 22px;border-left: white 3px solid;padding: 0 14px">参数管理</div>
        <div style="background-color: rgb(34, 48, 77);border-radius: 10px;margin-top: 15px;padding-left: 15px">
            <div  style="text-align: left;padding: 15px 0">
                <div class="mag_r-20" style="display: inline-block;vertical-align: middle;">
                    <span class="mag_r-20">区域</span>
                    <span>
          <select @change="getHistoryData()" v-model="building" class="allSelect sele" name="">
             <option :value="''">全部</option>
             <option v-for="(item, index) in sortTypeFilter2" :key="index" :value="item">{{ item }}</option>
          </select>
        </span>
                </div>
                <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
                    <span class="mag_r-20">线别</span>
                    <span>
          <select @change="getHistoryData()" v-model="line" class="allSelect sele" name="">
                     <option :value="''">全部</option>
                      <option v-for="(item, index) in lineNameFilter" :key="index" :value="item">{{ item }}</option>
          </select>
        </span>
                </div>
                <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
                    <span class="mag_r-20">开始时间</span>
                    <span>
            <div class="xd disp">
          <cander :newWidth="'150px'" class="allInput d_l" v-model="newStartDate"  ></cander>
              <img class="jd" src="../../assets/img/riqi.png" height="20px" style="margin-left: -27px;margin-top: 5px" alt="">
            </div>
                        <!--          <input @change="initRamdomData()" v-model="newStartDate" type="date" class="i_time" />-->
        </span>
                </div>
                <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
                    <span class="mag_r-20">结束时间</span>
                    <span>
            <div class="disp xd">
          <cander :newWidth="'150px'" class="allInput d_l" v-model="newEndDate" ></cander>
            <img class="jd" src="../../assets/img/riqi.png" height="20px" style="margin-left: -27px;margin-top: 5px" alt=""></div>
                        <!--          <input @change="initRamdomData()" v-model="newEndDate" type="date" class="i_time" />-->
        </span>
                </div>
                <button @click="getHistoryData()" class="btn btn_success mag_l-20 mag_r-20"><span>搜索</span></button>
                <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
                    <span style="border-radius: 5px;border: 1px solid #eee;padding: 10px 20px;cursor: pointer" @click="exportExcel(1)" class="mag_r-20">联锁测试数据导出</span>
                    <span style="border-radius: 5px;border: 1px solid #eee;padding: 10px 20px;cursor: pointer" @click="exportExcel(2)" class="mag_r-20">异常数据导出</span>
                </div>
            </div>
            <div id="hiddenHistoryList" v-show="dao" class="jz_flex"  style="height: 250px;overflow: auto;">
                <table class="allTable bd ba_white wz_black" border="1" cellspacing="0">
                    <thead>
                    <tr>
                        <th>设备名称</th>
                        <th>设备编号</th>
                        <!-- <th>报警名称</th> -->
                        <th>参数端口</th>
                        <th>修改时间</th>
                        <th>测试值</th>
                        <th>结果</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(item, index) in myList">
                        <tr :key="index">
                            <td>{{ item.deviceName }}</td>
                            <td>{{ item.macAddress }}</td>
                            <!-- <td>{{ item.deviceTypeName }}</td> -->
                            <td>{{ item.paramsName }}</td>
                            <td>{{ item.createTime }}</td>
<!--                            <td>{{ item.warningContent }}</td>-->
                            <td>{{ item.warningValue }}</td>
                            <td>{{returnIsTest(item)}}</td>
                            <!-- <td>{{ item.warningContent }}</td>
                            <td>{{ item.createTime }}</td>
                            <td>312</td> -->
                        </tr>
                    </template>
                    </tbody>
                </table>
            </div>
            <div id="hiddenHistoryList2" v-show="dao" class="jz_flex"  style="height: 250px;overflow: auto;">
                <table class="allTable bd ba_white wz_black" border="1" cellspacing="0">
                    <thead>
                    <tr>
                      <th>设备名称</th>
                      <th>设备编号</th>
                      <!-- <th>报警名称</th> -->
                      <th>参数端口</th>
                      <th>修改时间</th>
                      <th>测试值</th>
                      <th>结果</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(item, index) in myList2">
                        <tr :key="index">
                            <td>{{ item.deviceName }}</td>
                            <td>{{ item.macAddress }}</td>
                            <!-- <td>{{ item.deviceTypeName }}</td> -->
                            <td>{{ item.paramsName }}</td>
                            <td>{{ item.createTime }}</td>
                            <!--                            <td>{{ item.warningContent }}</td>-->
                            <td>{{ item.warningValue }}</td>
                            <td>{{returnIsTest(item)}}</td>
                            <!-- <td>{{ item.warningContent }}</td>
                            <td>{{ item.createTime }}</td>
                            <td>312</td> -->
                        </tr>
                    </template>
                    </tbody>
                </table>
            </div>
            <div class="p_r">
                <div v-if="showLoading" style="position: absolute;opacity: 1;background: #001E31;display: flex;align-items: center;justify-content: center;right: 20px" class="cover">
                    <div style="position: relative;top: -30px">
                        <img src="../../assets/img/newLoading.gif" height="494" width="658"/>
                        <p class="font20">加载中，请耐心等待</p>
                    </div>
                </div>
                <div id="main" style="display: inline-block;vertical-align: middle;width: 60%;height: calc(100vh - 240px)"></div>
                <div style="margin-top:-250px;display: inline-block;vertical-align: middle;width: 40%">
                    <div class="wh_8 he_max jz" style="background-color: rgb(34, 48, 77);border-radius: 10px;">
                        <div class="he_max" style="">
                            <div style="text-align: center;width: 100%" class="">
                                <div style="border-bottom: 2px #4A576A solid;padding: 20px 0">参数值</div>
                                <div v-if="waterChart.ifShow" class="split_2" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">
                                    <div>水流量标准值:</div>
                                    <div>≥{{ waterChart.warnLine }}m³/H</div>
                                </div>
                                <div v-if="flowChart.ifShow" class="split_2" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">
                                    <div>液位标准值:</div>
                                    <div>{{{1: '正常'}[flowChart.warnLine] || flowChart.warnLine}}</div>
                                </div>
                                <div v-if="temperatureChart.ifShow" class="split_2" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">
                                    <div>轴温标准值:</div>
                                    <div>≤ {{ temperatureChart.warnLine }}°C</div>
                                </div>
                                <div v-if="windChart.ifShow" class="split_2" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">
                                    <div>风速标准值:</div>
                                    <div>≥ {{ windChart.warnLine }}m/s</div>
                                </div>
                                <!-- <div :key="index" v-for="(item, index) in maintain" class="split_2" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">-->
                                <!-- <div>{{ item.name }}:</div>-->
                                <!-- <div>{{ item.value }}</div>-->
                                <!-- </div>-->
                                <!-- <div class="split_2" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">-->
                                <!-- <div>水流量标准值:</div>-->
                                <!-- <div>≥60m³/H</div>-->
                                <!-- </div>-->
                                <!-- <div class="split_2" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">-->
                                <!-- <div>液位标准值:</div>-->
                                <!-- <div>液位正常</div>-->
                                <!-- </div>-->
                                <!-- <div class="split_2" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">-->
                                <!-- <div>轴温标准值:</div>-->
                                <!-- <div>≤ 80°C</div>-->
                                <!-- </div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- <echart></echart> -->
    </div>
</template>

<script>
let timeInter
console.log(timeInter)
import * as echarts from "echarts";
import pubilshSet from "../../assets/js/pubilshSet";
import lodash from 'lodash'
export default {
    name: "echart",
    data() {
        return {
            lxUserInfo: JSON.parse(sessionStorage.lxUserInfo || '{}'),
            initPie: false,
            showLoading: false,
            list: [],
            normalData: [],
            myList: [],
            myList2: [],
            templateArray: [],
            deviceSortType: [],
            deviceSortArray: [],
            sortTypeFilter: [],
          sortTypeFilter2: [],
            lineNameFilter: [],
            templateDetailArray: [],
            templateData: [],
            warningData: [],
            totalData: [],
            echartsData: [],
            typeNum: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
            warntypeNum: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
            totalNum: 0,
            wrongNum: 0,
            Fcount: 0,
            Hcount: 0,
            totalWarnNum: [
            ],
            newStartDate: '',
            newEndDate: '',
            building: '',
            line: '',
            dao: false,
            maintain:[
            ],
            waterChart: [],
            windChart: [],
            flowChart: [],
            penshajiChart: [],
            temperatureChart: [],
            showbingtu: true,
            msg: "Welcome to Your Vue.js App",
        };
    },
    components:{
    },
    comments: {
        echarts
    },
    methods:{
        returnIsTest(item) {
            if (item.warningSet) {
                const min = item.warningSet.split('{}')[0]
                const max = item.warningSet.split('{}')[1]
                if (+item.paramsWarnType === 1) {
                    if (+item.warningValue > max) {
                        return '连锁测试'
                    } else {
                        return '参数异常修改'
                    }
                }
                if (+item.paramsWarnType === 0) {
                    if (+item.warningValue < min) {
                        return '连锁测试'
                    } else {
                        return '参数异常修改'
                    }
                }
                return '未配置归属'
            } else {
                return '未配置归属'
            }
        },
        getHistoryData () {
            this.templateData = []
            this.templateDetailArray = []
            this.templateArray = []
            this.deviceSortArray = []
            this.deviceSortType = []
            let url = this.$commonJs.returnQuery('/customer/devicePage', {page: 1, size: 99999, parentId: this.lxUserInfo.parentId || ''})
            this.$axiox.cpost(url).then(async msg => {
                if (msg.message === '成功') {
                    let newList = []
                    if (this.lxUserInfo.devices) {
                        for (let i = 0; i < msg.data.content.length; i++) {
                            if (this.lxUserInfo.devices.indexOf(msg.data.content[i].id) >=0) {
                              if (this.line.length >  0 && typeof this.line === 'string') {
                                // 过滤线别
                                if (msg.data.content[i].lineName === this.line) {
                                  newList.push(msg.data.content[i])
                                }
                              } else {
                                newList.push(msg.data.content[i])
                              }
                              // console.log(msg.data.content[i], 'msg.data.content[i]')
                            }
                        }
                    } else {
                        for (let i = 0; i < msg.data.content.length; i++) {
                            if (this.templateArray.indexOf(msg.data.content[i].templateId) >= 0) {
                                msg.data.content[i].templateParam = this.templateData[this.templateArray.indexOf(msg.data.content[i].templateId)]
                                msg.data.content[i].templateDetail = this.templateDetailArray[this.templateArray.indexOf(msg.data.content[i].templateId)]
                            } else {
                                const detail = await this.$axiox.cpost('/customer/paramsList?templateId=' + msg.data.content[i].templateId)
                                const array = []
                                for (let i = 0; i < detail.data.length; i++) {
                                    if (detail.data[i].chart === 1) {
                                        array.push(detail.data[i].paramsId)
                                    }
                                }
                                console.log(array)
                                msg.data.content[i].templateParam = array
                                msg.data.content[i].templateDetail = detail.data
                                this.templateData.push(array)
                                this.templateDetailArray.push(detail.data)
                                this.templateArray.push(msg.data.content[i].templateId)
                            }
                        }
                        if (this.line.length >  0 && typeof this.line === 'string') {
                          // 过滤线别
                          newList = msg.data.content.filter(item => item.lineName === this.line)
                        } else {
                          newList = msg.data.content
                        }
                      console.log(newList, '1111')
                    }
                    this.sortTypeFilter = []
                    for (let i = 0; i < newList.length; i++) {
                        if (newList[i].sortTypeId && this.deviceSortArray.indexOf(newList[i].sortTypeId) >= 0) {
                            this.deviceSortType[this.deviceSortArray.indexOf(newList[i].sortTypeId)].devices.push(newList[i])
                        } else {
                            this.deviceSortType.push({
                                sortTypeId: newList[i].sortTypeId || newList[i].id,
                                sortType: newList[i].sortType || newList[i].deviceName,
                                buildingName: newList[i].sortType || newList[i].deviceName,
                                devices: [newList[i]]
                            })
                            this.deviceSortArray.push(newList[i].sortTypeId || null)
                            if (this.line) {
                              console.log(newList[i], 'newList[i]22222')
                              if (this.sortTypeFilter.indexOf(newList[i].lineName) !== -1) {
                                console.log(1)
                              } else {
                                console.log(newList[i], 'newList[i]')
                                if (this.line === newList[i].lineName) {
                                  this.sortTypeFilter.push(newList[i].lineName)
                                }
                              }
                            } else {
                              if (this.building.length > 1 && typeof this.building === 'string') {
                                if (this.sortTypeFilter.indexOf(newList[i].sortType || newList[i].lineName) !== -1) {
                                  console.log(1)
                                } else {
                                  if (this.building === (newList[i].sortType || newList[i].lineName)) {
                                    this.sortTypeFilter.push(newList[i].sortType || newList[i].lineName)
                                  }
                                }
                              } else {
                                if (this.sortTypeFilter.indexOf(newList[i].sortType || newList[i].lineName) !== -1) {
                                  console.log(1)
                                } else {
                                  console.log(newList[i], 'newList[i]')
                                  this.sortTypeFilter.push(newList[i].sortType || newList[i].lineName)
                                }
                              }
                            }
                        }
                    }
                    for (let i = 0; i < this.deviceSortType.length; i++) {
                        const lineIdArray = []
                        this.deviceSortType[i].lineData = []
                        for (let j = 0; j < this.deviceSortType[i].devices.length; j++) {
                            if (this.deviceSortType[i].devices[j].lineId && lineIdArray.indexOf(this.deviceSortType[i].devices[j].lineId) >= 0) {
                                this.deviceSortType[i].lineData[lineIdArray.indexOf(this.deviceSortType[i].devices[j].lineId)].devices.push(this.deviceSortType[i].devices[j])
                            } else {
                                this.deviceSortType[i].lineData.push({
                                    lineName: this.deviceSortType[i].devices[j].lineName || this.deviceSortType[i].devices[j].deviceName,
                                    lineId: this.deviceSortType[i].devices[j].lineId || this.deviceSortType[i].devices[j].id,
                                    devices: [this.deviceSortType[i].devices[j]],
                                    portData: [],
                                    detailData: []
                                })
                                lineIdArray.push(this.deviceSortType[i].devices[j].lineId || null)
                                if (this.lineNameFilter.indexOf(this.deviceSortType[i].devices[j].lineName || this.deviceSortType[i].devices[j].deviceName) >= 0) {
                                    console.log(1)
                                } else {
                                    this.lineNameFilter.push(this.deviceSortType[i].devices[j].lineName || this.deviceSortType[i].devices[j].deviceName)
                                }
                            }
                        }
                    }
                    if (this.sortTypeFilter2.length === 0) {
                      this.sortTypeFilter2 = lodash.cloneDeep(this.sortTypeFilter)
                    }
                    this.deviceList = newList
                    console.log(this.deviceSortType, '123333')
                    this.totalWarnNum = [0,0,0,0,0,0]
                    this.normalData = []
                    this.warningData = []
                    // 筛选区域
                    if (typeof this.building === "string" && this.building.length > 0) {
                      this.deviceSortType = this.deviceSortType.filter(item => item.buildingName === this.building)
                    }
                    for (let a = 0 ;a < this.deviceSortType.length; a++) {
                        this.normalData.push(0)
                        this.warningData.push(0)
                    }
                    this.$axiox.cpost('/warning/waringHistoryPages', {handle:0,deviceName:'',customerId:null,typeId:"",start: this.newStartDate + ' 00:00:00',end: this.newEndDate + ' 23:59:59',size:9999,keep:2,page:1}).then(async msg2 => {
                        const warnData = msg2.data.content
                        this.myList = []
                        for (let i = 0; i < warnData.length; i++) {
                            for (let a = 0 ;a < this.deviceSortType.length; a++) {
                                if (typeof this.line === 'string' && this.line.length > 0) {
                                  // 筛选线别
                                  this.deviceSortType[a].lineData = this.deviceSortType[a].lineData.filter(item => item.lineName === this.line)
                                }
                                for (let b = 0 ;b < this.deviceSortType[a].lineData.length; b++) {
                                    for (let c = 0 ;c < this.deviceSortType[a].lineData[b].devices.length; c++) {
                                        const device = this.deviceSortType[a].lineData[b].devices[c]
                                        if (warnData[i].deviceId === device.id) {
                                            if (warnData[i].paramsWarnType) {
                                                const min = warnData[i].warningSet.split('{}')[0]
                                                const max = warnData[i].warningSet.split('{}')[1]
                                                if (+warnData[i].paramsWarnType === 1) {
                                                    if (+warnData[i].warningValue > max) {
                                                        this.normalData[a]++
                                                      // warnData[i].params = device
                                                        this.myList.push(warnData[i])
                                                    } else {
                                                        this.warningData[a]++
                                                        this.myList2.push(warnData[i])
                                                    }
                                                }
                                                if (+warnData[i].paramsWarnType === 0) {
                                                    if (+warnData[i].warningValue < min) {
                                                        this.normalData[a]++
                                                      // warnData[i].params = device
                                                        this.myList.push(warnData[i])
                                                    } else {
                                                        this.warningData[a]++
                                                        this.myList2.push(warnData[i])
                                                    }
                                                }
                                            } else {
                                                this.warningData[a]++
                                                this.myList2.push(warnData[i])
                                            }
                                            for (let s = 0; s < device.templateDetail.length; s++) {
                                                if (warnData[i].paramsId === device.templateDetail[s].paramsId) {
                                                    if (device.templateDetail[s].chart === 1) {
                                                        this.totalWarnNum[0]++
                                                    }
                                                    else if (device.templateDetail[s].chart === 2) {
                                                        this.totalWarnNum[1]++
                                                    }
                                                    else if (device.templateDetail[s].chart === 3) {
                                                        this.totalWarnNum[2]++
                                                    }
                                                    else if (device.templateDetail[s].chart === 4) {
                                                        this.totalWarnNum[3]++
                                                    }
                                                    else if (device.templateDetail[s].keep === 1) {
                                                        this.totalWarnNum[4]++
                                                    } else {
                                                        this.totalWarnNum[5]++
                                                    }
                                                    break
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        this.show()
                    })
                }
            })
        },
        exportExcel2 () {
            // console.log(pubilshSet.eomsApi + '/sys/sysWarningInfo/export?fileName=报警记录导出&sheetName=报警记录导出&area=' + this.building + '&line=' + this.line + '&startTime=' + this.newStartDate + '&endTime=' + this.newEndDate)
            window.open(pubilshSet.eomsApi + '/sys/sysWarningInfo/export?fileName=报警记录导出&sheetName=报警记录导出&area=' + this.building + '&line=' + this.line + '&startTime=' + this.newStartDate + '&endTime=' + this.newEndDate)
        },
        exportExcel (type) {
            let html = '<html><head><meta charset=\'utf-8\' /></head><body>'
            if (type === 1) {
                html += document.getElementById('hiddenHistoryList').innerHTML
            } else {
                html += document.getElementById('hiddenHistoryList2').innerHTML
            }
            html += '</body></html>'
            // 实例化一个Blob对象，其构造函数的第一个参数是包含文件内容的数组，第二个参数是包含文件类型属性的对象
            let blob = new Blob([html], {
                type: 'application/vnd.ms-excel'
            })
            let a = document.createElement('a')
            a.href = URL.createObjectURL(blob)
            if (type === 1) {
                a.download = '联锁测试数据.xls'
            } else {
                a.download = '异常数据.xls'
            }
            a.click()
        },
        show(){
            console.log(this.normalData, this.warningData, 'this.warningData')
            let hasBaoyang = this.normalData
            let waitBaoyang = this.warningData

            let Xdata = this.sortTypeFilter

            this.totalNum = 0
            this.wrongNum = 0
            for (let i = 0; i < hasBaoyang.length; i++) {
                this.totalNum += waitBaoyang[i] + hasBaoyang[i]
                this.wrongNum += waitBaoyang[i]
            }
            this.showbingtu = false
            setTimeout( () => {
                this.showbingtu = true
            }, 100)
            var chartDom = document.getElementById('main');
            var myChart = echarts.init(chartDom);
            var option;
            let that = this
            const seriesForMater = (e) => {
              return e.value || ''
            }
            option = {
                legend: {
                    textStyle:{
                        color:"white"
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: Xdata,
                        axisLabel: {
                            show: true,
                            textStyle:{
                                color:"white"
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            show: true,
                            textStyle:{
                                color:"white"
                            }
                        }
                    }
                ],
                tooltip: {
                    position: 'inside',
                    extraCssText: "background-color: #202F50;border:1px #87363F solid",
                    textStyle: {
                        color:"white"
                    },
                    formatter: function(params) {
                        console.log(params)
                        let html = ''
                        const newTotal = params.data
                        for (let i =0; i < that.totalWarnNum[params.dataIndex].length; i++) {
                            if (that.totalWarnNum[params.dataIndex][i] !== 0) {
                                html += '<div style="border-bottom: 2px #414C62 dotted;padding: 5px;text-align: left">'+'类型：' + that.$commonJs.errorData[i] + '<br />' + '数量：' + that.totalWarnNum[params.dataIndex][i] + '<br />' + '占比：' +
                                    // params.value[1] +
                                    parseFloat(that.totalWarnNum[params.dataIndex][i] / newTotal * 100).toFixed(2) + '%' + '<br />' +'</div>'
                            }

                        }
                        return html;
                    }
                },
                series: [
                    {
                        name: '联锁测试数据',
                        type: 'bar',
                        color: 'green',
                        emphasis: {
                            focus: 'series'
                        },
                        data: hasBaoyang,
                        label:{
                            show: true,
                            position:'inside',
                            color: 'white',
                            formatter: seriesForMater
                        }
                    },
                    {
                        name: '异常数据',
                        type: 'bar',
                        color: '#FF2D55',
                        data: waitBaoyang,
                        label:{
                            show: true,
                            position:'inside',
                            color: 'white',
                          formatter: seriesForMater
                        },
                        emphasis: {
                            focus: 'series'
                        }
                    }
                ]
            };

            option && myChart.setOption(option);
        }
    },
    mounted(){
        this.newEndDate = this.$commonJs.todayDate
        this.newStartDate = this.$commonJs.lastWeekDay()
        this.getHistoryData()
        if (this.lxUserInfo.waterChart) {
            this.waterChart = JSON.parse(this.lxUserInfo.waterChart)
        }
        if (this.lxUserInfo.windChart) {
            this.windChart = JSON.parse(this.lxUserInfo.windChart)
        }
        if (this.lxUserInfo.temperatureChart) {
            this.temperatureChart = JSON.parse(this.lxUserInfo.temperatureChart)
        }
        if (this.lxUserInfo.flowChart) {
            this.flowChart = JSON.parse(this.lxUserInfo.flowChart)
        }
        // this.show();
    }
}
</script>

<style scoped>

</style>
