<template>
  <div id="page">
    <div class="allPage" :class="explain ? 'd_f_spbe' : ''">
      <div v-if="explain === 0"></div>
      <div v-if="explain === 1">当前共{{totalPage}}页</div>
      <div v-if="pageRow === 1">
        <span>每页</span>
        <input type="text" v-model="pageNum" @blur="childePageNum()"/>
        <span>条</span>
      </div>
      <div>
        <span @click="prevPage()" class="botton"><i class="icon iconfont iconxialajiantou" style="transform: rotate(90deg);display: inline-block"></i></span>
        <span @click="thisPage(list)" :key="index" v-for="(list,index) in pageList" :class="list === newPage ? 'num active': 'num'">{{list}}</span>
        <!--<span class="little">···</span>-->
        <span @click="nextPage()" class="botton"><i class="icon iconfont iconxialajiantou" style="transform: rotate(-90deg);display: inline-block"></i></span>
        <span style="margin-left: 10px">跳转</span>
        <input v-model="goPage" type="text">
        <span>页</span>
        <span  @click="goToPage()"  class="go">GO</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'page',
  props: [
    'totalPage',
    'page',
    'explain',
    'pageRow'
  ],
  data () {
    return {
      pageNum: window.localStorage.getItem(window.location.hash + 'gyspage') || this.$commonJs.commonPageSize,
      newotalPage: this.totalPage,
      newPage: this.page,
      pageList: [],
      goPage: '1'
    }
  },
  created () { // vue 页面的生命周期 当我一进来页面之后 我先执行
    console.log(this.newPage)
  },
  mounted () { // vue 页面的生命周期 当我进来之后 且加载完资源了之后再执行
    this.getPageList()
  },
  methods: { // 所有的方法封装
    getPageList () {
      this.pageList = []
      if (this.newotalPage <= 10) {
        for (let i = 1; i <= this.newotalPage; i++) {
          this.pageList.push(i)
        }
      } else {
        if (this.newotalPage - this.newPage <= 10) {
          for (let i = this.newotalPage - 10; i <= this.newotalPage; i++) {
            this.pageList.push(i)
          }
        } else {
          if (this.newPage > 5) {
            for (let i = this.newPage - 4; i <= this.newPage + 5; i++) {
              this.pageList.push(i)
            }
          } else {
            for (let i = 1; i <= 9; i++) {
              this.pageList.push(i)
            }
          }
        }
      }
    },
    prevPage () {
      // console.log(this.page)
      if (this.newPage > 1) {
        // console.log(this.newPage)
        this.newPage--
        this.getPageList()
        this.$emit('on-getList', this.newPage)
      }
    },
    nextPage () {
      if (this.newPage < this.newotalPage) {
        // console.log(this.newPage)
        this.newPage++
        this.getPageList()
        this.$emit('on-getList', this.newPage)
      }
    },
    thisPage (page) {
      this.newPage = page
      this.getPageList()
      this.$emit('on-getList', this.newPage)
    },
    goToPage () {
      const num = /[0-9]/
      if (num.test(this.goPage) && this.goPage !== '0' && parseInt(this.goPage, 0) < this.newotalPage) {
        this.newPage = parseInt(this.goPage, 0)
        this.getPageList()
        this.$emit('on-getList', this.newPage)
      } else {
        this.$commonJs.alertTips({
          type: 'error',
          title: '页码不正确',
          content: '请输入正确的页数',
          stayTime: 2
        })
      }
    },
    childePageNum () {
      let regNumber = /^\+?[1-9][0-9]*$/
      if (!regNumber.test(this.pageNum)) {
        this.$commonJs.alertTips({
          type: 'error',
          title: '页码不正确',
          content: '请输入正整数',
          stayTime: 2
        })
        this.pageNum = window.localStorage.getItem(window.location.hash + 'gyspage', this.pageNum) || ''
        return
      }
      window.localStorage.setItem(window.location.hash + 'gyspage', this.pageNum)
      window.location.reload()
      // this.$emit('childePageNum', this.pageNum)
    }
  },
  components: {
  },
  watch: {
    totalPage (value) {
      console.log(value)
      this.newotalPage = value
      this.getPageList()
    },
    page (value) {
      this.newPage = value
      this.getPageList()
    }
  }
}
</script>

<style scoped>

</style>
