<template>
<div>
  <div style="text-align: left;font-size: 22px;border-left: white 3px solid;padding: 0 14px">报警记录</div>
  <div style="background-color: rgb(34, 48, 77);border-radius: 10px;margin-top: 15px;padding-left: 15px">
    <div  style="text-align: left;padding: 15px 0">
      <div class="mag_r-20" style="display: inline-block;vertical-align: middle;">
        <span class="mag_r-20">区域</span>
        <span>
          <select @change="show()" v-model="building" class="allSelect sele" name="">
             <option :value="''">全部</option>
             <option v-for="(item, index) in sortTypeFilter2" :key="index" :value="item">{{ item }}</option>
          </select>
        </span>
      </div>
      <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
        <span class="mag_r-20">线别</span>
        <span>
          <select @change="show()" v-model="line" class="allSelect sele" name="">
                     <option :value="''">全部</option>
                      <option v-for="(item, index) in lineNameFilter" :key="index" :value="item">{{ item }}</option>
          </select>
        </span>
      </div>
      <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
        <span class="mag_r-20">开始时间</span>
        <span>
            <div class="xd disp">
          <cander :newWidth="'150px'" class="allInput d_l" v-model="newStartDate"  ></cander>
              <img class="jd" src="../../assets/img/riqi.png" height="20px" style="margin-left: -27px;margin-top: 5px" alt="">
            </div>
          <!--          <input @change="initRamdomData()" v-model="newStartDate" type="date" class="i_time" />-->
        </span>
      </div>
      <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
        <span class="mag_r-20">结束时间</span>
        <span>
            <div class="disp xd">
          <cander :newWidth="'150px'" class="allInput d_l" v-model="newEndDate" ></cander>
            <img class="jd" src="../../assets/img/riqi.png" height="20px" style="margin-left: -27px;margin-top: 5px" alt=""></div>
          <!--          <input @change="initRamdomData()" v-model="newEndDate" type="date" class="i_time" />-->
        </span>
      </div>
      <button @click="getHistoryData()" class="btn btn_success mag_l-20 mag_r-20"><span>搜索</span></button>
      <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
          <span style="border-radius: 5px;border: 1px solid #eee;padding: 10px 20px;cursor: pointer" @click="exportExcel(2)" class="mag_r-20">待处理导出</span>
        <span style="border-radius: 5px;border: 1px solid #eee;padding: 10px 20px;cursor: pointer" @click="exportExcel(1)" class="mag_r-20">已处理导出</span>
      </div>
    </div>
      <div id="hiddenHistoryList" v-show="dao" class="jz_flex"  style="height: 250px;overflow: auto;">
          <table class="allTable bd ba_white wz_black" border="1" cellspacing="0">
              <thead>
              <tr >
                <th>设备名称</th>
                <th>设备编号</th>
                <!-- <th>报警名称</th> -->
                <th>报警名称</th>
                <th>报警时间</th>
                <th>是否处理</th>
                <th>报警值</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <template
                       v-for="(item, index) in warnExcel">
                  <tr :key="index">
                      <td>{{ item.deviceName }}</td>
<!--                      <td>{{ item.macAddress }}</td>-->
                      <td>{{ item.macAddress }}</td>
                      <td>{{ getWarningValue(item) }}</td>
                      <td>{{ item.createTime }}</td>
                      <td>{{ item.handle ? '处理' : '未处理' }}</td>
                      <td>{{ getWarningValue(item) }}</td>
                      <td></td>
                      <!-- <td>{{ item.warningContent }}</td>
                      <td>{{ item.createTime }}</td>
                      <td>312</td> -->
                  </tr>
              </template>
              </tbody>
          </table>
      </div>
      <div id="hiddenHistoryList2" v-show="dao" class="jz_flex"  style="height: 250px;overflow: auto;">
          <table class="allTable bd ba_white wz_black" border="1" cellspacing="0">
              <thead>
              <tr>
                  <th>区域/楼栋/线别</th>
                  <th>问题列表</th>
                  <th>检查方式</th>
                  <th>处理方法</th>
                  <th>报警时间</th>
                  <th>系统关机时间</th>
                  <th>集尘设备恢复开机时间</th>
                  <th>停机时长（分钟）</th>
                  <th>改善完成时间</th>
                  <!-- <th>报警值</th> -->
                  <!-- <th>MAC地址</th> -->
                  <!-- <th>打卡地点</th> -->
              </tr>
              </thead>
              <tbody>
              <template v-for="(item, index) in handleExcel">
                  <tr :key="index">
                      <td>{{ item.deviceName }}</td>
                      <td>{{ item.content }}</td>
                      <!-- 检查方式 -->
                      <td>{{ item.checkWay }}</td>
                      <!-- 处理方法 -->
                      <td>{{ item.handleWay }}</td>
                      <td>{{ item.createTime }}</td>
                      <td>{{ item.createTime }}</td>
                      <td>{{ item.rebootTime }}</td>
                      <th>{{ (new Date(item.rebootTime).getTime() - new Date(item.createTime).getTime()) / 1000 / 60 }}</th>
                      <td>{{ item.rebootTime }}</td>
                      <!-- <td>{{ item.warningValue }}</td> -->
                      <!-- <td>{{ item.paramsName }}</td> -->
                      <!-- 处理时间 -->
                      <!-- 现场照片 -->
                      <!-- 打卡地点 -->
                      <!-- <td></td> -->
                      <!-- <td>{{ item.warningContent }}</td>
                      <td>{{ item.createTime }}</td>
                      <td>312</td> -->
                  </tr>
              </template>
              </tbody>
          </table>
      </div>
    <div class="p_r">
      <div v-if="showLoading" style="position: absolute;opacity: 1;background: #001E31;display: flex;align-items: center;justify-content: center;right: 20px" class="cover">
        <div style="position: relative;top: -30px">
          <img src="../../assets/img/newLoading.gif" height="494" width="658"/>
          <p class="font20">加载中，请耐心等待</p>
        </div>
      </div>
      <div id="main" style="display: inline-block;width: 60%;height: calc(100vh - 240px)"></div>
      <div style="display: inline-block;width: 40%">
        <policesingto v-if="initPie" :totalWarnNum="totalWarnNum" :typeNum="typeNum" :warntypeNum="warntypeNum" :wrongNum="wrongNum" :totalNum="totalNum" :totalData="totalData" ></policesingto>
      </div>
    </div>
  </div>


    <!-- <echart></echart> -->
</div>
</template>

<script>
import policesingto from '../dorm/policesingto.vue'
import publishSet from '@/assets/js/pubilshSet'
let timeInter
console.log(timeInter)
import * as echarts from "echarts";
import pubilshSet from "../../assets/js/pubilshSet";
import lodash from "lodash";
export default {
name: "echart",
  data() {
    return {
      lxUserInfo: JSON.parse(sessionStorage.lxUserInfo || '{}'),
      initPie: false,
      showLoading: false,
      list: [],
      normalData: [],
      templateArray: [],
      deviceSortType: [],
      deviceSortArray: [],
      sortTypeFilter: [],
      sortTypeFilter2: [],
      lineNameFilter: [],
      templateDetailArray: [],
      templateData: [],
      warningData: [],
      totalData: [],
      echartsData: [],
      typeNum: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      warntypeNum: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      totalNum: 0,
      wrongNum: 0,
      Fcount: 0,
      Hcount: 0,
      totalWarnNum: [
      ],
      newStartDate: '',
      newEndDate: '',
      building: '',
      line: '',
      dao: false,
      maintain:[
      ],
        warnExcel: [],
        handleExcel: [],
      showbingtu: true,
      msg: "Welcome to Your Vue.js App",
    };
  },
  components:{
    policesingto
  },
  comments: {
    echarts

  },
  methods:{
      getWarningValue (item) {
        console.log(item, '模板值')
        // return item.warningValue
          const template = item.devicesTemplateParams
          if (template) {
              let value = eval(item.warningValue)
              let floatValue = eval(item.warningValue)
              if (typeof value === 'number') {
                  value = parseInt(value)
              }
              // 判断是否报警组
              // if (parseInt(template?.warningFlag) === 1 && returnClass(item, value, index) === 'error') {
              if (parseInt(template?.warningFlag) === 1) {
                  const remark = JSON.parse(template.warningRemark)
                  if (remark.filter(item => eval(item.number) === value)[0]?.text) {
                      return remark.filter(item => eval(item.number) === value)[0]?.text
                  }
              }
              switch (value) {
                  case 0:
                      floatValue = template.zero || floatValue
                      return floatValue
                  case 1:
                      floatValue = template.one || floatValue
                      // console.log(item.params[index], '111111111111');
                      return floatValue
                  case 2:
                      floatValue = template.two || floatValue
                      return floatValue
                  case 3:
                      floatValue = template.three || floatValue
                      return floatValue
                  case 4:
                      floatValue = template.four || floatValue
                      return floatValue
                  case 5:
                      floatValue = template.five || floatValue
                      return floatValue
                  // case 6:
                  //   returnTitle = template.five || item.template[key].cusParamsName || item.template[key].paramsName || key
                  //   break;
              }
              if (typeof floatValue === 'number') {
                  return (template && template.warnCondition) || parseFloat(floatValue).toFixed(2)
              }
              return floatValue
          }
      },
    getHistoryData () {
      this.initPie = false
      // 置空
      // this.normalData = []
      // this.warningData = []
      // this.normalData.forEach((item) => {
      //   item = 0
      //   console.log(item)
      // })
      // this.warningData.forEach((item) => {
      //   item = 0
      //   console.log(item)
      // })
      // this.normalData = []
      // this.typeNum = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
      // this.warntypeNum = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
      let url = this.$commonJs.returnQuery('/customer/devicePage', {page: 1, size: 99999, parentId: this.lxUserInfo.parentId || ''})
      this.$axiox.cpost(url).then(async msg => {
        if (msg.message === '成功') {
          let newList = []
          if (this.lxUserInfo.devices) {
            for (let i = 0; i < msg.data.content.length; i++) {
              if (this.lxUserInfo.devices.indexOf(msg.data.content[i].id) >=0) {
                newList.push(msg.data.content[i])
              }
            }
          } else {
            for (let i = 0; i < msg.data.content.length; i++) {
              if (this.templateArray.indexOf(msg.data.content[i].templateId) >= 0) {
                msg.data.content[i].templateParam = this.templateData[this.templateArray.indexOf(msg.data.content[i].templateId)]
                msg.data.content[i].templateDetail = this.templateDetailArray[this.templateArray.indexOf(msg.data.content[i].templateId)]
              } else {
                const detail = await this.$axiox.cpost('/customer/paramsList?templateId=' + msg.data.content[i].templateId)
                const array = []
                for (let i = 0; i < detail.data.length; i++) {
                  if (detail.data[i].chart === 1) {
                    array.push(detail.data[i].paramsId)
                  }
                }
                console.log(array)
                msg.data.content[i].templateParam = array
                msg.data.content[i].templateDetail = detail.data
                this.templateData.push(array)
                this.templateDetailArray.push(detail.data)
                this.templateArray.push(msg.data.content[i].templateId)
              }
            }
            newList = msg.data.content
          }
          this.deviceSortType = []
          this.deviceSortArray = []
          for (let i = 0; i < newList.length; i++) {
            if (newList[i].sortTypeId && this.deviceSortArray.indexOf(newList[i].sortTypeId) >= 0) {
              this.deviceSortType[this.deviceSortArray.indexOf(newList[i].sortTypeId)].devices.push(newList[i])
            } else {
              this.deviceSortType.push({
                sortTypeId: newList[i].sortTypeId || newList[i].id,
                sortType: newList[i].sortType || newList[i].deviceName,
                buildingName: newList[i].sortType || newList[i].deviceName,
                devices: [newList[i]]
              })
              this.deviceSortArray.push(newList[i].sortTypeId || null)
              if (this.line) {
                console.log(newList[i], 'newList[i]22222')
                if (this.sortTypeFilter.indexOf(newList[i].lineName) !== -1) {
                  console.log(1)
                } else {
                  console.log(newList[i], 'newList[i]')
                  if (this.line === newList[i].lineName) {
                    this.sortTypeFilter.push(newList[i].lineName)
                  }
                }
              } else {
                if (this.building.length > 1 && typeof this.building === 'string') {
                  if (this.sortTypeFilter.indexOf(newList[i].sortType || newList[i].lineName) !== -1) {
                    console.log(1)
                  } else {
                    if (this.building === (newList[i].sortType || newList[i].lineName)) {
                      this.sortTypeFilter.push(newList[i].sortType || newList[i].lineName)
                    }
                  }
                } else {
                  if (this.sortTypeFilter.indexOf(newList[i].sortType || newList[i].lineName) !== -1) {
                    console.log(1)
                  } else {
                    console.log(newList[i], 'newList[i]')
                    this.sortTypeFilter.push(newList[i].sortType || newList[i].lineName)
                  }
                }
              }
            }
          }
          for (let i = 0; i < this.deviceSortType.length; i++) {
            const lineIdArray = []
            this.deviceSortType[i].lineData = []
            for (let j = 0; j < this.deviceSortType[i].devices.length; j++) {
              if (this.deviceSortType[i].devices[j].lineId && lineIdArray.indexOf(this.deviceSortType[i].devices[j].lineId) >= 0) {
                this.deviceSortType[i].lineData[lineIdArray.indexOf(this.deviceSortType[i].devices[j].lineId)].devices.push(this.deviceSortType[i].devices[j])
              } else {
                this.deviceSortType[i].lineData.push({
                  lineName: this.deviceSortType[i].devices[j].lineName || this.deviceSortType[i].devices[j].deviceName,
                  lineId: this.deviceSortType[i].devices[j].lineId || this.deviceSortType[i].devices[j].id,
                  devices: [this.deviceSortType[i].devices[j]],
                  portData: [],
                  detailData: []
                })
                lineIdArray.push(this.deviceSortType[i].devices[j].lineId || null)
                if (this.lineNameFilter.indexOf(this.deviceSortType[i].devices[j].lineName || this.deviceSortType[i].devices[j].deviceName) >= 0) {
                  console.log(1)
                } else {
                  this.lineNameFilter.push(this.deviceSortType[i].devices[j].lineName || this.deviceSortType[i].devices[j].deviceName)
                }
              }
            }
          }
          if (this.sortTypeFilter2.length === 0) {
            this.sortTypeFilter2 = lodash.cloneDeep(this.sortTypeFilter)
          }
          // this.sortTypeFilter = lodash.cloneDeep(this.sortTypeFilter2)
          this.deviceList = newList
          this.totalWarnNum = [0,0,0,0,0,0]
          // this.normalData = null
          // this.warningData = null
          // console.log(lodash.cloneDeep(this.normalData), 'null')
          this.normalData = []
          this.warningData = []
          // this.normalData.length = 0
          // this.warningData.length = 0
          // console.log(lodash.cloneDeep(this.normalData), '清空了normal')
          // 筛选区域
          if (typeof this.building === "string" && this.building.length > 0) {
            this.deviceSortType = this.deviceSortType.filter(item => item.buildingName === this.building)
          }
          for (let a = 0 ;a < this.deviceSortType.length; a++) {
            // console.log('设备')
            this.normalData.push(0)
            this.warningData.push(0)
          }
          // console.log(lodash.cloneDeep(this.normalData), '清空了normal2', newList)
          this.$axiox.cpost('/warning/waringHistoryPages', {handle:0,deviceName:'',customerId:null,typeId:"",start: this.newStartDate + ' 00:00:00',end: this.newEndDate + ' 23:59:59',size:9999,keep:0,page:1}).then(async msg2 => {
            const warnData = msg2.data.content
            // console.log(this.deviceSortType, '设备分类', warnData)
            this.warnExcel = msg2.data.content
            // console.log(msg.data, 'templateList')
            // 给导出数据添加匹配模板
            // devicesTemplateParams
            this.warnExcel.forEach((item) => {
              for (const template of msg?.data.content ?? []) {
                for (const params of template.templateDetail) {
                  if (item.paramsAddress === params.paramsAddress) {
                    item.devicesTemplateParams = params
                  }
                }
              }
            })
            // let count = 0
            for (let i = 0; i < warnData.length; i++) {
              for (let a = 0 ;a < this.deviceSortType.length; a++) {
                if (typeof this.line === 'string' && this.line.length > 0) {
                  // 筛选线别
                  this.deviceSortType[a].lineData = this.deviceSortType[a].lineData.filter(item => item.lineName === this.line)
                }
                for (let b = 0 ;b < this.deviceSortType[a].lineData.length; b++) {
                  for (let c = 0 ;c < this.deviceSortType[a].lineData[b].devices.length; c++) {
                    const device = this.deviceSortType[a].lineData[b].devices[c]
                    if (warnData[i].deviceId === device.id) {
                      this.warningData[a]++
                      // count++
                      // console.log(count, 'count')
                      // console.log(device.templateDetail[s].chart, 'device.templateDetail[s].chart')
                      for (let s = 0; s < device.templateDetail.length; s++) {
                        if (warnData[i].paramsId === device.templateDetail[s].paramsId) {
                          if (device.templateDetail[s].chart === 1) {
                            this.totalWarnNum[0]++
                          }
                          else if (device.templateDetail[s].chart === 2) {
                            this.totalWarnNum[1]++
                          }
                          else if (device.templateDetail[s].chart === 3) {
                            this.totalWarnNum[2]++
                          }
                          else if (device.templateDetail[s].chart === 4) {
                            this.totalWarnNum[3]++
                          }
                          else if (device.templateDetail[s].keep === 1) {
                            this.totalWarnNum[4]++
                          } else if (parseInt(device.templateDetail[s].warningFlag) === 1) {
                            // console.log('报警组')
                            // 报警组
                            const warningRemark = JSON.parse(device.templateDetail[s].warningRemark)
                            for (const remark of warningRemark) {
                              if (Number(remark.number) === Number(warnData[i].warningValue)) {
                                switch (parseInt(remark.type)) {
                                  case 1:
                                    this.totalWarnNum[0]++
                                    // console.log('风速', warnData[i])
                                    break;
                                  case 2:
                                    this.totalWarnNum[1]++
                                    break;
                                  case 3:
                                    this.totalWarnNum[2]++
                                    break;
                                  case 4:
                                    this.totalWarnNum[3]++
                                    break;
                                  case 5:
                                    this.totalWarnNum[4]++
                                    break;
                                  default:
                                    // console.log('其他', warnData[i])
                                    this.totalWarnNum[5]++
                                    break;
                                }
                                break
                              }
                            }
                          } else {
                            this.totalWarnNum[5]++
                            // console.log('其他', warnData[i])
                          }
                          break
                        }
                      }
                    }
                  }
                }
              }
            }
            this.$axiox.cpost('/warning/handles', {handle:1,deviceName:'',customerId:null,typeId:"",start: this.newStartDate + ' 00:00:00',end: this.newEndDate + ' 23:59:59',size:9999,keep:0,page:1, type: 0}).then(async msg2 => {
              const warnData = msg2.data.list || []
              this.handleExcel = msg2.data.list
              for (let i = 0; i < warnData.length; i++) {
                for (let a = 0 ;a < this.deviceSortType.length; a++) {
                  if (typeof this.line === 'string' && this.line.length > 0) {
                    // 筛选线别
                    this.deviceSortType[a].lineData = this.deviceSortType[a].lineData.filter(item => item.lineName === this.line)
                  }
                  for (let b = 0 ;b < this.deviceSortType[a].lineData.length; b++) {
                    for (let c = 0 ;c < this.deviceSortType[a].lineData[b].devices.length; c++) {
                      const device = this.deviceSortType[a].lineData[b].devices[c]
                      if (warnData[i].deviceId === device.id) {
                        // console.log('设备对上了', device.templateDetail)
                        this.normalData[a]++
                        for (let s = 0; s < device.templateDetail.length; s++) {
                          if (warnData[i].paramsId === device.templateDetail[s].paramsId) {
                            // console.log('进来了', device.templateDetail[s])
                            if (device.templateDetail[s].chart === 1) {
                              this.totalWarnNum[0]++
                            } else if (device.templateDetail[s].chart === 2) {
                              this.totalWarnNum[1]++
                            } else if (device.templateDetail[s].chart === 3) {
                              this.totalWarnNum[2]++
                            } else if (device.templateDetail[s].chart === 4) {
                              this.totalWarnNum[3]++
                            } else if (device.templateDetail[s].keep === 1) {
                              this.totalWarnNum[4]++
                            } else if (parseInt(device.templateDetail[s].warningFlag) === 1) {
                              // 报警组
                              const warningRemark = JSON.parse(device.templateDetail[s].warningRemark)
                              for (const remark of warningRemark) {
                                if (Number(remark.number) === Number(warnData[i].warningValue)) {
                                  switch (parseInt(remark.type)) {
                                    case 1:
                                      this.totalWarnNum[0]++
                                      break;
                                    case 2:
                                      this.totalWarnNum[1]++
                                      break;
                                    case 3:
                                      this.totalWarnNum[2]++
                                      break;
                                    case 4:
                                      this.totalWarnNum[3]++
                                      break;
                                    case 5:
                                      this.totalWarnNum[4]++
                                      break;
                                    default:
                                      this.totalWarnNum[5]++
                                      break;
                                  }
                                  break
                                }
                              }
                            } else {
                              this.totalWarnNum[5]++
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              this.show()
              console.log(this.totalWarnNum)
              this.initPie = true
            })
          })
        }
      })
    },
    exportExcel2 () {
      // console.log(pubilshSet.eomsApi + '/sys/sysWarningInfo/export?fileName=报警记录导出&sheetName=报警记录导出&area=' + this.building + '&line=' + this.line + '&startTime=' + this.newStartDate + '&endTime=' + this.newEndDate)
      window.open(pubilshSet.eomsApi + '/sys/sysWarningInfo/export?fileName=报警记录导出&sheetName=报警记录导出&area=' + this.building + '&line=' + this.line + '&startTime=' + this.newStartDate + '&endTime=' + this.newEndDate)
    },
    returnWaringName (deviceId, paramsAddress) {
      // console.log(deviceId, this.deviceList, paramsAddress)
      const template = this.deviceList.find(item => item.id === deviceId)
      for (const param of template.templateDetail) {
        if (param.paramsAddress === paramsAddress) {
          return param
        }
      }
    },
    exportExcel (type) {
      let html = '<html><head><meta charset=\'utf-8\' /></head><body>'
        if (type === 2) {
            html += document.getElementById('hiddenHistoryList').innerHTML
            html += '</body></html>'
            // 实例化一个Blob对象，其构造函数的第一个参数是包含文件内容的数组，第二个参数是包含文件类型属性的对象
            let blob = new Blob([html], {
              type: 'application/vnd.ms-excel'
            })
            let a = document.createElement('a')
            a.href = URL.createObjectURL(blob)
            if (type === 2) {
              a.download = '未处理报警数据.xls'
            } else {
              // a.download = '已处理报警数据.xls'
            }
            a.click()
        } else {
          // 已处理导出
          let url = "";
          let data = {
            deviceName: '',
            typeId: '',
            start: this.newStartDate + ' 00:00:00',
            end: this.newEndDate + ' 23:59:59',
            // handle: 1,
            type: 0
          };
          let xmlResquest = new XMLHttpRequest();
          url = publishSet.custBaseUrl + "/customer/excel/handlesExport";
          xmlResquest.open("POST", url, true);
          xmlResquest.setRequestHeader("Content-type", "application/json");
          xmlResquest.setRequestHeader(
              "token",
              window.sessionStorage.getItem("lixtoken")
          );
          xmlResquest.setRequestHeader(
              "userType",
              "2"
          );
          xmlResquest.responseType = "blob";
          xmlResquest.onload = function () {
            let content = xmlResquest.response;
            if (content.type) {
              if (content.type.indexOf("json") >= 0) {
                this.$commonJs.alertTips({
                  type: "error",
                  title: "失败",
                  content: "暂无数据",
                  stayTime: 5,
                });
                return;
              }
            }
            let elink = document.createElement("a");
            elink.download = "报警记录.xlsx";
            elink.style.display = "none";
            let blob = new Blob([content]);
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            document.body.removeChild(elink);
          };
          xmlResquest.send(JSON.stringify(data));
            // html += document.getElementById('hiddenHistoryList2').innerHTML
        }
    },
      show(){
        console.log(this.normalData, this.warningData, 'this.warningData')
        let hasBaoyang = this.normalData
        let waitBaoyang = this.warningData

        let Xdata = this.sortTypeFilter

        this.totalNum = 0
        this.wrongNum = 0
        for (let i = 0; i < hasBaoyang.length; i++) {
          this.totalNum += waitBaoyang[i] + hasBaoyang[i]
          this.wrongNum += waitBaoyang[i]
        }
        this.showbingtu = false
        setTimeout( () => {
          this.showbingtu = true
        }, 100)
        var chartDom = document.getElementById('main');
        var myChart = echarts.init(chartDom);
        var option;
        let that = this
        const seriesForMater = (e) => {
          return e.value || ''
        }
        option = {
          legend: {
            textStyle:{
              color:"white"
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: Xdata,
              axisLabel: {
                show: true,
                textStyle:{
                  color:"white"
                }
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              axisLabel: {
                show: true,
                textStyle:{
                  color:"white"
                }
              }
            }
          ],
          tooltip: {
            position: 'inside',
            extraCssText: "background-color: #202F50;border:1px #87363F solid",
            textStyle: {
              color:"white"
            },
            formatter: function(params) {
              console.log(params)
              let html = ''
              const newTotal = params.data
              for (let i =0; i < that.totalWarnNum[params.dataIndex].length; i++) {
                if (that.totalWarnNum[params.dataIndex][i] !== 0) {
                  html += '<div style="border-bottom: 2px #414C62 dotted;padding: 5px;text-align: left">'+'类型：' + that.$commonJs.errorData[i] + '<br />' + '数量：' + that.totalWarnNum[params.dataIndex][i] + '<br />' + '占比：' +
                      // params.value[1] +
                      parseFloat(that.totalWarnNum[params.dataIndex][i] / newTotal * 100).toFixed(2) + '%' + '<br />' +'</div>'
                }

              }
              return html;
            }
          },
          series: [
            {
              name: '已处理报警',
              type: 'bar',
              color: 'orange',
              emphasis: {
                focus: 'series'
              },
              data: hasBaoyang,
              label:{
                show: true,
                position:'inside',
                color: 'white',
                formatter: seriesForMater
              }
            },
            {
              name: 'open数量',
              type: 'bar',
              color: '#FF2D55',
              data: waitBaoyang,
              label:{
                show: true,
                position:'inside',
                color: 'white',
                formatter: seriesForMater
              },
              emphasis: {
                focus: 'series'
              }
            }
          ]
        };

        option && myChart.setOption(option);
      }
  },
  mounted(){
    this.newEndDate = this.$commonJs.todayDate
    this.newStartDate = this.$commonJs.lastWeekDay()
    this.getHistoryData()
      // this.show();
  }
}
</script>

<style scoped>

</style>
