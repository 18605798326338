<template>
  <section  @click="stopShow($event)" id="multipleSelect" class="multipleSelectFather">
    <section :id="randomId + 'father'" :style="{'max-width': width ? width + '!important' : '','min-width': width ? width + '!important' : ''}" class="multipleSelect">
      <section v-if="!needSearch">
        <section :style="{'background': newDisabled ? '#ECF2FD' : '','cursor':newDisabled? ' no-drop': 'pointer', 'border': '1px solid ' + (newDisabled ? '#ECF2FD' : borderLine)}" :title="placeholder" @click="showDropFun" v-if="showSelectValue === ''" style="color: #819df7;font-size: 13.3px" class="multipleSelectShowPlace">{{placeholder}}</section>
        <section :style="{'background': newDisabled ? '#ECF2FD' : '','cursor':newDisabled? ' no-drop': 'pointer', 'border': '1px solid ' + (newDisabled ? '#ECF2FD' : borderLine)}" :title="showSelectValue" v-else @click="showDropFun" class="multipleSelectShowPlace" style="font-size: 13.3px">{{showSelectValue}}</section>
      </section>
      <section v-else>
        <section :style="{'background': newDisabled ? '#ECF2FD' : '','cursor':newDisabled? ' no-drop': 'pointer', 'border': '1px solid' + (newDisabled ? '#ECF2FD' : borderLine)}" :title="placeholder" @click="needSearchShowDropFun" v-if="showSelectValue === ''" style="color: #819df7;font-size: 0" class="multipleSelectShowPlace">
          <input :disabled="newDisabled" autocomplete="off" :id="randomId + 'input'" @focus="backAllList" @input="searchValue" style="background:transparent; color:white;border: none;outline: none;color: #819df7;width: 100%;font-size: 13.3px;height: 17px" :placeholder="showSelectValue || placeholder" type="text">
        </section>
        <section :style="{'background': newDisabled ? '#ECF2FD' : '','cursor':newDisabled? ' no-drop': 'pointer', 'border': '1px solid' + (newDisabled ? '#ECF2FD' : borderLine)}" :title="showSelectValue" v-else @click="needSearchShowDropFun" class="multipleSelectShowPlace" style="font-size: 0">
          <input :disabled="newDisabled" autocomplete="off" :id="randomId + 'input'" @focus="backAllList" @input="searchValue" style="background:transparent; color:white;border: none;outline: none;width: 100%;font-size: 13.3px;height: 17px" :placeholder="showSelectValue || placeholder" :value="showSelectValue" type="text">
        </section>
      </section>
      <i @click="showDropFun" v-show="!newDisabled" class="icon iconfont iconxialajiantou multipleSelectArror f_w"></i>
      <section :id="randomId" class="multipleSelectDrop">
        <section v-if="shownothing" disabled="disabled" class="multipleSelectSelectData">{{$t('common.NoValueFound')}}</section>
        <section @click="confirmData(item)" :class="item.checked ? 'active' : ''" v-html="returnShowValue(item)" class="multipleSelectSelectData" :key="index" v-for="(item, index) in selectData" style="font-size: 13.3px">{{returnShowValue(item)}}</section>
      </section>
    </section>
  </section>
</template>

<script>
let timeOut
let array = []
export default {
  name: 'cander',
  model: {
    prop: 'selectValue',
    event: 'selectValue'
  },
  props: [
    'needSearch',
    'multiple',
    'canInputSearch',
    'selectValue',
    'chooseData',
    'needReturn',
    'showValue',
    'matchValue',
    'placeholder',
    'width',
    'disabled',
    'item',
    'borderLine'
  ],
  data () {
    return {
      shownothing: false,
      newDisabled: this.disabled,
      randomId: 'selectId' + new Date().getTime() + Math.random() * 1000,
      newSelectValue: this.selectValue,
      showSelectValue: '',
      selectData: this.chooseData
    }
  },
  created () { // vue 页面的生命周期 当我一进来页面之后 我先执行
    array = []
    this.handleData()
  },
  mounted () { // vue 页面的生命周期 当我进来之后 且加载完资源了之后再执行
    if (document.getElementById('index')) {
      document.getElementById('index').addEventListener('click', closetab)
    }
  },
  destroyed () {
    if (document.getElementById('index')) {
      document.getElementById('index').removeEventListener('click', closetab)
    }
  },
  methods: {
    searchValue (e) {
      clearTimeout(timeOut)
      timeOut = setTimeout(() => {
        let searchValue = e.target.value
        let selDom = document.getElementById(this.randomId)
        let selList = selDom.querySelectorAll('.multipleSelectSelectData')
        let num = 0
        for (let i = 0; i < selList.length; i++) {
          if (searchValue === '') {
            selList[i].style.display = 'block'
          } else {
            selList[i].style.display = 'none'
            if (selList[i].innerHTML.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0) {
              selList[i].style.display = 'block'
            }
          }
          if (selList[i].style.display !== 'none') {
            num++
          }
        }
        if (num === 0) {
          this.shownothing = true
        } else {
          this.shownothing = false
        }
        if (this.canInputSearch) {
          this.$emit('selectValue', searchValue)
        }
      }, 500)
    },
    backAllList (e) {
      let selDom = document.getElementById(this.randomId)
      let selList = selDom.querySelectorAll('.multipleSelectSelectData')
      for (let i = 0; i < selList.length; i++) {
        selList[i].style.display = 'block'
      }
      if (e) {
        e.target.value = ''
      }
      this.shownothing = false
    },
    handleData () {
      this.showSelectValue = ''
      let splitValue = (this.newSelectValue === '' || this.newSelectValue === null || this.newSelectValue === undefined) ? [''] : this.newSelectValue.toString().split(',')
      if (this.selectData) {
        for (let i = 0; i < this.selectData.length; i++) {
          this.$set(this.selectData[i], 'checked', false)
          for (let j = 0; j < splitValue.length; j++) {
            // console.log(splitValue[j])
            if (this.selectData[i][this.needReturn].toString() === splitValue[j] && splitValue[j] !== '') {
              // if (this.multiple) {
              //   this.selectData[i].checked = true
              // }
              this.selectData[i].checked = true
              this.showSelectValue += this.selectData[i][this.matchValue] + ','
            }
          }
        }
      }
      this.showSelectValue = this.showSelectValue.substring(0, this.showSelectValue.length - 1)
      // console.log(this.showSelectValue)
      let html = this.showValue
      getItem(html)
    },
    confirmData (item) {
      if (this.multiple) {
        let splitValue = (this.newSelectValue === '' || this.newSelectValue === null || this.newSelectValue === undefined) ? [''] : this.newSelectValue.split(',')
        let splitshowSelectValue = this.showSelectValue === '' ? [] : this.showSelectValue.split(',')
        if (item[this.needReturn].toString() === '' && splitValue.length > 0) {
          splitValue = ['']
        }
        if (splitValue.indexOf(item[this.needReturn].toString()) >= 0) {
          splitValue.splice(splitValue.indexOf(item[this.needReturn].toString()), 1)
          splitshowSelectValue.splice(splitshowSelectValue.indexOf(item[this.needReturn].toString()), 1)
          let selectHmtl = ''
          let selectHmtl2 = ''
          for (let i = 0; i < splitValue.length; i++) {
            selectHmtl += splitValue[i] + ','
            selectHmtl2 += splitshowSelectValue[i] + ','
          }
          selectHmtl = selectHmtl.substring(0, selectHmtl.length - 1)
          selectHmtl2 = selectHmtl2.substring(0, selectHmtl2.length - 1)
          this.newSelectValue = selectHmtl
          this.showSelectValue = selectHmtl2
          this.$emit('selectValue', this.newSelectValue)
          if (this.item) {
            item.object = this.item
          }
          this.$emit('on-change', item)
        } else {
          if (this.newSelectValue === '') {
            this.newSelectValue += item[this.needReturn]
          } else {
            this.newSelectValue += ',' + item[this.needReturn]
          }
          this.$emit('selectValue', this.newSelectValue)
          if (this.item) {
            item.object = this.item
          }
          this.$emit('on-change', item)
          if (this.showSelectValue === '') {
            this.showSelectValue += item[this.matchValue]
          } else {
            this.showSelectValue += ',' + item[this.matchValue]
          }
        }
        this.handleData()
      } else {
        this.newSelectValue = item[this.needReturn]
        if (this.showSelectValue) {
          this.showSelectValue = item[this.matchValue]
        }
        this.$emit('selectValue', this.newSelectValue)
        if (this.item) {
          item.object = this.item
        }
        this.$emit('on-change', item)
        let dom = document.getElementById(this.randomId)
        dom.style.opacity = '0'
        setTimeout(() => {
          dom.style.display = 'none'
        }, 200)
      }
    },
    needSearchShowDropFun () {
      if (!this.newDisabled) {
        this.backAllList()
        let dom = document.getElementById(this.randomId)
        let domList = document.querySelectorAll('.multipleSelectFather')
        for (let i = 0; i < domList.length; i++) {
          domList[i].style.zIndex = 1
        }
        dom.parentNode.parentNode.style.zIndex = 2
        if (dom.style.display === 'none' || dom.style.display === '') {
          dom.style.display = 'block'
          setTimeout(() => {
            dom.style.opacity = '1'
          }, 10)
        }
      }
    },
    showDropFun () {
      if (!this.newDisabled) {
        let fatherId = document.getElementById(this.randomId + 'father')
        console.log(fatherId)
        let dom = document.getElementById(this.randomId)
        dom.style.width = fatherId.clientWidth + 'px'
        let domList = document.querySelectorAll('.multipleSelectFather')
        for (let i = 0; i < domList.length; i++) {
          domList[i].style.zIndex = 1
        }
        dom.parentNode.parentNode.style.zIndex = 2
        if (dom.style.display === 'none' || dom.style.display === '') {
          dom.style.display = 'block'
          setTimeout(() => {
            dom.style.opacity = '1'
          }, 10)
        } else {
          dom.style.opacity = '0'
          setTimeout(() => {
            dom.style.display = 'none'
          }, 200)
        }
      }
    },
    returnShowValue (object) {
      let html = this.showValue
      for (let i = 0; i < array.length; i++) {
        html = html.replace('{{' + array[i] + '}}', object[array[i]])
      }
      return html
    },
    stopShow (e) {
      e.stopPropagation()
    }
  },
  watch: {
    selectValue (value) {
      this.newSelectValue = value
      this.showSelectValue = ''
      this.handleData()
      if (value === '' && this.needSearch) {
        if (document.getElementById(this.randomId + 'input')) {
          document.getElementById(this.randomId + 'input').value = ''
        }
      }
      // console.log(value)
    },
    chooseData (value) {
      this.selectData = value
      this.handleData()
    },
    disabled (value) {
      this.newDisabled = value
      this.handleData()
    }
  },
  computed: {
  }
}
function closetab () {
  let canderList = document.querySelectorAll('.multipleSelectDrop')
  if (canderList) {
    for (let i = 0; i < canderList.length; i++) {
      canderList[i].style.opacity = '0'
    }
    let domList = document.querySelectorAll('.allTablePlace')
    for (let i = 0; i < domList.length; i++) {
      // domList[i].classList.remove('over_in')
    }
    let domList2 = document.querySelectorAll('.tableFixedRight')
    for (let i = 0; i < domList2.length; i++) {
      // domList2[i].classList.remove('over_in')
    }
    setTimeout(() => {
      for (let i = 0; i < canderList.length; i++) {
        canderList[i].style.display = 'none'
      }
    }, 200)
  }
}
function getItem (html) {
  if (html.indexOf('{{') >= 0) {
    let aPos = html.indexOf('{{')
    let bPos = html.indexOf('}}')
    let newName = html.substr(aPos + 2, bPos - aPos - 2)
    array.push(newName)
    html = html.replace('{{', '').replace('}}', '')
    getItem(html)
  }
}
</script>

<style scoped>
  .multipleSelectFather{
    position: relative;
  }
  .multipleSelect{
    position: relative;
    max-width: 180px;
    min-width: 180px;
    width: 100%;
    text-align: left;
    z-index: 5;
  }
  .multipleSelect .multipleSelectShowPlace {
    background-color: #243577;
    padding: 6px 20px 6px 10px;
    border-radius: 4px;
    border:1px solid #809cf5;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .multipleSelect .multipleSelectDrop {
    position: absolute;
    top: 32px;
    background: #314593;
    width: 100%;
    display: none;
    opacity: 0;
    transition: opacity 0.2s;
    max-height: 150px;
    overflow: auto;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 4px;
    color: #7e9cf5;
  }
  .multipleSelect .multipleSelectDrop .multipleSelectSelectData{
    padding: 6px 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
  }
  .multipleSelect .multipleSelectDrop .multipleSelectSelectData.active::after{
    content: '√';
    font-family: "微软雅黑", "Arial";
    position: absolute;
    right: 5px;
  }
  .multipleSelect .multipleSelectDrop .multipleSelectSelectData:hover{
    color: #fff;
    cursor: pointer;
    background: #6a7fc4;
  }
  .multipleSelectArror{
    position: absolute;
    right: 8px;
    top: 9px;
    color: #809cf5;
    font-size: 10px;
  }
  @media screen and (max-width: 1370px) {
    .multipleSelect {
      max-width: 200px!important;
      min-width: 200px!important;
    }
  }
</style>
