<template>
  <div class="whe_max">
    <div style="text-align: left;font-size: 22px;border-left: white 3px solid;padding: 0 14px">连锁实时状态</div>
    <div class="mag_t-15" style="font-size: 30px;width: 90%">
      <Datagraph1 style="background-color: rgb(34, 48, 77);border-radius:10px;display: inline-block;vertical-align: middle; width: 45%;margin:0 1%;height: 40vh;overflow: hidden" :hidden="true" ></Datagraph1>
      <Datagraph2 style="background-color: rgb(34, 48, 77);border-radius:10px;display: inline-block;vertical-align: middle; width: 45%;margin:0 1%;height: 40vh;overflow: hidden" :hidden="true" ></Datagraph2>
      <Datagraph3 style="background-color: rgb(34, 48, 77);border-radius:10px;display: inline-block;vertical-align: middle; width: 45%;margin:30px 1%;height: 40vh;overflow: hidden" :hidden="true" ></Datagraph3>
      <Datagraph4 style="background-color: rgb(34, 48, 77);border-radius:10px;display: inline-block;vertical-align: middle; width: 45%;margin:0 1%;height: 40vh;overflow: hidden" :hidden="true" ></Datagraph4>
    </div>
    <router-view>
    </router-view>
  </div>
</template>

<script>
import Datagraph1 from '../dorm/Datagraph/Datagraph1.vue'
import Datagraph2 from '../dorm/Datagraph/Datagraph2.vue'
import Datagraph3 from '../dorm/Datagraph/Datagraph3.vue'
import Datagraph4 from '../dorm/Datagraph/Datagraph4.vue'
export default {
  name: "chainPage",
  data () {
    return {

    }
  },
  destroyed () {
  },
  created () {
  },
  mounted () {
  },
  methods: {
  },
  components:{
    Datagraph1,
    Datagraph2,
    Datagraph3,
    Datagraph4,
  }
}
</script>

<style scoped>
</style>
