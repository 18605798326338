<template>
<div>
  <div style="text-align: left;font-size: 22px;border-left: white 3px solid;padding: 0 14px">保养状况</div>
  <div style="background-color: rgb(34, 48, 77);border-radius: 10px;margin-top: 15px;padding-left: 15px">
    <div style="text-align: left;padding: 15px 0">
<!--      <div class="mag_r-20" style="display: inline-block;vertical-align: middle;">-->
<!--        <span class="mag_r-20">区域</span>-->
<!--        <span>-->
<!--          <select @change="getTemplate()" v-model="building" class="allSelect sele" name="">-->
<!--              <option :value="''">全部</option>-->
<!--          <template v-if="lxUserInfo.id === 125">-->
<!--              <option :value="'TR'">TR</option>-->
<!--            </template>-->
<!--            <template v-else>-->
<!--            <option :value="'A区'">A区</option>-->
<!--              <option :value="'B区'">B区</option>-->
<!--              <option :value="'C区'">C区</option>-->
<!--              <option :value="'F区'">F区</option>-->
<!--              <option :value="'G区'">G区</option>-->
<!--              <option :value="'H区'">H区</option>-->
<!--              <option :value="'I区'">I区</option>-->
<!--            </template>-->
<!--          </select>-->
<!--        </span>-->
<!--      </div>-->
<!--      <div class="mag_r-20" style="display: inline-block;vertical-align: middle">-->
<!--        <span class="mag_r-20">线别</span>-->
<!--        <span>-->
<!--          <select @change="getTemplate()" v-model="line" class="allSelect sele" name="">-->
<!--                        <option :value="''">全部</option>-->
<!--          <template v-if="lxUserInfo.id === 125">-->
<!--            <option :value="'1线'">线别1</option>-->
<!--            </template>-->
<!--            <template v-else>-->
<!--         <option :value="'1线'">线别1</option>-->
<!--            <option :value="'2线'">线别2</option>-->
<!--            <option :value="'3线'">线别3</option>-->
<!--            <option :value="'4线'">线别4</option>-->
<!--               </template>-->
<!--          </select>-->
<!--        </span>-->
<!--      </div>-->
<!--      <button @click="getTemplate()" class="btn btn_success mag_l-20 mag_r-20"><span>搜索</span></button>-->
      <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
        <span class="m_r_5">开始时间: </span>
        <cander :newWidth="'150px'" class="allInput d_l" v-model="queryDate.start"  ></cander>
        <span class="m_l_20 m_r_5">结束时间: </span>
        <cander :newWidth="'150px'" class="allInput d_l" v-model="queryDate.end"  ></cander>
        <button @click="getTemplate" class="btn btn_success mag_l-20 mag_r-20"><span>搜索</span></button>
        <!--        <span style="border-radius: 5px;border: 1px solid #eee;padding: 10px 20px;cursor: pointer" @click="exportExcel" class="mag_r-20">导出数据</span>-->
        <span style="border-radius: 5px;margin-left: 20px;border: 1px solid #eee;padding: 10px 20px;cursor: pointer" @click="exportExcel(1)" class="mag_r-20">已保养数据</span>
        <span style="border-radius: 5px;border: 1px solid #eee;padding: 10px 20px;cursor: pointer" @click="webExportMaintenance(2)" class="mag_r-20">待保养数据</span>
      </div>
    </div>
      <div id="hiddenHistoryList" v-show="dao" class="jz_flex"  style="height: 250px;overflow: auto;">
          <table class="allTable bd ba_white wz_black" border="1" cellspacing="0">
              <thead>
              <tr>
                  <th>序号</th>
                  <th>设备名称</th>
                  <th>设备编号</th>
                  <!-- <th>报警名称</th> -->
                  <th>保养名称</th>
                  <th>保养时间</th>
                  <th>是否处理</th>
                  <th>报警值</th>
                  <th></th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(item, index) in warnExcel">
                  <tr :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ item.deviceName }}</td>
                      <td>{{ item.macAddress }}</td>
                      <!-- <td>{{ item.deviceTypeName }}</td> -->
                      <td>{{ item.paramsName }}</td>
                      <td>{{ item.createTime }}</td>
                      <td>{{ item.handle ? '处理' : '未处理' }}</td>
                      <td>{{ item.warningValue }}</td>
                      <td></td>
                      <!-- <td>{{ item.warningContent }}</td>
                      <td>{{ item.createTime }}</td>
                      <td>312</td> -->
                  </tr>
              </template>
              </tbody>
          </table>
      </div>
<!--      <div id="hiddenHistoryList2" v-show="dao" class="jz_flex"  style="height: 250px;overflow: auto;">-->
<!--          <table class="allTable bd ba_white wz_black" border="1" cellspacing="0">-->
<!--              <thead>-->
<!--              <tr>-->
<!--                  <th>序号</th>-->
<!--                  <th>设备名称</th>-->
<!--                  &lt;!&ndash; <th>报警名称</th>-->
<!--                  <th>报警地址</th>-->
<!--                  <th>报警时间</th>-->
<!--                  <th>是否处理</th> &ndash;&gt;-->
<!--                  <th>保养结果</th>-->
<!--                  &lt;!&ndash; <th>MAC地址</th> &ndash;&gt;-->
<!--                  <th>处理时间</th>-->
<!--                  <th>现场照片</th>-->
<!--                  &lt;!&ndash; <th>打卡地点</th> &ndash;&gt;-->
<!--              </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <template v-for="(item, index) in handleExcel">-->
<!--                  <tr  :key="index">-->
<!--                      <td>{{ index + 1 }}</td>-->
<!--                      <td>{{ item.deviceName }}</td>-->
<!--                      &lt;!&ndash; <td>{{ item.deviceTypeName }}</td>-->
<!--                      <td>{{ item.paramsAddress }}</td>-->
<!--                      <td>{{ item.macAddress }}</td>-->
<!--                      <td>{{ item.warningSet }}</td> &ndash;&gt;-->
<!--                      <td>{{ item.normal === 1 ? '异常' : '正常' }}</td>-->
<!--                      &lt;!&ndash; <td> {{ item.paramsName }} </td> &ndash;&gt;-->
<!--                      <td>{{ item.rebootTime }}</td>-->
<!--                      <td>-->
<!--                          <img :src="item.imageUrl"-->
<!--                               style="width: 80px;"-->
<!--                               alt="">-->
<!--                      </td>-->
<!--                      &lt;!&ndash; <td></td> &ndash;&gt;-->
<!--                      &lt;!&ndash; <td>{{ item.warningContent }}</td>-->
<!--                      <td>{{ item.createTime }}</td>-->
<!--                      <td>312</td> &ndash;&gt;-->
<!--                  </tr>-->
<!--              </template>-->
<!--              </tbody>-->
<!--          </table>-->
<!--      </div>-->
    <div
        id="hiddenHistoryList2">
      <table border="1" cellspacing="1">
        <thead>
        <tr style="background-color: #c0c0c0;padding: 20px;">
          <!--                 <th>序号</th>-->
          <th>设备名称</th>
          <th>设备类型</th>
          <th>保养项目</th>
          <th>保养周期</th>
          <th>检查明细</th>
          <th>检查工具</th>
          <th>保养结果</th>
          <th>处理时间</th>
          <th>处理人</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(item, index) in dataAfterMaintenanceRecordsProcessingParameters">
          <tr
            :key="index"
            v-if="(lxUserInfo.devices && lxUserInfo.devices.indexOf(item.deviceId) !== -1) || !lxUserInfo.parentId"
            >
            <!--                <td>{{index}}</td>-->
            <td :data-hashkey="item.createTime" class="rowDeviceName">
              {{ item.deviceName }}
            </td>
            <td class="rowDeviceType">{{ item.deviceType }}</td>
            <td>{{ item.content }}</td>
            <td>{{ item.zhouqi }}</td>
            <td>{{ item.detail }}</td>
            <td>{{ item.tool }}</td>
            <!--                <td>{{item.jieguo}}</td>-->
            <td>{{ item.jieguo.toString() === '0' ? '正常' : (
                '异常: ' + (item.jieguo.split('-')[1] || '')
            ) }}</td>
            <td>{{ item.createTime }}</td>
            <td>{{item.headName}}</td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
    <div>
      <div class="split_2">
        <div id="main" style="margin-top:50px" :style="{  height: 'calc(100vh - 290px)'}"></div>
        <div style="vertical-align: bottom;margin-bottom: 40px;margin-left: 40px" class="t_l" >
         <div style="width: 90%" class="split_1_9">
           <div style="vertical-align: top;width: 120px">
             <div>
               <div>
                 <template  class="d_in"   v-for="(deviceItem, deviceIndex) in showDeveceList" >
                   <div v-if="deviceItem.paramsDataList && deviceItem.paramsDataList.length > 0" style="padding: 4px 0" :key="deviceIndex" >
                     <div><span style="display: block;width: calc(100% - 40px);padding: 4px 8px">{{ deviceItem.deviceName }}</span></div>
                   </div>
                   <div style="padding: 4px 0" v-else>
                     <div><span style="display: block;width: calc(100% - 40px);padding: 4px 8px">{{ deviceItem.deviceName }}</span></div>
                   </div>
                 </template>
               </div>
             </div>
             <div>
             </div>
           </div>
           <div id="whitewireframe" style="width: calc(100% - 120px);">
             <div style="border-left: 1px solid #fff;border-bottom: 1px solid #fff;width: 100%;">
               <div :key="deviceIndex" v-for="(deviceItem, deviceIndex) in showDeveceList" style="width: 100%;">
                 <div class="usage-line" style="width: 100%;">
                   <template v-if="deviceItem.paramsDataList && deviceItem.paramsDataList.length > 0">
                     <template  class="d_in" v-for="(tdItem, tdIndex) in deviceItem.paramsDataList" >
                       <!--                     <div  class="d_in p_r" v-if="tdItem.paramsAddress == 4360  || tdItem.paramsAddress == 4358 || tdItem.paramsAddress == 4362 || tdItem.paramsAddress == 4364" :key="tdIndex" style=" width: 120px;border: 1px solid #1d97ff;margin:5px 20px;height: 20px;line-height: 20px;border-radius: 100px">-->
                       <div v-if="deviceItem.template[tdItem.paramsAddress] && deviceItem.template[tdItem.paramsAddress].keep === 1" class="d_in p_r" :key="tdIndex" style="width: calc(100% / 3 - 40px);border: 1px solid #1d97ff;margin:5px 20px;height: 20px;line-height: 20px;border-radius: 100px">
                         <div style="border-radius: 100px" :style="{'width': parseFloat((tdItem.paramsNowValue / (deviceItem.template[tdItem.paramsAddress] && deviceItem.template[tdItem.paramsAddress].serviceInterval || 700)) * 100 ).toFixed(1)+ '%','background': returnBackColor(tdItem)}"> <span style="display: block;">{{returnNowValue(tdItem)}}</span></div>
                         <div style="position: absolute;right: 0;top: 0px">
                           <!-- <span>{{deviceItem.template[tdItem.paramsAddress].serviceInterval}}</span> -->
                           {{deviceItem.template[tdItem.paramsAddress] && deviceItem.template[tdItem.paramsAddress].serviceInterval || 700}}
                           {{deviceItem.template[tdItem.paramsAddress] && deviceItem.template[tdItem.paramsAddress].paramsUnit || '小时'}}
                         </div>
                       </div>
                     </template>
                   </template>
                   <template v-else>
                     <div class="d_in p_r"  style="width: calc(100% / 3 - 40px);border: 1px solid transparent;margin:5px 20px;height: 20.5px;line-height: 20px;border-radius: 100px">
                       离线
                     </div>
                   </template>
                 </div>
               </div>
             </div>
             <div style="margin-top: 20px">
               <div :key="deviceIndex"  v-for="(deviceItem, deviceIndex) in showDeveceList">
                 <div style="width: 100%" v-if="deviceItem.paramsDataList && deviceItem.paramsDataList.length > 0">
                   <template v-for="(tdItem, tdIndex) in deviceItem.paramsDataList" >
                     <div class="d_in" v-if="(deviceItem.template[tdItem.paramsAddress] && deviceItem.template[tdItem.paramsAddress].keep === 1) && templateShowIndex === deviceItem.macAddress" :key="tdIndex" style="width: calc(100% / 3 - 40px);margin: 10px 20px;text-align: center">
                       <div style="color: #fff" class="m_l_10 d_in "> <span>{{returnNewName(deviceItem, tdItem)}}</span></div>
                     </div>
                   </template>
                 </div>

               </div>
             </div>
           </div>
         </div>
<!--          <div style="height: 60vh;border: 2px #4A576A solid;box-sizing: border-box;border-radius: 10px;overflow: auto">-->
<!--            <div class=" he_max jz" style="background-color: rgb(34, 48, 77);border-radius: 10px;">-->
<!--              <div class="he_max" style="">-->
<!--                <div  style="text-align: center;width: 100%" class="">-->
<!--                  <div style="border-bottom: 2px #4A576A solid;padding: 20px 0">参数值</div>-->
<!--                  <div class="split_3" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">-->
<!--                    <div>名称:</div>-->
<!--                    <div>标准</div>-->
<!--                    <div>剩余</div>-->
<!--                  </div>-->
<!--                  <div :key="index" v-for="(item, index) in showleasonData">-->
<!--                    <div :key="deviceIndex" style="border: 1px solid rgb(74, 87, 106)" v-for="(deviceItem, deviceIndex) in item.deviceList">-->
<!--                      <div style="color: #fff" class="p_d20 back_color_blue7fa5f8 ">{{ deviceItem.deviceName }}</div>-->
<!--                      <template v-for="(tdItem, tdIndex) in deviceItem.paramsDataList" >-->
<!--                        <div v-if="tdItem.paramsAddress == 4358  || tdItem.paramsAddress == 4360 || tdItem.paramsAddress == 4364" :key="tdIndex" class="split_3" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">-->
<!--                          <div>{{ returnNewName(tdItem) }}:</div>-->
<!--                          <div>700h</div>-->
<!--                          <div > <span style="display: block;width: calc(100% - 40px);padding: 10px 8px">{{returnNowValue(tdItem)}}</span></div>-->
<!--                        </div>-->
<!--                      </template>-->
<!--&lt;!&ndash;                      <div class="split_3" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">&ndash;&gt;-->
<!--&lt;!&ndash;                        <div>电控系统:</div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div>700h</div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div>{{ baoyangB }}h</div>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="split_3" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">&ndash;&gt;-->
<!--&lt;!&ndash;                        <div>管道系统:</div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div>700h</div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div>{{ baoyangC }}h</div>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--                    </div>-->
<!--                  </div>-->
                  <!--                <div :key="index" v-for="(item, index) in maintain" class="split_2" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">-->
                  <!--                  <div>{{ item.name }}:</div>-->
                  <!--                  <div>{{ item.value }}</div>-->
                  <!--                </div>-->
                  <!--                <div class="split_2" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">-->
                  <!--                  <div>水流量标准值:</div>-->
                  <!--                  <div>≥60m³/H</div>-->
                  <!--                </div>-->
                  <!--                <div class="split_2" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">-->
                  <!--                  <div>液位标准值:</div>-->
                  <!--                  <div>液位正常</div>-->
                  <!--                </div>-->
                  <!--                <div class="split_2" style="border-bottom: 2px #4A576A dotted;padding: 15px 0">-->
                  <!--                  <div>轴温标准值:</div>-->
                  <!--                  <div>≤ 80°C</div>-->
                  <!--                </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
/* import echart from '../dorm/echart/echarts1.vue' */
import pubilshSet from "../../assets/js/pubilshSet";
import lodash from "lodash"
let timeInter
console.log(timeInter)
import * as echarts from "echarts";
import mqtt from "@/assets/js/mqtt";
import dayjs from "dayjs";
import publishSet from "@/assets/js/pubilshSet";
export default {
name: "echart",
  data() {
    return {
      queryDate: {
        start: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD')
      },
      lineName: [],
      deviceSortArray: [],
      deviceSortType: [],
      templateArray: [],
      templateData: [],
      sortTypeFilter: [],
      lineNameFilter: [],
      templateDetailArray: [],
      sortName: [],
      lxUserInfo: JSON.parse(sessionStorage.lxUserInfo || '{}'),
      dao: false,
      templateShowIndex: 0,
      newStartDate: '',
      newEndDate: '',
      showDeveceList: [],
        handleExcel: [],
        warnExcel: [],
      showleasonData: [],
      maintain:[
      ],
      templateList: [],
      deviceList: [],
      maintain2:[
      ],
      building: '',
      line: '',
      baoyangA: 0,
      baoyangB: 0,
      baoyangC: 0,
      timeout: 0,
      msg: "Welcome to Your Vue.js App",
      dataAfterMaintenanceRecordsProcessingParameters: [],
      dataAfterMaintenanceRecordsProcessingParametersHashRowSpan: {}
    };
  },
  comments: {
    echarts,
  },
  methods:{
    returnNewName (deviceItem, item) {
      return (deviceItem.template && deviceItem.template[item.paramsAddress] && deviceItem.template[item.paramsAddress].paramsName) || item.paramsAddress
    },
    returnBackColor (object) {
      if (object.paramsNowValue <= 100) {
        return 'orange'
      }
      if (object.paramsNowValue <= 0) {
        return 'red'
      }
      return '#1d97ff'
    },
    returnNowValue (object) {
      let unit = ''
      if (object.paramType === 'FLOAT_THIRTYTWO' || object.paramType === 'FLOAT_SIXTEEN') {
        unit = object.floatUnit || ''
      }
      if (object.paramType === 'INTEGER_SIXTEEN' || object.paramType === 'INTEGER_THIRTYTWO') {
        unit = object.integerUnit || ''
      }
      if (object.paramType === 'TIMING' || object.paramType === 'TIMING_INTEGER') {
        unit = object.timingUnit || ''
      }
      if ((object.paramType === 'INTEGER_SIXTEEN' || object.paramType === 'INTEGER_THIRTYTWO') && (object.zeroName || object.oneName || object.twoName)) {
        if (parseInt(object.paramsNowValue) === 0) {
          return object.zeroName
        }
        if (parseInt(object.paramsNowValue) === 1) {
          return object.oneName
        }
        if (parseInt(object.paramsNowValue) === 2) {
          return object.twoName
        }
        if (parseInt(object.paramsNowValue) !== 0 && parseInt(object.paramsNowValue) !== 1 && parseInt(object.paramsNowValue) !== 2) {
          return '数据采集中'
        }
      } else {
        return (parseFloat(object.paramsNowValue).toFixed(2) || '-') + ' ' + unit
      }
      return (parseFloat(object.paramsNowValue).toFixed(2) || '-') + ' ' + unit
    },
    returnClass (object) {
      if (object.integerWarning && (object.zeroName || object.oneName || object.twoName)) {
        console.log(object.integerName, (parseInt(object.paramsNowValue) === parseInt(object.integerWarning)))
        if (parseInt(object.paramsNowValue) === parseInt(object.integerWarning)) {
          return 'warning_yellow'
        }
      }
      if (object.paramType === 'INTEGER_SIXTEEN' || object.paramType === 'INTEGER_THIRTYTWO') {
        if (parseInt(object.paramsNowValue) === 0) {
          if (object.zeroColor && parseInt(object.zeroColor) === 0) {
            return 'warning_red'
          } else if (object.zeroColor && parseInt(object.zeroColor) === 1) {
            return 'greed_Nomal'
          } else if (object.zeroColor && parseInt(object.zeroColor) === 2) {
            return 'yellow_nomal'
          } else {
            return 'warning_red'
          }
        } else if (parseInt(object.paramsNowValue) === 1) {
          if (object.oneColor && parseInt(object.oneColor) === 0) {
            return 'warning_red'
          } else if (object.oneColor && parseInt(object.oneColor) === 1) {
            return 'greed_Nomal'
          } else if (object.oneColor && parseInt(object.oneColor) === 2) {
            return 'yellow_nomal'
          } else {
            return 'yellow_nomal'
          }
        } else {
          if (object.twoColor && parseInt(object.twoColor) === 0) {
            return 'warning_red'
          } else if (object.twoColor && parseInt(object.twoColor) === 1) {
            return 'greed_Nomal'
          } else if (object.twoColor && parseInt(object.twoColor) === 2) {
            return 'yellow_nomal'
          } else {
            return 'greed_Nomal'
          }
        }
      }
      let warningLine = object.integerWarning || object.floatWarning || object.timingWarning
      if (warningLine && parseFloat(object.paramsNowValue) < parseFloat(warningLine)) {
        return 'warning_red'
      }
      if (object.paramsNowValue) {
        return 'greed_Nomal'
      } else {
        return ''
      }
    },
    exportExcel2 () {
      window.open(pubilshSet.eomsApi + '/sys/sysMaintainRecord/exportNew?fileName=保养记录导出&sheetName=保养记录导出&area=' + this.building + '&line=' + this.line)
    },
    /**
     * @param { number | 1 | 2 } type (1: 已保养, 2: 待保养)
     * @description 远程导出保养数据
    */
    webExportMaintenance (type) {
      switch (type) {
        case 1:
          this.$axiox.cpost3('/warning/maintenanceExport', {handle:1,deviceName:'',customerId:null,typeId:"",start:'',end:'',size:9999,keep:1,page:1, type: 1})
          .then(async (data) => {
            // console.log(data, '导出的数据')
            const blobUrl = new Blob([data])
            // console.log(blobUrl);
            // 创建下载URL
            const loadLink = window.URL.createObjectURL(blobUrl)
            // 创建A标签进行下载
            const targetLink = document.createElement('a')
            targetLink.style.display = 'none'
            targetLink.href = loadLink
            targetLink.setAttribute('download', `${JSON.parse(sessionStorage.getItem('lxUserInfo')).name}${dayjs().format('YYYY-MM-DD HH:mm:ss')}-设备保养数据导出.xlsx`)
            if (typeof targetLink.download === 'undefined') {
              targetLink.setAttribute('target', '_blank')
            }
            document.body.appendChild(targetLink)
            targetLink.click()
            document.body.removeChild(targetLink)
            window.URL.revokeObjectURL(loadLink)
          })
          break;
        case 2:
          this.$axiox.cpost3('/warning/waitMaintenanceExport', {handle:0,deviceName:'',customerId:null,typeId:"",start:'',end:'',size:9999,keep:1,page:1})
          .then(async (data) => {
            const blobUrl = new Blob([data])
            // console.log(blobUrl);
            // 创建下载URL
            const loadLink = window.URL.createObjectURL(blobUrl)
            // 创建A标签进行下载
            const targetLink = document.createElement('a')
            targetLink.style.display = 'none'
            targetLink.href = loadLink
            targetLink.setAttribute('download', `${JSON.parse(sessionStorage.getItem('lxUserInfo')).name}${dayjs().format('YYYY-MM-DD HH:mm:ss')}-设备待保养数据导出.xlsx`)
            if (typeof targetLink.download === 'undefined') {
              targetLink.setAttribute('target', '_blank')
            }
            document.body.appendChild(targetLink)
            targetLink.click()
            document.body.removeChild(targetLink)
            window.URL.revokeObjectURL(loadLink)
          })
          break;
        default:
          break;
      }
    },
    exportExcel (type) {
      let that = this
      let html = '<html><head><meta charset=\'utf-8\' /></head><body>'
        if (type === 1) {
            // 已保养
            // html += document.getElementById('hiddenHistoryList2').innerHTML
            // 新的导出方法
          let xmlResquest = new XMLHttpRequest();
          let url = publishSet.custBaseUrl + "/customer/excel/exportNew";
          xmlResquest.open("POST", url, true);
          xmlResquest.setRequestHeader("Content-type", "application/json");
          xmlResquest.setRequestHeader(
              "token",
              window.sessionStorage.getItem("lixtoken")
          );
          xmlResquest.setRequestHeader(
              "userType",
              "2"
          );
          xmlResquest.responseType = "blob";
          xmlResquest.onload = function () {
            let content = xmlResquest.response;
            if (content.type) {
              if (content.type.indexOf("json") >= 0) {
                that.$commonJs.alertTips({
                  type: "error",
                  title: "失败",
                  content: "暂无数据",
                  stayTime: 5,
                });
                return;
              }
            }
            let elink = document.createElement("a");
            elink.download = "已保养记录.xlsx";
            elink.style.display = "none";
            let blob = new Blob([content]);
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            document.body.removeChild(elink);
          };
          xmlResquest.send(JSON.stringify({handle:1,deviceName:'',customerId:null,typeId:"",start: this.queryDate.start ? (this.queryDate.start + ' 00:00:00') : '',end: this.queryDate.end ? (this.queryDate.end + ' 23:59:59') : '',size:9999,keep:1,page:1, type: 1}));
            return
        } else {
            // 未保养
            html += document.getElementById('hiddenHistoryList').innerHTML
        }
      html += '</body></html>'
      // 实例化一个Blob对象，其构造函数的第一个参数是包含文件内容的数组，第二个参数是包含文件类型属性的对象
      let blob = new Blob([html], {
        type: 'application/vnd.ms-excel'
      })
      let a = document.createElement('a')
      a.href = URL.createObjectURL(blob)
        if (type === 1) {
            a.download = '已保养列表.xls'
        } else {
            a.download = '待保养列表.xls'
        }

      a.click()
    },
      show(){
      let mouseCurValue = ''
        // let hasBaoyang = [Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1]
        // let waitBaoyang = [Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1]
        // let Baoyangout = [Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1]
        let hasBaoyang = this.normalData
        let waitBaoyang = this.timeoffData
        let Baoyangout = this.warningData
        // if (this.building.length === 1) {
        //   hasBaoyang = [5]
        //   waitBaoyang = [8]
          // Baoyangout = [7]
        // }
        let Xdata = this.lxUserInfo.id === 125 ? ['TR'] : ["A区", "B区", "C区", "F区", "G区",  "H区", "I区"]
        var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: "axis",
          extraCssText: "background-color: #202F50;border:1px #234A71 solid",
          textStyle: {
            color:"white"
          },
          axisPointer: {
            type: "cross",
            label: {
              formatter: function (params) {
                if (params.seriesData.length === 0) {
                  mouseCurValue = params.value;
                }
              }
            }
          },
          formatter: function (params) {
            console.log(params)
            let res = "", sum = 0;
            for (let i = 0; i < params.length; i++) {
              let series = params[i];
              // 累计series.data，与mouseCurValue做比较，找出鼠标位置对应的series
              sum += Number(series.data);
              if (sum >= mouseCurValue) {
                // res = series.axisValue + "<br/>" + series.marker + series.seriesName + ":" + series.data + "<br/>";
                res = '<p style="border-bottom: 1px #414C62 solid;padding: 10px;text-align:left">'+ series.axisValue + '<br />'+'</p>'
                    + '<p style="border-bottom: 2px #414C62 dotted;padding: 10px;text-align:left">'+series.seriesName + '：' + series.data + '<br />'+'</p>'
                break;
              }
            }
            console.log(mouseCurValue)
            return res;
          }
        },
        legend: {
                textStyle:{
                    color:"white"
                }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: Xdata,
            axisLabel: {
                show: true,
                textStyle:{
                    color:"white"
                }
            }
          }
        ],
        yAxis: [
          {
            type: "value",
            minInterval: 1,
            axisLabel: {
                show: true,
                textStyle:{
                    color:"white"
                }
            }
          }
          ,
        ],
        series: [
          {
            name: "已保养",
            type: "bar",
            stack: "Ad",
            color: "#0CCF67",
            axisLabel: {
                show: true,
                textStyle:{
                    color:"white"
                }
            },
            emphasis: {
              focus: "series",
            },
            data: hasBaoyang,
            label:{
              show: true,
              formatter:function (e) {console.log(hasBaoyang[e.dataIndex])
                if (hasBaoyang[e.dataIndex] + waitBaoyang[e.dataIndex] + Baoyangout[e.dataIndex] === 0) {
                  return 0
                }
                if (parseInt(((hasBaoyang[e.dataIndex] / (hasBaoyang[e.dataIndex] + waitBaoyang[e.dataIndex] + Baoyangout[e.dataIndex])) * 100)).toFixed(0) == 0) {
                  return  ''
                }
                return parseInt(((hasBaoyang[e.dataIndex] / (hasBaoyang[e.dataIndex] + waitBaoyang[e.dataIndex] + Baoyangout[e.dataIndex])) * 100)).toFixed(0) + '%'

              },
              position:'inside',
              color: 'white',
              fontSize: '12px'
            }
          },
          {
            name: "待保养",
            type: "bar",
            stack: "Ad",
            color: "#FEF770",
            emphasis: {
              focus: "series",
            },
            data: waitBaoyang,
            label:{
              show: true,
              formatter:function (e) {
                if (hasBaoyang[e.dataIndex] + waitBaoyang[e.dataIndex] + Baoyangout[e.dataIndex] === 0) {
                  return 0
                }
                if (parseInt(((waitBaoyang[e.dataIndex] / (hasBaoyang[e.dataIndex] + waitBaoyang[e.dataIndex] + Baoyangout[e.dataIndex])) * 100)).toFixed(0) == 0) {
                  return  ''
                }
                return parseInt(((waitBaoyang[e.dataIndex] / (hasBaoyang[e.dataIndex] + waitBaoyang[e.dataIndex] + Baoyangout[e.dataIndex])) * 100)).toFixed(0) + '%'
              },
              position:'inside',
              color: 'black',
              fontSize: '12px'
            }
          },
          {
            name: "到期",
            type: "bar",
            stack: "Ad",
            color: "red",
            emphasis: {
              focus: "series",
            },
            data: Baoyangout,
            label:{
              show: true,
              formatter:function (e) {
                if (hasBaoyang[e.dataIndex] + waitBaoyang[e.dataIndex] + Baoyangout[e.dataIndex] === 0) {
                  return 0
                }
                if (parseInt(((Baoyangout[e.dataIndex] / (hasBaoyang[e.dataIndex] + waitBaoyang[e.dataIndex] + Baoyangout[e.dataIndex])) * 100)).toFixed(0) == 0) {
                  return  ''
                }
                return parseInt(((Baoyangout[e.dataIndex] / (hasBaoyang[e.dataIndex] + waitBaoyang[e.dataIndex] + Baoyangout[e.dataIndex])) * 100)).toFixed(0) + '%'
              },
              position:'inside',
              color: 'white',
              fontSize: '12px'
            }
          },
        ],
      };
      option && myChart.setOption(option);
      },
    getTemplates () {
      this.leasionData = []
      let url = this.$commonJs.returnQuery('/customer/templates', {page: 1, size: 99999})
      this.$axiox.cpost(url).then(msg => {
        if (msg.message === '成功') {
          this.leasionData = msg.data.content
          this.getParamData()
        } else {
          this.$commonJs.alertTips({
            type: 'error',
            title: '错误:',
            content: msg.message,
            stayTime: 5
          })
        }
      }).catch(() => {
        this.$commonJs.alertTips({
          type: 'error',
          title: '获取接口错误:',
          content: '服务器报错',
          stayTime: 5
        })
      })
    },
    // setWhitewireframe () {
    //   const whitewireframe = document.getElementById('whitewireframe')
    //   const deviceUsageTimeList = document.querySelectorAll("#whitewireframe ")
    // },
    async getParamData () {
      for (let i = 0; i < this.leasionData.length; i++) {
        if (this.leasionData[i]) {
          this.leasionData[i].paramsList = []
          this.leasionData[i].deviceList = []
          let paramsList = await this.getParamsSet(this.leasionData[i].id)
          this.leasionData[i].paramsList = paramsList
        }
      }
      this.getDevice()
    },
    getDevice () {
      let url = this.$commonJs.returnQuery('/customer/devicePage', {page: 1, size: 99999, parentId: this.lxUserInfo.parentId || ''})
      this.$axiox.cpost(url).then(msg => {
        if (msg.message === '成功') {
          let newList = []
          if (this.lxUserInfo.devices) {
            for (let i = 0; i < msg.data.content.length; i++) {
              if (this.lxUserInfo.devices.indexOf(msg.data.content[i].id) >=0) {
                newList.push(msg.data.content[i])
              }
            }
          } else {
            newList = msg.data.content
          }

          this.deviceList = newList

          for (let i = 0; i < msg.data.content.length; i++) {
            for (let j = 0; j < this.leasionData.length; j++) {
              if (msg.data.content[i].templateId === this.leasionData[j].id) {
                if (this.building === '' || msg.data.content[i].deviceName.indexOf(this.building) >= 0) {
                  if (this.line === '' || msg.data.content[i].deviceName.indexOf(this.line) >= 0) {
                    msg.data.content[i].paramsDataList = this.leasionData[j].paramsList
                    this.leasionData[j].deviceList.push(msg.data.content[i])
                  }
                }
              }
            }
          }
          this.getMyDeviceData()
        } else {
          this.$commonJs.alertTips({
            type: 'error',
            title: '错误:',
            content: msg.message,
            stayTime: 5
          })
        }
      }).catch(() => {
        this.$commonJs.alertTips({
          type: 'error',
          title: '获取接口错误:',
          content: '服务器报错',
          stayTime: 5
        })
      })
    },
    async getMyDeviceData () {
      this.initEcharts = false
      this.warningDianwei = 0
      this.normalData = [0, 0,0, 0,0, 0,0]
      this.warningData = [0, 0,0, 0,0, 0,0]
      this.timeoffData = [0, 0,0, 0,0, 0,0]
      this.warningContent = [[], [], [], [], [], [], []]
      this.normalContent = [[], [], [], [], [], [], []]
      this.timeoffContent = [[], [], [], [], [], [], []]
      this.maintain = []
      this.maintain2 = []
      for (let i = 0; i < this.leasionData.length; i++) {
        for (let j = 0; j < this.leasionData[i].deviceList.length; j++) {
          let array = [
            {
              buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
          area: this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0010',
              data3: '除雾环保球清洗或更换',
              data4: '700',
              data5: '检查环保球是否有杂物，更换环保球是否新品',
              data6: '目测'
            },
            {
              buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0011',
              data3: '观察口透明板更换',
              data4: '700',
              data5: '检查透明板是否是新品',
              data6: '目测'
            },
            {
              buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0012',
              data3: '塔体清洗',
              data4: '700',
              data5: '检查塔体内测是否有杂物',
              data6: '目测'
            },
            {
              buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0013',
              data3: '旋流板清洗',
              data4: '700',
              data5: '检查旋流板上是否有杂物',
              data6: '目测'
            },
            {
              buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0014',
              data3: '喷头更换',
              data4: '700',
              data5: '检查喷头是否是新品',
              data6: '目测'
            },
            {
              buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0015',
              data3: '喷淋管道清理',
              data4: '700',
              data5: '检查喷淋管内是否有杂物',
              data6: '目测'
            },
            {
              buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0016',
              data3: '水泵进出口清理',
              data4: '700',
              data5: '检查水泵进出口过滤网是否有干净',
              data6: '目测'
            },
            {
              buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0017',
              data3: '水泵散热风扇清理',
              data4: '700',
              data5: '检查水泵散热口是否干净有杂物',
              data6: '目测'
            },
            {
              buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0018',
              data3: '水槽清理',
              data4: '700',
              data5: '检查环保球是否有杂物',
              data6: '目测'
            },
            {
              buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0019',
              data3: '液位传感器清理',
              data4: '700',
              data5: '检查水泥是否泥沙，水是否清澈',
              data6: '目测'
            },
            {
              buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0020',
              data3: '温控传感器清理',
              data4: '700',
              data5: '检查传感器上是否干净，有没有杂物',
              data6: '目测'
            },
            {
              buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0021',
              data3: '加热管清理',
              data4: '700',
              data5: '检查加热管上是否有泥沙。',
              data6: '目测'
            },
            {
              buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BJC0022',
              data3: '液位浮球清理',
              data4: '700',
              data5: '检查浮球上是否有杂物，进水是否顺畅',
              data6: '目测'
            },
            {
                 buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BFJ0010',
              data3: '叶轮清理',
              data4: '700',
              data5: '检查叶轮是否有干净有没有杂物',
              data6: '目测'
            },
            {
                 buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BFJ0011',
              data3: '叶轮震动检查',
              data4: '700',
              data5: '用震动仪测试震动值是否有30以下',
              data6: '震动仪'
            },
            {
                 buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BFJ0012',
              data3: '机壳清理',
              data4: '700',
              data5: '检查机壳是否有干净有没有杂物',
              data6: '目测'
            },
            {
                 buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BFJ0013',
              data3: '传动皮带调整或更换',
              data4: '700',
              data5: '检查皮带是否有打滑，磨损是否更换为新品',
              data6: '目测'
            },
            {
                 buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BFJ0014',
              data3: '轴承箱油添加或更换',
              data4: '700',
              data5: '检查轴承箱油量是否在中间油标处。',
              data6: '目测'
            },
            {
                 buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BFJ0015',
              data3: '马达散热风扇清理',
              data4: '700',
              data5: '检查散热网上是否有干净有没有杂物。',
              data6: '目测'
            },
            {
                 buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BFJ0016',
              data3: '马达轴承加油',
              data4: '700',
              data5: '检查轴承加油是否有黄油。',
              data6: '目测'
            },
            {
                 buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BFJ0017',
              data3: '减震器维护',
              data4: '700',
              data5: '检查减震器螺丝是否有松动。',
              data6: '目测'
            },
            {
                 buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '集尘设施',
              data2: 'TK-BFJ0018',
              data3: '进出口软接维护',
              data4: '700',
              data5: '检查进出口软件是否有漏风。',
              data6: '目测'
            },
            {
                 buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '电控系统',
              data2: 'TK-BDK0010',
              data3: '热成像检测',
              data4: '700',
              data5: '用热成像仪测试温度是否在60°以下',
              data6: '热成像仪'
            },
            {
                 buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '电控系统',
              data2: 'TK-BDK0011',
              data3: '变频器散热风扇清理',
              data4: '700',
              data5: '检查变频器散热口是否干净，有没有杂物。',
              data6: '目测'
            },
            {
                 buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '电控系统',
              data2: 'TK-BDK0012',
              data3: '电箱散热风扇清理',
              data4: '700',
              data5: '检查电箱散热口是否干净，有没有杂物。',
              data6: '目测'
            },
            {
                 buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '电控系统',
              data2: 'TK-BDK0013',
              data3: '电器元件维护',
              data4: '700',
              data5: '检查电器元件螺丝是否上紧。',
              data6: '目测'
            },
            {
                 buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '电控系统',
              data2: 'TK-BDK0014',
              data3: '风压传感器传送管清理',
              data4: '700',
              data5: '检查传送管是否干净，有没有粉尘',
              data6: '目测'
            },
            {
                 buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '电控系统',
              data2: 'TK-BDK0015',
              data3: '风压传感器校正或更换',
              data4: '700',
              data5: '用风速仪测试对比风速误差是否在3m/s内',
              data6: '风速仪'
            },
            {
                 buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '电控系统',
              data2: 'TK-BDK0016',
              data3: '水流传感器清理',
              data4: '700',
              data5: '检查传感器上是否干净，有没有杂物',
              data6: '目测'
            },
            {
                 buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '管道系统',
              data2: 'TK-XGD0010',
              data3: '阀体清理',
              data4: '700',
              data5: '检查阀体内是否干净，有没有杂物',
              data6: '目测'
            },
            {
                 buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '管道系统',
              data2: 'TK-XGD0011',
              data3: '叶片轴承加油',
              data4: '700',
              data5: '检查轴承加油是否有黄油。',
              data6: '目测'
            },
            {
                 buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '管道系统',
              data2: 'TK-XGD0012',
              data3: '泄爆片清理或更换',
              data4: '700',
              data5: '检查泄爆片是否有杂物，更换是否为新品，有没有漏气。',
              data6: '目测'
            },
            {
                 buiname: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[1],
              area: this.deviceList[i] && this.deviceList[i].deviceName.split('-')[0],
              li: '1线',
              data1: '管道系统',
              data2: 'TK-XGD0013',
              data3: '管道清理',
              data4: '700',
              data5: '检查管道内是否干净',
              data6: '目测'
            }
          ]
          for (let s = 0; s < array.length; s++) {
            this.maintain2.push(array[s])
          }

          let deviceData = await this.DeviceData(this.deviceList[i].id)
          let nowDevide = JSON.parse(JSON.stringify(this.deviceList[i]))
          for (let o = 0; o < nowDevide.paramsDataList.length; o++) {
            for (let k = 0; k < deviceData.length; k++) {
              if (parseInt(this.deviceList[i].template[j].paramsAddress) === 4358|| parseInt(this.deviceList[i].template[j].paramsAddress) === 4360|| parseInt(this.deviceList[i].template[j].paramsAddress) === 4364) {
                if (parseInt(this.deviceList[i].template[j].paramsAddress) === 4358) {
                  this.baoyangA = this.deviceList[i].params[j]
                }
                if (parseInt(this.deviceList[i].template[j].paramsAddress) === 4360) {
                  this.baoyangB = this.deviceList[i].params[j]
                }
                if (parseInt(this.deviceList[i].template[j].paramsAddress) === 4364) {
                  this.baoyangC = this.deviceList[i].params[j]
                }
                if (this.deviceList[i].params[j] <= 100) {
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('A区') >= 0) {
                    this.timeoffData[0]++
                    this.timeoffContent[0].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('TR') >= 0) {
                    this.timeoffData[0]++
                    this.timeoffContent[0].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('B区') >= 0) {
                    this.timeoffData[1]++
                    this.timeoffContent[1].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('C区') >= 0) {
                    this.timeoffData[2]++
                    this.timeoffContent[2].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('F区') >= 0) {
                    this.timeoffData[3]++
                    this.timeoffContent[3].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('G区') >= 0) {
                    this.timeoffData[4]++
                    this.timeoffContent[4].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('H区') >= 0) {
                    this.timeoffData[5]++
                    this.timeoffContent[5].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('I区') >= 0) {
                    this.timeoffData[6]++
                    this.timeoffContent[6].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                }
                if (this.deviceList[i].params[j] > 100) {
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('A区') >= 0) {
                    console.log(this.deviceList[i].params[j], 'A区')
                    this.normalData[0]++
                    this.normalContent[0].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('TR') >= 0) {
                    console.log(this.deviceList[i].params[j], 'A区')
                    this.normalData[0]++
                    this.normalContent[0].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('B区') >= 0) {
                    this.normalData[1]++
                    this.normalContent[1].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('C区') >= 0) {
                    this.normalData[2]++
                    this.normalContent[2].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('F区') >= 0) {
                    this.normalData[3]++
                    this.normalContent[3].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('G区') >= 0) {
                    this.normalData[4]++
                    this.normalContent[4].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('H区') >= 0) {
                    this.normalData[5]++
                    this.normalContent[5].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('I区') >= 0) {
                    this.normalData[6]++
                    this.normalContent[6].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                }
                if (this.deviceList[i].params[j] <= 0) {
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('A区') >= 0) {
                    this.warningData[0]++
                    this.warningContent[0].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('TR') >= 0) {
                    this.warningData[0]++
                    this.warningContent[0].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('B区') >= 0) {
                    this.warningData[1]++
                    this.warningContent[1].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('C区') >= 0) {
                    this.warningData[2]++
                    this.warningContent[2].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('F区') >= 0) {
                    this.warningData[3]++
                    this.warningContent[3].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('G区') >= 0) {
                    this.warningData[4]++
                    this.warningContent[4].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('H区') >= 0) {
                    this.warningData[5]++
                    this.warningContent[5].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                  if (this.leasionData[i].deviceList[j].deviceName.indexOf('I区') >= 0) {
                    this.warningData[6]++
                    this.warningContent[6].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                  }
                }
              }
            }
          }
        }
      }
      console.log(this.normalData, 'normalData')
      console.log(this.timeoffData)
      console.log(this.warningData)
      this.showleasonData = []
      this.showleasonData = JSON.parse(JSON.stringify(this.leasionData))
      // this.$nextTick(p => {
      //   this.showLoading = false
      // })
      console.log(this.showleasonData)
      this.initEcharts = true
      this.$nextTick(() => {
        this.show()
      })
      // timeInter = setTimeout(() => {
      //   this.getMyDeviceData()
      // }, 30000)
    },
    DeviceData (id) {
      var p = new Promise((resolve, reject) => {
        this.$axiox.post('/device/dataOfDevice?deviceId=' + id).then(msg => {
          resolve(msg.data || [])
          console.log(reject)
        }).catch(() => {
        })
      })
      return p
    },
    show2 () {
      this.normalData = []
      this.warningData = []
      this.timeoffData = []
      this.warningContent = []
      this.normalContent = []
      this.timeoffContent = []
      this.sortName = []
      for (let i = 0; i < this.deviceSortType.length; i++) {
        // console.log(this.deviceSortType[i], 'deviceSortType[i]');
        this.sortName.push(this.deviceSortType[i].sortType)
        this.normalData.push(0)
        this.warningData.push(0)
        this.timeoffData.push(0)
        this.warningContent.push([])
        this.normalContent.push([])
        this.timeoffContent.push([])
      }
      this.showDeveceList = []
      // let count = 0
      this.templateShowIndex = 0
      for (let i in this.deviceList) {
        const data = this.deviceList[i]
        data.paramsDataList = []
        for (let j in data.params) {
          data.paramsDataList.push({
            paramsAddress: j,
            paramsNowValue: data.params[j]
          })
        }
        if (data.paramsDataList.length !== 0) {
          this.templateShowIndex = i
        }
        this.showDeveceList.push(this.deviceList[i])
        for (let j in this.deviceList[i].params) {
          // console.log(this.deviceList[i])
          // 计算保养柱状图数据
          // if (this.deviceList[i].template[j]) {
          // if (this.deviceList[i].template[j] && (parseInt(this.deviceList[i].template[j].paramsAddress) === 4358|| parseInt(this.deviceList[i].template[j].paramsAddress) === 4360|| parseInt(this.deviceList[i].template[j].paramsAddress) === 4364)) {
          // if (this.deviceList[i].template[j] && (parseInt(this.deviceList[i].template[j].paramsAddress) === 4358|| parseInt(this.deviceList[i].template[j].paramsAddress) === 4360|| parseInt(this.deviceList[i].template[j].paramsAddress) === 4364 || parseInt(this.deviceList[i].template[j].paramsAddress) === 4362)) {
          if (this.deviceList[i].template[j] && this.deviceList[i].template[j].keep === 1) {
          let tableData = {
              buiname: this.deviceList[i].deviceName,
              li: '1线',
              byname: this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j,
              bypinlv:'700hrs/time',
              scby: '',
              xcby: '',
              jrjxcby:  this.deviceList[i].params[j],
              over: '',
              admin: '',
              overtime: ''
            }
            if (parseInt(this.deviceList[i].template[j].paramsAddress) === 4358) {
              this.baoyangA = this.deviceList[i].params[j]
            }
            if (parseInt(this.deviceList[i].template[j].paramsAddress) === 4360) {
              this.baoyangB = this.deviceList[i].params[j]
            }
            if (parseInt(this.deviceList[i].template[j].paramsAddress) === 4364) {
              this.baoyangC = this.deviceList[i].params[j]
            }
            if (this.deviceList[i].params[j] <= 100) {
              console.log(this.deviceList[i], 'this.deviceList[i]')
              for (let s = 0; s < this.deviceSortType.length; s++) {
                if (this.deviceList[i].sortType === this.deviceSortType[s].sortType || this.deviceList[i].deviceName === this.deviceSortType[s].sortType) {
                  this.timeoffData[s]++
                  this.timeoffContent[s].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                }
              }
            }
            if (this.deviceList[i].params[j] > 100) {
              for (let s = 0; s < this.deviceSortType.length; s++) {
                if (this.deviceList[i].sortType === this.deviceSortType[s].sortType || this.deviceList[i].deviceName === this.deviceSortType[s].sortType) {
                  // if (this.deviceList[i].sortType.indexOf('A') !== -1) {
                  //   count++
                  // }
                  this.normalData[s]++
                  this.normalContent[s].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                }
              }
            }
            if (this.deviceList[i].params[j] <= 0) {
              for (let s = 0; s < this.deviceSortType.length; s++) {
                if (this.deviceList[i].sortType === this.deviceSortType[s].sortType || this.deviceList[i].deviceName === this.deviceSortType[s].sortType) {
                  this.warningData[s]++
                  this.warningContent[s].push(this.deviceList[i].template[j].cusParamsName || this.deviceList[i].template[j].paramsName || j)
                }
              }
            }
            this.maintain.push(tableData)
          }
        }
      }
      // console.log(count, 'A栋正常数量')
      let mouseCurValue = ''
      // let hasBaoyang = [Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1]
      // let waitBaoyang = [Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1]
      // let Baoyangout = [Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1, Math.floor(Math.random() * 10)+ 1]
      let hasBaoyang = this.normalData
      let waitBaoyang = this.timeoffData
      let Baoyangout = this.warningData
      // if (this.building.length === 1) {
      //   hasBaoyang = [5]
      //   waitBaoyang = [8]
      // Baoyangout = [7]
      // }
      let Xdata = this.sortName
      var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: "axis",
          extraCssText: "background-color: #202F50;border:1px #234A71 solid",
          textStyle: {
            color:"white"
          },
          axisPointer: {
            type: "cross",
            label: {
              formatter: function (params) {
                if (params.seriesData.length === 0) {
                  mouseCurValue = params.value;
                }
              }
            }
          },
          formatter: function (params) {
            console.log(params)
            let res = "", sum = 0;
            for (let i = 0; i < params.length; i++) {
              let series = params[i];
              // 累计series.data，与mouseCurValue做比较，找出鼠标位置对应的series
              sum += Number(series.data);
              if (sum >= mouseCurValue) {
                // res = series.axisValue + "<br/>" + series.marker + series.seriesName + ":" + series.data + "<br/>";
                res = '<p style="border-bottom: 1px #414C62 solid;padding: 10px;text-align:left">'+ series.axisValue + '<br />'+'</p>'
                    + '<p style="border-bottom: 2px #414C62 dotted;padding: 10px;text-align:left;display: flex;align-items: center;">'+ `<span style="width: 60px;">${series.seriesName}：</span>` + series.data + '<br />'+'</p>'
                break;
              }
              res = '<p style="border-bottom: 1px #414C62 solid;padding: 10px;text-align:left">'+ series.axisValue + '<br />'+'</p>'
                  + '<p style="border-bottom: 2px #414C62 dotted;padding: 10px;text-align:left;display: flex;align-items: center;">'+ `<span style="width: 60px;">${series.seriesName}：</span>` + series.data + '<br />'+'</p>'
            }
            console.log(mouseCurValue)
            return res;
          }
        },
        legend: {
          textStyle:{
            color:"white"
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: Xdata,
            axisLabel: {
              show: true,
              textStyle:{
                color:"white"
              }
            }
          }
        ],
        yAxis: [
          {
            type: "value",
            minInterval: 1,
            axisLabel: {
              show: true,
              textStyle:{
                color:"white"
              }
            }
          }
          ,
        ],
        series: [
          {
            name: "已保养",
            type: "bar",
            stack: "Ad",
            color: "#0CCF67",
            axisLabel: {
              show: true,
              textStyle:{
                color:"white"
              }
            },
            emphasis: {
              focus: "series",
            },
            data: hasBaoyang,
            label:{
              show: true,
              formatter:function (e) {
                if (hasBaoyang[e.dataIndex] + waitBaoyang[e.dataIndex] + Baoyangout[e.dataIndex] === 0) {
                  return 0
                }
                if (parseInt(((hasBaoyang[e.dataIndex] / (hasBaoyang[e.dataIndex] + waitBaoyang[e.dataIndex] + Baoyangout[e.dataIndex])) * 100)).toFixed(0) == 0) {
                  return  ''
                }
                return parseInt(((hasBaoyang[e.dataIndex] / (hasBaoyang[e.dataIndex] + waitBaoyang[e.dataIndex] + Baoyangout[e.dataIndex])) * 100)).toFixed(0) + '%'

              },
              position:'inside',
              color: 'white',
              fontSize: '12px'
            }
          },
          {
            name: "待保养",
            type: "bar",
            stack: "Ad",
            color: "#FEF770",
            emphasis: {
              focus: "series",
            },
            data: waitBaoyang,
            label:{
              show: true,
              formatter:function (e) {
                if (hasBaoyang[e.dataIndex] + waitBaoyang[e.dataIndex] + Baoyangout[e.dataIndex] === 0) {
                  return 0
                }
                if (parseInt(((waitBaoyang[e.dataIndex] / (hasBaoyang[e.dataIndex] + waitBaoyang[e.dataIndex] + Baoyangout[e.dataIndex])) * 100)).toFixed(0) == 0) {
                  return  ''
                }
                return parseInt(((waitBaoyang[e.dataIndex] / (hasBaoyang[e.dataIndex] + waitBaoyang[e.dataIndex] + Baoyangout[e.dataIndex])) * 100)).toFixed(0) + '%'
              },
              position:'inside',
              color: 'black',
              fontSize: '12px'
            }
          },
          {
            name: "到期",
            type: "bar",
            stack: "Ad",
            color: "red",
            emphasis: {
              focus: "series",
            },
            data: Baoyangout,
            label:{
              show: true,
              formatter:function (e) {
                if (hasBaoyang[e.dataIndex] + waitBaoyang[e.dataIndex] + Baoyangout[e.dataIndex] === 0) {
                  return 0
                }
                if (parseInt(((Baoyangout[e.dataIndex] / (hasBaoyang[e.dataIndex] + waitBaoyang[e.dataIndex] + Baoyangout[e.dataIndex])) * 100)).toFixed(0) == 0) {
                  return  ''
                }
                return parseInt(((Baoyangout[e.dataIndex] / (hasBaoyang[e.dataIndex] + waitBaoyang[e.dataIndex] + Baoyangout[e.dataIndex])) * 100)).toFixed(0) + '%'
              },
              position:'inside',
              color: 'white',
              fontSize: '12px'
            }
          },
        ],
      };
      option && myChart.setOption(option);
    },
    getTemplate () {
      const url = this.$commonJs.returnQuery('/customer/templates', { page: 1, size: 99999 })
      this.$axiox.cpost(url).then((msg) => {
        const promiseAll = []
        for (let i = 0; i < msg.data.content.length; i++) {
          promiseAll.push(this.getParamsSet(msg.data.content[i].id))
          this.templateList[msg.data.content[i].id] = msg.data.content[i]
        }
        Promise.all(promiseAll).then(e => {
          console.log(e)
          console.log(this.templateList)
          for (let i = 0; i < e.length; i++) {
            for (let j = 0; j < e[i].length; j++) {
              this.templateList[e[i][0].templateId][e[i][j].paramsAddress] = e[i][j]
            }
          }
          this.getDevideList(e)
        })
      })
    },
    getDevideList () {
      const that = this
      const url = this.$commonJs.returnQuery('/customer/devicePage', { page: 1, size: 99999 })
      this.$axiox.cpost(url).then((msg) => {
        const newList = msg.data.content
        for (let i = 0; i < newList.length; i++) {
          this.deviceList[newList[i].macAddress] = newList[i]
          this.deviceList[newList[i].macAddress].template = this.templateList[this.deviceList[newList[i].macAddress].templateId]
        }
        for (let i = 0; i < newList.length; i++) {
          if (newList[i].sortTypeId && this.deviceSortArray.indexOf(newList[i].sortTypeId) >= 0) {
            this.deviceSortType[this.deviceSortArray.indexOf(newList[i].sortTypeId)].devices.push(newList[i])
          } else {
            this.deviceSortType.push({
              sortTypeId: newList[i].sortTypeId || newList[i].id,
              sortType: newList[i].sortType || newList[i].deviceName,
              buildingName: newList[i].sortType || newList[i].deviceName,
              devices: [newList[i]]
            })
            this.deviceSortArray.push(newList[i].sortTypeId || null)
            if (this.sortTypeFilter.indexOf(newList[i].sortType || newList[i].deviceName) >= 0) {
              console.log(1)
            } else {
              this.sortTypeFilter.push(newList[i].sortType || newList[i].deviceName)
            }
          }
        }
        for (let i = 0; i < this.deviceSortType.length; i++) {
          const lineIdArray = []
          this.deviceSortType[i].lineData = []
          for (let j = 0; j < this.deviceSortType[i].devices.length; j++) {
            if (this.deviceSortType[i].devices[j].lineId && lineIdArray.indexOf(this.deviceSortType[i].devices[j].lineId) >= 0) {
              this.deviceSortType[i].lineData[lineIdArray.indexOf(this.deviceSortType[i].devices[j].lineId)].devices.push(this.deviceSortType[i].devices[j])
            } else {
              this.deviceSortType[i].lineData.push({
                lineName: this.deviceSortType[i].devices[j].lineName || this.deviceSortType[i].devices[j].deviceName,
                lineId: this.deviceSortType[i].devices[j].lineId || this.deviceSortType[i].devices[j].id,
                devices: [this.deviceSortType[i].devices[j]],
                portData: [],
                detailData: []
              })
              lineIdArray.push(this.deviceSortType[i].devices[j].lineId || null)
              if (this.lineNameFilter.indexOf(this.deviceSortType[i].devices[j].lineName || this.deviceSortType[i].devices[j].deviceName) >= 0) {
                console.log(1)
              } else {
                this.lineNameFilter.push(this.deviceSortType[i].devices[j].lineName || this.deviceSortType[i].devices[j].deviceName)
              }
            }
          }
        }
        console.log(this.deviceSortType, 'this.deviceSortType')
          this.$axiox.cpost('/warning/waringHistoryPages', {handle:0,deviceName:'',customerId:null,typeId:"",start: this.queryDate.start ? (this.queryDate.start + ' 00:00:00') : '',end: this.queryDate.end ? (this.queryDate.end + ' 23:59:59') : '',size:9999,keep:0,page:1}).then(async msg2 => {
              this.warnExcel = msg2.data.content
          })
          this.$axiox.cpost('/warning/handles', {handle:1,deviceName:'',customerId:null,typeId:"",start: this.queryDate.start ? (this.queryDate.start + ' 00:00:00') : '',end: this.queryDate.end ? (this.queryDate.end + ' 23:59:59') : '',size:9999,keep:1,page:1, type: 1}).then(async msg => {
            // this.handleExcel = msg2.data.list
            // 渲染详情数据
            const newDetailList = []
            const promiseAll = []
            this.dataAfterMaintenanceRecordsProcessingParametersHashRowSpan = {}
            for (const devicesRecord of msg.data.list) {
              // console.log(devicesRecord, 'devicesRecord');
              console.log(devicesRecord.content, 'devicesRecord.content')
              const devicesQuery = JSON.parse(devicesRecord.content || '{}')
              // 如果保养设备记录不存在设备id就返回
              if (!devicesQuery.deviceId) {
                continue
              }
              promiseAll.push(
                  // 查询报警的模板信息
                  this.$axiox.cpost(`/warning/allTemplates?id=${devicesQuery?.deviceId}`, {
                    id: devicesQuery?.deviceId
                  }).then(data => {
                    if (data.message === '成功') {
                      // const keep = data.data?.keep
                      // const keepExtend = data.data?.keepExtend
                      // console.log(data.data?.keepExtend
                      //     ?.filter(item => parseInt(item.remark) === parseInt(devicesQuery?.paramsAddress)), '数据过滤')
                      const objEach = data.data?.keepExtend
                          ?.filter(item => String(item.remark) === String(devicesQuery?.paramsAddress))
                          .reduce((obj, item) => {
                                // const handleWay = myData.postData.handleWay || {}
                                console.log(devicesRecord.handleWay, 'devicesRecord.handleWay')
                                const handleWay = JSON.parse(devicesRecord.handleWay)|| {}
                                // console.log(handleWay, 'handleWay')
                                const keys = Object?.keys(handleWay || {})
                                let jieguo = 0
                                if (!obj[item.extendName]) {
                                  obj[item.extendName] = []
                                  for (const id of keys) {
                                    // console.log(id, '123444')
                                    if (JSON.parse(id) === JSON.parse(item.id)) {
                                      if (typeof handleWay[id] === 'string') {
                                        jieguo = handleWay[id]
                                      } else {
                                        jieguo = handleWay[id]
                                      }
                                      break
                                    }
                                  }
                                  obj[item.extendName].push({ ...item, jieguo: jieguo })
                                } else {
                                  for (const id of keys) {
                                    // console.log(id, '1234565')
                                    if (JSON.parse(id) === JSON.parse(item.id)) {
                                      if (typeof handleWay[id] === 'string') {
                                        jieguo = handleWay[id]
                                      } else {
                                        jieguo = handleWay[id]
                                      }
                                      break
                                    }
                                  }
                                  obj[item.extendName].push({ ...item, jieguo: jieguo })
                                }
                                return obj
                              },
                              {}) || ''
                      const mapObjEach = Object.entries(objEach)
                      // const tableRowSpan = {}
                      for (const [ deviceName, value ] of mapObjEach) {
                        for (const item of value) {
                          if (!this.dataAfterMaintenanceRecordsProcessingParametersHashRowSpan[devicesRecord.rebootTime]) {
                            this.dataAfterMaintenanceRecordsProcessingParametersHashRowSpan[devicesRecord.rebootTime] = 1
                          } else {
                            this.dataAfterMaintenanceRecordsProcessingParametersHashRowSpan[devicesRecord.rebootTime]++
                          }
                          console.log(deviceName, 'deviceType')
                          // if (!tableRowSpan.value[deviceName]) {
                          //   tableRowSpan.value[deviceName] = 1
                          // } else {
                          //   tableRowSpan.value[deviceName]++
                          // }
                          newDetailList.push({
                            ...item,
                            createTime: devicesRecord.rebootTime,
                            deviceName: devicesRecord.deviceName,
                            headName: devicesRecord.headName,
                            deviceType: deviceName
                          })
                        }
                      }
                      // console.log(tableRowSpan, '表格数据');
                    }
                  })
              )
            }
            Promise.all(promiseAll).then(() => {
              // 老已保养导出
              // myData.myList = msg.data.list
              const deiveceNameSort = lodash.sortBy(newDetailList, (item) => {
                return item.deviceName
              })
              // const deviceNameHash = {}
              // deiveceNameSort.forEach((item) => {
              //   if (!deviceNameHash[item.deviceName]) {
              //     deviceNameHash[item.deviceName] = true
              //   }
              // })
              this.dataAfterMaintenanceRecordsProcessingParameters = deiveceNameSort
              console.log(this.dataAfterMaintenanceRecordsProcessingParameters, 'this.dataAfterMaintenanceRecordsProcessingParameters')
              // for (const key in deviceNameHash) {
              //   myData.exportList.push(
              //       ...lodash.sortBy(deiveceNameSort.filter(item => item.deviceName === key), (item) => item.deviceType)
              //   )
              // }
              let shake = null
              that.$nextTick(() => {
                clearTimeout(shake)
                shake = setTimeout(() => {
                  const hashRun = {}
                  const rows = document.getElementById('exportTable')?.querySelectorAll('td[data-hashkey].rowDeviceName')
                  const removes = []
                  const cols = []
                  rows?.forEach((row) => {
                    const rowKey = row.getAttribute('data-hashkey')
                    if (!hashRun[rowKey]) {
                      hashRun[rowKey] = true
                      cols.push(row)
                      // row.setAttribute('rowspan', tableRowSpan.value[rowKey] || 1)
                    } else {
                      removes.push(row)
                    }
                  })
                  // console.log(cols, '123')
                  // console.log(removes, '456')
                  removes.forEach(item => item.remove())
                  cols.forEach((row) => {
                    const rowKey = row.getAttribute('data-hashkey')
                    row.setAttribute('rowspan', this.dataAfterMaintenanceRecordsProcessingParametersHashRowSpan[rowKey] || 1)
                  })
                  // const rows2 = document.getElementById('exportTable').querySelectorAll('td[data-hashkey].rowDeviceType')
                  // const removes2 = []
                  // const cols2 = []
                  // rows2.forEach((row) => {
                  //   const rowKey = row.getAttribute('data-hashkey')
                  //   if (!hashRun[rowKey]) {
                  //     hashRun[rowKey] = true
                  //     cols2.push(row)
                  //     // row.setAttribute('rowspan', tableRowSpan.value[rowKey] || 1)
                  //   } else {
                  //     removes2.push(row)
                  //   }
                  // })
                  // // console.log(cols, '123')
                  // // console.log(removes, '456')
                  // removes2.forEach(item => item.remove())
                  // cols2.forEach((row) => {
                  //   const rowKey = row.getAttribute('data-hashkey')
                  //   row.setAttribute('rowspan', tableRowSpan.value[rowKey] || 1)
                  // })
                }, 500)
              })
            }).finally(() => {
              console.log(newDetailList, '123')
            })
          })
      })
      // console.log(myData.deviceList, 'dev');
    },
    getParamsSet (id) {
      var p = new Promise((resolve, reject) => {
        this.$axiox.cpost('/customer/paramsList?templateId=' + id || '').then(msg => {
          if (msg.message === '成功') {
            resolve(msg.data)
          } else {
            reject(msg.data)
          }
        }).catch(msg => {
          reject(msg.data)
        })
      })
      return p
    }
  },
  destroyed() {
    clearTimeout(timeInter)
    clearInterval(this.timeout)
  },
  mounted(){
    const options = {
      clean: true, // true: 清除会话, false: 保留会话
      connectTimeout: 4000, // 超时时间
      // 认证信息
      clientId: new Date().getTime() + '_' + (Math.random() * 99999),	//客户端ID
      username: 'admin', //连接用户名
      password: 'YXVIVLDHREDF',//连接密码，有的密码默认为public
      // 心跳时间
      keepalive: 60,
    }
    // 连接字符串, 通过协议指定使用的连接方式
    const connectUrl = 'wss://mqtt.taikunyun.com/mqtt' //连接服务端地址，注意查看ws协议对应的端口号
    const client = mqtt.connect(connectUrl, options)

    client.on('connect', () => {
      console.log('连接成功')
      // 订阅多个主题
      client.subscribe(
          ['SBOX/+/+/+/device/data/push'], //主题
          { qos: 1 },
          (err) => {
            console.log(err || '订阅成功')
          },
      );
      // // 发布消息
      // client.publish('tourist_enter', 'Hello EMQ X', (err) => {
      //   console.log(err || '发布成功')
      // })
    })
    //失败重连
    client.on('reconnect', (error) => {
      console.log('正在重连:', error)
    })
    //连接失败
    client.on('error', (error) => {
      console.log('连接失败:', error)
    })
    //接收消息
    client.on('message', (topic, message) => {
      if (topic && message) {
        const deviceName = topic.split('/')[3]
        if (this.deviceList[deviceName]) {
          this.deviceList[deviceName].params = JSON.parse(message.toString()).Params
        }
      }
    })
    this.timeout = setInterval(() => {
      this.show2()
    }, 1000)
    this.getTemplate()
      // this.show();
  }
}
</script>

<style scoped>
*{
  padding: 0;
  margin:0;
}
#hiddenHistoryList2 {
  display: none;
}
</style>
