<template>
  <div style="width: 100%;">
    <div style="text-align: left;font-size: 22px;border-left: white 3px solid;padding: 0 14px">权限管理</div>
    <div style="background-color: rgb(34, 48, 77);border-radius: 10px;margin-top: 15px;padding: 25px">
<!--      <div style="border: 1px solid;width: 90%;display: flex; margin:0px 40px auto;border-radius: 10px">-->
<!--        <div style="flex: 1;align-items: center;justify-content: center;display: flex">-->
<!--          <span>管理2</span>-->
<!--        </div>-->
<!--        <div style="flex: 1;border-left: 1px solid;border-right: 1px solid;">-->
<!--          <div class="botm jz_flex">-->
<!--            <span>A区</span>-->
<!--          </div>-->
<!--          <div class="botm jz_flex">-->
<!--            <span>B区</span>-->
<!--          </div>-->
<!--          <div class="botm jz_flex">-->
<!--            <span>C区</span>-->
<!--          </div>-->
<!--          <div class="botm jz_flex">-->
<!--            <span>D区</span>-->
<!--          </div>-->
<!--          <div class="botm jz_flex">-->
<!--            <span>E区</span>-->
<!--          </div>-->
<!--          <div class="jz_flex" style="height: 80px">-->
<!--            <span>F区</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div style="flex: 6">-->
<!--          <div class="wh_f czjz_flex" style="height:80px;margin-top:-1px;border-bottom: 2px #404C63 dotted;">-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备1</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备2</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备3</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备4</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备5</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="wh_f czjz_flex" style="height:80px;margin-top:-1px;border-bottom: 2px #404C63 dotted;">-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备1</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备2</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备3</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备4</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备5</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="wh_f czjz_flex" style="height:80px;margin-top:-1px;border-bottom: 2px #404C63 dotted;">-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备1</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备2</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备3</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备4</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备5</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="wh_f czjz_flex" style="height:80px;margin-top:-1px;border-bottom: 2px #404C63 dotted;">-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备1</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备2</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备3</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备4</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备5</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="wh_f czjz_flex" style="height:80px;margin-top:-1px;border-bottom: 2px #404C63 dotted;">-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备1</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备2</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备3</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备4</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备5</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="wh_f czjz_flex" style="height:80px;margin-top:-1px;">-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备1</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备2</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备3</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备4</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备5</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->
<!--      <div style="border: 1px solid;width: 90%;display: flex; margin:15px 40px auto;border-radius: 10px">-->
<!--        <div style="flex: 1;align-items: center;justify-content: center;display: flex">-->
<!--          <span>管理2</span>-->
<!--        </div>-->
<!--        <div style="flex: 1;border-left: 1px solid;border-right: 1px solid;">-->
<!--          <div class="botm jz_flex">-->
<!--            <span>A区</span>-->
<!--          </div>-->
<!--          <div class="botm jz_flex">-->
<!--            <span>B区</span>-->
<!--          </div>-->
<!--          <div class="botm jz_flex">-->
<!--            <span>C区</span>-->
<!--          </div>-->
<!--          <div class="botm jz_flex">-->
<!--            <span>D区</span>-->
<!--          </div>-->
<!--          <div class="botm jz_flex">-->
<!--            <span>E区</span>-->
<!--          </div>-->
<!--          <div class="jz_flex" style="height: 80px">-->
<!--            <span>F区</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div style="flex: 6">-->
<!--          <div class="wh_f czjz_flex" style="height:80px;margin-top:-1px;border-bottom: 2px #404C63 dotted;">-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备1</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备2</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备3</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备4</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备5</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="wh_f czjz_flex" style="height:80px;margin-top:-1px;border-bottom: 2px #404C63 dotted;">-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备1</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备2</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备3</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备4</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备5</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="wh_f czjz_flex" style="height:80px;margin-top:-1px;border-bottom: 2px #404C63 dotted;">-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备1</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备2</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备3</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备4</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备5</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="wh_f czjz_flex" style="height:80px;margin-top:-1px;border-bottom: 2px #404C63 dotted;">-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备1</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备2</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备3</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备4</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备5</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="wh_f czjz_flex" style="height:80px;margin-top:-1px;border-bottom: 2px #404C63 dotted;">-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备1</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备2</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备3</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备4</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备5</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="wh_f czjz_flex" style="height:80px;margin-top:-1px;">-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备1</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备2</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备3</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备4</span>-->
<!--            </div>-->
<!--            <div class="flex_1" style="padding: 5px 10px;display: inline-block;border-radius: 5px ;margin: 10px">-->
<!--              <input type="checkbox"/>-->
<!--              <span>设备5</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "deviceManager"
}
</script>

<style scoped>
.botm{
  height: 80px;
  margin-top: -1.5px;
  border-bottom: #404C63 dotted 2px;
  text-align: center;
}
span{
  margin-left: 5px;
}
</style>
