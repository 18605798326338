export default {
  // baseUrl: 'http://8.140.114.182:8091',
  // custBaseUrl: 'http://8.140.114.182:8091',
  baseUrl: 'https://www.yuncaidata.cn:8091',
  custBaseUrl: 'https://www.yuncaidata.cn:8091',
  fileBaseUrl: 'https://www.taikunyun.com:8098',
  // fileBaseUrl: 'https://www.taikunyun.com:8098',
  tkBaseUrl: 'https://www.tk-eoms.com/#/login',
  eomsApi: 'https://www.tk-eoms.com:9091/admin',
}
