<template>
  <div>
      <div id="mains" style="margin-top:50px" :style="{ width: '100%', height: '60vh'}"></div>
  </div>
</template>

<script>
/* import echart from '../dorm/echart/echarts1.vue' */
import * as echarts from "echarts";
export default {
name: "echart",
  props: ['totalData', 'wrongNum', 'totalNum', 'typeNum', 'warntypeNum', 'totalWarnNum'],
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
    };
  },
  comments: {
    echarts,
  },
  methods:{
      show(){
        let pieData = [
          { value: 0, name: '风速' },
          { value: 0, name: '水流量' },
          { value: 0, name: '轴温' },
          { value: 0, name: '液位' },
          { value: 0, name: '保养' },
          { value: 0, name: '其他' }
        ]
        console.log(this.totalWarnNum)
        for (let i = 0; i < this.totalWarnNum.length; i++) {
          pieData[i].value = this.totalWarnNum[i]
        }
        // for (let i = 0; i < this.typeNum.length; i++) {
        //   if (this.typeNum[i]!== 0) {
        //     if ((i >= 2 && i<=6) || i === 9) {
        //       pieData[1].value += this.typeNum[i]
        //     }
        //     if (i === 7) {
        //       pieData[0].value += this.typeNum[i]
        //     }
        //     if (i === 10) {
        //       pieData[2].value += this.typeNum[i]
        //     }
        //     if (i >= 11 && i<=34 ) {
        //       pieData[3].value += this.typeNum[i]
        //     }
        //     if (i >= 35) {
        //       pieData[4].value += this.typeNum[i]
        //     }
        //     if (i === 8) {
        //       pieData[5].value += this.typeNum[i]
        //     }
        //   }
        // }
        // for (let i = 0; i < this.warntypeNum.length; i++) {
        //   if (this.warntypeNum[i]!== 0) {
        //     if ((i >= 2 && i<=6) || i === 9) {
        //       pieData[1].value += this.warntypeNum[i]
        //     }
        //     if (i === 7) {
        //       pieData[0].value += this.warntypeNum[i]
        //     }
        //     if (i === 10) {
        //       pieData[2].value += this.warntypeNum[i]
        //     }
        //     if (i >= 11 && i<=34 ) {
        //       pieData[3].value += this.warntypeNum[i]
        //     }
        //     if (i >= 35) {
        //       pieData[4].value += this.warntypeNum[i]
        //     }
        //     if (i === 8) {
        //       pieData[5].value += this.warntypeNum[i]
        //     }
        //   }
        // }
      var chartDom = document.getElementById('mains');
      var myChart = echarts.init(chartDom);
      var option;

option = {
  color:["#1f6cc0","#038333", "#0d858d", "#a09a01", '#d89864', "#8d94c1", "#ff3333"],
  tooltip: {
    trigger: 'item',
    extraCssText: "background-color: #202F50;border:1px #234A71 solid",
    textStyle: {
      color:"white"
    },
  },
  legend: {
    orient: 'horizontal',
    x: 'center', // 'center' | 'left' | {number},
    y: '88%', // 'center' | 'bottom' | {number}
    textStyle:{
                    color:"white"
    }
  },
  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: ['40%', '60%'],
      center: ['50%', '50%'],
      label:{
        show: true,
        formatter:function (e) {
          console.log(e, 'eeee')
          return e.data.name + '( ' + Number(e.percent).toFixed(2) + '% )'
        },
        position:'insideTop',
        color: 'white',
        fontSize: '14px'
      },
      data:pieData,
      tooltip: {
        position: "top",
        formatter: function(params) {
          console.log(params)
          return (
              '<p style="text-align: left">'+'类型：' + params.data.name + '<br />'+'</p>'+
              '<p style="text-align: left">'+'数量：' + params.data.value+'</p>'

          );
        }
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
};

option && myChart.setOption(option);
      }
  },
  mounted(){
      this.show();
  }
}
</script>

<style scoped>

</style>
