<template>
  <div style="height: 100%;position: relative">
<!--    <div style="text-align: left;font-size: 22px;border-left: white 3px solid;padding: 0 14px">喷砂机数据</div>-->
    <div  style="background-color: rgb(34, 48, 77);border-radius: 10px;margin-top: 15px;padding-left: 15px;height: calc(100% - 40px)">
      <div v-if="!hidden" style="text-align: left;padding: 15px 0">
        <div class="mag_r-20" style="display: inline-block;vertical-align: middle;">
          <span class="mag_r-20">区域</span>
          <span>
          <select @change="getParams()" v-model="building" class="allSelect sele" name="">
             <option :value="''">全部</option>
             <option v-for="(item, index) in sortTypeFilter" :key="index" :value="item">{{ item }}</option>
          </select>
        </span>
        </div>
        <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
          <span class="mag_r-20">线别</span>
          <span>
          <select @change="getParams()" v-model="line" class="allSelect sele" name="">
                     <option :value="''">全部</option>
                      <option v-for="(item, index) in lineNameFilter" :key="index" :value="item">{{ item }}</option>
          </select>
        </span>
        </div>
        <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
          <span class="mag_r-20">开始时间</span>
          <span>
            <div class="xd disp">
          <cander :newWidth="'150px'" class="allInput d_l" v-model="newStartDate"  ></cander>
              <img class="jd" src="../../../assets/img/riqi.png" height="20px" style="margin-left: -27px;margin-top: 5px" alt="">
            </div>
            <!--          <input @change="initRamdomData()" v-model="newStartDate" type="date" class="i_time" />-->
        </span>
        </div>
        <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
          <span class="mag_r-20">结束时间</span>
          <span>
            <div class="disp xd">
          <cander :newWidth="'150px'" class="allInput d_l" :min-date="newStartDate" v-model="newEndDate" ></cander>
            <img class="jd" src="../../../assets/img/riqi.png" height="20px" style="margin-left: -27px;margin-top: 5px" alt=""></div>
            <!--          <input @change="initRamdomData()" v-model="newEndDate" type="date" class="i_time" />-->
        </span>
        </div>
<!--          <div class="mag_r-20" style="display: inline-block;vertical-align: middle">-->
<!--              <span class="mag_r-20">筛选间隔</span>-->
<!--              <span>-->
<!--                <select @change="getParams()" v-model="timeType" class="allSelect sele" name="">-->
<!--                            <option :value="1">10分钟</option>-->
<!--                            <option :value="2">30分钟</option>-->
<!--                            <option :value="3">1小时</option>-->
<!--                            <option :value="4">4小时</option>-->
<!--                </select>-->
<!--              </span>-->
<!--          </div>-->
        <button @click="getParams()" class="btn btn_success mag_l-20 mag_r-20"><span>搜索</span></button>
        <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
          <span style="border-radius: 5px;border: 1px solid #eee;padding: 10px 20px;cursor: pointer" @click="exportExcel" class="mag_r-20">导出数据</span>
        </div>
      </div>
        <div id="hiddenHistoryList" v-show="dao" class="jz_flex"  style="height: 250px;overflow: auto;">
            <table class="allTable bd ba_white wz_black" border="1" cellspacing="0">
                <thead>
                <tr class="bd">
                    <th>区域</th>
                    <th>线别</th>
                    <th>喷砂机</th>
                    <th>时间</th>
                    <th>开机时长</th>
                    <th>停机时长</th>
                    <th>离线时长</th>
                    <!--<th>审核状态</th>-->
                </tr>
                </thead>
                <tbody>
                <tr class="bd" :key="index" v-for="(item, index) in maintain">
                    <td>{{ item.Building }}</td>
                    <td>{{ item.Line }}</td>
                    <td>{{ item.Port }}</td>
                    <td>{{ item.Date }}</td>
                    <td>{{ item.openTime }}</td>
                    <td>{{ item.closeTime }}</td>
                    <td>0</td>
                    <!--<td>{{ item.handleStatus }}</td>-->
                </tr>
                </tbody>
            </table>
        </div>
      <div style="position: relative;height: 100%" class="jz_flex">

        <div v-if="showLoading" style="position: absolute;opacity: 1;background: #001E31;display: flex;align-items: center;justify-content: center;right: 20px" class="cover">
          <div style="position: relative;top: -30px">
            <img src="../../../assets/img/newLoading.gif" width="50%"/>
            <p class="font20">加载中，请耐心等待</p>
          </div>
        </div>
        <div id="mains1" :style="{'height': !hidden ? 'calc(100vh - 290px)' : '100%'}" style="padding: 20px;border-radius: 10px;width: 100%;"></div>
      </div>
    </div>

  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "Datagraph1",
  props: ['hidden'],
  data() {
    return {
      templateArray: [],
      templateData: [],
      sortTypeFilter: [],
      lineNameFilter: [],
      templateDetailArray: [],
      sortName: [],
      lxUserInfo: JSON.parse(sessionStorage.lxUserInfo || '{}'),
      newStartDate: '',
      newEndDate: '',
        timeType: 4,
      building: '',
      line: '',
      dao: false,
      showLoading: false,
      lineName: [],
      deviceSortArray: [],
      deviceSortType: [],
        totalPensha: [],
        totalAddress: [],
      list: [],
      windChart: {},
      maintain: [
      ],
      echartsData: [
      ]
    };
  },
  created() {
    this.newEndDate = this.$commonJs.todayDate
    this.newStartDate = this.$commonJs.lastWeekDay()
    if (this.lxUserInfo.penshajiChart) {
      this.windChart = JSON.parse(this.lxUserInfo.penshajiChart)
      console.log(this.windChart)
    }
    this.getHistoryData()
  },
  methods: {
    getHistoryData () {
        this.totalPensha = []
        this.totalAddress = []
      let url = this.$commonJs.returnQuery('/customer/devicePage', {page: 1, size: 99999, parentId: this.lxUserInfo.parentId || ''})
      this.$axiox.cpost(url).then(async msg => {
        if (msg.message === '成功') {
            let newList = []
            if (this.lxUserInfo.devices) {
                for (let i = 0; i < msg.data.content.length; i++) {
                    if (this.lxUserInfo.devices.indexOf(msg.data.content[i].id) >=0) {
                        newList.push(msg.data.content[i])
                    }
                }
            } else {
                newList = msg.data.content
            }
            for (let i = 0; i < newList.length; i++) {
                if (this.templateArray.indexOf(newList[i].templateId) >= 0) {
                    newList[i].templateParam = this.templateData[this.templateArray.indexOf(newList[i].templateId)]
                    newList[i].stopTimeParam = this.templateData[this.templateArray.indexOf(newList[i].templateId)]
                    newList[i].templateDetail = this.templateDetailArray[this.templateArray.indexOf(newList[i].templateId)]
                } else {
                    const detail = await this.$axiox.cpost('/customer/paramsList?templateId=' + newList[i].templateId)
                    const array = []
                    const stopTimeArray = []
                    const openTimeArray = []
                    for (let i = 0; i < detail.data.length; i++) {
                        if (detail.data[i].chart === 5) {
                            array.push(detail.data[i].paramsAddress)
                        }
                        if (detail.data[i].stopTime === 1) {
                            stopTimeArray.push(detail.data[i].paramsAddress)
                        }
                        if (detail.data[i].penshajiStatus === 1) {
                            if (this.totalAddress.indexOf(detail.data[i].paramsAddress) < 0) {
                                this.totalAddress.push(detail.data[i].paramsAddress)
                                this.totalPensha.push(detail.data[i])
                            }
                            openTimeArray.push(detail.data[i].paramsAddress)
                        }
                    }
                    console.log(array,'array')
                    newList[i].templateParam = array
                    newList[i].stopTimeParam = stopTimeArray
                    newList[i].openTimeParam = openTimeArray
                    newList[i].templateDetail = detail.data
                    this.templateData.push(array)
                    this.templateDetailArray.push(detail.data)
                    this.templateArray.push(newList[i].templateId)
                }
            }
          for (let i = 0; i < newList.length; i++) {
            if (newList[i].sortTypeId && this.deviceSortArray.indexOf(newList[i].sortTypeId) >= 0) {
              this.deviceSortType[this.deviceSortArray.indexOf(newList[i].sortTypeId)].devices.push(newList[i])
            } else {
              this.deviceSortType.push({
                sortTypeId: newList[i].sortTypeId || newList[i].id,
                sortType: newList[i].sortType || newList[i].deviceName,
                buildingName: newList[i].sortType || newList[i].deviceName,
                devices: [newList[i]]
              })
              this.deviceSortArray.push(newList[i].sortTypeId || null)
              if (this.sortTypeFilter.indexOf(newList[i].sortType || newList[i].deviceName) >= 0) {
                console.log(1)
              } else {
                this.sortTypeFilter.push(newList[i].sortType || newList[i].deviceName)
              }
            }
          }
          for (let i = 0; i < this.deviceSortType.length; i++) {
            const lineIdArray = []
            this.deviceSortType[i].lineData = []
            for (let j = 0; j < this.deviceSortType[i].devices.length; j++) {
              if (this.deviceSortType[i].devices[j].lineId && lineIdArray.indexOf(this.deviceSortType[i].devices[j].lineId) >= 0) {
                this.deviceSortType[i].lineData[lineIdArray.indexOf(this.deviceSortType[i].devices[j].lineId)].devices.push(this.deviceSortType[i].devices[j])
              } else {
                this.deviceSortType[i].lineData.push({
                  lineName: this.deviceSortType[i].devices[j].lineName || this.deviceSortType[i].devices[j].deviceName,
                  lineId: this.deviceSortType[i].devices[j].lineId || this.deviceSortType[i].devices[j].id,
                  devices: [this.deviceSortType[i].devices[j]],
                  portData: [],
                    paramsNameData: [],
                  time: [],
                    deviceName: [],
                  detailData: []
                })
                lineIdArray.push(this.deviceSortType[i].devices[j].lineId || null)
                if (this.lineNameFilter.indexOf(this.deviceSortType[i].devices[j].lineName || this.deviceSortType[i].devices[j].deviceName) >= 0) {
                  console.log(1)
                } else {
                  this.lineNameFilter.push(this.deviceSortType[i].devices[j].lineName || this.deviceSortType[i].devices[j].deviceName)
                }
              }
            }
          }
          this.deviceList = newList
          this.getParams()
        }
      })
    },
    async getParams () {
        console.log(1)
      this.showLoading = true
      let date = []
      this.echartsData = []
      if (this.newStartDate && this.newEndDate) {
        date = this.getAllDates(this.newStartDate, this.newEndDate)
      }
      let allDeviceParams = []
      this.maintain = []
      let promiseAll = []
      let nowDeviceList = []
      for (let i = 0; i < this.deviceList.length; i++) {
        if (this.building === '' || (this.deviceList[i].sortType && this.deviceList[i].sortType.indexOf(this.building) >= 0) || this.deviceList[i].deviceName.indexOf(this.building) >= 0) {
          if (this.line === '' || (this.deviceList[i].lineName && this.deviceList[i].lineName.indexOf(this.line) >= 0)  || this.deviceList[i].deviceName.indexOf(this.line) >= 0) {
              let url = '/device/dataOfDeByTime?deviceId=' + this.deviceList[i].id + '&startTime=' + this.newStartDate +' 00:00:00&endTime=' + this.newEndDate +' 23:59:59&t=1635866005546'
              if (this.timeType === 1) {
                  url = '/device/dataOfDeByTenMinutes?deviceId=' + this.deviceList[i].id + '&startTime=' + this.newStartDate +' 00:00:00&endTime=' + this.newEndDate +' 23:59:59&t=1635866005546'
              }
              if (this.timeType === 2) {
                  url = '/device/dataOfDeByHalfHour?deviceId=' + this.deviceList[i].id + '&startTime=' + this.newStartDate +' 00:00:00&endTime=' + this.newEndDate +' 23:59:59&t=1635866005546'
              }
              if (this.timeType === 3) {
                  url = '/device/dataOfDeByOneHour?deviceId=' + this.deviceList[i].id + '&startTime=' + this.newStartDate +' 00:00:00&endTime=' + this.newEndDate +' 23:59:59&t=1635866005546'
              }
              promiseAll.push(this.$axiox.post(url))
            nowDeviceList.push(this.deviceList[i])
          }
        }
      }
      Promise.all(promiseAll).then((e) => {
        for (let i = 0; i < e.length; i++) {
          let paramsData = e[i]
          allDeviceParams.push(paramsData.data)
            nowDeviceList[i].params = []
          for (let j in paramsData.data) {
              const data = {
                  data: paramsData.data[j],
                  time: j
              }
              nowDeviceList[i].params.push(data)
          }
        }
          for (let i = 0; i < date.length; i++) {
              let data = {
                  time: date[i],
                  bulidingData: JSON.parse(JSON.stringify(this.deviceSortType))
              }
              for (let s = 0; s < data.bulidingData.length; s++) {
                  for (let m = 0; m < data.bulidingData[s].devices.length; m++) {
                      const devices = JSON.parse(JSON.stringify(data.bulidingData[s].devices[m]))
                      devices.params.forEach(d => {
                          if (date[i].split('-')[0] === d.time.split('-')[0]
                              && date[i].split('-')[1] === d.time.split('-')[1]
                              && date[i].split('-')[2] === d.time.split('-')[2].split(' ')[0]
                          ) {
                              if (!devices.nowData || (devices.nowData && devices.nowData[0] && (parseInt(devices.nowData[0].time.split('-')[2].split(' ')[1].split(':')[0]) < parseInt(d.time.split(' ')[1].split(':')[0])))) {
                                  devices.nowData = d.data
                              }
                          }
                      })
                      data.bulidingData[s].devices[m] = devices
                  }
              }
              this.echartsData.push(data)
          }
          // 和前一天相减
          // for (let i = 0; i < this.echartsData.length; i++) {
          //     for (let s = 0; s < this.echartsData[i].bulidingData.length; s++) {
          //         for (let m = 0; m < this.echartsData[i].bulidingData[s].devices.length; m++) {
          //             const devices = this.echartsData[i].bulidingData[s].devices[m]
          //             if (this.echartsData[i - 1]) {
          //                 for (let y = 0; y < this.echartsData[i - 1].bulidingData.length; y++) {
          //                     for (let b = 0; b < this.echartsData[i - 1].bulidingData[y].devices.length; b++) {
          //                         const devices2 = this.echartsData[i - 1].bulidingData[y].devices[b]
          //                         if (devices.id === devices2.id) {
          //                             if (devices.nowData && devices2.nowData) {
          //                                 devices.nowData.forEach(e => {
          //                                     if (devices.openTimeParam.indexOf(!e.pointId) >= 0) {
          //                                         devices2.nowData.forEach(res => {
          //                                             if (devices.openTimeParam.indexOf(!res.pointId) >= 0) {
          //                                                 e.value = +e.value - +res.value
          //                                             }
          //                                         })
          //                                     }
          //                                 })
          //                             }
          //                         }
          //                     }
          //                 }
          //             }
          //         }
          //     }
          // }
        this.showLoading = false
        this.show2(date)
      })
    },
    getAllDates (begin, end) {
      var arr = []
      var ab = begin.split('-')
      var ae = end.split('-')
      var db = new Date()
      db.setUTCFullYear(ab[0], ab[1] - 1, ab[2])
      var de = new Date()
      de.setUTCFullYear(ae[0], ae[1] - 1, ae[2])
      var unixDb = db.getTime() - 24 * 60 * 60 * 1000
      var unixDe = de.getTime() - 24 * 60 * 60 * 1000
      for (var k = unixDb; k <= unixDe;) {
        k = k + 24 * 60 * 60 * 1000
        arr.push(this.returnTimeDate(parseInt(k), 'YYMMDDHeng'))
      }
      return arr
    },
    // 时间格式
    returnTimeDate (date, type) {
      if (date === null || date === '' || date === undefined) {
        return ''
      } else {
        let newDate = new Date(date)
        let year = newDate.getFullYear()
        let month = newDate.getMonth() + 1
        let Todate = newDate.getDate()
        let hour = newDate.getHours()
        let min = newDate.getMinutes()
        let sec = newDate.getSeconds()
        let zhYear = window.localStorage.getItem('language') === 'en-US' ? '/' : '年'
        let zhMouth = window.localStorage.getItem('language') === 'en-US' ? '/' : '月'
        let zhDate = window.localStorage.getItem('language') === 'en-US' ? ' ' : '日'
        month = month < 10 ? '0' + month : month
        Todate = Todate < 10 ? '0' + Todate : Todate
        hour = hour < 10 ? '0' + hour : hour
        min = min < 10 ? '0' + min : min
        sec = sec < 10 ? '0' + sec : sec
        if (type === 'YYMMDDHHMMSS') {
          return year + '/' + month + '/' + Todate + ' ' + hour + ':' + min + ':' + sec
        } else if (type === 'YYMMDD') {
          return year + '/' + month + '/' + Todate
        } else if (type === 'YYMMDDHHMM') {
          return year + '/' + month + '/' + Todate + ' ' + hour + ':' + min
        } else if (type === 'YYMMDDZH') {
          return year + zhYear + month + zhMouth + Todate + zhDate
        } else if (type === 'YYMMDDHHMMSSHeng') {
          return year + '-' + month + '-' + Todate + ' ' + hour + ':' + min + ':' + sec
        } else if (type === 'YYMMDDHeng') {
          return year + '-' + month + '-' + Todate
        }
      }
    },
    initRamdomData() {
    },
    exportExcel () {
      let html = '<html><head><meta charset=\'utf-8\' /></head><body>'
      html += document.getElementById('hiddenHistoryList').innerHTML
      html += '</body></html>'
      // 实例化一个Blob对象，其构造函数的第一个参数是包含文件内容的数组，第二个参数是包含文件类型属性的对象
      let blob = new Blob([html], {
        type: 'application/vnd.ms-excel'
      })
      let a = document.createElement('a')
      a.href = URL.createObjectURL(blob)
      a.download = '喷砂机数据.xls'
      a.click()
    },
    returnFengsuName (address) {
      if (parseInt(address) === 4306) {
        return '1#主风速'
      }
      if (parseInt(address) === 4308) {
        return '2#主风速'
      }
      if (parseInt(address) === 4310) {
        return '3#主风速'
      }
      if (parseInt(address) === 4312) {
        return '4#主风速'
      }
      if (parseInt(address) === 4314) {
        return '5#主风速'
      }
      if (parseInt(address) === 4316) {
        return '6#主风速'
      }
      if (parseInt(address) === 4318) {
        return '7#主风速'
      }
      if (parseInt(address) === 4320) {
        return '8#主风速'
      }
      if (parseInt(address) === 4322) {
        return '1#支风速'
      }
      if (parseInt(address) === 4324) {
        return '2#支风速'
      }
      if (parseInt(address) === 4326) {
        return '3#支风速'
      }
      if (parseInt(address) === 4328) {
        return '4#支风速'
      }
      if (parseInt(address) === 4330) {
        return '5#支风速'
      }
      if (parseInt(address) === 4332) {
        return '6#支风速'
      }
      if (parseInt(address) === 4334) {
        return '7#支风速'
      }
      if (parseInt(address) === 4336) {
        return '8#支风速'
      }
      if (parseInt(address) === 4338) {
        return '9#支风速'
      }
      if (parseInt(address) === 4340) {
        return '10#支风速'
      }
      if (parseInt(address) === 4342) {
        return '11#支风速'
      }
      if (parseInt(address) === 4344) {
        return '12#支风速'
      }
      if (parseInt(address) === 4346) {
        return '13#支风速'
      }
      if (parseInt(address) === 4348) {
        return '14#支风速'
      }
      if (parseInt(address) === 4350) {
        return '15#支风速'
      }
      if (parseInt(address) === 4352) {
        return '16#支风速'
      }
      return ''
    },
      show2 (date) {
          let that = this
          console.log(this.echartsData, 'this.echartsData')
        console.log(that)
          const newDate = date
          newDate.splice(0, 1)
          const newX = []
          for (let i = 0; i < newDate.length; i++) {
              const data = {
                  name: newDate[i],
                  value: '运行         停机            离线\n\n' + newDate[i]
              }
              newX.push(data)
          }
          const series = []
          const seriesName = []
          for (let i = 0 ; i < this.totalPensha.length; i++) {
              console.log(this.totalPensha, 'this.totalPensha')
              const data = {
                  name: this.totalPensha[i].paramsName.replace('开机时间', ''),
                  paramsAddress: this.totalPensha[i].paramsAddress,
                  paramsUnit: this.totalPensha[i].paramsUnit,
                  type: 'bar',
                  stack: 'openTime',
                  dataType: 'openTime',
                  emphasis: {
                      focus: 'series'
                  },
                  label:{
                      show: true,
                      position:'inside',
                      color: 'white'
                  },
                  axisLabel: {
                      show: true,
                      textStyle:{
                          color:"white"
                      }
                  },
                  data: []
              }
              const data2 = {
                  name: this.totalPensha[i].paramsName.replace('开机时间', ''),
                  paramsAddress: this.totalPensha[i].paramsAddress,
                  paramsUnit: this.totalPensha[i].paramsUnit,
                  type: 'bar',
                  stack: 'closeTime',
                  dataType: 'closeTime',
                  label:{
                      show: true,
                      position:'inside',
                      color: 'white'
                  },
                  axisLabel: {
                      show: true,
                      textStyle:{
                          color:"white"
                      }
                  },
                  emphasis: {
                      focus: 'series'
                  },
                  data: []
              }
              const data3 = {
                  name: this.totalPensha[i].paramsName.replace('开机时间', ''),
                  paramsAddress: this.totalPensha[i].paramsAddress,
                  paramsUnit: this.totalPensha[i].paramsUnit,
                  type: 'bar',
                  stack: 'offLineTime',
                  dataType: 'offLineTime',
                  label:{
                      show: true,
                      position:'inside',
                      color: 'white'
                  },
                  axisLabel: {
                      show: true,
                      textStyle:{
                          color:"white"
                      }
                  },
                  emphasis: {
                      focus: 'series'
                  },
                  data: []
              }
              series.push(data)
              series.push(data2)
              series.push(data3)
          }
          for (let i = 1; i < this.echartsData.length; i++) {
              for (let j = 0; j < this.echartsData[i].bulidingData.length; j++) {
                  for (let s = 0; s < this.echartsData[i].bulidingData[j].devices.length; s++) {
                      const devices = this.echartsData[i].bulidingData[j].devices[s]
                      console.log(devices.openTimeParam, 'devices.openTimeParam')
                      // for (let o = 0; 0 < devices.openTimeParam.length; o++) {
                      //     console.log(devices.openTimeParam[o])
                      // }
                      if (devices.openTimeParam) {
                          for (let o = 0; o < devices.openTimeParam.length; o++) {
                              if (devices.nowData) {
                                let flag = false
                                  for (let y = 0; y < devices.nowData.length; y++) {
                                      if (devices.nowData[y].pointId === devices.openTimeParam[o]) {
                                        flag = true
                                          console.log(devices)
                                          for (let l = 0 ; l < series.length; l++) {
                                              if (series[l].paramsAddress === devices.nowData[y].pointId && series[l].stack === 'openTime') {
                                                  series[l].data[i - 1] = devices.nowData[y].value
                                              }
                                              if (series[l].paramsAddress === devices.nowData[y].pointId && series[l].stack === 'closeTime') {
                                                  series[l].data[i - 1] = 24 - devices.nowData[y].value
                                              }
                                          }
                                          let tableData = {
                                              Building: devices.deviceName,
                                              Line: devices.lineName,
                                              Port: devices.templateDetail.find(s => {
                                                  return s.paramsAddress === devices.openTimeParam[o]
                                              }).paramsName,
                                              Date: this.echartsData[i].time,
                                              openTime: devices.nowData[y].value,
                                              closeTime: 24 - devices.nowData[y].value,
                                          }
                                          this.maintain.push(tableData)
                                      }
                                  }
                                  if (!flag) {
                                    for (let l = 0 ; l < series.length; l++) {
                                      if (series[l].stack === 'openTime') {
                                        if (series[l].data[i - 1] === undefined || series[l].data[i - 1] === null){
                                          series[l].data[i - 1] = 0
                                        }
                                      }
                                      if (series[l].stack === 'closeTime') {
                                        if (series[l].data[i - 1] === undefined || series[l].data[i - 1] === null){
                                          series[l].data[i - 1] = 24
                                        }
                                      }
                                    }
                                  }
                                  console.log(flag)
                              } else {
                                  for (let l = 0 ; l < series.length; l++) {
                                      if (series[l].stack === 'openTime') {
                                          series[l].data[i - 1] = 0
                                      }
                                      if (series[l].stack === 'closeTime') {
                                          series[l].data[i - 1] = 24
                                      }
                                  }
                              }
                          }
                      }


                  }
              }
          }
          console.log(series)
          console.log(seriesName)
          var chartDom = document.getElementById("mains1");
          var myChart = echarts.init(chartDom);
          const option = {
              color:["#1f6cc0","#038333", "#0d858d", "#a09a01", '#d89864', "#8d94c1", "#ff3333"],
              tooltip: {
                  left: "right",
                  trigger: 'axis',
                  axisPointer: {
                      type: 'shadow'
                  },
                  formatter: function (params, a) {
                      console.log(params)
                      console.log(a)
                      console.log(series)
                      let html = ''
                      for (let i = 0 ; i < params.length; i++) {
                          if (series[params[i].componentIndex]) {
                              if (series[params[i].componentIndex].stack === 'openTime') {
                                  html += series[params[i].componentIndex].name + '开机时间' + ' ' + (params[i].value || 0) + series[params[i].componentIndex].paramsUnit + '<br />'
                              }
                              if (series[params[i].componentIndex].stack === 'closeTime') {
                                  html += series[params[i].componentIndex].name + '关机时间' + ' ' + (params[i].value || 0) + series[params[i].componentIndex].paramsUnit + '<br />'
                              }
                          }
                      }
                      return html;
                  }
              },
              legend: {
                  left: "right",
                  textStyle: {
                      color:"white"
                  },
              },
              grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
              },
              xAxis: [
                  {
                      axisLabel: {
                          show: true,
                          textStyle:{
                              color:"white"
                          }
                      },
                      type: 'category',
                      data: newX
                  }
              ],
              yAxis: [
                  {
                      axisLabel: {
                          show: true,
                          textStyle:{
                              color:"white"
                          }
                      },
                      type: 'value'
                  }
              ],
              series: series
          };
          option && myChart.setOption(option);
      },
    show(date, yrate, newhours) {
      let that = this
      // const lineName = this.lineName
      // const line = this.line
      var chartDom = document.getElementById("mains1");
      var myChart = echarts.init(chartDom);
      var option;
      let arr = []
      let arr2 = []
      let index = parseFloat(this.windChart.ymin)
      for (let i = index; i < this.windChart.ymax / this.windChart.yjiange; i++) {
        if (parseFloat(parseFloat(index).toFixed(1)) === +this.windChart.preLine) {
          arr.push(parseFloat(parseFloat(index).toFixed(1)))
          arr2.push(parseFloat(parseFloat(index).toFixed(1)) + '小时预警')
        } else {
          if (parseFloat(parseFloat(index).toFixed(1)) === +this.windChart.warnLine) {
            arr.push(parseFloat(parseFloat(index).toFixed(1)))
            arr2.push(parseFloat(parseFloat(index).toFixed(1)) + '小时标准')
          } else {
            arr.push(parseFloat(parseFloat(index).toFixed(1)))
            arr2.push(parseFloat(parseFloat(index).toFixed(1)) + '小时')
          }
        }
        index += parseFloat(this.windChart.yjiange)
      }
      let Yrate = yrate || arr || [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21]
      const hours = newhours || arr2 || Yrate.map((p) => {return p + '小时'});
      const days = []
      for (let i = 0; i < date.length; i++) {
        days.push(date[i].split('-')[1] + '-' + date[i].split('-')[2])
      }
      let series = []
      // let colorArray = ["#64AD59"]
      let allData = []
      for (let n = 0; n < this.deviceSortType.length; n++) {
        allData.push([])
      }
      for (let i = 0; i < this.echartsData.length; i++) {
        for (let j = 0; j < this.echartsData[i].bulidingData.length; j++) {
          for (let o = 0; o < this.echartsData[i].bulidingData[j].lineData.length; o++) {
            for (let k = 0; k < this.echartsData[i].bulidingData[j].lineData[o].detailData.length; k++) {
              this.lineName.push(this.line.indexOf(o))
              let yRate = -1
              for (let h = 0; h < Yrate.length; h++) {
                if (Yrate[h - 1]) {
                  let value = this.echartsData[i].bulidingData[j].lineData[o].detailData[k]
                  if (value >= Yrate[h - 1] && value < Yrate[h]) {
                    yRate = parseFloat(h - 1) + parseFloat((value - Yrate[h - 1]) / (Yrate[h] - Yrate[h - 1]))
                  }
                }
              }
              let SerData = {deviceName: this.echartsData[i].bulidingData[j].lineData[o].deviceName[k],port: this.echartsData[i].bulidingData[j].lineData[o].portData[k],paramName: this.echartsData[i].bulidingData[j].lineData[o].paramsNameData[k],time: this.echartsData[i].bulidingData[j].lineData[o].time[k], line: (o + 1) + '线', value: [i, parseFloat(parseFloat(yRate).toFixed(1))]}
              allData[j].push(SerData)
            }
          }
        }
      }
      for (let i = 0; i < this.deviceSortType.length; i++) {
        let seriseData = {
          name: this.deviceSortType[i].buildingName,
          type: "scatter",
          itemStyle: {
            // normal: {
            //   color: colorArray[i],
            // },
          },
          data: [],
          animationDelay: function(idx) {
            return idx * 5;
          },
          markLine: {
            silent: true,
            symbol: ["none", "none"],
            data : [{
              // silent:true,             //鼠标悬停事件  true没有，false有
              lineStyle:{               //警戒线的样式  ，虚实  颜色
                type:"solid",
                color:"#ffffff",
                width: 2,
              },
              label:{
                position:'end',
                formatter: that.windChart.warnLine + "小时标准",
                color: '#ffffff',
                fontSize: 18
              },
              yAxis: that.windChart.warnLine + '小时标准'         // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
            }, {
              // silent:true,             //鼠标悬停事件  true没有，false有
              lineStyle:{               //警戒线的样式  ，虚实  颜色
                type:"dashed",
                width: 2,
                color:"yellow",
              },
              label:{
                position:'end',
                formatter: that.windChart.preLine + "小时预警",
                color: '#ffffff',
                fontSize: 18
              },
              yAxis: that.windChart.preLine + '小时预警'         // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
            }]
          }
        }
        for (let j = 0; j < allData[i].length; j++) {
          seriseData.data.push(allData[i][j])
        }
        series.push(seriseData)
      }
      option = {
        title: {
          text: "喷砂机/日期",
          textStyle: {
            color: 'white'
          }
        },
        legend: {
          left: "right",
          textStyle:{
            color:"white"
          }
        },
        backgroundColor: "transparent",
        tooltip: {
            left: "right",
          position: "top",
          extraCssText: "background-color: #202F50;border:1px #234A71 solid",
          textStyle: {
              left: "right",
            color:"white"
          },
          formatter: function(params) {
            if (params.seriesName) {
              console.log(params)


              return (
                  // params.value[1] +
                  '<p style="border-bottom: 2px #414C62 dotted;padding: 5px;text-align:left">'
                  +" 喷砂机: " +
                  params.data.deviceName +'</br>' +'</p>'+
                  '<p style="border-bottom: 2px #414C62 dotted;padding: 5px;text-align:left">'
                  +" 时间: " + params.data.time + '</br>' +'</p>'+
                  '<p style="border-bottom: 2px #414C62 dotted;padding: 5px;text-align:left">'
                  +" 区域: " +params.seriesName +'</br>' +'</p>'+
                  '<p style="border-bottom: 2px #414C62 dotted;padding: 5px;text-align:left">'
                  +" 线别:" + params.data.port +'</br>' + '</p>'+
                  '<p style="border-bottom: 2px #414C62 dotted;padding: 5px;text-align:left">'
                  +" 端口:" + params.data.paramName +'</br>' + '</p>'
              );
            }
          }
        },
        grid: {
          left: 50,
          bottom: 40,
          right: 150,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: days,
          // boundaryGap: false,
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
          },
          axisLabel: {
            show: true,
            textStyle:{
              color:"white",
              fontSize: 18
            }
          }
        },
        yAxis: {
          data: hours,
          type : 'category',
          minInterval: 0.5,
          axisLine: {
            show: true
          },
          axisLabel: {
            show: true,
            textStyle:{
              color:"white",
              fontSize: 18
            }
          }
        },
        series: series,
      };
      option && myChart.setOption(option);
    }
  },
  mounted() {
    this.initRamdomData()
  },
};
</script>

<style scoped></style>
