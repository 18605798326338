import { defineStore } from 'pinia'
import { readonly, ref} from "vue";
export const useStore = defineStore('store', () => {
  const devicePartitioningHash = ref({})
  const currentlySelectedArea = ref(null)
  currentlySelectedArea.value = sessionStorage.getItem('currentlySelectedArea') || null
  // devicePartitioningHash.value = JSON.parse(sessionStorage.getItem('devicePartitioningHash') || '{}}') || {}
  const setDevicePartitioningHash = (e) => {
    devicePartitioningHash.value = e
    // sessionStorage.setItem('devicePartitioningHash', JSON.stringify(e))
  }
  const setCurrentlySelectedArea = (e) => {
    currentlySelectedArea.value = e
    sessionStorage.setItem('currentlySelectedArea', e)
  }
  return {
    devicePartitioningHash: readonly(devicePartitioningHash),
    currentlySelectedArea: readonly(currentlySelectedArea),
    setDevicePartitioningHash,
    setCurrentlySelectedArea
  }
})
