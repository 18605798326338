<template>
  <div>
    <div style="text-align: left;font-size: 22px;border-left: white 3px solid;padding: 0 14px">能耗</div>
    <div style="background-color: rgb(34, 48, 77);border-radius: 10px;margin-top: 15px;padding-left: 15px">
      <div style="text-align: left;padding: 15px 0">
<!--        <div class="mag_r-20" style="display: inline-block;vertical-align: middle;">-->
<!--          <span class="mag_r-20">区域</span>-->
<!--          <span>-->
<!--          <select @change="getTemplates()" v-model="building" class="allSelect sele" name="">-->
<!--           <option :value="''">全部</option>-->
<!--            <template v-if="lxUserInfo.id === 125">-->
<!--              <option :value="'TR'">TR</option>-->
<!--            </template>-->
<!--            <template v-else>-->
<!--           <option :value="'A区'">A区</option>-->
<!--              <option :value="'B区'">B区</option>-->
<!--              <option :value="'C区'">C区</option>-->
<!--              <option :value="'F区'">F区</option>-->
<!--              <option :value="'G区'">G区</option>-->
<!--              <option :value="'H区'">H区</option>-->
<!--              <option :value="'I区'">I区</option>-->
<!--            </template>-->
<!--          </select>-->
<!--        </span>-->
<!--        </div>-->
<!--        <div class="mag_r-20" style="display: inline-block;vertical-align: middle">-->
<!--          <span class="mag_r-20">线别</span>-->
<!--          <span>-->
<!--          <select @change="getTemplates()" v-model="line" class="allSelect sele" name="">-->
<!--                       <option :value="''">全部</option>-->
<!--            <template v-if="lxUserInfo.id === 125">-->
<!--            <option :value="'1线'">线别1</option>-->
<!--            </template>-->
<!--            <template v-else>-->
<!--           <option :value="'1线'">线别1</option>-->
<!--            <option :value="'2线'">线别2</option>-->
<!--            <option :value="'3线'">线别3</option>-->
<!--            <option :value="'4线'">线别4</option>-->
<!--               </template>-->
<!--          </select>-->
<!--        </span>-->
<!--        </div>-->
<!--        <div class="mag_r-20" style="display: inline-block;vertical-align: middle">-->
<!--          <span class="mag_r-20">开始时间</span>-->
<!--          <span>-->
<!--            <div class="xd disp">-->
<!--             <cander :newWidth="'150px'" class="allInput d_l" v-model="newStartDate" ></cander>-->
<!--              <img class="jd" src="../../assets/img/riqi.png" height="20px" style="margin-left: -27px;margin-top: 5px" alt=""></div>-->
<!--            &lt;!&ndash;          <input @change="show()" v-model="newStartDate" type="date" class="i_time" />&ndash;&gt;-->
<!--        </span>-->
<!--        </div>-->
<!--        <div style="display: inline-block;vertical-align: middle">-->
<!--          <span class="mag_r-20">结束时间</span>-->
<!--          <span>-->
<!--            <div class="disp">-->
<!--             <cander :newWidth="'150px'" class="allInput d_l" v-model="newEndDate" ></cander>-->
<!--              <img class="jd" src="../../assets/img/riqi.png" height="20px" style="margin-left: -27px;margin-top: 5px" alt=""></div>-->
<!--            &lt;!&ndash;          <input @change="show()" v-model="newEndDate" type="date" class="i_time" />&ndash;&gt;-->
<!--        </span>-->
<!--        </div>-->
<!--        <button @click="getTemplates()" class="btn btn_success mag_l-20 mag_r-20"><span>搜索</span></button>-->
        <div class="mag_r-20" style="display: inline-block;vertical-align: middle">
          <span style="border-radius: 5px;border: 1px solid #eee;padding: 10px 20px;cursor: pointer" @click="exportExcel" class="mag_r-20">导出数据</span>
        </div>
      </div>
      <div id="hiddenHistoryList" v-show="dao" class="jz_flex"  style="height: 250px;overflow: auto;">
        <table class="allTable bd ba_white wz_black" border="1" cellspacing="0">
          <thead>
          <tr class="bd">
            <th>设备名</th>
            <th>区域</th>
            <th>行线</th>
            <th>集尘机开机时长</th>
            <th>用电量</th>
            <!--<th>审核状态</th>-->
          </tr>
          </thead>
          <tbody>
          <tr class="bd" :key="index" v-for="(item, index) in maintain">
            <td>{{ item.deviceName }}</td>
            <td>{{ item.buiname }}</td>
            <td>{{ item.li }}</td>
            <td>{{ item.gotime }}</td>
            <td>{{ item.electric }}</td>
            <!--<td>{{ item.handleStatus }}</td>-->
          </tr>
          </tbody>
        </table>
      </div>
      <div style="justify-content: flex-start" class="spjz_flex">
        <div id="main" :style="{ width: '100%', height: 'calc(100vh - 290px)'}" style="margin-top: 50px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import mqtt from "../../assets/js/mqtt";
let timeInter
console.log(timeInter)
export default {
  name: "echart",
  data() {
    return {
      lineName: [],
        openTimeData: [],
      deviceSortArray: [],
      deviceSortType: [],
      templateArray: [],
      templateData: [],
      sortTypeFilter: [],
      lineNameFilter: [],
      templateDetailArray: [],
      sortName: [],
      lxUserInfo: JSON.parse(sessionStorage.lxUserInfo || '{}'),
      newStartDate: '',
      newEndDate: '',
      building: '',
      timeout: '',
      line: '',
        unit1: '',
        unit2: '',
      lineData: [],
      templateList: [],
      dao: false,
      maintain:[
      ],
      deviceList: [],
      msg: "Welcome to Your Vue.js App",
    };
  },
  comments: {
    echarts,
  },
  methods:{
    exportExcel () {
        this.maintain = []
        for (let i = 0; i < this.deviceSortType.length; i++) {
            for (let j = 0; j < this.deviceSortType[i].devices.length; j++) {
                const devices = this.deviceSortType[i].devices[j]
                const data = {
                    buiname: devices.sortType,
                    deviceName: devices.deviceName,
                    li: devices.lineName,
                    gotime: 0,
                    electric: 0
                }
                for (let o in devices.template) {
                    if (devices.template[o].openTime === 1) {
                        data.gotime += ((devices.params && devices.params[o]) || 0)
                    }
                    if (devices.template[o].energy === 1) {
                        data.electric += ((devices.params && devices.params[o]) || 0)
                    }
                }
                this.maintain.push(data)
            }
        }
        this.$nextTick(() => {
            let html = '<html><head><meta charset=\'utf-8\' /></head><body>'
            html += document.getElementById('hiddenHistoryList').innerHTML
            html += '</body></html>'
            // 实例化一个Blob对象，其构造函数的第一个参数是包含文件内容的数组，第二个参数是包含文件类型属性的对象
            let blob = new Blob([html], {
                type: 'application/vnd.ms-excel'
            })
            let a = document.createElement('a')
            a.href = URL.createObjectURL(blob)
            a.download = '能耗数据.xls'
            a.click()
        })
    },
    show(){
      let hasBaoyang = this.normalData
      // let waitBaoyang = [Math.floor(Math.random() * 20000)+ 10000, Math.floor(Math.random() * 20000)+ 10000, Math.floor(Math.random() * 20000)+ 10000, Math.floor(Math.random() * 20000)+ 10000, Math.floor(Math.random() * 20000)+ 10000, Math.floor(Math.random() * 20000)+ 10000, Math.floor(Math.random() * 20000)+ 10000]
      // if (this.building.length === 1) {
      //   hasBaoyang = [Math.floor(Math.random() * 20000)+ 10000]
      //   // waitBaoyang = [Math.floor(Math.random() * 20000)+ 10000]
      // }
      let Xdata = this.lxUserInfo.id === 125 ? ['TR'] : ["A区", "B区", "C区", "F区", "G区",  "H区", "I区"]
      var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      let that = this
      var option;
      option = {
        tooltip: {
          trigger: "axis",
          formatter: function(params) {
            console.log(params)
            let html = ''
            html += '1线：' + that.lineData[params[0].dataIndex][0] + '度' + '<br />'
            return html
          },
          extraCssText: "background-color: #202F50;border:1px #234A71 solid",
          textStyle: {
            align: 'left',
            color:"white"
          },
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          textStyle:{
            color:"white"
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: Xdata,
            axisLabel: {
              show: true,
              textStyle:{
                color:"white"
              }
            }
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {

              formatter: '{value} 度',
              show: true,
              textStyle:{
                color:"white"
              }
            }
          }
          ,
        ],
        series: [
          {
            name: "正常用电",
            type: "bar",
            stack: "Ad",
            color: "#0CCF67",
            emphasis: {
              focus: "series",
            },
            data: hasBaoyang,
          },
          // {
          //   name: "节约用电",
          //   type: "bar",
          //   stack: "Ad",
          //   color: "#AED46E",
          //   emphasis: {
          //     focus: "series",
          //   },
          //   data: waitBaoyang,
          // },
        ],
      };
      option && myChart.setOption(option);
    },
    getTemplates () {
      let url = this.$commonJs.returnQuery('/customer/templates', {page: 1, size: 99999})
      this.$axiox.cpost(url).then(msg => {
        if (msg.message === '成功') {
          this.leasionData = msg.data.content
          this.getParamData()
        } else {
          this.$commonJs.alertTips({
            type: 'error',
            title: '错误:',
            content: msg.message,
            stayTime: 5
          })
        }
      }).catch(() => {
        this.$commonJs.alertTips({
          type: 'error',
          title: '获取接口错误:',
          content: '服务器报错',
          stayTime: 5
        })
      })
    },
    async getParamData () {
      for (let i = 0; i < this.leasionData.length; i++) {
        if (this.leasionData[i]) {
          this.leasionData[i].paramsList = []
          this.leasionData[i].deviceList = []
          let paramsList = await this.getParamsSet(this.leasionData[i].id)
          this.leasionData[i].paramsList = paramsList
        }
      }
      this.getDevice()
    },
    getDevice () {
      let url = this.$commonJs.returnQuery('/customer/devicePage', {page: 1, size: 99999, parentId: this.lxUserInfo.parentId || ''})
      this.$axiox.cpost(url).then(msg => {
        if (msg.message === '成功') {
          this.deviceList = msg.data.content
          for (let i = 0; i < msg.data.content.length; i++) {
            for (let j = 0; j < this.leasionData.length; j++) {
              if (msg.data.content[i].templateId === this.leasionData[j].id) {
                if (this.building === '' || msg.data.content[i].customerDeviceName.indexOf(this.building) >= 0) {
                  if (this.line === '' || msg.data.content[i].customerDeviceName.indexOf(this.line) >= 0) {
                    msg.data.content[i].paramsDataList = this.leasionData[j].paramsList
                    this.leasionData[j].deviceList.push(msg.data.content[i])
                  }
                }
              }
            }
          }
          this.getMyDeviceData()
        } else {
          this.$commonJs.alertTips({
            type: 'error',
            title: '错误:',
            content: msg.message,
            stayTime: 5
          })
        }
      }).catch(() => {
        this.$commonJs.alertTips({
          type: 'error',
          title: '获取接口错误:',
          content: '服务器报错',
          stayTime: 5
        })
      })
    },
    async getMyDeviceData () {
      this.initEcharts = false
      this.warningDianwei = 0
      this.normalData = [0, 0,0, 0,0, 0,0]
      this.warningData = [0, 0,0, 0,0, 0,0]
      this.timeoffData = [0, 0,0, 0,0, 0,0]
      this.lineData = [[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0], [0,0,0,0],[0,0,0,0],[0,0,0,0]]
      this.warningContent = [[], [], [], [], [], [], []]
      this.normalContent = [[], [], [], [], [], [], []]
      this.timeoffContent = [[], [], [], [], [], [], []]
      this.maintain = []
      for (let i = 0; i < this.leasionData.length; i++) {
        for (let j = 0; j < this.leasionData[i].deviceList.length; j++) {
          let deviceData = await this.DeviceData(this.leasionData[i].deviceList[j].id)
          let nowDevide = JSON.parse(JSON.stringify(this.leasionData[i].deviceList[j]))
          for (let o = 0; o < nowDevide.paramsDataList.length; o++) {
            for (let k = 0; k < deviceData.length; k++) {
              if (parseInt(nowDevide.paramsDataList[o].paramsAddress) === 4356 ) {
                let tableData = {
                  buiname: this.leasionData[i].deviceList[j].customerDeviceName,
                  li: '1线',
                  gotime: 0,
                  electric: ''
                }
                if (nowDevide.paramsDataList[o].paramsId === deviceData[k].paramsId) {
                  nowDevide.paramsDataList[o].paramsNowValue = deviceData[k].paramsValue
                  if (this.lxUserInfo.id === 125) {
                    if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('TR') >= 0) {
                      this.normalData[0] += parseFloat(deviceData[k].paramsValue)
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('1线') >= 0) {
                        this.lineData[0][0] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('2线') >= 0) {
                        this.lineData[0][1] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('3线') >= 0) {
                        this.lineData[0][2] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('4线') >= 0) {
                        this.lineData[0][3] += parseFloat(deviceData[k].paramsValue)
                      }
                    }
                  } else {
                    if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('A区') >= 0) {
                      this.normalData[0] += parseFloat(deviceData[k].paramsValue)
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('1线') >= 0) {
                        this.lineData[0][0] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('2线') >= 0) {
                        this.lineData[0][1] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('3线') >= 0) {
                        this.lineData[0][2] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('4线') >= 0) {
                        this.lineData[0][3] += parseFloat(deviceData[k].paramsValue)
                      }
                    }

                    if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('B区') >= 0) {
                      this.normalData[1] += parseFloat(deviceData[k].paramsValue)
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('1线') >= 0) {
                        this.lineData[1][0] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('2线') >= 0) {
                        this.lineData[1][1] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('3线') >= 0) {
                        this.lineData[1][2] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('4线') >= 0) {
                        this.lineData[1][3] += parseFloat(deviceData[k].paramsValue)
                      }
                    }

                    if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('C区') >= 0) {
                      this.normalData[2] += parseFloat(deviceData[k].paramsValue)
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('1线') >= 0) {
                        this.lineData[2][0] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('2线') >= 0) {
                        this.lineData[2][1] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('3线') >= 0) {
                        this.lineData[2][2] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('4线') >= 0) {
                        this.lineData[2][3] += parseFloat(deviceData[k].paramsValue)
                      }
                    }

                    if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('F区') >= 0) {
                      this.normalData[3] += parseFloat(deviceData[k].paramsValue)
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('1线') >= 0) {
                        this.lineData[3][0] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('2线') >= 0) {
                        this.lineData[3][1] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('3线') >= 0) {
                        this.lineData[3][2] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('4线') >= 0) {
                        this.lineData[3][3] += parseFloat(deviceData[k].paramsValue)
                      }
                    }

                    if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('G区') >= 0) {
                      this.normalData[4] += parseFloat(deviceData[k].paramsValue)
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('1线') >= 0) {
                        this.lineData[4][0] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('2线') >= 0) {
                        this.lineData[4][1] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('3线') >= 0) {
                        this.lineData[4][2] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('4线') >= 0) {
                        this.lineData[4][3] += parseFloat(deviceData[k].paramsValue)
                      }
                    }

                    if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('H区') >= 0) {
                      this.normalData[5] += parseFloat(deviceData[k].paramsValue)
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('1线') >= 0) {
                        this.lineData[5][0] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('2线') >= 0) {
                        this.lineData[5][1] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('3线') >= 0) {
                        this.lineData[5][2] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('4线') >= 0) {
                        this.lineData[5][3] += parseFloat(deviceData[k].paramsValue)
                      }
                    }

                    if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('I区') >= 0) {
                      this.normalData[6] += parseFloat(deviceData[k].paramsValue)
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('1线') >= 0) {
                        this.lineData[6][0] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('2线') >= 0) {
                        this.lineData[6][1] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('3线') >= 0) {
                        this.lineData[6][2] += parseFloat(deviceData[k].paramsValue)
                      }
                      if (this.leasionData[i].deviceList[j].customerDeviceName.indexOf('4线') >= 0) {
                        this.lineData[6][3] += parseFloat(deviceData[k].paramsValue)
                      }
                    }
                  }

                  tableData.electric += parseFloat(deviceData[k].paramsValue)
                  nowDevide.paramsDataList[o].paramsNowAddress = deviceData[k].address
                  tableData.gotime = deviceData.find(p => { return parseInt(p.paramsId) === 1303}).paramsValue
                  this.maintain.push(tableData)
                }
              }
            }
            if (deviceData.length === 0) {
              nowDevide.paramsDataList[o].paramsNowValue = ''
            }
          }
          this.leasionData[i].deviceList[j] = nowDevide
        }
      }
      console.log(this.normalData)
      console.log(this.timeoffData)
      console.log(this.warningData)
      this.showleasonData = []
      this.showleasonData = JSON.parse(JSON.stringify(this.leasionData))
      // this.$nextTick(p => {
      //   this.showLoading = false
      // })
      console.log(this.showleasonData)
      this.initEcharts = true
      this.$nextTick(() => {
        this.show()
      })
    },
    DeviceData (id) {
      var p = new Promise((resolve, reject) => {
        this.$axiox.post('/device/dataOfDevice?deviceId=' + id).then(msg => {
          resolve(msg.data || [])
          console.log(reject)
        }).catch(() => {
        })
      })
      return p
    },
    getDevideList () {
      const url = this.$commonJs.returnQuery('/customer/devicePage', { page: 1, size: 99999 })
      this.$axiox.cpost(url).then((msg) => {
        const newList = msg.data.content
        for (let i = 0; i < newList.length; i++) {
          this.deviceList[newList[i].macAddress] = newList[i]
          this.deviceList[newList[i].macAddress].template = this.templateList[this.deviceList[newList[i].macAddress].templateId]
        }
        for (let i = 0; i < newList.length; i++) {
          if (newList[i].sortTypeId && this.deviceSortArray.indexOf(newList[i].sortTypeId) >= 0) {
            this.deviceSortType[this.deviceSortArray.indexOf(newList[i].sortTypeId)].devices.push(newList[i])
          } else {
            this.deviceSortType.push({
              sortTypeId: newList[i].sortTypeId || newList[i].id,
              sortType: newList[i].sortType || newList[i].deviceName,
              buildingName: newList[i].sortType || newList[i].deviceName,
              devices: [newList[i]]
            })
            this.deviceSortArray.push(newList[i].sortTypeId || null)
            if (this.sortTypeFilter.indexOf(newList[i].sortType || newList[i].deviceName) >= 0) {
              console.log(1)
            } else {
              this.sortTypeFilter.push(newList[i].sortType || newList[i].deviceName)
            }
          }
        }
        for (let i = 0; i < this.deviceSortType.length; i++) {
          const lineIdArray = []
          this.deviceSortType[i].lineData = []
          for (let j = 0; j < this.deviceSortType[i].devices.length; j++) {
            if (this.deviceSortType[i].devices[j].lineId && lineIdArray.indexOf(this.deviceSortType[i].devices[j].lineId) >= 0) {
              this.deviceSortType[i].lineData[lineIdArray.indexOf(this.deviceSortType[i].devices[j].lineId)].devices.push(this.deviceSortType[i].devices[j])
            } else {
              this.deviceSortType[i].lineData.push({
                lineName: this.deviceSortType[i].devices[j].lineName || this.deviceSortType[i].devices[j].deviceName,
                lineId: this.deviceSortType[i].devices[j].lineId || this.deviceSortType[i].devices[j].id,
                devices: [this.deviceSortType[i].devices[j]],
                portData: [],
                detailData: []
              })
              lineIdArray.push(this.deviceSortType[i].devices[j].lineId || null)
              if (this.lineNameFilter.indexOf(this.deviceSortType[i].devices[j].lineName || this.deviceSortType[i].devices[j].deviceName) >= 0) {
                console.log(1)
              } else {
                this.lineNameFilter.push(this.deviceSortType[i].devices[j].lineName || this.deviceSortType[i].devices[j].deviceName)
              }
            }
          }
        }
        console.log(this.deviceSortType, 'this.deviceSortType')
        console.log(this.showDeveceList)
      })
      // console.log(myData.deviceList, 'dev');
    },
    show2 () {
        const that = this
      this.normalData = []
      this.openTimeData = []
      this.sortName = []
      for (let i = 0; i < this.deviceSortType.length; i++) {
        // console.log(this.deviceSortType[i], '排序的设备')
        const facility = this.deviceSortType[i]
        for (const line of facility.lineData) {
          this.sortName.push(this.deviceSortType[i].sortType + '-' + line.lineName)
          this.normalData.push(0)
          this.openTimeData.push(0)
        }
        // this.sortName.push(this.deviceSortType[i].sortType)
        // this.normalData.push(0)
        // this.openTimeData.push(0)
      }
      console.log(this.deviceSortType, 'this.deviceSortType')
      console.log(this.deviceList, 'this.deviceList')
      for (let i in this.deviceList) {
        for (let j in this.deviceList[i].params) {
          // console.log(this.deviceList[i], '设备数据')
          if (this.deviceList[i].template[j] && this.deviceList[i].template[j].energy === 1) {
              this.unit1 = (this.deviceList[i].template[j].paramsUnit || '')
            let indexSort = -1
            for (let s = 0; s < this.deviceSortType.length; s++) {
              for (let l = 0;l < this.deviceSortType[s].lineData.length;l++) {
                indexSort++
                const line = this.deviceSortType[s].lineData[l]
                // console.log(this.deviceSortType, '123')
                // if (this.deviceList[i].sortType === this.deviceSortType[s].sortType || this.deviceList[i].deviceName === this.deviceSortType[s].sortType) {
                if (line.lineName === this.deviceList[i].lineName && this.deviceSortType[s].sortType === this.deviceList[i].sortType) {
                  // console.log(this.deviceList[i].params, '电量数值')
                  console.log(l, 'llllllllllllll')
                  this.normalData[indexSort] += parseFloat(this.deviceList[i].params[j])
                }
              }
            }
          }
          if (this.deviceList[i].template[j] && this.deviceList[i].template[j].openTime === 1) {
              this.unit2 = (this.deviceList[i].template[j].paramsUnit || '')
            let indexSort = -1
              for (let s = 0; s < this.deviceSortType.length; s++) {
                for (let l = 0;l < this.deviceSortType[s].lineData.length;l++) {
                  indexSort++
                  const line = this.deviceSortType[s].lineData[l]
                  // if (this.deviceList[i].sortType === this.deviceSortType[s].sortType || this.deviceList[i].deviceName === this.deviceSortType[s].sortType) {
                  if (line.lineName === this.deviceList[i].lineName && this.deviceSortType[s].sortType === this.deviceList[i].sortType) {
                    this.openTimeData[indexSort] += parseFloat(this.deviceList[i].params[j])
                  }
                }
              }
          }
        }
      }
      console.log(this.normalData)
      let hasBaoyang = this.normalData
      // let waitBaoyang = [Math.floor(Math.random() * 20000)+ 10000, Math.floor(Math.random() * 20000)+ 10000, Math.floor(Math.random() * 20000)+ 10000, Math.floor(Math.random() * 20000)+ 10000, Math.floor(Math.random() * 20000)+ 10000, Math.floor(Math.random() * 20000)+ 10000, Math.floor(Math.random() * 20000)+ 10000]
      // if (this.building.length === 1) {
      //   hasBaoyang = [Math.floor(Math.random() * 20000)+ 10000]
      //   // waitBaoyang = [Math.floor(Math.random() * 20000)+ 10000]
      // }
      let Xdata = this.sortName
      var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      // let that = this
      var option;
      option = {
        tooltip: {
          trigger: "axis",
          formatter: function(param) {
            console.log(param, 'param')
            let html = ''
            html += `<div style="margin-bottom: 12px;">${param[0].axisValueLabel}</div>`
            html += '<div style="display: flex;align-items: center;">' + `<span style="width: 80px;">用电量：</span>` + param[0].value + ' ' + that.unit1 + '</div>'
              html += '<div style="display: flex;align-items: center;">' + `<span style="width: 80px;">开机时长：</span>` + that.openTimeData[param[0].dataIndex] + ' ' + that.unit2 + '</div>'
            return html
          },
          extraCssText: "background-color: #202F50;border:1px #234A71 solid",
          textStyle: {
            align: 'left',
            color:"white"
          },
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          textStyle:{
            color:"white"
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: Xdata,
            axisLabel: {
              show: true,
              textStyle:{
                color:"white"
              }
            }
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {

              formatter: '{value} 度',
              show: true,
              textStyle:{
                color:"white"
              }
            }
          }
          ,
        ],
        series: [
          {
            name: "正常用电",
            type: "bar",
            stack: "Ad",
            color: "#0CCF67",
            emphasis: {
              focus: "series",
            },
            data: hasBaoyang,
          },
          // {
          //   name: "节约用电",
          //   type: "bar",
          //   stack: "Ad",
          //   color: "#AED46E",
          //   emphasis: {
          //     focus: "series",
          //   },
          //   data: waitBaoyang,
          // },
        ],
      };
      option && myChart.setOption(option);
    },
    getTemplate () {
      const url = this.$commonJs.returnQuery('/customer/templates', { page: 1, size: 99999 })
      this.$axiox.cpost(url).then((msg) => {
        const promiseAll = []
        for (let i = 0; i < msg.data.content.length; i++) {
          promiseAll.push(this.getParamsSet(msg.data.content[i].id))
          this.templateList[msg.data.content[i].id] = {}
        }
        Promise.all(promiseAll).then(e => {
          console.log(e)
          console.log(this.templateList)
          for (let i = 0; i < e.length; i++) {
            for (let j = 0; j < e[i].length; j++) {
              this.templateList[e[i][0].templateId][e[i][j].paramsAddress] = e[i][j]
            }
          }
          this.getDevideList(e)
        })
      })
    },
    getParamsSet (id) {
      var p = new Promise((resolve, reject) => {
        this.$axiox.cpost('/customer/paramsList?templateId=' + id || '').then(msg => {
          if (msg.message === '成功') {
            resolve(msg.data)
          } else {
            reject(msg.data)
          }
        }).catch(msg => {
          reject(msg.data)
        })
      })
      return p
    }
  },
  destroyed() {
    clearTimeout(timeInter)
    clearTimeout(this.timeout)
  },
  mounted(){
    // this.getTemplates()
    const options = {
      clean: true, // true: 清除会话, false: 保留会话
      connectTimeout: 4000, // 超时时间
      // 认证信息
      clientId: new Date().getTime() + '_' + (Math.random() * 99999),	//客户端ID
      username: 'admin', //连接用户名
      password: 'YXVIVLDHREDF',//连接密码，有的密码默认为public
      // 心跳时间
      keepalive: 60,
    }
    // 连接字符串, 通过协议指定使用的连接方式
    const connectUrl = 'wss://mqtt.taikunyun.com/mqtt' //连接服务端地址，注意查看ws协议对应的端口号
    const client = mqtt.connect(connectUrl, options)

    client.on('connect', () => {
      console.log('连接成功')
      // 订阅多个主题
      client.subscribe(
          ['SBOX/+/+/+/device/data/push'], //主题
          { qos: 1 },
          (err) => {
            console.log(err || '订阅成功')
          },
      );
      // // 发布消息
      // client.publish('tourist_enter', 'Hello EMQ X', (err) => {
      //   console.log(err || '发布成功')
      // })
    })
    //失败重连
    client.on('reconnect', (error) => {
      console.log('正在重连:', error)
    })
    //连接失败
    client.on('error', (error) => {
      console.log('连接失败:', error)
    })
    //接收消息
    client.on('message', (topic, message) => {
      if (topic && message) {
        const deviceName = topic.split('/')[3]
        if (this.deviceList[deviceName]) {
          this.deviceList[deviceName].params = JSON.parse(message.toString()).Params
        }
      }
    })
    this.timeout = setInterval(() => {
      this.show2()
    }, 1000)
    this.getTemplate()
    // this.show();
  }
}
</script>

<style>

</style>
