<template>
  <div id="index">
    <div class="imgBg">
      <img style="position: relative;z-index: 1" src="../../assets/img/logbg.png" width="100%" :height="minHeight"/>
      <div style="position: absolute;top: 28%;left: 10%;z-index: 2">
        <!--<div class="logoNav">大数据后台管理</div>-->
        <div class="loginDiv">
          <section style="color: #fff;font-size: 36px" class="t_c">Dust Facility E-Boarding</section>
          <div style="margin-top: 40px">欢迎登录</div>
<!--          <section style="color: #fff;text-align: center">-->
<!--            <input :value="1" v-model="loginType" id="foSignal"  class="allRadio" name="foSignal" type="radio">-->
<!--            <label for="foSignal"></label>-->
<!--            <label for="foSignal">一对一版</label>-->
<!--            <input :value="2" v-model="loginType" id="foSignal2" class="allRadio" name="foSignal" type="radio">-->
<!--            <label style="margin-left: 50px" for="foSignal2"></label>-->
<!--            <label for="foSignal2">一对多版</label>-->

<!--          </section>-->
          <p><i class="icon iconfont iconyonghu"></i><input @keyup.enter="goToIndex()" v-model="account" placeholder="请输入用户名" type="text"></p>
          <p>
            <!--<i class="icon iconfont iconmima"></i>-->
            <img src="../../assets/img/pass.png" width="28" style="margin: 10px 0 10px 20px;"><input @keyup.enter="goToIndex()" v-model="myPassword" placeholder="请输入密码" type="password"></p>
          <button class="logButton" @click="goToIndex()">登录</button>
        </div>
      </div>
      <img style="position: absolute;z-index: 1;right: 10%" src="../../assets/img/logbg1.png" width="700"/>
    </div>
<!--    <div style="text-align: center;margin: 20px 0;position: fixed;bottom: 0;width: 100%;z-index: 10;color:#fff">-->
<!--      <p>版权所有：苏州钛锟环保节能科技有限公司</p>-->
<!--      <p><a href="https://beian.miit.gov.cn" style="color: #fff" target="_blank" class="m_b_10 m_t_10">Copyright © 苏ICP备19012628号-3</a></p>-->
<!--    </div>-->
  </div>
</template>

<script>
import md5 from 'js-md5';
export default {
  name: 'index',
  data () { // 所有的数据都写在里面
    return {
      msg: '',
      account: '',
      loginType: 2,
      myPassword: '',
      minHeight: ''
    }
  },
  mounted () {
    this.minHeight = window.innerHeight
  },
  methods: {// 所有的方法写在里面
    goToIndex () {
      const that = this
      // const data = {
      //   user_name: this.account,
      //   pass_word: this.myPassword
      // }
      this.$axiox.cpost('/account/login?loginName=' + this.account + '&password=' + md5(this.myPassword)).then(msg => {
        console.log(msg.message)
        if (msg.message === '成功') {
            if (msg.data.parentMessage) {
                msg.data.user.menu = msg.data.parentMessage.menu
                msg.data.user.waterChart = msg.data.parentMessage.waterChart
                msg.data.user.windChart = msg.data.parentMessage.windChart
                msg.data.user.penshajiChart = msg.data.parentMessage.penshajiChart
                msg.data.user.flowChart = msg.data.parentMessage.flowChart
                msg.data.user.bodyUrl = msg.data.parentMessage.bodyUrl
                msg.data.user.eboradType = msg.data.parentMessage.eboradType
            }
          window.sessionStorage.setItem('lixtoken', msg.data.token)
          // window.sessionStorage.setItem('userAccount', 'user')
          sessionStorage.lxUserInfo = JSON.stringify(msg.data.user)
          sessionStorage.enterpriseLx = JSON.stringify(msg.data.enterprise)
          if (msg.data.user.isEboard === 1 || (msg.data.parentMessage && msg.data.parentMessage.isEboard === 1)) {
            this.$router.push({
              name: 'allShow'
            })
          } else {
            that.$commonJs.alertTips({
              type: 'error',
              title: '错误:',
              content: '无权限访问',
              stayTime: 5
            })
          }
        } else {
          that.$commonJs.alertTips({
            type: 'error',
            title: '错误:',
            content: msg.data || msg.message,
            stayTime: 5
          })
        }
      }).catch(msg => {
        console.log(msg)
        that.$commonJs.alertTips({
          type: 'error',
          title: '获取接口错误:',
          content: '服务器报错',
          stayTime: 5
        })
      })
    }
  }
}
</script>

<style scoped>
.logoNav{
  font-size: 30px;
  text-align: center;
  z-index: 2;
  color: white;
  margin-bottom: 20px;
}
</style>
