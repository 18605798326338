import Vue from 'vue'
import VueRouter from 'vue-router'
import demo1 from '../views/dorm/dorm01.vue'
import login from '../views/login/login.vue'
import wuliu from '../views/login/wuliu.vue'
import allShow from "../views/dorm/totalView";
import monitorList from "../views/dorm/monitorList";
import allShow2 from "../views/dorm/allShow2";
import chainPage from "../views/dorm/chainPage";
import myLogManager from "../views/dorm/myLogManager";
import chainPage1 from "../views/dorm/chainPage/chainPage1";
import chainPage2 from "../views/dorm/chainPage/chainPage2";
import chainPage3 from "../views/dorm/chainPage/chainPage3";
import chainPage4 from "../views/dorm/chainPage/chainPage4";
import policesing from "../views/dorm/policesing";
import policesingto from "../views/dorm/policesingto";
import dataTable from "../views/dorm/dataTable";
import deviceManager from "../views/dorm/deviceManager";
import upKeep from "../views/dorm/upKeep";
import energyPage from "../views/dorm/energyPage";
import Datagraph1 from "../views/dorm/Datagraph/Datagraph1";
import Datagraph2 from "../views/dorm/Datagraph/Datagraph2";
import Datagraph3 from "../views/dorm/Datagraph/Datagraph3";
import Datagraph4 from "../views/dorm/Datagraph/Datagraph4";
import Datagraph5 from "../views/dorm/Datagraph/Datagraph5";
import parameter from "../views/dorm/parameter";
import alarmList from "../views/dorm/alarmList";
import checkingList from "../views/dorm/checkingList";
import maintainList from "../views/dorm/maintainList";
import checking from "../views/dorm/checking";
import Datagraph6 from "@/views/dorm/Datagraph/Datagraph6.vue";
import Datagraph7 from "@/views/dorm/Datagraph/Datagraph7.vue";
Vue.use(VueRouter)

/*
const routes = [
  {
    path: '/demo1',
    redirect: '/demo1'
  }
]
*/

const router = new VueRouter({
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'login',
      component: login
    },
    {
      path: '/wuliu',
      name: 'wuliu',
      component: wuliu
    },
    {
      path: '/demo1',
      name: 'demo1',
      component: demo1,
      children:[
        {
          path: '/allShow',
          name: 'allShow',
          component: allShow
        },
        {
          path: '/myLogManager',
          name: 'myLogManager',
          component: myLogManager
        },
        {
          path: '/monitorList',
          name: 'monitorList',
          component: monitorList
        },
        {
          path: '/allShow2',
          name: 'allShow2',
          component: allShow2
        },
        {
          path: '/chainPage',
          name: 'chainPage',
          component: chainPage,
        },
        {
          path: '/chainPage1',
          name: 'chainPage1',
          component: chainPage1,
        },
        {
          path: '/chainPage2',
          name: 'chainPage2',
          component: chainPage2,
        },
        {
          path: '/chainPage3',
          name: 'chainPage3',
          component: chainPage3,
        },
        {
          path: '/chainPage4',
          name: 'chainPage4',
          component: chainPage4,
        },
        {
          path: '/policesing',
          name: 'policesing',
          component: policesing
        },
        {
          path: '/dataTable',
          name: 'dataTable',
          component: dataTable
        },
        {
          path: '/deviceManager',
          name: 'deviceManager',
          component: deviceManager
        },
        {
          path: '/policesingto',
          name: 'policesingto',
          component: policesingto
        },
        {
          path: '/upKeep',
          name: 'upKeep',
          component: upKeep
        },
        {
          path: '/Datagraph1',
          name: 'Datagraph1',
          component: Datagraph1
        },
        {
          path: '/Datagraph2',
          name: 'Datagraph2',
          component: Datagraph2
        },
        {
          path: '/parameter',
          name: 'parameter',
          component: parameter
        },
        {
          path: '/Datagraph3',
          name: 'Datagraph3',
          component: Datagraph3
        },
        {
          path: '/Datagraph4',
          name: 'Datagraph4',
          component: Datagraph4
        },
        {
          path: '/Datagraph5',
          name: 'Datagraph5',
          component: Datagraph5
        },
        {
          path: '/Datagraph6',
          name: 'Datagraph6',
          component: Datagraph6
        },
        {
          path: '/Datagraph7',
          name: 'Datagraph7',
          component: Datagraph7
        },
        {
          path: '/energyPage',
          name: 'energyPage',
          component: energyPage
        },
        {
          path: '/alarmList',
          name: 'alarmList',
          component: alarmList
        },
        {
          path: '/checkingList',
          name: 'checkingList',
          component: checkingList
        },
        {
          path: '/maintainList',
          name: 'maintainList',
          component: maintainList
        },
        {
          path: '/checking',
          name: 'checking',
          component: checking
        }
      ]
    },

  ]
})

export default router
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
