<template>
  <div style="text-align: left" id="index">
    <div style="text-align: left;font-size: 22px;border-left: white 3px solid;padding: 0 14px;margin-bottom: 10px">设备总览</div>

    <div>
      <div :style="{width: +lxUserInfo.eboradType === 2 ? '100%' : ''}" class="newTotalView p_r" :key="deviceIndex" v-for="(deviceItem, deviceIndex) in showDeveceList">
        <div class="p_r">
          <img :src="deviceItem.url" style="width: 100%" />
          <template v-for="(item, index) in deviceItem.text" >
            <div :key="index" class="p_a" :style="{left: (item.imgLeft / deviceItem.imgWidth * 100) + '%', top: (item.imgTop / deviceItem.imgHeight * 100) + '%', width: (item.imgWidth / deviceItem.imgWidth * 100) + '%', height: (item.imgHeight / deviceItem.imgHeight  * 100) + '%', color: deviceItem.text.name}">
              <span v-if="item.paramsId === -1">
                  <img :class="deviceItem.params && (+deviceItem.params['4301'] === 0 || +deviceItem.params[4301] === 0)  ? 'moveTop' : 'moveDisabled'" src="../../assets/img/upArrow_yellow.png" style="width: 100%;user-select: none;height: 100%" alt="">
              </span>
              <span v-else-if="item.paramsId === -2">
                  <img :class="deviceItem.params && (+deviceItem.params['4301'] === 0 || +deviceItem.params[4301] === 0)  ? 'moveLeft' : 'moveDisabled'" src="../../assets/img/rightArrow_yellow.png" style="width: 100%;user-select: none;height: 100%" alt="">
              </span>
              <span v-else-if="item.paramsId === -3">
                  <img :class="deviceItem.params && (+deviceItem.params['4301'] === 0 || +deviceItem.params[4301] === 0)  ? 'moveTop' : 'moveDisabled'" src="../../assets/img/downArrow_yellow.png" style="width: 100%;user-select: none;height: 100%" alt="">
              </span>
              <span v-else-if="item.paramsId === -4">
                  <img :class="deviceItem.params && (+deviceItem.params['4301'] === 0 || +deviceItem.params[4301] === 0)  ? 'moveLeft' : 'moveDisabled'" src="../../assets/img/leftArrow_yellow.png" style="width: 100%;user-select: none;height: 100%" alt="">
              </span>
              <span :style="{background: item.type === 1 ? '#1d97ff' : ''}" style="display: flex;align-items: center;justify-content: center;height: 100%;width: 100%;user-select: none;border-radius: 5px;" v-else-if="item.paramsId === -5">
                 <div>
                   {{deviceItem.deviceName}}
                 </div>
              </span>
              <span @click="seeMonilist(deviceItem)" :style="{background: item.type === 1 ? '#efb524' : ''}" style="display: flex;align-items: center;justify-content: center;height: 100%;width: 100%;user-select: none;border-radius: 5px;" class="c_p" v-else-if="item.paramsId === -6">
                 <div>
                   {{item.paramsName}}
                 </div>
              </span>
              <div :class="returnClass3(deviceItem.params && deviceItem.params[item.address], deviceItem.template[item.address], deviceItem, item.type)" style="display: flex;align-items: center;justify-content: center;height: 100%;width: 100%;user-select: none;border-radius: 5px"  v-else>
                <div>
                  <div :class="returnClass2(deviceItem.params && deviceItem.params[item.address], deviceItem.template[item.address], deviceItem, item.type)" :style="{color: item.type === 1 ? '#fff' : ''}" >{{item.paramsName}}</div>
                  <div v-if="item.type === 1 && deviceItem.params" :style="{color: item.type === 1 ? '#fff!important' : ''}" >{{newReturnNowValue(deviceItem.params[item.address], deviceItem, item.address)}}</div>
                  <div :class="returnClass2(deviceItem.params && deviceItem.params[item.address], deviceItem.template[item.address], deviceItem, item.type)" v-if="item.type === 2 && deviceItem.params">{{newReturnNowValue(deviceItem.params[item.address], deviceItem, item.address)}}</div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <model :button="1" v-if="showModel" :title="'查看详情'" :minWidth="'1400px'" v-on:on-cancel="cancelModel()" v-on:on-confirm="confirModel()">
      <div style="max-height: 960px;overflow-y: auto">
        <div>
          <div>
            <div>
              <div style="display: inline-block; width: 200px">
                <cander :className="'allInput'" :placeholderName="'开始时间'" :showTimePicker="true" v-model="hisStartTime" :newWidth="'100%'"></cander>
              </div>
              <div style="display: inline-block; width: 200px">
                <cander :className="'allInput'" :placeholderName="'结束时间'" :showTimePicker="true" v-model="hisEndTime" :newWidth="'100%'"></cander>
              </div>
                <div style="display: inline-block; width: 200px">
                    <span class="mag_r-20">筛选间隔</span>
                    <span>
                <select v-model="timeType" class="allSelect sele" name="">
                            <option :value="1">10分钟</option>
                            <option :value="2">30分钟</option>
                            <option :value="3">1小时</option>
                            <option :value="4">4小时</option>
                </select>
              </span>
                </div>
              <div v-if="signliFilter.length > 0" style="display: inline-block; width: 200px;vertical-align: top">
                <allSelect :chooseData="signliFilter" :multiple="true" @on-change="changeSearchFilter" :needReturn="'time'" :placeholder="'筛选单条数据'" :matchValue="'name'" :showValue="'{{name}}'" v-model="signliFilterIndex">
                </allSelect>
              </div>
              <div style="display: inline-block; width: 200px" >
                <button @click="searchHisData" class="redButton">搜索</button>
              </div>
            </div>

            <div v-show="windChart.ifShow" style="min-height: 340px">
              <div class="font20 t_l" style="color: #fff">风速折线图</div>
              <div style="height: 300px" id="myCharts"></div>
            </div>
            <div v-show="waterChart.ifShow" style="min-height: 340px">
              <div class="font20 t_l" style="color: #fff">水流量折线图</div>
              <div style="height: 300px" id="myCharts2"></div>
            </div>
            <div v-show="temperatureChart.ifShow" style="min-height: 340px">
              <div class="font20 t_l" style="color: #fff">轴温折线图</div>
              <div style="height: 300px" id="myCharts3"></div>
            </div>
          </div>
        </div>
      </div>
    </model>
  </div>
</template>

<script>
import mqtt from "@/assets/js/mqtt";
let timeInter
let TimeOut
let echarts = require('echarts')
let myChart
let myChart2
let myChart3
export default {
  name: 'index',
  data () {
    return {
      signliFilter: [],
      templateArray: {},
      lxUserInfo: JSON.parse(sessionStorage.lxUserInfo || '{}'),
      option: {
        title: {
          textStyle: {
            color: '#ffffff'
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: []
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
          axisLabel: {
            show: true,
            color: '#ffffff',
            fontSize: 10
          },
          nameTextStyle: {
            color: '#ffffff'
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true,
            color: '#ffffff',
            fontSize: 10
          },
          nameTextStyle: {
            color: '#ffffff'
          }
        },
        series: [
        ]
      },
      signliFilterIndex: '',
      mode: parseInt(this.$route.query.mode),
      minNumber: '-6',
        timeType: 3,
      maxNumber: '700',
      Percentage: '',
      hisStartTime: '',
      hisEndTime: '',
      templateList: [],
      deviceList: [],
      hiddenSearch: true,
      showModel: false,
      totalPage: 0,
      page: 1,
      nowIndex: 0,
      showLoading: true,
      singleDate: {
      },
      postData: {
        user_name: '',
        phone: '',
        status: '', // 1 开启 0 关闭
        page_row: 10,
        current_page: 1
      },
      nowItem: {},
      myList: [],
      editIndex: 0,
      receivedMsg: {},
      leasionData: [],
      showleasonData: [],
      sameDtuList: [],
      showDeveceList: [],
      signalData: {},
        waterChart: {},
        windChart: {},
        temperatureChart: {},
        flowChart: {},
      first: true
    }
  },
  created () {
    this.templateArray = {}
    const options = {
      clean: true, // true: 清除会话, false: 保留会话
      connectTimeout: 4000, // 超时时间
      // 认证信息
      clientId: new Date().getTime() + '_' + (Math.random() * 99999),	//客户端ID
      username: 'admin', //连接用户名
      password: 'YXVIVLDHREDF',//连接密码，有的密码默认为public
      // 心跳时间
      keepalive: 60,
    }
      if (this.lxUserInfo.waterChart) {
          this.waterChart = JSON.parse(this.lxUserInfo.waterChart)
      }
      if (this.lxUserInfo.windChart) {
          this.windChart = JSON.parse(this.lxUserInfo.windChart)
      }
      if (this.lxUserInfo.temperatureChart) {
          this.temperatureChart = JSON.parse(this.lxUserInfo.temperatureChart)
      }
      if (this.lxUserInfo.flowChart) {
          this.flowChart = JSON.parse(this.lxUserInfo.flowChart)
      }
    // 连接字符串, 通过协议指定使用的连接方式
    const connectUrl = 'wss://mqtt.taikunyun.com/mqtt' //连接服务端地址，注意查看ws协议对应的端口号
    const client = mqtt.connect(connectUrl, options)

    client.on('connect', () => {
      console.log('连接成功')
      // 订阅多个主题
      client.subscribe(
          ['SBOX/+/+/+/device/data/push'], //主题
          { qos: 1 },
          (err) => {
            console.log(err || '订阅成功')
          },
      );
      // // 发布消息
      // client.publish('tourist_enter', 'Hello EMQ X', (err) => {
      //   console.log(err || '发布成功')
      // })
    })
    //失败重连
    client.on('reconnect', (error) => {
      console.log('正在重连:', error)
    })
    //连接失败
    client.on('error', (error) => {
      console.log('连接失败:', error)
    })
    //接收消息
    client.on('message', (topic, message) => {
      if (topic && message) {
        const deviceName = topic.split('/')[3]
        if (this.deviceList[deviceName]) {
          this.deviceList[deviceName].params = JSON.parse(message.toString()).Params
        }
      }
    })
    this.getTemplate()
    setInterval(() => {
      this.getMyDeviceData()
    }, 1000)
  },
  destroyed () {
    clearTimeout(timeInter)
  },
  watch: {
    // 'postData.start_timeInput' () {
    //   if (this.postData.start_timeInput !== '' && this.postData.start_timeInput !== null && this.postData.start_timeInput !== undefined) {
    //     this.postData.start_time = this.postData.start_timeInput.replace(/-/g, '')
    //   }
    // },
    // 'postData.end_timeInput' () {
    //   if (this.postData.end_timeInput !== '' && this.postData.end_timeInput !== null && this.postData.end_timeInput !== undefined) {
    //     this.postData.end_time = this.postData.end_timeInput.replace(/-/g, '')
    //   }
    // }
  },
  methods: {
    newReturnNowValue (paramsNowValue, deviceItem, paramsAddress) {
      let name = paramsNowValue
        try {
            if (paramsNowValue && paramsNowValue.toString().split('.')[1]  && paramsNowValue.toString().split('.')[1].length > 2) {
                name =  parseFloat(paramsNowValue).toFixed(2)
            }
        } catch (e) {
            console.log(e)
        }
      if (+name === 0) {
        name = (deviceItem.template && deviceItem.template[paramsAddress] && deviceItem.template[paramsAddress].zero) || paramsNowValue
      }
      if (+name === 1) {
        name = (deviceItem.template && deviceItem.template[paramsAddress] && deviceItem.template[paramsAddress].one) || paramsNowValue
      }
      if (+name === 2) {
        name = (deviceItem.template && deviceItem.template[paramsAddress] && deviceItem.template[paramsAddress].two) || paramsNowValue
      }
      if (+name === 3) {
        name = (deviceItem.template && deviceItem.template[paramsAddress] && deviceItem.template[paramsAddress].three) || paramsNowValue
      }
      if (+name === 4) {
        name = (deviceItem.template && deviceItem.template[paramsAddress] && deviceItem.template[paramsAddress].four) || paramsNowValue
      }
      if (+name === 5) {
        name = (deviceItem.template && deviceItem.template[paramsAddress] && deviceItem.template[paramsAddress].five) || paramsNowValue
      }
      return (name || paramsNowValue || '') + ((deviceItem.template && deviceItem.template[paramsAddress] && deviceItem.template[paramsAddress].paramsUnit) || '')
    },
    returnParams (paramsId) {
      return this.nowItem.template[paramsId] || {}
    },
    searchHisData () {
      if (this.hisStartTime && this.hisEndTime) {
        const windPoint = []
        const waterPoint = []
        const tempPoint = []
          console.log(this.signalData.template)
        for (let i in this.signalData.template) {
            console.log(this.signalData.template[i])
          if (+this.signalData.template[i].chart === 1) {
            windPoint.push(+this.signalData.template[i].paramsAddress)
          }
          if (+this.signalData.template[i].chart === 2) {
            waterPoint.push(+this.signalData.template[i].paramsAddress)
          }
          if (+this.signalData.template[i].chart === 3) {
            tempPoint.push(+this.signalData.template[i].paramsAddress)
          }
        }
        this.signliFilter = []
          let url = '/device/dataOfDeByTime?deviceId=' + this.nowItem.id + '&startTime=' + encodeURIComponent(this.hisStartTime) +'&endTime=' + encodeURIComponent(this.hisEndTime) +'&t=1635866005546'
          if (this.timeType === 1) {
              url = '/device/dataOfDeByTenMinutes?deviceId=' + this.nowItem.id + '&startTime=' + encodeURIComponent(this.hisStartTime) +'&endTime=' + encodeURIComponent(this.hisEndTime) +'&t=1635866005546'
          }
          if (this.timeType === 2) {
              url = '/device/dataOfDeByHalfHour?deviceId=' + this.nowItem.id + '&startTime=' + encodeURIComponent(this.hisStartTime) +'&endTime=' + encodeURIComponent(this.hisEndTime) +'&t=1635866005546'
          }
          if (this.timeType === 3) {
              url = '/device/dataOfDeByOneHour?deviceId=' + this.nowItem.id + '&startTime=' + encodeURIComponent(this.hisStartTime) +'&endTime=' + encodeURIComponent(this.hisEndTime) +'&t=1635866005546'
          }
        this.$axiox.post(url).then(p => {
          if (p.data) {
            let Xray = []
            let Yray = []
            let Yray2 = []
            let Yray3 = []
            let tempY = []
            for (let i in p.data) {
                // todo
                Xray.push(i)
              // if ( p.data[i].find(p => {return p.pointId == 4301 && parseInt(p.value) === 0})) {
              //   Xray.push(i)
              // }
              if (p.data[i] && p.data[i].length > 0) {
                tempY = p.data[i]
              }
            }

            const newList = JSON.parse(JSON.stringify(Xray))
            newList.sort((a, b) => {
              if (new Date(a).getTime() < new Date(b).getTime()) {
                return -1
              } else {
                return 1
              }
            })
            console.log(tempY)
            // && tempY.find(p => {return p.address == 4301 && parseInt(p.paramsValue) === 0})
            for (let i = 0; i < tempY.length; i++) {
              if (windPoint.indexOf(+tempY[i].pointId) >= 0) {
                let params = this.returnParams(tempY[i].pointId)
                let data = {
                  time: i - 3,
                  name: params.paramsName,
                  type: 'line',
                  data: []
                }
                for (let j in p.data) {
                    // todo
                    if (p.data[j][i].value > 1) {
                        data.data[newList.indexOf(j)] = p.data[j][i].value
                    }
                  // if (p.data[j][i] && p.data[j].find(p => {return p.pointId == 4301 && parseInt(p.value) === 0})) {
                  //   console.log(p.data[j][i])
                  //   if (p.data[j][i].value > 1) {
                  //     data.data[newList.indexOf(j)] = p.data[j][i].value
                  //   }
                  // }
                }
                Yray.push(data)
              }
              if (waterPoint.indexOf(+tempY[i].pointId) >= 0) {
                let params = this.returnParams(tempY[i].pointId)
                let data = {
                  time: i,
                  name: params.paramsName,
                  type: 'line',
                  data: []
                }
                for (let j in p.data) {
                    // todo
                    data.data[newList.indexOf(j)] = p.data[j][i].value
                  // if (p.data[j][i] && p.data[j].find(p => {return p.pointId == 4301 && parseInt(p.value) === 0})) {
                  //   console.log(p.data[j][i])
                  //   data.data[newList.indexOf(j)] = p.data[j][i].value
                  // }
                }
                Yray2.push(data)
              }
              if (tempPoint.indexOf(+tempY[i].pointId) >= 0) {
                let params = this.returnParams(tempY[i].pointId)
                let data = {
                  time: i,
                  name: params.paramsName,
                  type: 'line',
                  data: []
                }
                for (let j in p.data) {
                  // if (p.data[j][i] && p.data[j].find(p => {return p.pointId == 4301 && parseInt(p.value) === 0})) {
                  //   console.log(p.data[j][i])
                  //   data.data[newList.indexOf(j)] = p.data[j][i].value
                  // }
                    // todo
                    data.data[newList.indexOf(j)] = p.data[j][i].value
                }
                Yray3.push(data)
              }
            }
            console.log(Yray)
            this.option.xAxis.data = newList
            let options2 = JSON.parse(JSON.stringify(this.option))
            let options3 = JSON.parse(JSON.stringify(this.option))
            this.option.series = Yray
            options2.series = Yray2
            options3.series = Yray3
            this.signliFilter = Yray
            console.log(this.signliFilter)
            myChart = echarts.init(document.getElementById('myCharts'))
            myChart2 = echarts.init(document.getElementById('myCharts2'))
            myChart3 = echarts.init(document.getElementById('myCharts3'))
            myChart.setOption(this.option)
            myChart2.setOption(options2)
            myChart3.setOption(options3)
          }
        })
      } else {
        this.$commonJs.alertTips({
          type: 'error',
          title: '错误:',
          content: '开始时间 结束时间必填',
          stayTime: 5
        })
      }
    },
    changeSearchFilter () {
      clearTimeout(TimeOut)
      TimeOut = setTimeout(() => {
        if (this.signliFilterIndex) {
          console.log(this.signliFilterIndex)
          let tempFilter = []
          for (let i = 0; i < this.signliFilterIndex.split(',').length; i++) {
            tempFilter.push(this.signliFilter[this.signliFilterIndex.split(',')[i]])
          }
          console.log(tempFilter)
          this.option.series = tempFilter
          document.getElementById('myCharts').removeAttribute('_echarts_instance_')
          myChart = echarts.init(document.getElementById('myCharts'))
          myChart.setOption(this.option)
        } else {
          this.option.series = this.signliFilter
          document.getElementById('myCharts').removeAttribute('_echarts_instance_')
          myChart = echarts.init(document.getElementById('myCharts'))
          myChart.setOption(this.option)
        }
      }, 1000)
    },
    returnNewName (deviceItem, item) {
      return (deviceItem.template && deviceItem.template[item.paramsAddress] && deviceItem.template[item.paramsAddress].paramsName) || item.paramsAddress
    },
    // // 取值百分比
    // allPercentage (number1, number2) {
    //   return (Math.round(number1 / number2 * 10000) / 100.00)
    // },
    resetMode () {
      if (this.mode === 1) {
        this.$router.replace({
          name: 'monitorList',
          query: {
            mode: 2
          }
        })
      } else {
        this.$router.replace({
          name: 'monitorList',
          query: {
            mode: 1
          }
        })
      }
      setTimeout(() => {
        window.location.reload()
      }, 10)
    },
    prevPage () {
      if (this.nowIndex > 0) {
        this.nowIndex--
      }
    },
    nextPage () {
      if (this.nowIndex < this.showleasonData.length - 1) {
        this.nowIndex++
      }
    },
    async getParamData () {
      for (let i = 0; i < this.leasionData.length; i++) {
        if (this.leasionData[i]) {
          this.leasionData[i].paramsList = []
          this.leasionData[i].deviceList = []
          let paramsList = await this.getParamsSet(this.leasionData[i].id)
          this.leasionData[i].paramsList = paramsList
        }
      }
      this.getDevice()
    },
    getDevice () {
      let url = this.$commonJs.returnQuery('/customer/devicePage', {page: 1, size: 99999, parentId: this.lxUserInfo.parentId || ''})
      this.$axiox.cpost(url).then(msg => {
        if (msg.message === '成功') {
          for (let i = 0; i < msg.data.content.length; i++) {
            for (let j = 0; j < this.leasionData.length; j++) {
              if (msg.data.content[i].templateId === this.leasionData[j].id) {
                msg.data.content[i].paramsDataList = this.leasionData[j].paramsList
                this.leasionData[j].deviceList.push(msg.data.content[i])
              }
            }
          }

          this.getMyDeviceData()
        } else {
          this.$commonJs.alertTips({
            type: 'error',
            title: '错误:',
            content: msg.message,
            stayTime: 5
          })
        }
      }).catch(() => {
        this.$commonJs.alertTips({
          type: 'error',
          title: '获取接口错误:',
          content: '服务器报错',
          stayTime: 5
        })
      })
    },
    // async getMyDeviceData () {
    //   for (let i = 0; i < this.leasionData.length; i++) {
    //     for (let j = 0; j < this.leasionData[i].deviceList.length; j++) {
    //       let deviceData = await this.DeviceData(this.leasionData[i].deviceList[j].id)
    //       let nowDevide = JSON.parse(JSON.stringify(this.leasionData[i].deviceList[j]))
    //       for (let o = 0; o < nowDevide.paramsDataList.length; o++) {
    //         for (let k = 0; k < deviceData.length; k++) {
    //           if (nowDevide.paramsDataList[o].paramsId === deviceData[k].paramsId) {
    //             nowDevide.paramsDataList[o].paramsNowValue = deviceData[k].paramsValue
    //             nowDevide.paramsDataList[o].paramsNowAddress = deviceData[k].address
    //           }
    //         }
    //         if (deviceData.length === 0) {
    //           nowDevide.paramsDataList[o].paramsNowValue = ''
    //         }
    //       }
    //       if (!nowDevide.deviceName) {
    //         nowDevide.deviceName = ''
    //       }
    //       this.leasionData[i].deviceList[j] = nowDevide
    //     }
    //   }
    //   this.showleasonData = []
    //   this.showleasonData = JSON.parse(JSON.stringify(this.leasionData))
    //   this.$nextTick(() => {
    //     this.showLoading = false
    //   })
    //   console.log(this.showleasonData)
    //   timeInter = setTimeout(() => {
    //     this.getMyDeviceData()
    //   }, 30000)
    // },
    async getMyDeviceData () {
      this.normalData = []
      this.warningData = []
      this.timeoffData = []
      this.showDeveceList = []
      for (let i in this.deviceList) {
        let flag = 0
        if (this.deviceList[i].params && this.deviceList[i].params['4301']) {
          flag = this.deviceList[i].params['4301']
        } else {
          flag = 1
        }
        if (parseInt(flag) === 0) {
          this.normalData.push(this.deviceList[i].deviceName || '')
        } else if (parseInt(flag) === 1) {
          this.timeoffData.push(this.deviceList[i].deviceName || '')
        } else {
          this.warningData.push(this.deviceList[i].deviceName || '')
        }
      }
      this.showDeveceList = []
      for (let i in this.deviceList) {
        const data = this.deviceList[i]
        data.paramsDataList = []
        for (let j in data.params) {
          data.paramsDataList.push({
            paramsAddress: j,
            paramsNowValue: data.params[j]
          })
        }
        console.log(this.templateArray[data.templateId])
        if (!this.templateArray[data.templateId]) {
          const detail = await this.$axiox.get('/device/template/queryTemplateDetailByTemplateId?templateId=' + data.templateId + '&t=1678171596627')
          this.templateArray[data.templateId] = detail || {data: {}}
          // this.detailData.url = p.data.url
          // this.detailData.text = JSON.parse(p.data.text)
        }
        if (this.templateArray[data.templateId].data) {
            data.url = this.templateArray[data.templateId].data.url
            data.imgWidth = this.templateArray[data.templateId].data.imgWidth
            data.imgHeight = this.templateArray[data.templateId].data.imgHeight
            data.text = JSON.parse(this.templateArray[data.templateId].data.text)
        }
        this.showDeveceList.push(this.deviceList[i])
      }
      console.log(this.showDeveceList)
    },
    DeviceData (id) {
      var p = new Promise((resolve, reject) => {
        this.$axiox.post('/device/dataOfDevice?deviceId=' + id).then(msg => {
          resolve(msg.data || [])
        }).catch(() => {
          reject()
        })
      })
      return p
    },
    seeMonilist (item) {
      console.log(item)
      this.showModel = true
      this.signalData = JSON.parse(JSON.stringify(item))
      this.nowItem = JSON.parse(JSON.stringify(item))
      this.signliFilterIndex = ''
      this.signliFilter = []
      this.hisStartTime = ''
      this.hisEndTime = ''
      this.sameDtuList = []
      for (let i = 0; i < this.showleasonData.length; i++) {
        for (let j = 0; j < this.showleasonData[i].deviceList.length; j++) {
          if (this.showleasonData[i].deviceList[j].macAddress === item.macAddress && this.showleasonData[i].deviceList[j].id !== item.id) {
            this.sameDtuList.push(this.showleasonData[i].deviceList[j])
          }
        }
      }
    },
    cancelModel () {
      this.showModel = false
    },
    confirModel () {},
    getList (page) {
      this.getAllList(page)
    },
    returnClass (object) {
      if (object.integerWarning && (object.zeroName || object.oneName || object.twoName)) {
        console.log(object.integerName, (parseInt(object.paramsNowValue) === parseInt(object.integerWarning)))
        if (parseInt(object.paramsNowValue) === parseInt(object.integerWarning)) {
          return 'warning_yellow'
        }
      }
      if (object.paramType === 'INTEGER_SIXTEEN' || object.paramType === 'INTEGER_THIRTYTWO') {
        if (parseInt(object.paramsNowValue) === 0) {
          if (object.zeroColor && parseInt(object.zeroColor) === 0) {
            return 'warning_red'
          } else if (object.zeroColor && parseInt(object.zeroColor) === 1) {
            return 'greed_Nomal'
          } else if (object.zeroColor && parseInt(object.zeroColor) === 2) {
            return 'yellow_nomal'
          } else {
            return 'warning_red'
          }
        } else if (parseInt(object.paramsNowValue) === 1) {
          if (object.oneColor && parseInt(object.oneColor) === 0) {
            return 'warning_red'
          } else if (object.oneColor && parseInt(object.oneColor) === 1) {
            return 'greed_Nomal'
          } else if (object.oneColor && parseInt(object.oneColor) === 2) {
            return 'yellow_nomal'
          } else {
            return 'yellow_nomal'
          }
        } else {
          if (object.twoColor && parseInt(object.twoColor) === 0) {
            return 'warning_red'
          } else if (object.twoColor && parseInt(object.twoColor) === 1) {
            return 'greed_Nomal'
          } else if (object.twoColor && parseInt(object.twoColor) === 2) {
            return 'yellow_nomal'
          } else {
            return 'greed_Nomal'
          }
        }
      }
      let warningLine = object.integerWarning || object.floatWarning || object.timingWarning
      if (warningLine && parseFloat(object.paramsNowValue) < parseFloat(warningLine)) {
        return 'warning_red'
      }
      if (object.paramsNowValue) {
        return 'greed_Nomal'
      } else {
        return ''
      }
    },
    returnClass3 (value, template, deviceItem, tpye) {
      if (value === undefined || value === null) {
        if (tpye === 1) {
          return 'b_whiteGrey'
        } else {
          return ''
        }
      }
      if (deviceItem.params && (+deviceItem.params['4301'] === 1 || +deviceItem.params[4301] === 1) && tpye === 1) {
        return 'b_grey'
      }
      // if (deviceItem.params && (+deviceItem.params['4301'] === 0 || +deviceItem.params[4301] === 0) && tpye === 1) {
      //   return 'b_green'
      // }
      if (deviceItem.params && (+deviceItem.params['4301'] === 1 || +deviceItem.params[4301] === 1) && tpye === 2) {
        return ''
      }
      if (template) {
        if (template.upWarn) {
          if (+value >= +template.upWarn) {
            if (tpye === 1) {
              return 'b_red sharke'
            }
          }
        }
        if (template.lowWarn) {
          if (+value <= +template.lowWarn) {
            if (tpye === 1) {
              return 'b_red sharke'
            }
          }
        }
        if (template.upYu) {
          if (+value >= +template.upYu) {
            if (tpye === 1) {
              return 'b_orange sharke'
            }
          }
        }
        if (template.lowYu) {
          if (+value <= +template.lowYu) {
            if (tpye === 1) {
              return 'b_orange sharke'
            }
          }
        }
      }
      if (tpye === 1) {
        return 'b_green'
      } else {
        return ''
      }

    },
    returnClass2 (value, template, deviceItem, type) {
      if (value === undefined || value === null) {
        if (type === 1) {
          return 'c_black'
        } else {
          return ''
        }
      }
      if (deviceItem.params && (+deviceItem.params['4301'] === 1 || +deviceItem.params[4301] === 1)) {
        return 'c_grey'
      }
      if (template) {
        if (template.upWarn) {
          if (+value >= +template.upWarn) {
            if (type !== 1) {
              return 'c_red sharke'
            }
          }
        }
        if (template.lowWarn) {
          if (+value <= +template.lowWarn) {
            if (type !== 1) {
              return 'c_red sharke'
            }
          }
        }
        if (template.upYu) {
          if (+value >= +template.upYu) {
            if (type !== 1) {
              return 'c_yellow sharke'
            }
          }
        }
        if (template.lowYu) {
          if (+value <= +template.lowYu) {
            if (type !== 1) {
              return 'c_yellow sharke'
            }
          }
        }
      }
      return ''
    },
    returnBClass (object) {
      if (object.integerWarning && (object.zeroName || object.oneName || object.twoName)) {
        if (parseInt(object.paramsNowValue) === parseInt(object.integerWarning)) {
          return 'b_red'
        }
      }
      let warningLine = object.floatWarning || object.timingWarning
      if (warningLine && parseFloat(object.paramsNowValue) < parseFloat(warningLine)) {
        return 'b_red'
      }
      return ''
    },
    returnWidth (item) {
      let data = (parseInt(item.paramsNowValue) / parseInt(item.timingIni || item.paramsNowValue) * 100).toFixed(2)
      if (data < 0) {
        return 0
      }
      if (data > 100) {
        return 100
      }
      return data
    },
    replaceHeng (value) {
      return value.replace(/-/g, '<br />')
    },
    returnNowValue (deviceItem, item) {
      let name = item.paramsNowValue
      if (+name === 0) {
        name = (deviceItem.template && deviceItem.template[item.paramsAddress] && deviceItem.template[item.paramsAddress].zero) || item.paramsNowValue
      }
      if (+name === 1) {
        name = (deviceItem.template && deviceItem.template[item.paramsAddress] && deviceItem.template[item.paramsAddress].one) || item.paramsNowValue
      }
      if (+name === 2) {
        name = (deviceItem.template && deviceItem.template[item.paramsAddress] && deviceItem.template[item.paramsAddress].two) || item.paramsNowValue
      }
      if (+name === 3) {
        name = (deviceItem.template && deviceItem.template[item.paramsAddress] && deviceItem.template[item.paramsAddress].three) || item.paramsNowValue
      }
      if (+name === 4) {
        name = (deviceItem.template && deviceItem.template[item.paramsAddress] && deviceItem.template[item.paramsAddress].four) || item.paramsNowValue
      }
      if (+name === 5) {
        name = (deviceItem.template && deviceItem.template[item.paramsAddress] && deviceItem.template[item.paramsAddress].five) || item.paramsNowValue
      }
      return (name || item.paramsNowValue) + (deviceItem.template && deviceItem.template[item.paramsAddress] && deviceItem.template[item.paramsAddress].paramsUnit)
    },
    getTemplate () {
      const url = this.$commonJs.returnQuery('/customer/templates', { page: 1, size: 99999 })
      this.$axiox.cpost(url).then((msg) => {
        const promiseAll = []
        for (let i = 0; i < msg.data.content.length; i++) {
          promiseAll.push(this.getParamsSet(msg.data.content[i].id))
          this.templateList[msg.data.content[i].id] = {}
        }
        Promise.all(promiseAll).then(e => {
          console.log(e)
          console.log(this.templateList)
          for (let i = 0; i < e.length; i++) {
            for (let j = 0; j < e[i].length; j++) {
              this.templateList[e[i][0].templateId][e[i][j].paramsAddress] = e[i][j]
            }
          }
          this.getDevideList(e)
        })
      })
    },
    getDevideList () {
      const url = this.$commonJs.returnQuery('/customer/devicePage', { page: 1, size: 99999, parentId: this.lxUserInfo.parentId || '' })
      this.$axiox.cpost(url).then((msg) => {
        let newList = []
        if (this.lxUserInfo.devices) {
          for (let i = 0; i < msg.data.content.length; i++) {
            if (this.lxUserInfo.devices.indexOf(msg.data.content[i].id) >=0) {
              newList.push(msg.data.content[i])
            }
          }
        } else {
          newList = msg.data.content
        }
        for (let i = 0; i < newList.length; i++) {
          this.deviceList[newList[i].macAddress] = newList[i]
          this.deviceList[newList[i].macAddress].template = this.templateList[this.deviceList[newList[i].macAddress].templateId]
        }
      })
      // console.log(myData.deviceList, 'dev');
    },
    getParamsSet (id) {
      var p = new Promise((resolve, reject) => {
        this.$axiox.cpost('/customer/paramsList?templateId=' + id || '').then(msg => {
          if (msg.message === '成功') {
            resolve(msg.data)
          } else {
            reject(msg.data)
          }
        }).catch(msg => {
          reject(msg.data)
        })
      })
      return p
    }
  },
  components: {
  }
}
</script>

<style scoped>
.recordlbody {}
.recordlbody nav{
  font-size: 18px;
  margin: 10px 0;
}
.recordlbody dl dd {
  position: relative;
  height: 38px;
}
.recordlbody dl dt{
  margin: 10px 0;
}
.recordlbody dl dd > div{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: calc(100% - 2px);
}
.recordlbody dl dd > div:first-child{
  height: 36px;
  background: #4062e0;
  border-radius: 100px;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
}
.recordlbody dl dd > div:last-child{
  height: 36px;
  overflow: hidden;
  border: 1px solid #819cf8;
  z-index: 10;
  border-radius: 100px;
}
.recordlbody dl dd ul{
  width: 100%;
  padding: 10px 0;
  display: inline-block;
  white-space: normal;
}
.recordlbody dl dd ul li{
  width: calc(32% - 10px);
  display: inline-block;
  padding: 0 5px;
}
.userBasInfo > ul li {
  display: inline-block;
  vertical-align: middle;
  margin-top: 0;
  margin-bottom:0;
  padding: 10px;
}
.widthli_50 > li {
  width: calc(50% - 28px);
}
.widthli_50 > li:nth-child(2n) {
  margin-left: 10px;
  border-left: 1px solid #809cf5;
}
</style>
